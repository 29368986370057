import { FormFieldTypes } from "../../data/formFieldType";


const ckEditorFormData = {
    name: "CK Editor Form Data",
    initialState: {
        test: null,
    },
    formFields: [
        {
            id: "test",
            lable: "CK Editor",
            Field_Name: "CK Editor",
            fieldType: FormFieldTypes.CKEDITOR,
            dataField: "test",
            fieldSetting: {
                // placeholder: "Enter Details",
                allowSpace: true,
                // maxLength: 1000,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12  mb-3",
            },
        },
    ],
};

export default ckEditorFormData;