import React, { useRef, useState } from "react";
import CardSection from "../../components/ui/card/CardSection";
import FormCreator from "../../components/Forms/FormCreator";
import {
  createEditImageUploaderData,
  createEditResourcesData,
  resourcesDetailsData,
  resourcesDetailsDataImageUploaderData,
} from "./components/CreateRditResourcesData";
import Buttons from "../../components/ui/button/Buttons";
import { useNavigate } from "react-router-dom";
const CreateEditResources = () => {
  const CreateEditResourcesDataFormRef = useRef();
  const ResourcesDetailsDataFormRef = useRef();
  const ResourcesDetailsDataImageUploaderDataFormRef = useRef();

  const [createEditResourcesDataForm] = useState(createEditResourcesData);
  const [createEditImageUploaderDataForm] = useState(
    createEditImageUploaderData
  );
  const [resourcesDetailsDataForm] = useState(resourcesDetailsData);

  const navigate = useNavigate();

  const handlePreview = () => {
    navigate("/previewResources");
  };
  return (
    <div>
      <div className="mb-4">
        <CardSection cardTitle="Basic Information">
          <div className="row">
            <div className="col-8">
              <div className="row">
                <FormCreator
                  ref={CreateEditResourcesDataFormRef}
                  {...createEditResourcesDataForm}
                // onActionChange={formActionHandler}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="row justify-content-center">
                <FormCreator
                  ref={CreateEditResourcesDataFormRef}
                  {...createEditImageUploaderDataForm}
                // onActionChange={formActionHandler}
                />
              </div>
            </div>
          </div>
        </CardSection>
      </div>
      <div className="mb-4">
        <CardSection cardTitle="Resources Details">
          <div className="row">
            <div className="col-8">
              <div className="row">
                <FormCreator
                  ref={ResourcesDetailsDataFormRef}
                  {...resourcesDetailsDataForm}
                // onActionChange={formActionHandler}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="row justify-content-center">
                <FormCreator
                  ref={ResourcesDetailsDataImageUploaderDataFormRef}
                  {...resourcesDetailsDataImageUploaderData}
                // onActionChange={formActionHandler}
                />
              </div>
            </div>
          </div>
        </CardSection>
      </div>
      <div className="row mb-3">
        <div className="common-custom-pagination mt-5">
          <div className="">
            <Buttons
              buttonText="Back"
              buttonTypeClassName="back-btn"
            // onClick={() => handleSearchMemberDirectory()}
            />
          </div>
          <div className="right-btn d-flex align-content-center">
            <Buttons
              buttonText="Preview"
              buttonTypeClassName="themecolor-hover-btn mr-5"
              onClick={() => handlePreview()}
            />
            <Buttons
              buttonText="Save"
              buttonTypeClassName="success-btn"
            // onClick={() => handlePreview()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEditResources;
