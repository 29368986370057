import React, { useEffect, useRef, useState } from "react";
import MolGrid from "../../../components/Grid/MolGrid";
import { ApproveCpdSubmissionConfig } from "./component/ApproveCpdSubmissionTableData";
import { useNavigate } from "react-router-dom";
import { encryptAESID } from "../../../utils/Encryption";
import { useGetCPDSubmissionTaskListMutation } from "../../../app/services/CPD/ApproveCPDSubmissionApi";

const ApproveCpdSubmissionTable = ({ paginationReq }) => {
  const ApproveCpdSubmissionTableRef = useRef();
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState();

  const [getCPDSubmissionTaskList,
    { isSuccess: isCPDSumbmissionTaskListSuccess,
      isLoading: isCPDSumbmissionTaskListFetching,
      data: cpdSumbmissionTaskListResponse },
  ] = useGetCPDSubmissionTaskListMutation();


  const [paginationRequest, setPaginationRequest] = useState({
    pageNo: 1,
    pageSize: 10,
    endPage: 100,
    SearchText: "",
    gmapNumber: "",
    submissionStartDate: "",
    submissionEndDate: "",
    totalCount: 0,
    StatusId: null
  });


  useEffect(() => {
    setDataSource([]);
    setPaginationRequest({ ...paginationRequest, ...paginationReq })
    getCPDSubmissionTaskList(paginationReq)
  }, [paginationReq])


  useEffect(() => {
    if (!isCPDSumbmissionTaskListFetching && isCPDSumbmissionTaskListSuccess && cpdSumbmissionTaskListResponse) {
      setPaginationRequest({ ...paginationRequest, totalCount: cpdSumbmissionTaskListResponse.totalCount })
      setDataSource(cpdSumbmissionTaskListResponse.itemList)
      console.log({ cpdSumbmissionTaskListResponse })
    }
  }, [isCPDSumbmissionTaskListFetching, isCPDSumbmissionTaskListSuccess, cpdSumbmissionTaskListResponse]);

  useEffect(() => {
    getCPDSubmissionTaskList(paginationRequest)
  }, [])

  const handlePageChange = ({ pageNumber, pageSize }) => {
    getCPDSubmissionTaskList({ ...paginationRequest, pageNo: pageNumber, pageSize: pageSize })
  }

  const handleEditClick = (data) => {
    navigate(`/cpdSubmission/${encryptAESID(data.gmapMemberId, false)}`)
  }

  const actionHandler = {
    UPDATE: handleEditClick,
  }


  return (
    <div>
      <MolGrid
        ref={ApproveCpdSubmissionTableRef}
        configuration={ApproveCpdSubmissionConfig}
        dataSource={dataSource}
        allowPagination={true}
        isLoading={isCPDSumbmissionTaskListFetching}
        pagination={paginationRequest}
        onPageChange={handlePageChange}
        onActionChange={actionHandler}
      />
    </div>
  );
};

export default ApproveCpdSubmissionTable;
