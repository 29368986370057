import React, { useEffect, useRef } from "react";
import MolGrid from "../../../components/Grid/MolGrid";
import { MemberDirectoryTableDataConfig } from "./MemberDirectoryTableData";

const MemberDirectoryTable = ({ datasource, pagination, onPageChange, handleViewMember, handleDeleteMember }) => {
  const MemberDirectoryListRef = useRef();
  const handleDeleteClick = (data) => {
    let key = data?.gmapMemberId;
    handleDeleteMember(key)
  }
  const handleViewClick = (data) => {
    handleViewMember(data)
  }

  const handlePageChange = (newPage) => {
    onPageChange(newPage)
  }

  const actionHandler = {
    DELETE: handleDeleteClick,
    View: handleViewClick,
  };

  return (
    <div>
      <MolGrid
        ref={MemberDirectoryListRef}
        configuration={MemberDirectoryTableDataConfig}
        dataSource={datasource}
        allowPagination={true}
        pagination={pagination}
        onPageChange={handlePageChange}
        onActionChange={actionHandler}
      />
    </div>
  );
};

export default MemberDirectoryTable;
