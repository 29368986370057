import React, { useRef, useState } from 'react'
import Buttons from '../../../components/ui/button/Buttons';
import { addEditMemberModelData, addEditProfileImageData } from './AddEditMemberModel.Data';
import FormCreator from '../../../components/Forms/FormCreator';

const AddEditMemberModel = (props) => {
    // CPD Information form creator
    const AddEditMemberModelRef = useRef();
    const [addEditMemberModelForm, SetaddEditMemberModel] = useState(addEditMemberModelData);
    const handleClear = () => {
        const updatedForm = { ...addEditMemberModelForm };
    }

    const ResourcesDetailsDataImageUploaderDataFormRef = useRef();

    // Function to download Employer Contract file
    const handleDownload = (data) => {
        if (props.onDownload) {
            props.onDownload(data);
        }
    }
    const formActionHandler = {
        CLEAR: handleClear,
        DOWNLOAD: handleDownload,
    }

    return (
        <div>
            <div className='row'>
                <div className='add-edit-member-modal'>
                    <div className='row'>
                        <div className='col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3'>
                            <FormCreator
                                ref={AddEditMemberModelRef}
                                {...addEditMemberModelForm}
                            // onActionChange={formActionHandler} 
                            />
                        </div>
                        <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3">
                            <div className="row justify-content-center">
                                <FormCreator
                                    ref={ResourcesDetailsDataImageUploaderDataFormRef}
                                    {...addEditProfileImageData}
                                // onActionChange={formActionHandler}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end column-gap-4 mt-4'>
                        <Buttons
                            buttonText="Cancel"
                            buttonTypeClassName="back-btn"
                        />
                        <Buttons
                            buttonText="Save"
                            buttonTypeClassName="success-btn"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddEditMemberModel