import { FormFieldTypes } from "../../../../../data/formFieldType";

export const bcaGreenMarkProjectData = {
    name: 'BcaGreenMarkProject',
    initialState: {
        projectName: '',
        greenMarkProjectNumber: '',
        dateofLetterofAward: '',
        // isGenderMale: true,
        awardRating: "",
        gmapGreenMarkCategoryId: "",
        developerBuildingOwner: "",
        describeYourRoleintheProject: '',
        uploadEndorsementAttached: "",
        uploadGreenMarkCategory: "",
        CpdPoints: "",
        remarks: "",
        processedBy: "",
        cpdPointsAwarded: "",
        notes: "",
    },
    formFields: [

        {
            id: "projectInformation",
            lable: "",
            Field_Name: "projectInformation",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "projectInformation",
            fieldSetting: {
                formTitle: "Project Information",
            },
            style: {
                containerCss: "col-lg-12 mb-4 text-left",
            },
        },
        {
            id: "projectName",
            lable: "Project Name",
            Field_Name: "Project Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "projectName",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        {
            id: "greenMarkProjectNumber",
            lable: "Green Mark Project Number",
            Field_Name: "Green Mark Project Number",
            fieldType: FormFieldTypes.INPUT,
            dataField: "greenMarkProjectNumber",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "dateofLetterofAward",
            lable: "Date of Letter of Award",
            Field_Name: "Date of Letter of Award",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "dateofLetterofAward",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        {
            id: "awardRating",
            lable: "Award Rating",
            Field_Name: "Award Rating",
            fieldType: FormFieldTypes.SELECT,
            dataField: "awardRating",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        {
            id: "gmapGreenMarkCategoryId",
            lable: "Green Mark Category",
            Field_Name: "Green Mark Category",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmapGreenMarkCategoryId",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        {
            id: "developerBuildingOwner",
            lable: "Developer/Building Owner",
            Field_Name: "Developer/Building Owner",
            fieldType: FormFieldTypes.INPUT,
            dataField: "developerBuildingOwner",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "describeYourRoleintheProject",
            lable: "Describe your role in the project",
            Field_Name: "Describe your role in the project",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "describeYourRoleintheProject",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                maxLength: 4000,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-12 col-12 input-margin-space",
            },
        },
        {
            id: "uploadEndorsementAttached",
            lable: 'Upload Clients Letter of Endorsement Attached',
            subLable: "(please upload in pdf format max 2mb)",
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'uploadEndorsementAttached',
            fieldSetting: {
                // presentFileName: null,
                isDownloadable: true,
                acceptedFiles: '.pdf',
                maxFileSizeInKB: 2048,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        {
            id: "uploadGreenMarkCategory",
            lable: 'Upload Green Mark Category',
            subLable: "(please upload in pdf format max 2mb)",
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'uploadGreenMarkCategory',
            fieldSetting: {
                // presentFileName: null,
                isDownloadable: true,
                acceptedFiles: '.pdf',
                maxFileSizeInKB: 2048,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        // {
        //     fieldType: FormFieldTypes.SEPARATOR,
        //     style: {
        //         containerCss: "separation-line pl-0 pr-0",
        //     },
        // },
        {
            id: "CpdPoints",
            lable: "CPD Points",
            Field_Name: "CPD Points",
            fieldType: FormFieldTypes.INPUT,
            dataField: "CpdPoints",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "remarks",
            lable: "Remarks",
            Field_Name: "Remarks",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "remarks",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                maxLength: 4000,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-12 col-12 input-margin-space",
            },
        },
        {
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line pl-0 pr-0",
            },
        },
        {
            id: "sgbcSection",
            lable: "",
            Field_Name: "sgbcSection",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "sgbcSection",
            fieldSetting: {
                formTitle: "SGBC Section",
            },
            style: {
                containerCss: "col-lg-12 mb-4 text-left",
            },
        },
        {
            id: "processedBy",
            lable: "Processed by",
            Field_Name: "Processed by",
            fieldType: FormFieldTypes.SELECT,
            dataField: "processedBy",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6  "
            }
        },
        {
            id: "cpdPointsAwarded",
            lable: "CPD Points Awarded",
            Field_Name: "CPD Points Awarded ",
            fieldType: FormFieldTypes.SELECT,
            dataField: "cpdPointsAwarded",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6"
            }
        },
        {
            id: "notes",
            lable: "Notes",
            Field_Name: "Notes",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "notes",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                maxLength: 4000,
            },
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3",
            },
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}