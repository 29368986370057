// dropdownUtils.js

import { APIUrl } from "../../../../../utils/API/fetchBaseQuery";
import { addEditMemberModelData } from "../../../../membership/component/AddEditMemberModel.Data";
import { academicQualificationData } from "../AcademicQualification/AcademicQualification.Data";
import { AccountInformationFormData } from "../AccountInformation/AccountInformation.Data";
import { CurrentEmpData, careerHistoryData } from "../CareerHistory/CareerHistory.data";
import { handleConditionBaseFields } from "../CareerHistory/utils/CareerHistoryFormUtils";
import { certificateCourseData } from "../CertificateCourse/CertificateCourse.Data";
import { gmapMembershipData } from "../GmapMembership/GmapMembership.Data";
import { greenMarkProjectData } from "../GreenMarkProject/GreenMarkProject.Data";
import { personalParticularsData } from "../PersonalParticulars/PersonalParticulars.Data";

export const setFieldParameter = (apiResponse, section) => {
    const defaultCss = "col-md-4 my-4";
    const hiddenCss = "col-md-4 mb-2 d-none";
    const careerHistoryIndex = careerHistoryData.formFields.findIndex(field => field.id === "careerHistoryTable");
    const courseHistoryIndex = certificateCourseData.formFields.findIndex(field => field.id === "certificateHistoryTable");
    const greenMarkProjectIndex = greenMarkProjectData.formFields.findIndex(field => field.id === "greenMarkProjectTable");
    const pathWayField = certificateCourseData.formFields.find((item) => item.id === "gmaPathwayTypeID");
    const gmapCertificateTypeField = certificateCourseData.formFields.find((item) => item.id === "gmapCertificateTypeId");
    const gmaapCertificateTypeField = certificateCourseData.formFields.find((item) => item.id === "gmaapCertificateTypeId");
    const gmapPathwayTypeIDField = greenMarkProjectData.formFields.find((item) => item.id === "gmapPathwayTypeID");
    const gmaapPathwayTypeIDField = greenMarkProjectData.formFields.find((item) => item.id === "gmaapPathwayTypeID");

    if (section === "memberDetails") {
        if (apiResponse?.gmapProfessionTypeId) {
            handleConditionBaseFields(apiResponse?.gmapProfessionTypeId, "CurrentOtherProfessionName");
        }
        personalParticularsData.initialState.salutationsID = apiResponse.gmapSalutationId;
        personalParticularsData.initialState.fullName = apiResponse.fullName;
        personalParticularsData.initialState.primaryEmail = apiResponse.primaryEmail;
        personalParticularsData.initialState.secondaryEmail = apiResponse.secondaryEmail;
        personalParticularsData.initialState.dateOfBirthDate = apiResponse.dateOfBirth;
        personalParticularsData.initialState.isGenderMale = apiResponse.isGenderMale ? "male" : "female";
        personalParticularsData.initialState.mobilePhone = apiResponse.mobilePhone;
        personalParticularsData.initialState.addressLine1 = apiResponse.addressLine1;
        personalParticularsData.initialState.addressLine2 = apiResponse.addressLine2;
        personalParticularsData.initialState.addressLine3 = apiResponse.addressLine3;
        personalParticularsData.initialState.postalCode = apiResponse.postalCode;
        personalParticularsData.initialState.comments = apiResponse.professionalProfileDescription;
        personalParticularsData.initialState.nationalityID = apiResponse.gmapNationalityId;
        personalParticularsData.initialState.countryOfResID = apiResponse.countryofResidenceId;
        personalParticularsData.initialState.profilePhotoFile = apiResponse.professionalProfilPhotoName;
        personalParticularsData.initialState.linkedinAdd = apiResponse.linkedInAddressOrProfessionalHomepage;
        personalParticularsData.initialState.competenciesID = apiResponse.gmapCompetenciesId?.split(",")?.map(Number);
        personalParticularsData.initialState.otherProfessionQualificationID = apiResponse.gmapOtherProfessionalQualificationId?.split(",")?.map(Number);
        personalParticularsData.initialState.password = apiResponse.passwordSalt;
        personalParticularsData.initialState.confirmPassword = apiResponse.hashedPassword;
        academicQualificationData.initialState.graduateYearID = apiResponse.graduateYearId;
        academicQualificationData.initialState.highestQualificationID = apiResponse.gmapHighestRelevantQualificationId;
        academicQualificationData.initialState.academicQualification = apiResponse.academicInstitution;
        academicQualificationData.initialState.fieldOfStudy = apiResponse.fieldOfStudy;
        academicQualificationData.initialState.academicQualificationCertificationFile = apiResponse.academicCertificateName;

        CurrentEmpData.initialState.isUnemployed = apiResponse?.isCurrentlyEmployed || false;
        CurrentEmpData.initialState.CurrentCompanyName = apiResponse?.currentCompanyName;
        CurrentEmpData.initialState.CurrentPeriodFrom = apiResponse?.currentPeriodFrom;
        //CurrentEmpData.initialState.CurrentPeriodTo = apiResponse?.currentPeriodTo;
        CurrentEmpData.initialState.CurrentDesignation = apiResponse?.currentDesignation;
        CurrentEmpData.initialState.GMAPProfessionTypeId = apiResponse?.gmapProfessionTypeId?.value || apiResponse?.gmapProfessionTypeId;

        CurrentEmpData.initialState.CurrentOtherProfessionName = apiResponse?.otherProfessionName;
        if (careerHistoryIndex !== -1) {
            const updatedFormData = { ...careerHistoryData };
            updatedFormData.formFields[careerHistoryIndex].fieldSetting.dataSource = apiResponse.gmapCareerHistoryList;
        }

        pathWayField.style.containerCss = hiddenCss;
        pathWayField.validation = [];

        //hide and show fields  
        //pathWayField.style.containerCss = (apiResponse.gmapApplicationTypeId === 1) ? defaultCss : hiddenCss;
        gmapCertificateTypeField.style.containerCss = (apiResponse.gmapApplicationTypeId === 2) ? "col-md-6 mb-2" : hiddenCss;
        gmaapCertificateTypeField.style.containerCss = (apiResponse.gmapApplicationTypeId === 3) ? "col-md-6 mb-2" : hiddenCss;
        gmapPathwayTypeIDField.style.containerCss = (apiResponse.gmapApplicationTypeId === 2) ? defaultCss : hiddenCss;
        gmaapPathwayTypeIDField.style.containerCss = (apiResponse.gmapApplicationTypeId === 3) ? defaultCss : hiddenCss;
        //add and remove validation on fields

        //pathWayField.validation = (apiResponse.gmapApplicationTypeId === 1) ? [{ type: "require" }] : [];
        gmapCertificateTypeField.validation = (apiResponse.gmapApplicationTypeId === 2) ? [{ type: "require" }] : [];
        gmaapCertificateTypeField.validation = (apiResponse.gmapApplicationTypeId === 3) ? [{ type: "require" }] : [];
        gmapPathwayTypeIDField.validation = (apiResponse.gmapApplicationTypeId === 2) ? [{ type: "require" }] : [];
        gmaapPathwayTypeIDField.validation = (apiResponse.gmapApplicationTypeId === 3) ? [{ type: "require" }] : [];

        if (courseHistoryIndex !== -1) {
            const updatedFormData = { ...certificateCourseData };
            let tempCertificateListResponse = apiResponse.gmapCertificationCourseList
            tempCertificateListResponse = tempCertificateListResponse.map((obj) => {
                return { ...obj, certificateLink: `${APIUrl}/Common/getPDF?type=CertificateMarketRefresherCourse&fileName=${obj.certificateAttachment}` }
            })
            updatedFormData.formFields[courseHistoryIndex].fieldSetting.dataSource = tempCertificateListResponse;
        }
        if (apiResponse?.gmapApplicationTypeId === 1) {
            const updatedFormData = { ...certificateCourseData };
            updatedFormData.initialState.gmaPathwayTypeID = 2;
        }
        if (greenMarkProjectIndex !== -1) {
            const updatedFormData = { ...greenMarkProjectData };
            let tempGMProjectListData = apiResponse.gmapGreenMarkProjectList
            tempGMProjectListData = tempGMProjectListData.map((obj) => {
                return {
                    ...obj,
                    clientLetterOfEndorsementLink: `${APIUrl}/Common/getPDF?type=LetterofEndorsement&fileName=${obj.clientLetterOfEndorsement}`,
                    projectLetterOfAwardLink: `${APIUrl}/Common/getPDF?type=GreenMarkProjectLetterOfAward&fileName=${obj.greenMarkProjectLetterOfAward}`
                }
            })

            if (apiResponse.gmapApplicationTypeId === 3) {
                tempGMProjectListData = tempGMProjectListData.map((obj) => {
                    return {
                        ...obj,
                        proofofJobDescriptionLink: `${APIUrl}/Common/getPDF?type=ProofOfJobDesc&fileName=${obj.proofofJobDescription}`,
                        proofofsignificantinvolvementLink: `${APIUrl}/Common/getPDF?type=ProofOfSignificantInvolvement&fileName=${obj.proofofsignificantinvolvement}`
                    }
                })
            }
            updatedFormData.formFields[greenMarkProjectIndex].fieldSetting.dataSource = tempGMProjectListData;
        }
    } else if (section === "accountInformation") {
        AccountInformationFormData.initialState.accountType = apiResponse.gmapApplicationTypeId;
        AccountInformationFormData.initialState.joinedDate = apiResponse.submissionDate;
        AccountInformationFormData.initialState.accountstatus = apiResponse.statusId;
        AccountInformationFormData.initialState.gmapStatus = apiResponse.subscriptionStatusId
        AccountInformationFormData.initialState.billingCategory = apiResponse.billingCategoryId;
        AccountInformationFormData.initialState.gmapNumber = apiResponse.gmapNumber;
        AccountInformationFormData.initialState.subscriptionStartDate = apiResponse.qualificationStartDate;
        AccountInformationFormData.initialState.subscriptionEndDate = apiResponse.qualificationEndDate;
        AccountInformationFormData.initialState.updatedBy = apiResponse.updatedBy;
        AccountInformationFormData.initialState.updatedDate = apiResponse.updatedDate;
        AccountInformationFormData.initialState.appeal = apiResponse.isAppeal;
        AccountInformationFormData.initialState.qualify = apiResponse.isQualify;
        AccountInformationFormData.initialState.voidLateFee = apiResponse.isvoidLateFee;
        AccountInformationFormData.initialState.pendingExpiry = apiResponse.isPendingExpiry;
    }
    else if (section === "viewMemberDirectory") {
        addEditMemberModelData.initialState.name = apiResponse.gmapContactName;
        addEditMemberModelData.initialState.companyName = apiResponse.companyName;
        addEditMemberModelData.initialState.gmapNumber = apiResponse.mobilePhone;
        addEditMemberModelData.initialState.gmapTier = apiResponse.gmapApplicationType;
        addEditMemberModelData.initialState.areaofCompetencies = apiResponse.gmapCompetenciesId?.split(",").map(Number);
        addEditMemberModelData.initialState.typeofProfessionalQualification = apiResponse.gmapOtherProfessionalQualificationId?.split(",").map(Number);
        addEditMemberModelData.initialState.linkedin = apiResponse.linkedInAddressOrProfessionalHomepage;
        addEditMemberModelData.initialState.description = apiResponse.professionalProfileDescription;

    }
    else if (section === "membershipAccResponse") {
        gmapMembershipData.initialState.accountType = apiResponse.gmapApplicationTypeId;
        gmapMembershipData.initialState.billingCategory = apiResponse.billingCategoryId;
        gmapMembershipData.initialState.subscriptionStartDate = apiResponse.qualificationStartDate;
        gmapMembershipData.initialState.subscriptionEndDate = apiResponse.qualificationEndDate;
        gmapMembershipData.initialState.updatedBy = apiResponse.updatedBy;
        gmapMembershipData.initialState.lastUpdatedDate = apiResponse.updatedAt;
        gmapMembershipData.initialState.penaltyCharge = apiResponse.isPendingExpiry;
        gmapMembershipData.initialState.qualify = apiResponse.isQualify;
        gmapMembershipData.initialState.pendingExpiry = apiResponse.ispenaltyCharge;

    }

};
