import { FormFieldTypes } from "../../../data/formFieldType";


export const memberDirectoryData = {
    name: 'MemberDirectoryData',
    initialState: {
        name: '',
        gmapNumber: '',
        otherProfessionQualificationID: '',
        competenciesID: '',
    },
    formFields: [
        {
            id: "name",
            lable: "Name",
            Field_Name: "Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "name",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3",
            },
        },


        {
            id: "gmapNumber",
            lable: "GMAP Number",
            Field_Name: "GMAP Number",
            fieldType: FormFieldTypes.INPUT,
            dataField: "gmapNumber",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },

        {
            id: "otherProfessionQualificationID",
            lable: "Type of Professional Qualification",
            Field_Name: "Type of Professional Qualification",
            fieldType: FormFieldTypes.SELECT,
            dataField: "otherProfessionQualificationID",
            fieldSetting: {
                placeholder: " ",
                options: [],
                isMultiSelect: true
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12"
            }
        },
        {
            id: "competenciesID",
            lable: "Area of Competencies",
            Field_Name: "Area of Competencies",
            fieldType: FormFieldTypes.SELECT,
            dataField: "competenciesID",
            fieldSetting: {
                placeholder: " ",
                options: [],
                isMultiSelect: true
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 "
            }
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}