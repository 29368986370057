/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { gMapQualificationData } from "./GMapQualification.data";
import FormCreator from "../../../../../components/Forms/FormCreator";
import Buttons from "../../../../../components/ui/button/Buttons";
import { useGMapMemberSignUpMutation } from "../../../../../app/services/common/commonApi";
import { CreateIndividualMembersContext } from "../../../../../pages/gmap/viewIndividualMembers/createIndividualMembers";
import ToastService from "../../../../../services/toastService/ToastService";

const GMapQualificationForm = forwardRef((props, ref) => {
    const gmapQualificationRef = useRef();
    const { newMemberData, setContextData } = useContext(CreateIndividualMembersContext);
    const [gMapQualificationForm, setGMapQualificationForm] = useState(gMapQualificationData);
    const [gMapMemberSignUp,
        { isSuccess: isNewMemberSignup,
            isLoading: isNewMemberLoading,
            data: addNewMember }
    ] = useGMapMemberSignUpMutation();
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const qualificationData = gmapQualificationRef.current.getFormData();
                return qualificationData;
            }
            return null;
        }
    }));
    useEffect(() => {
        if (!isNewMemberLoading && isNewMemberSignup && addNewMember) {
            if (!addNewMember.hasError && addNewMember.errorMessage === "") {
                setContextData(prevData => ({ ...prevData, gMAPMemberID: addNewMember.keyId }));
                ToastService.success("Application Type Save Successfully");
            }
        }
    }, [isNewMemberLoading, isNewMemberSignup, addNewMember]);

    const handleSave = () => {
        const qualificationData = gmapQualificationRef.current.getFormData();
        let request = {
            GMAPMemberId: 0,
            GMAPApplicationTypeId: qualificationData?.applicationTypeID?.value || null,
        }
        gMapMemberSignUp(request);
    }
    const handleDropdownChange = (data, dataField) => {
        if (props.handleDropdownChange) {
            setContextData(prevData => ({ ...prevData, applicationTypeID: data.value }));
            props.handleDropdownChange(data, dataField)
        }
    }
    const formActionHandler = {
        DDL_CHANGED: handleDropdownChange
    }

    return (
        <React.Fragment>
            <FormCreator ref={gmapQualificationRef} {...gMapQualificationForm} onActionChange={formActionHandler} />
            {newMemberData.gMAPMemberID === 0 && <div className='col-lg-6 mt-4'>
                <Buttons
                    buttonText="Save"
                    buttonTypeClassName="outline-btn"
                    isLoading={isNewMemberLoading}
                    onClick={handleSave}
                />
            </div>}
        </React.Fragment>
    )
})

export default GMapQualificationForm