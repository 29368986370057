import React, { Suspense } from 'react'
import EventTabWrapper from './component/EventTabWrapper'
import PageLoader from '../../components/ui/pageLoader/PageLoader'
import { BasicDetailContextProvider } from './utils/BasicDetailContext'

const EventMangementForm = () => {
    return (
        <Suspense fallback={<PageLoader />}>
            <BasicDetailContextProvider>
                <EventTabWrapper />
            </BasicDetailContextProvider>
        </Suspense>
    )
}

export default EventMangementForm