import React from 'react'
import CpdRenewalCycleForm from '../../../features/cpd/cpdRenewalCycle/CpdRenewalCycleForm'
const CpdRenewalCycle=() => {
    return (
        <>
            <CpdRenewalCycleForm />

        </>
    )
}

export default CpdRenewalCycle