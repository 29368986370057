import { CurrentEmpData, careerHistoryData } from "../CareerHistory.data";

export const updateContainerCssForPeriodTo = (newCssValueForPeriodTo, newCssValueForIsCurrent, newValidationValueForPeriodTo, formData) => {
    // Find the index of the periodTo field in formFields array

    const periodFromIndex = careerHistoryData.formFields.findIndex(field => field.id === "periodFrom");
    const periodToIndex = careerHistoryData.formFields.findIndex(field => field.id === "periodTo");
    const isCurrentToIndex = careerHistoryData.formFields.findIndex(field => field.id === "isCurrent");
    if (periodToIndex !== -1) {
        // Create a copy of careerHistoryData to avoid mutating state directly
        const updatedFormData = { ...careerHistoryData };

        // Update the containerCss value for periodTo field
        updatedFormData.formFields[periodToIndex].style.containerCss = newCssValueForPeriodTo;
        updatedFormData.formFields[isCurrentToIndex].style.containerCss = newCssValueForIsCurrent;
        updatedFormData.formFields[periodFromIndex].validation = newValidationValueForPeriodTo;
        updatedFormData.formFields[periodToIndex].validation = newValidationValueForPeriodTo;

        return updatedFormData;
    } else {
        console.error("Field with id 'periodTo' not found.");
        return formData;
    }
};

const defaultCss = "col-md-6 mb-2";
const hiddenCss = "col-md-6 mb-2 d-none";
export const handleConditionBaseFields = (value, fieldId) => {
    const css = value === 13 ? defaultCss : hiddenCss;
    const dataFile = fieldId === "otherProfessionName" ? careerHistoryData : CurrentEmpData;

    const fieldIndex = dataFile.formFields.findIndex(field => field.id === fieldId);
    if (fieldIndex !== -1) {
        const updatedFormData = { ...dataFile };
        updatedFormData.formFields[fieldIndex].style.containerCss = css;
        return updatedFormData;
    } else {
        console.error("Field not found.");
        return false;
    }
};