import { FormFieldTypes } from "../../../../../data/formFieldType";

export const accreditedCpdEventsData = {
    name: 'AccreditedCpdEvents',
    initialState: {
        projectName: '',
        greenMarkProjectNumber: '',
        dateofLetterofAward: '',
        awardRating: "",
        gmapGreenMarkCategoryId: "",
        developerBuildingOwner: "",
        describeYourRoleintheProject: '',
        endorsementAttachedFile: "",
        CpdPoints: "",
        remarks: "",
        processedBy: "",
        cpdPointsAwarded: "",
        notes: "",
    },
    formFields: [
        {
            id: "projectInformation",
            lable: "",
            Field_Name: "projectInformation",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "projectInformation",
            fieldSetting: {
                formTitle: "Project Information",
            },
            style: {
                containerCss: "col-lg-12 mb-4 text-left",
            },
        },
        {
            id: "titleOfEvent",
            lable: "Title of the SGBC-accredited event",
            Field_Name: "Title of the SGBC-accredited event",
            fieldType: FormFieldTypes.INPUT,
            dataField: "titleOfEvent",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        {
            id: "eventOrg",
            lable: "Event Organiser",
            Field_Name: "Event Organiser",
            fieldType: FormFieldTypes.SELECT,
            dataField: "eventOrg",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        {
            id: "eventStartDate",
            lable: "Event start date",
            Field_Name: "Event start date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "eventStartDate",
            fieldSetting: {
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        {
            id: "certificateOfAttendance",
            lable: 'Certificate of attendance',
            subLable: '(please upload in PDF format max 2mb)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'certificateOfAttendance',
            fieldSetting: {
                isDownloadable: true,
                acceptedFiles: '.pdf',
                maxFileSizeInKB: 2048,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6"
            }
        },
        {
            id: "durations",
            lable: "Duration of Event (hours)",
            Field_Name: "Duration of Event (hours)",
            fieldType: FormFieldTypes.NUMERIC,
            dataField: "durations",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6",
            },
        },
        {
            id: "CpdPoints",
            lable: "CPD Points",
            Field_Name: "CPD Points",
            fieldType: FormFieldTypes.INPUT,
            dataField: "CpdPoints",
            fieldSetting: {
                // placeholder: "",
                // allowSpace: true,
                isDisable: true,
                // value: 6,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "remarks",
            lable: "Remarks",
            Field_Name: "Remarks",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "remarks",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                maxLength: 4000,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-12 col-12 input-margin-space",
            },
        },
        {
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line pl-0 pr-0",
            },
        },
        {
            id: "sgbcSection",
            lable: "",
            Field_Name: "sgbcSection",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "sgbcSection",
            fieldSetting: {
                formTitle: "SGBC Section",
            },
            style: {
                containerCss: "col-lg-12 mb-4 text-left",
            },
        },
        {
            id: "processedBy",
            lable: "Processed by",
            Field_Name: "Processed by",
            fieldType: FormFieldTypes.SELECT,
            dataField: "processedBy",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6  "
            }
        },
        {
            id: "cpdPointsAwarded",
            lable: "CPD Points Awarded",
            Field_Name: "CPD Points Awarded ",
            fieldType: FormFieldTypes.SELECT,
            dataField: "cpdPointsAwarded",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6"
            }
        },
        {
            id: "notes",
            lable: "Notes",
            Field_Name: "Notes",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "notes",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                maxLength: 4000,
            },
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3",
            },
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}