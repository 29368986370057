import { createApi } from "@reduxjs/toolkit/query/react";
import { transformRequest } from '../../../utils/API/requestMiddleware';
import { defautBaseQuery } from '../../../utils/API/fetchBaseQuery';
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";


const gmapApplicationApi = createApi({
    reducerPath: 'gmapApplicationApi',
    baseQuery: defautBaseQuery,
    endpoints: (builder) => ({
        AddEditGMACourseDetailsBatch: builder.mutation({
            query: (details) => ({
                url: '/GMAPAccount/AddEditGMACourseDetailsBatch',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GmaCourseMembersList: builder.mutation({
            query: (details) => ({
                url: '/GMAPAccount/GmaCourseMembersList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),

})

export const {
    useAddEditGMACourseDetailsBatchMutation,
    useGmaCourseMembersListMutation
} = gmapApplicationApi;

export default gmapApplicationApi;