import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'
import { AvailabilityAndScheduleFormData } from './data/AvailabilityAndSchedule.Data';
import CardSection from '../../../../components/ui/card/CardSection';
import FormCreator from '../../../../components/Forms/FormCreator';
import BasicDetailContext from '../../utils/BasicDetailContext';
import CourseLocation from './component/CourseLocation';
import ZoomAccount from './component/ZoomAccount';

const AvailabilityAndSchedule = forwardRef((props, ref) => {
    const availabilityAndScheduleRef = useRef();
    const courseLocationRef = useRef();
    const zoomAccountRef = useRef();
    const [availabilityAndScheduleForm, setAvailabilityAndScheduleForm] = useState(AvailabilityAndScheduleFormData);
    const { redirectionFlag, setRedirectionFlag, scheduleType, setScheduleType } = useContext(BasicDetailContext);

    // Expose method `getFormData` to parent components to retrieve form data from `FormCreator`
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const availabilityAndScheduleFormData = availabilityAndScheduleRef.current.getFormData();
                return availabilityAndScheduleFormData;
            }
            return null;
        }
    }))

    const handleFormFieldChange = (fieldName, fieldValue) => {
        switch (fieldName) {
            case "isRedirection":
                setRedirectionFlag(fieldValue === "yes");
                break;
            case "scheduleType":
                setScheduleType(fieldValue);
                break;
            default:
                return false;
        }
    };

    return (
        <React.Fragment>
            <CardSection cardTitle="Schedule & Capacity">
                <div className='row'>
                    <FormCreator ref={availabilityAndScheduleRef} {...availabilityAndScheduleForm} onFormFieldValueChange={handleFormFieldChange} />
                </div>

            </CardSection>
            {scheduleType === "physical" && <React.Fragment>
                <CardSection cardTitle="Course Location">
                    <CourseLocation ref={courseLocationRef} />
                </CardSection>
            </React.Fragment>}
            {scheduleType === "zoom" && <React.Fragment>
                <CardSection cardTitle="Select Zoom Account">
                    <ZoomAccount ref={zoomAccountRef} />
                </CardSection>
            </React.Fragment>}
            {scheduleType === "hybrid" && <React.Fragment>
                <CardSection cardTitle="Select Zoom Account">
                    <ZoomAccount ref={zoomAccountRef} />
                </CardSection>
                <CardSection cardTitle="Course Location">
                    <CourseLocation ref={courseLocationRef} />
                </CardSection>
            </React.Fragment>}
        </React.Fragment >
    )
})

export default AvailabilityAndSchedule