import React from 'react'
import MonthlyXeroBatchForm from './components/MonthlyXeroBatchForm'

const XeroBatch = () => {
    return (
        <div>
            <MonthlyXeroBatchForm />
        </div>
    )
}

export default XeroBatch
