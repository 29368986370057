import { EditGridColumnType } from "../../../../components/FinalMolGrid/libs/data/editGridColumnType";
import { GridColumnType } from "../../../../data/gridColumnType";

export const DropdownSelectConfig = {
    columns: [
        {
            name: "Dropdown Name",
            fieldName: "name",
            allowEditColumn: true,
            editColumn: {
                editColType: EditGridColumnType.INPUT,
                placeholder: "test",
                editColFieldName: "name",
                isDisable: false,
                colConfig: {
                    maxLength: 5,
                    isReadOnly: false,
                },
                editColValidation: [
                    { type: "required", message: "Dropdown name is required." },
                ],
            },
            colStyle: {
                width: "30%",
                textAlign: "center",
            },
            allowShort: false
        },
        {
            name: "Value Order",
            fieldName: "valueOrdering",
            allowEditColumn: true,
            editColumn: {
                editColType: EditGridColumnType.INPUT,
                placeholder: "test",
                editColFieldName: "valueOrdering",
                isDisable: false,
                colConfig: {
                    maxLength: 5,
                    isReadOnly: false,
                },
                editColValidation: [
                    { type: "required", message: "Value Order is required." },
                ],
            },
            colStyle: {
                width: "30%",
                textAlign: "center",
            },
            allowShort: false
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: true,
            },
            allowShort: false
        },
    ],
    editSettings: {
        buttons: {
            save: true,
            cancel: true
        }
    },
    allowEdit: true,
    handleRowDataUpdate: null,
    OnColumnChangeEdit: null,
    hasChildGridTable: false,
};

export const DropdownSelect2Config = {
    columns: [
        {
            name: "Dropdown Name",
            fieldName: "name",
            allowEditColumn: true,
            editColumn: {
                editColType: EditGridColumnType.INPUT,
                placeholder: "test",
                editColFieldName: "name",
                isDisable: false,
                colConfig: {
                    maxLength: 5,
                    isReadOnly: false,
                },
                editColValidation: [
                    { type: "required", message: "Dropdown name is required." },
                ],
            },
            colStyle: {
                width: "30%",
                textAlign: "center",
            },
            allowShort: false
        },
        {
            name: "Value Order",
            fieldName: "valueOrdering",
            allowEditColumn: true,
            editColumn: {
                editColType: EditGridColumnType.INPUT,
                placeholder: "test",
                editColFieldName: "valueOrdering",
                isDisable: false,
                colConfig: {
                    maxLength: 5,
                    isReadOnly: false,
                },
                editColValidation: [
                    { type: "required", message: "Value Order is required." },
                ],
            },
            colStyle: {
                width: "30%",
                textAlign: "center",
            },
            allowShort: false
        },
        {
            name: "Points",
            fieldName: "cpdPointsforBCA",
            allowShort: false,
            colType: GridColumnType.NUMBER,
        },
        {
            name: "Points",
            fieldName: "cpdPointsforBCA",
            allowEditColumn: true,
            editColumn: {
                editColType: EditGridColumnType.INPUT,
                placeholder: "test",
                editColFieldName: "cpdPointsforBCA",
                isDisable: false,
                colConfig: {
                    maxLength: 5,
                    isReadOnly: false,
                },
                editColValidation: [
                    { type: "required", message: "Points is required." },
                ],
            },
            colStyle: {
                width: "30%",
                textAlign: "center",
            },
            allowShort: false
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: true,
            },
            allowShort: false
        },
    ],
    editSettings: {
        buttons: {
            save: true,
            cancel: true
        }
    },
    allowEdit: true,
    handleRowDataUpdate: null,
    OnColumnChangeEdit: null,
    hasChildGridTable: false,
};
export const DropdownSelect3Config = {
    columns: [
        {
            name: "Dropdown Name",
            fieldName: "name",
            allowEditColumn: true,
            editColumn: {
                editColType: EditGridColumnType.INPUT,
                placeholder: "test",
                editColFieldName: "name",
                isDisable: false,
                colConfig: {
                    maxLength: 20,
                    isReadOnly: false,
                },
                editColValidation: [
                    { type: "required", message: "Dropdown name is required." },
                ],
            },
            colStyle: {
                width: "30%",
                textAlign: "center",
            },
            allowShort: false
        },
        {
            name: "Start Year",
            fieldName: "startDate",
            colType: GridColumnType.DATE,
            colSettings: {
                format: "DD-MMM-YYYY"
            },
            allowEditColumn: true,
            editColumn: {
                editColType: EditGridColumnType.DATEPICKER,
                editColFieldName: "startDate",
                isDisable: false,
                colConfig: {
                    format: "DD/MM/YYYY"
                },
                editColValidation: [
                    { type: "required", message: "Start Date is required." },
                ],
            },
            allowShort: false,
        },
        {
            name: "End Year",
            fieldName: "endDate",
            colType: GridColumnType.DATE,
            colSettings: {
                format: "DD-MMM-YYYY"
            },
            allowEditColumn: true,
            editColumn: {
                editColType: EditGridColumnType.DATEPICKER,
                editColFieldName: "endDate",
                isDisable: false,
                colConfig: {
                    format: "DD/MM/YYYY"
                },
                editColValidation: [
                    { type: "required", message: "End Date is required." },
                ],
            },
            allowShort: false,
        }, 
        {
            name: "Value Order",
            fieldName: "valueOrdering",
            allowEditColumn: true,
            editColumn: {
                editColType: EditGridColumnType.INPUT,
                placeholder: "test",
                editColFieldName: "valueOrdering",
                isDisable: false,
                colConfig: {
                    maxLength: 5,
                    isReadOnly: false,
                },
                editColValidation: [
                    { type: "required", message: "Value Order is required." },
                ],
            },
            colStyle: {
                width: "30%",
                textAlign: "center",
            },
            allowShort: false
        },

        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: true,
            },
            allowShort: false
        },
    ],

    editSettings: {
        buttons: {
            save: true,
            cancel: true
        }
    },
    allowEdit: true,
    handleRowDataUpdate: null,
    OnColumnChangeEdit: null,
    hasChildGridTable: false,
};