import React from 'react'
import XeroBatch from '../../../features/Financials/XeroBatch/XeroBatch'

const XeroBatchExtraction = () => {
    return (
        <XeroBatch />
    )
}

export default XeroBatchExtraction
