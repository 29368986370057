import React, { useEffect, useRef, useState } from 'react'
import { CurrentCpdCyclePointFormData } from './CurrentCpdCyclePointForm.Data';
import Buttons from '../../../../components/ui/button/Buttons';
import FormCreator from '../../../../components/Forms/FormCreator';
import CardSection from '../../../../components/ui/card/CardSection';
import CenterModel from '../../../../components/ui/centerModel/CenterModel';
import RejectReason from './RejectReason';
import { SgbcsectionFormData } from './SgbcsectionFormData';
import { useAcceptCpdSubmissionMutation, useRejectCpdSubmissionMutation } from '../../../../app/services/CPD/CPDApi';

const CurrentCpdCyclePointForm = (props) => {
    // CPD Information form creator
    const currentCpdCyclePointRef = useRef();
    const sgbcsectionRef = useRef();
    const [sgbcsectionForm, SetsgbcsectionForm] = useState(SgbcsectionFormData);

    const [currentCpdCyclePointForm, SetcurrentCpdCyclePointForm] = useState(CurrentCpdCyclePointFormData);
    // const handleClear = () => {
    //     const updatedForm = { ...currentCpdCyclePointForm };
    // }

    // Function to download Employer Contract file
    // const handleDownload = (data) => {
    //     if (props.onDownload) {
    //         props.onDownload(data);
    //     }
    // }
    const formActionHandler = {
        // CLEAR: handleClear,
        // DOWNLOAD: handleDownload,
    }

    const [approveCpdSubmission, {
        isLoading: approveIsLoading,
        data: approveCpdData,
        isSuccess: approveIsSuccess,
    }] = useAcceptCpdSubmissionMutation()


    useEffect(() => {
        if (!approveIsLoading && approveCpdData && approveIsSuccess) {
            console.log({ approveCpdData })
        }
    }, [approveIsLoading, approveCpdData, approveIsSuccess])

    const [rejectCpdSubmission, {
        isLoading: rejectIsLoading,
        data: rejectCpdData,
        isSuccess: rejectIsSuccess,
    }] = useRejectCpdSubmissionMutation()

    useEffect(() => {
        if (!rejectIsLoading && rejectCpdData && rejectIsSuccess) {
            console.log({ rejectCpdData })
        }
    }, [rejectIsLoading, rejectCpdData, rejectIsSuccess])


    return (
        <div>
            <div className='row'>
                <div className='current-cpd-cycle-point'>
                    <CardSection cardTitle="CPD Information">
                        <div className='row gx-5'>
                            <FormCreator ref={currentCpdCyclePointRef} {...currentCpdCyclePointForm} onActionChange={formActionHandler} />
                        </div>
                    </CardSection>
                    <div className='sgbc-section-main'>
                        <CardSection cardTitle="SGBC Section">
                            <div className='row gx-5'>
                                <FormCreator ref={sgbcsectionRef} {...sgbcsectionForm} onActionChange={formActionHandler} />
                            </div>
                        </CardSection>
                    </div>


                    <div className='row'>
                        <div className='common-custom-pagination mt-5'>
                            <div>
                                {/* <Buttons
                                    buttonText="Back"
                                    buttonTypeClassName="black-btn"
                                // onClick={handleSubmit}
                                /> */}
                                <Buttons
                                    buttonText="Save"
                                    buttonTypeClassName="outline-btn"
                                // onClick={() => handleSearchMemberDirectory()}
                                />
                            </div>
                            <div className='d-flex column-gap-3'>
                                {/* <Buttons
                                    buttonText="Save"
                                    buttonTypeClassName="success-btn"
                                // onClick={handleSubmit}
                                /> */}
                                <Buttons
                                    buttonText="Reject"
                                    buttonTypeClassName="red-btn"
                                    onClick={() => { props.openCloseRejectModal() }}
                                />
                                <Buttons
                                    buttonText="Approve"
                                    buttonTypeClassName="success-btn"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CurrentCpdCyclePointForm