import { FormFieldTypes } from "../../../data/formFieldType";
import { GridColumnType } from "../../../data/gridColumnType";

// uploadGMAMemberConfig 
export const uploadGMAMemberConfig = {
  columns: [
    {
      name: "upload Date",
      fieldName: "uploadDate",
      allowShort: false,
      colType: GridColumnType.DATE,
      colSettings: {
        format: 'DD MMM YYYY',
      }
    },
    {
      name: "Full Name",
      fieldName: "fullName",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
      name: "Email Address",
      fieldName: "primaryEmail",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
      name: "Valid From",
      fieldName: "qualificationStartDate",
      allowShort: false,
      colType: GridColumnType.DATE,
      colSettings: {
        format: 'DD MMM YYYY',
      }
    },
    {
      name: "Valid To",
      fieldName: "qualificationEndDate",
      allowShort: false,
      colType: GridColumnType.DATE,
      colSettings: {
        format: 'DD MMM YYYY',
      }
    },
    {
      name: "Status",
      fieldName: "statusName",
      colType: GridColumnType.Text,
    },
  ],
};

export const GMACourseFormData = {
  name: "stutusInfo",
  initialState: {
    status: null
  },
  formFields: [
    {
      id: "name",
      lable: "Name/Email",
      Field_Name: "Name/Email",
      fieldType: FormFieldTypes.INPUT,
      dataField: "name",
      fieldSetting: {
        placeholder: "",
        allowSpace: true,
      },
      style: {
        containerCss: "col-lg-3 ",
      },
    },
    {
      id: "status",
      lable: "Status",
      Field_Name: "status",
      fieldType: FormFieldTypes.SELECT,
      dataField: "statusId",
      fieldSetting: {
        placeholder: "Status",
        options: [
          { value: 11, label: "Pending" },
          { value: 4, label: "Completed" },
        ],
      },
      style: {
        containerCss: "col-lg-2"
      }
    }
  ],
  formSetting: {
    isViewOnly: false
  }
}