import React, { useEffect, useRef, useState } from "react";
import { gmapPricingTableFormData } from "./GmapPricingTableForm.Data";
import FormCreator from "../../../components/Forms/FormCreator";
import Buttons from "../../../components/ui/button/Buttons";
import { useAddEditMembershipPricingMutation, useLazyGetGmapPricingTableQuery } from "../../../app/services/GMAP/gmapPricingTableApi";
import SwalAlert from "../../../services/swalService/SwalService";

function GmapPricingTableForm(props) {
  const GmapPricingTableRef = useRef();
  const [GmapPricingTableForm, SetGmapPricingTable] = useState(
    gmapPricingTableFormData
  );

  const { success } = SwalAlert()

  const [getGmapPricingTable, {
    isSuccess: isGmapPricingTableSuccess,
    isFetching: isGmapPricingTableFetching,
    data: GmapPricingTable
  }] = useLazyGetGmapPricingTableQuery()

  const [AddEditMembershipPricing, {
    isSuccess: isAddEditMembershipPricingSuccess,
    isFetching: isAddEditMembershipPricingFetching,
    data: AddEditMembershipPricingData
  }] = useAddEditMembershipPricingMutation()

  useEffect(() => {
    getGmapPricingTable();
  }, [])

  useEffect(() => {
    if (!isGmapPricingTableFetching && isGmapPricingTableSuccess && GmapPricingTable) {
      SetGmapPricingTable({ ...GmapPricingTableForm, initialState: GmapPricingTable })
    }
  }, [isGmapPricingTableFetching, isGmapPricingTableSuccess, GmapPricingTable]);

  useEffect(() => {
    if (!isAddEditMembershipPricingFetching && isAddEditMembershipPricingSuccess && AddEditMembershipPricingData) {
      success("Pricing updated", "Success")
      getGmapPricingTable();
    }
  }, [isAddEditMembershipPricingFetching, isAddEditMembershipPricingSuccess, AddEditMembershipPricingData]);

  const convertStringToNumber = (obj) => {
    const convertedObj = {};
    if (!obj)
      return
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'string' && !isNaN(Number(value.replace(/\$|,/g, '').trim()))) {
        convertedObj[key] = Number(value.replace(/\$|,/g, '').trim());
      } else {
        convertedObj[key] = value !== undefined && value !== null ? value : 0;
      }
    }

    return convertedObj;
  };

  const handleSave = () => {
    const pricingFormData = GmapPricingTableRef.current.getFormData();
    if (!pricingFormData)
      return;
    const convertedData = convertStringToNumber(pricingFormData);
    AddEditMembershipPricing(convertedData);
  }


  // const handleClear = () => {
  //   const updatedForm = { ...GmapPricingTableForm };
  // };
  // const handleDownload = (data) => {
  //   if (props.onDownload) {
  //     props.onDownload(data);
  //   }
  // };
  // const formActionHandler = {
  //   CLEAR: handleClear,
  //   DOWNLOAD: handleDownload,
  // };


  return (

    <React.Fragment>
      {
        <div className="gmap-pricing-table">
          <div className="common-project-info-main">
            <div className="custom-main-grid">
              {GmapPricingTableForm && <FormCreator
                ref={GmapPricingTableRef}
                {...GmapPricingTableForm}
              // onActionChange={formActionHandler}
              />
              }
            </div>
            <div className="row">
              <div className="col-12">
                <div className="common-custom-pagination">
                  <div className="">
                    <Buttons
                      buttonText="Back"
                      buttonTypeClassName="back-btn"
                    // onClick={() => handleSearchMemberDirectory()}
                    />
                  </div>
                  <div className="">
                    <Buttons
                      buttonText="Save"
                      buttonTypeClassName="success-btn"
                      onClick={handleSave}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment >
  );
}

export default GmapPricingTableForm;
