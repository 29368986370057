import React from "react";
import Image from "../../image/Image";
import "./Breadcome.scss";
import { useLocation } from "react-router-dom";


function Breadcome(props) {
  const location = useLocation()
  const newStr = location.pathname.replace('/', '').toLowerCase();
  const pageName = newStr?.split('/')[0];
  const breadCrumbs = props.componentRoutes?.find(x => x.id.toLowerCase() === pageName);
  const renderPageIcon = () => {
    if (props.brcimg && props.brcimg !== "") {
      return (
        <div className="page-icon-part">
          <Image
            imgCustomClassName="icon-page"
            imagePath={props.brcimg}
            altText="Icon"
          />
        </div>
      );
    }
    return null;
  };
  const renderTitle = () => {
    if (breadCrumbs) {
      return <h3>{breadCrumbs?.title}</h3>;
    }
    else {
      return <h3>Dashboard</h3>;
    }
  };


  return (
    <div className="main-top-title">
      <div className="left-section">
        <div className="page-title">
          {renderPageIcon()}
          {renderTitle()}
        </div>
      </div>
    </div>
  );
}
export default Breadcome;