import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'
import CardSection from '../../../../components/ui/card/CardSection';
import FormCreator from '../../../../components/Forms/FormCreator';
import { NotificationToAttendeesFormData } from './data/NotificationToAttendeesForm.Data';
import { DietaryRequirementFormData } from './data/DietaryRequirementForm.Data';
import TextBoxComponent from './component/TextBoxComponent';

const Others = forwardRef((props, ref) => {
    const dietaryRequirementRef = useRef();
    const notificationToAttendeesRef = useRef();

    const [dietaryRequirementForm, setDietaryRequirementForm] = useState(DietaryRequirementFormData);
    const [notificationToAttendeeForm, setNotificationToAttendeeForm] = useState(NotificationToAttendeesFormData);

    return (
        <React.Fragment>
            <TextBoxComponent />
            <CardSection cardTitle="Dietary Requirement">
                <div className='row'>
                    <FormCreator ref={dietaryRequirementRef} {...dietaryRequirementForm} />
                </div>
            </CardSection>
            <CardSection cardTitle="Additional Questions">
                <div className='row'>
                </div>
            </CardSection>
            <CardSection cardTitle="Feedback Questions">
                <div className='row'>
                </div>
            </CardSection>
            <CardSection cardTitle="Notification To Attendees (Email)">
                <div className='row'>
                    <FormCreator ref={notificationToAttendeesRef} {...notificationToAttendeeForm} />
                </div>
            </CardSection>
        </React.Fragment>
    )
})

export default Others