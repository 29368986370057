import { FormFieldTypes } from "../../../../../data/formFieldType";

export const BasicInformationFormData = {
    name: "BasicInformationFormData",
    initialState: {
        entryName: '',
        entryType: 1,
        eventImage: '',
        workshopFlagTag: '',
        entryTopic: '',
        isRegistrationApproval: 'yes',
        notificationPeriod: '',
        about: '',
        outLines: '',
        speaker: '',
        speakerImage: '',
        readMore: ''
    },
    formFields: [
        {
            id: "entryName",
            lable: "Entry Name",
            Field_Name: "Entry Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "entryName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-4 mb-2",
            },
        },
        {
            id: "entryType",
            lable: "Entry Type",
            Field_Name: "Entry Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "entryType",
            fieldSetting: {
                placeholder: "Select Entry Type",
                options: [
                    { value: 1, label: "Workshop" },
                    { value: 2, label: "Flagship" },
                    { value: 3, label: "Expression of Interest" },
                ],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-4 mb-2",
            }
        },
        {
            id: "eventImage",
            lable: 'Upload Event Image',
            subLable: "(Dimension: 814px by 460px Maximum Size: 2MB)",
            Field_Name: 'Upload Event Image',
            fieldType: FormFieldTypes.FILE,
            dataField: 'eventImage',
            fieldSetting: {
                placeholder: '',
                acceptedFiles: '.jpg, .png',
                imageCrop: true,
                viewImage: true
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-4 mb-2",
            }
        },
        {
            id: "workshopFlagTag",
            lable: "Workshop Flag Tag",
            Field_Name: "workshopFlagTag",
            fieldType: FormFieldTypes.INPUT,
            dataField: "Workshop Flag Tag",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [],
            style: {
                containerCss: "col-lg-4 mb-2",
            },
        },
        {
            id: "entryTopic",
            lable: "Entry Topic (You can select more than one option)",
            Field_Name: "Entry Topic",
            fieldType: FormFieldTypes.SELECT,
            dataField: "entryTopic",
            fieldSetting: {
                placeholder: "Select Entry Topic",
                options: [
                    { value: 1, label: "Flagship Event" },
                    { value: 2, label: "Workshop" },
                    { value: 3, label: "Course" },
                ],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-4 mb-2",
            }
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: '',
            style: {
                containerCss: "col-lg-4 mb-2",
            }
        },
        {
            id: "isRegistrationApproval",
            label: "Registration Approval",
            Field_Name: "Registration Approval",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "isRegistrationApproval",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "Yes", value: "yes", isDisable: false, optionClass: "", },
                    { label: "No", value: "no", isDisable: false, optionClass: "ml-3", },
                ]
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-4 my-4",
            },
        },
        {
            id: "notificationPeriod",
            lable: "Notification Period(days)",
            Field_Name: "Notification Period(days)",
            fieldType: FormFieldTypes.INPUT,
            dataField: "notificationPeriod",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-4 my-4",
            },
        },
        {
            id: "about",
            lable: "About",
            Field_Name: "About",
            fieldType: FormFieldTypes.CKEDITOR,
            dataField: "about",
            fieldSetting: {
                placeholder: "Enter Details",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12  mb-2",
            },
        },
        {
            id: "outLines",
            lable: "Outlines",
            Field_Name: "Outlines",
            fieldType: FormFieldTypes.CKEDITOR,
            dataField: "outLines",
            fieldSetting: {
                placeholder: "Enter Details",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12  mb-2",
            },
        },
        {
            id: "speaker",
            lable: "Speaker",
            Field_Name: "Speaker",
            fieldType: FormFieldTypes.CKEDITOR,
            dataField: "speaker",
            fieldSetting: {
                placeholder: "Enter Details",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-xxl-8 col-xl-8 col-md-8 col-8  mb-2",
            },
        },
        {
            id: "speakerImage",
            lable: 'Upload Speaker Image',
            Field_Name: 'Upload Speaker Image',
            fieldType: FormFieldTypes.FILE,
            dataField: 'speakerImage',
            fieldSetting: {
                placeholder: '',
                acceptedFiles: '.jpg, .png',
                imageCrop: true,
                viewImage: true
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-xxl-4 col-xl-4 col-lg-4 mb-2",
            }
        },
        {
            id: "readMore",
            lable: "Read More",
            Field_Name: "Read More",
            fieldType: FormFieldTypes.CKEDITOR,
            dataField: "readMore",
            fieldSetting: {
                placeholder: "Enter Details",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12  mb-2",
            },
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}