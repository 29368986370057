import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { certificateCourseData } from '../CertificateCourse.Data';
import { handleConditionBaseFields } from '../utils/ValidationBaseFields';
import FormCreator from '../../../../../../components/Forms/FormCreator';

const CertificateCourseForm = forwardRef((props, ref) => {
    const certificateCourseRef = useRef();
    const [formData, setFormData] = useState(certificateCourseData);
    const resetForm = () => {
        let form = { ...certificateCourseData }
        form.initialState = { ...props.initData }
        setFormData(form);
    }

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            const certificateData = certificateCourseRef.current.getFormData();
            if (ref?.current && certificateData) {
                resetForm();
                return certificateData;
            }
            return null;
        }
    }))
    //** UseEffect */   
    useEffect(() => {
        resetForm();
        if (props.isEdit && props.initData) {
            // alert("hello edit calling")
        }
    }, [props.isEdit, props.initData]);

    const handleClear = () => {
        const updatedForm = { ...formData };
        updatedForm.initialState.certificateAttachment = null;
        // certificateCourseData.formFields.find(item => item.id === "certificateAttachment").fieldSetting.isDownloadable = false;
        setFormData(updatedForm);
    }

    const handleDownload = (data, dataField) => {
        if (props.onDownload) {
            props.onDownload(data, dataField);
        }
    }
    const handleDropdownChange = (data, dataField) => {
        if (dataField === "gmaPathwayTypeID") {
            handleConditionBaseFields(data.value, dataField)
        }
    }
    const formActionHandler = {
        CLEAR: handleClear,
        DOWNLOAD: handleDownload,
        DDL_CHANGED: handleDropdownChange
    }
    const handleFormFieldChange = (dataField, value) => {

        if (props.onFormFieldValueChange) {
            props.onFormFieldValueChange(dataField, value)
        }
    };

    return (
        <FormCreator ref={certificateCourseRef} {...formData} onActionChange={formActionHandler} onFormFieldValueChange={handleFormFieldChange} handleTableEdit={props.handleTableEdit} handleTableDelete={props.handleTableDelete} />
    )
})

export default CertificateCourseForm