import { FormFieldTypes } from "../../../../../data/formFieldType";

export const NotificationToAttendeesFormData = {
    name: "NotificationToAttendeesFormData",
    initialState: {
        notificationMessage: "",
    },
    formFields: [
        {
            id: "notificationMessage",
            lable: "After completing their booking, the customer will get a confirmation email. Below is where you type text for this email. E.g. include information about parking / where to meet / finish time / what to bring / what to wear etc.",
            Field_Name: "Message",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "notificationMessage",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                maxLength: 4000,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-12 col-12 input-margin-space",
            },
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}