import { FormFieldTypes } from "../../../data/formFieldType"

export const gmapPricingTableFormData = {
    // name: 'CareerHistory',
    initialState: {
        apApplicationFee: null,
        apFirstYearAnnualFee: null,
        apSubsequentAnnualFee: null,
        apMaintaningCertificationFee: null,
        aApApplicationFee: null,
        aApFirstYearAnnualFee: null,
        aApSubsequentAnnualFee: null,
        aApMaintaningCertificationFee: null,
        associateApplicationFee: null,
        associateFirstYearAnnualFee: null,
        associateSubsequentAnnualFee: null,
        associateMaintaningCertificationFee: null,
        latePenaltyChargeApplicationFee: null,
        latePenaltyChargeFirstYearAnnualFee: null,
        latePenaltyChargeSubsequentAnnualFee: null,
        latePenaltyChargeMaintaningCertificationFee: null
    },
    formFields: [

        // title object start
        {
            id: "category",
            lable: "",
            Field_Name: "Category",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "category",
            fieldSetting: {
                formTitle: "Category",
            },
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "applicationFee",
            lable: "",
            Field_Name: "Application Fee (Before GST)",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "applicationFee",
            fieldSetting: {
                formTitle: "Application Fee (Before GST)",
            },
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "firstYearAnnualFee",
            lable: "",
            Field_Name: "First Year Annual Fee (Before GST)",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "firstYearAnnualFee",
            fieldSetting: {
                formTitle: "First Year Annual Fee (Before GST)",
            },
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "subsequentAnnualFee",
            lable: "",
            Field_Name: "Subsequent Annual Fee (Before GST)",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "subsequentAnnualFee",
            fieldSetting: {
                formTitle: "Subsequent Annual Fee (Before GST)",
            },
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "maintainingCertification",
            lable: "",
            Field_Name: "Maintaining Certification (CPD)",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "maintainingCertification",
            fieldSetting: {
                formTitle: "Maintaining Certification (CPD)",
            },
            style: {
                containerCss: "custom-grid last-grid",
            },
        },

        // Green Mark AP object start

        {
            id: "greenMarkApfees",
            lable: "",
            Field_Name: "",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "greenMarkApfees",
            fieldSetting: {
                formTitle: "Green Mark AP",
            },
            style: {
                containerCss: "category-title",
            },
        },
        {
            id: "greenMarkApFees01",
            lable: "",
            Field_Name: "apApplicationFee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "apApplicationFee",
            fieldSetting: {
                placeholder: "$ ",
                allowSpace: true,
                maskFormat: "$ ",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "greenMarkApFees02",
            lable: "",
            Field_Name: "apFirstYearAnnualFee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "apFirstYearAnnualFee",
            fieldSetting: {
                placeholder: "$",
                allowSpace: true,
                maskFormat: "$ ",

            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "greenMarkApFees03",
            lable: "",
            Field_Name: "apSubsequentAnnualFee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "apSubsequentAnnualFee",
            fieldSetting: {
                placeholder: "$",
                allowSpace: true,
                maskFormat: "$ ",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "greenMarkApFees04",
            lable: "",
            Field_Name: "apMaintaningCertificationFee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "apMaintaningCertificationFee",
            fieldSetting: {
                placeholder: "",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid last-grid",
            },
        },
        // Green Mark AP object end


        // Green mark aap object start
        {
            id: "GreenMarkAap",
            lable: "",
            Field_Name: "Green Mark AAP",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "GreenMarkAap",
            fieldSetting: {
                formTitle: "Green Mark AAP",
            },
            style: {
                containerCss: "category-title",
            },
        },
        {
            id: "GreenMarkAap01",
            lable: "",
            Field_Name: "aApApplicationFee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "aApApplicationFee",
            fieldSetting: {
                placeholder: "$ ",
                allowSpace: true,
                maskFormat: "$ ",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "GreenMarkAap02",
            lable: "",
            Field_Name: "aApFirstYearAnnualFee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "aApFirstYearAnnualFee",
            fieldSetting: {
                placeholder: "$",
                allowSpace: true,
                maskFormat: "$ ",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "GreenMarkAap03",
            lable: "",
            Field_Name: "aApSubsequentAnnualFee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "aApSubsequentAnnualFee",
            fieldSetting: {
                placeholder: "$",
                allowSpace: true,
                maskFormat: "$ ",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "GreenMarkAap04",
            lable: "",
            Field_Name: "aApMaintaningCertificationFee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "aApMaintaningCertificationFee",
            fieldSetting: {
                placeholder: "",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid last-grid",
            },
        },
        // Green mark app object end

        // // Green Mark AP (FM) start
        // {
        //     id: "greenMarkApFee",
        //     lable: "",
        //     Field_Name: "Green Mark AP (FM)",
        //     fieldType: FormFieldTypes.MAINFORMTITLE,
        //     dataField: "greenMarkApFee",
        //     fieldSetting: {
        //         formTitle: "Green Mark AP (FM)",
        //     },
        //     style: {
        //         containerCss: "category-title",
        //     },
        // },
        // {
        //     id: "greenMarkApFee01",
        //     lable: "",
        //     Field_Name: "",
        //     fieldType: FormFieldTypes.INPUT,
        //     dataField: "greenMarkApFee01",
        //     fieldSetting: {
        //         placeholder: "$",
        //         allowSpace: true,
        //         maskFormat: "$ ",
        //     },
        //     validation: [{ type: "require" }],
        //     style: {
        //         containerCss: "custom-grid",
        //     },
        // },
        // {
        //     id: "greenMarkApFee02",
        //     lable: "",
        //     Field_Name: "",
        //     fieldType: FormFieldTypes.INPUT,
        //     dataField: "greenMarkApFee02",
        //     fieldSetting: {
        //         placeholder: "$",
        //         allowSpace: true,
        //         maskFormat: "$ ",
        //     },
        //     validation: [{ type: "require" }],
        //     style: {
        //         containerCss: "custom-grid",
        //     },
        // },
        // {
        //     id: "greenMarkApFee03",
        //     lable: "",
        //     Field_Name: "",
        //     fieldType: FormFieldTypes.INPUT,
        //     dataField: "greenMarkApFee03",
        //     fieldSetting: {
        //         placeholder: "$",
        //         allowSpace: true,
        //         maskFormat: "$ ",
        //     },
        //     validation: [{ type: "require" }],
        //     style: {
        //         containerCss: "custom-grid",
        //     },
        // },
        // {
        //     id: "greenMarkApFee04",
        //     lable: "",
        //     Field_Name: "",
        //     fieldType: FormFieldTypes.SELECT,
        //     dataField: "greenMarkApFee04",
        //     fieldSetting: {
        //         placeholder: "",
        //         options: []
        //     },
        //     validation: [{ type: "require" }],
        //     style: {
        //         containerCss: "custom-grid last-grid",
        //     },
        // },
        // // Green Mark AP (FM) object end


        // // Green Mark AAP (FM) object start
        // {
        //     id: "greenMarkAapFee",
        //     lable: "",
        //     Field_Name: "Green Mark AAP (FM)",
        //     fieldType: FormFieldTypes.MAINFORMTITLE,
        //     dataField: "greenMarkAapFee",
        //     fieldSetting: {
        //         formTitle: "Green Mark AAP (FM)",
        //     },
        //     style: {
        //         containerCss: "category-title",
        //     },
        // },
        // {
        //     id: "greenMarkAapFee01",
        //     lable: "",
        //     Field_Name: "",
        //     fieldType: FormFieldTypes.INPUT,
        //     dataField: "greenMarkAapFee01",
        //     fieldSetting: {
        //         placeholder: "$",
        //         allowSpace: true,
        //         maskFormat: "$ ",
        //     },
        //     validation: [{ type: "require" }],
        //     style: {
        //         containerCss: "custom-grid",
        //     },
        // },
        // {
        //     id: "greenMarkAapFee02",
        //     lable: "",
        //     Field_Name: "",
        //     fieldType: FormFieldTypes.INPUT,
        //     dataField: "greenMarkAapFee02",
        //     fieldSetting: {
        //         placeholder: "$",
        //         allowSpace: true,
        //         maskFormat: "$ ",
        //     },
        //     validation: [{ type: "require" }],
        //     style: {
        //         containerCss: "custom-grid",
        //     },
        // },
        // {
        //     id: "greenMarkAapFee03",
        //     lable: "",
        //     Field_Name: "",
        //     fieldType: FormFieldTypes.INPUT,
        //     dataField: "greenMarkAapFee03",
        //     fieldSetting: {
        //         placeholder: "$",
        //         allowSpace: true,
        //         maskFormat: "$ ",
        //     },
        //     validation: [{ type: "require" }],
        //     style: {
        //         containerCss: "custom-grid",
        //     },
        // },
        // {
        //     id: "greenMarkAapFee04",
        //     lable: "",
        //     Field_Name: "",
        //     fieldType: FormFieldTypes.SELECT,
        //     dataField: "greenMarkAapFee04",
        //     fieldSetting: {
        //         placeholder: "",
        //         options: []
        //     },
        //     validation: [{ type: "require" }],
        //     style: {
        //         containerCss: "custom-grid last-grid",
        //     },
        // },
        // // Green Mark AAP (FM) object end


        // Green Mark Associate object start
        {
            id: "greenMarkAssociate",
            lable: "",
            Field_Name: "Green Mark Associate",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "greenMarkAssociate",
            fieldSetting: {
                formTitle: "Green Mark Associate",
            },
            style: {
                containerCss: "category-title",
            },
        },
        {
            id: "greenMarkAssociate01",
            lable: "",
            Field_Name: "associateApplicationFee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "associateApplicationFee",
            fieldSetting: {
                placeholder: "$",
                allowSpace: true,
                maskFormat: "$ ",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "greenMarkAssociate02",
            lable: "",
            Field_Name: "associateFirstYearAnnualFee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "associateFirstYearAnnualFee",
            fieldSetting: {
                placeholder: "$ ",
                allowSpace: true,
                maskFormat: "$ ",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "greenMarkAssociate03",
            lable: "",
            Field_Name: "associateSubsequentAnnualFee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "associateSubsequentAnnualFee",
            fieldSetting: {
                placeholder: "$ ",
                allowSpace: true,
                maskFormat: "$ ",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "greenMarkAssociate04",
            lable: "",
            Field_Name: "associateMaintaningCertificationFee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "associateMaintaningCertificationFee",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid last-grid",
            },
        },
        //Green Mark Associate object end


        // Late Penalty charges
        {
            id: "latepenaltycharge",
            lable: "",
            Field_Name: "Late Penalty Charge",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "latepenaltycharge",
            fieldSetting: {
                formTitle: "Late Penalty Charge",
            },
            style: {
                containerCss: "category-title",
            },
        },
        {
            id: "greenMarkAssociate01",
            lable: "",
            Field_Name: "latePenaltyChargeApplicationFees",
            fieldType: FormFieldTypes.INPUT,
            dataField: "latePenaltyChargeApplicationFee",
            fieldSetting: {
                placeholder: "$",
                allowSpace: true,
                maskFormat: "$ ",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "greenMarkAssociate02",
            lable: "",
            Field_Name: "latePenaltyChargeFirstYearAnnualFee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "latePenaltyChargeFirstYearAnnualFee",
            fieldSetting: {
                placeholder: "$ ",
                allowSpace: true,
                maskFormat: "$ ",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "greenMarkAssociate03",
            lable: "",
            Field_Name: "latePenaltyChargeSubsequentAnnualFee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "latePenaltyChargeSubsequentAnnualFee",
            fieldSetting: {
                placeholder: "$ ",
                allowSpace: true,
                maskFormat: "$ ",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid",
            },
        },
        {
            id: "greenMarkAssociate04",
            lable: "",
            Field_Name: "latePenaltyChargeMaintaningCertificationFee",
            fieldType: FormFieldTypes.INPUT,
            dataField: "latePenaltyChargeMaintaningCertificationFee",
            fieldSetting: {
                placeholder: "$ ",
                allowSpace: true,
                maskFormat: "$ ",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid last-grid",
            },
        },
        // Green Mark Associate object end


    ],
    formSetting: {
        isViewOnly: false
    }
}

