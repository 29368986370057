import { FormFieldTypes } from "../../../../data/formFieldType";

export const RichTextData = {
    name: 'CreatePages',
    initialState: {
        projectName: '',
        greenMarkProjectNumber: '',
        dateofLetterofAward: '',
        // isGenderMale: true,
        awardRating: "",
        gmapGreenMarkCategoryId: "",
        developerBuildingOwner: "",
        describeYourRoleintheProject: '',
        uploadEndorsementAttached: "",
        uploadGreenMarkCategory: "",
        CpdPoints: "",
        remarks: "",
        processedBy: "",
        cpdPointsAwarded: "",
    },
    formFields: [

        {
            id: "componentUniqueId",
            lable: "Component Unique Id :",
            Field_Name: "ComponentUniqueID",
            fieldType: FormFieldTypes.INPUT,
            dataField: "componentUniqueId",
            fieldSetting: {
                placeholder: "Component Unique Id",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "componentName",
            lable: "Component Name :",
            Field_Name: "ComponentName",
            fieldType: FormFieldTypes.INPUT,
            dataField: "componentName",
            fieldSetting: {
                placeholder: "Component Name",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "descriptions",
            lable: "Description",
            Field_Name: "Description",
            fieldType: FormFieldTypes.SUNEDITOR,
            dataField: "description",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-10 col-sm-10 col-md-11 col-12 mb-3",
            },
        },
        
    ],
    formSetting: {
        isViewOnly: false
    }
}

export const RichTextWithImageData = {
    name: 'CreatePages',
    initialState: {
        projectName: '',
        greenMarkProjectNumber: '',
        dateofLetterofAward: '',
        // isGenderMale: true,
        awardRating: "",
        gmapGreenMarkCategoryId: "",
        developerBuildingOwner: "",
        describeYourRoleintheProject: '',
        uploadEndorsementAttached: "",
        uploadGreenMarkCategory: "",
        CpdPoints: "",
        remarks: "",
        processedBy: "",
        cpdPointsAwarded: "",
    },
    formFields: [

        {
            id: "componentUniqueId",
            lable: "Component Unique Id :",
            Field_Name: "ComponentUniqueID",
            fieldType: FormFieldTypes.INPUT,
            dataField: "componentUniqueId",
            fieldSetting: {
                placeholder: "Component Unique Id",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "componentName",
            lable: "Component Name :",
            Field_Name: "ComponentName",
            fieldType: FormFieldTypes.INPUT,
            dataField: "componentName",
            fieldSetting: {
                placeholder: "Component Name",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "uploadFeatureImage",
            lable: '(Dimension: 260px by 130px Maximum Size: 1MB)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'uploadFeatureImage',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                isDownloadable: false,
                acceptedFiles: '.jpg, .png',
                isDisable: false,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-2xl-5 col-xl-5 col-lg-5 col-md-5 col-12 mb-3 file-uploader-img"
            }
        },
       
        {
            id: "descriptions",
            lable: "Description",
            Field_Name: "Description",
            fieldType: FormFieldTypes.SUNEDITOR,
            dataField: "description",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-2xl-7 col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 mb-3",
            },
        },
        
    ],
    formSetting: {
        isViewOnly: false
    }
}

export const ListingData = {
    name: 'CreatePages',
    initialState: {
        projectName: '',
        greenMarkProjectNumber: '',
        dateofLetterofAward: '',
        // isGenderMale: true,
        awardRating: "",
        gmapGreenMarkCategoryId: "",
        developerBuildingOwner: "",
        describeYourRoleintheProject: '',
        uploadEndorsementAttached: "",
        uploadGreenMarkCategory: "",
        CpdPoints: "",
        remarks: "",
        processedBy: "",
        cpdPointsAwarded: "",
    },
    formFields: [

        {
            id: "componentUniqueId",
            lable: "Component Unique Id :",
            Field_Name: "ComponentUniqueID",
            fieldType: FormFieldTypes.INPUT,
            dataField: "componentUniqueId",
            fieldSetting: {
                placeholder: "Component Unique Id",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "componentName",
            lable: "Component Name :",
            Field_Name: "ComponentName",
            fieldType: FormFieldTypes.INPUT,
            dataField: "componentName",
            fieldSetting: {
                placeholder: "Component Name",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "listName",
            lable: "List Name :",
            Field_Name: "ListName",
            fieldType: FormFieldTypes.INPUT,
            dataField: "listName",
            fieldSetting: {
                placeholder: "List Name",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4 input-margin-space",
            },
        },
        {
            id: "listURL",
            lable: "List URL :",
            Field_Name: "ListURL",
            fieldType: FormFieldTypes.INPUT,
            dataField: "listURL",
            fieldSetting: {
                placeholder: "List URL",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-4 col-xl-4 col-lg-4 input-margin-space",
            },
        },
        {
            id: "uploadFeatureImage",
            lable: '(Dimension: 260px by 130px Maximum Size: 1MB)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'uploadFeatureImage',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                isDownloadable: false,
                acceptedFiles: '.jpg, .png',
                isDisable: false,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-2xl-4 col-xl-4 col-lg-4 col-md-4 col-12 mb-3 file-uploader-img"
            }
        },
        
    ],
    formSetting: {
        isViewOnly: false
    }
}


export const ImagesData = {
    name: 'CreatePages',
    initialState: {
        projectName: '',
        greenMarkProjectNumber: '',
        dateofLetterofAward: '',
        // isGenderMale: true,
        awardRating: "",
        gmapGreenMarkCategoryId: "",
        developerBuildingOwner: "",
        describeYourRoleintheProject: '',
        uploadEndorsementAttached: "",
        uploadGreenMarkCategory: "",
        CpdPoints: "",
        remarks: "",
        processedBy: "",
        cpdPointsAwarded: "",
    },
    formFields: [

        {
            id: "componentUniqueId",
            lable: "Component Unique Id :",
            Field_Name: "ComponentUniqueID",
            fieldType: FormFieldTypes.INPUT,
            dataField: "componentUniqueId",
            fieldSetting: {
                placeholder: "Component Unique Id",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "componentName",
            lable: "Component Name :",
            Field_Name: "ComponentName",
            fieldType: FormFieldTypes.INPUT,
            dataField: "componentName",
            fieldSetting: {
                placeholder: "Component Name",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "altTag",
            lable: "Alt Tag :",
            Field_Name: "Alt Tag",
            fieldType: FormFieldTypes.INPUT,
            dataField: "altTag",
            fieldSetting: {
                placeholder: "Alt Tag",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        
        {
            id: "uploadFeatureImage",
            lable: '(Dimension: 260px by 130px Maximum Size: 1MB)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'uploadFeatureImage',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                isDownloadable: false,
                acceptedFiles: '.jpg, .png',
                isDisable: false,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-2xl-6 col-xl-6 col-lg-6 col-md-6 col-12 mb-3 file-uploader-img"
            }
        },
        {
            id: "altTag",
            lable: "Image Size (px)",
            Field_Name: "Image Size (px)",
            fieldType: FormFieldTypes.INPUT,
            dataField: "altTag",
            fieldSetting: {
                placeholder: "100",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1 text-nowrap input-margin-space",
            },
        },
        {
            id: "altTag",
            lable: "X",
            Field_Name: "",
            fieldType: FormFieldTypes.TIME,
            dataField: "altTag",
            fieldSetting: {
                placeholder: "X",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "d-inline w-10 col-1",
            },
        },
        {
            id: "altTag",
            lable: "",
            Field_Name: "",
            fieldType: FormFieldTypes.INPUT,
            dataField: "altTag",
            fieldSetting: {
                placeholder: "100",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-1 col-xl-1 col-lg-1 input-margin-space mt-auto",
            },
        },
        
    ],
    formSetting: {
        isViewOnly: false
    }
}


export const ObjectData = {
    name: 'CreatePages',
    initialState: {
        projectName: '',
        greenMarkProjectNumber: '',
        dateofLetterofAward: '',
        // isGenderMale: true,
        awardRating: "",
        gmapGreenMarkCategoryId: "",
        developerBuildingOwner: "",
        describeYourRoleintheProject: '',
        uploadEndorsementAttached: "",
        uploadGreenMarkCategory: "",
        CpdPoints: "",
        remarks: "",
        processedBy: "",
        cpdPointsAwarded: "",
    },
    formFields: [

        {
            id: "componentUniqueId",
            lable: "Component Unique Id :",
            Field_Name: "ComponentUniqueID",
            fieldType: FormFieldTypes.INPUT,
            dataField: "componentUniqueId",
            fieldSetting: {
                placeholder: "Component Unique Id",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "componentName",
            lable: "Component Name :",
            Field_Name: "ComponentName",
            fieldType: FormFieldTypes.INPUT,
            dataField: "componentName",
            fieldSetting: {
                placeholder: "Component Name",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "columnName",
            lable: "Column Name :",
            Field_Name: "ListName",
            fieldType: FormFieldTypes.INPUT,
            dataField: "columnName",
            fieldSetting: {
                placeholder: "Column Name",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "value",
            lable: "Value :",
            Field_Name: "Value",
            fieldType: FormFieldTypes.INPUT,
            dataField: "value",
            fieldSetting: {
                placeholder: "Value",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        
        
    ],
    formSetting: {
        isViewOnly: false
    }
}
export const ViewPagesData = {
    name: 'CreatePages',
    initialState: {
        projectName: '',
        greenMarkProjectNumber: '',
        dateofLetterofAward: '',
        // isGenderMale: true,
        awardRating: "",
        gmapGreenMarkCategoryId: "",
        developerBuildingOwner: "",
        describeYourRoleintheProject: '',
        uploadEndorsementAttached: "",
        uploadGreenMarkCategory: "",
        CpdPoints: "",
        remarks: "",
        processedBy: "",
        cpdPointsAwarded: "",
    },
    formFields: [

        {
            id: "filterByMainMenu",
            lable: "Filter by Main Menu",
            Field_Name: "Filter by Main Menu",
            fieldType: FormFieldTypes.SELECT,
            dataField: "filterByMainMenu",
            fieldSetting: {
                placeholder: "Select",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-5 col-lg-5  "
            }
        },
        {
            id: "searchByName",
            lable: "Search By Sub Menu / Page Title",
            Field_Name: "Search By Sub Menu / Page Title",
            fieldType: FormFieldTypes.INPUT,
            dataField: "searchByName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-sm-12 col-md-5 col-5 mb-3",
            },
        },
      
        
    ],
    formSetting: {
        isViewOnly: false
    }
}

export const ObjectComponentData = {
    name: 'CreatePages',
    initialState: {
        projectName: '',
        greenMarkProjectNumber: '',
        dateofLetterofAward: '',
        // isGenderMale: true,
        awardRating: "",
        gmapGreenMarkCategoryId: "",
        developerBuildingOwner: "",
        describeYourRoleintheProject: '',
        uploadEndorsementAttached: "",
        uploadGreenMarkCategory: "",
        CpdPoints: "",
        remarks: "",
        processedBy: "",
        cpdPointsAwarded: "",
    },
    formFields: [


        {
            id: "Field Name",
            lable: "Field Name :",
            Field_Name: "Field Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "fieldName",
            fieldSetting: {
                placeholder: "Field Name",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-5 col-xl-5 col-lg-5 input-margin-space",
            },
        },

        {
            id: "fieldType",
            lable: "Field Type",
            Field_Name: "Field Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "fieldType",
            fieldSetting: {
                placeholder: "Select",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-5 col-xl-5 col-lg-5  "
            }
        },
       
      
        
    ],
    formSetting: {
        isViewOnly: false
    }
}
