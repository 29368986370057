import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";

const XeroBatchExtractionApi = createApi({
    reducerPath: 'XeroBatchExtractionApi',
    baseQuery: defautBaseQuery,
    endpoints: (builder) => ({
        // getDropdownRecordList: builder.mutation({
        //     query: () => ({
        //         url: '/FieldManagement/GetDropdownRecordList',
        //         method: 'GET',
        //     }),
        //     transformResponse: transformSucessResponse,
        //     transformErrorResponse: transformErrorResponse,
        // }),
        getXeroBatchJobList: builder.mutation({
            query: (details) => ({
                url: '/BatchJob/GetXeroBatchJobList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GenerateXeroMonthlyReport: builder.mutation({
            query: (details) => ({
                url: '/BatchJob/GenerateXeroMonthlyReport',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    }),
})

export const {
    useGetXeroBatchJobListMutation,
    useGenerateXeroMonthlyReportMutation
} = XeroBatchExtractionApi;

export default XeroBatchExtractionApi;