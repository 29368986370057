import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import FormCreator from '../../../../components/Forms/FormCreator';
import { RejectReasonData } from './RejectReasonData';
import Buttons from '../../../../components/ui/button/Buttons';
import { useRejectCpdSubmissionMutation } from '../../../../app/services/CPD/CPDApi';
import { CurrentCPDCycleDataContext } from '../CpdSubmissionForm';

const RejectReason = forwardRef((props, ref) => {
  // CPD Information form creator
  const RejectReasonRef = useRef();
  const [rejectReasonForm, SetrejectReasonForm] = useState(RejectReasonData);
  const { cpdSubmissionData, setCpdSubmissionData } = useContext(CurrentCPDCycleDataContext);

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = RejectReasonRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));

  const [rejectCpdSubmission, {
    isLoading: rejectIsLoading,
    data: rejectCpdData,
    isSuccess: rejectIsSuccess,
  }] = useRejectCpdSubmissionMutation()

  useEffect(() => {
    if (rejectCpdData && rejectIsSuccess && !rejectIsLoading) {
      props.formActionHandler["REJECT_SUCCESS"]();
    }
  }, [rejectIsLoading, rejectCpdData, rejectIsSuccess])


  const handleSubmit = () => {
    const data = RejectReasonRef.current.getFormData();
    if (cpdSubmissionData.rejectRequestData && data) {
      rejectCpdSubmission({
        ...cpdSubmissionData.rejectRequestData, rejectCPDSubmissionReason: data.rejectReason
      })
    }
  }

  return (
    <div>
      <div className='row'>
        <div className='reaject-application-main'>
          <div className='row'>
            <FormCreator ref={RejectReasonRef} {...rejectReasonForm} />
          </div>
          <div className='d-flex justify-content-center mt-3'>
            <Buttons
              buttonText="Submit"
              buttonTypeClassName="success-btn"
              onClick={handleSubmit}
              isLoading={rejectIsLoading}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

export default RejectReason