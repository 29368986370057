export const FormFieldTypes = {
  INPUT: 1,
  PASSWORD: 2,
  NUMERIC: 3,
  CHECKBOX: 4,
  DATEPICKER: 5,
  SELECT: 6,
  TEXTAREA: 7,
  SUNEDITOR: 8,
  TINYEDITOR: 9,
  FILE: 10,
  RADIOBUTTON: 11,
  SEPARATOR: 12,
  MAINFORMTITLE: 13,
  EDITABLEDROPDOWN: 14,
  TIME: 16,
  TABLE: 17,
  CKEDITOR: 18,
  COMPONENT: 19,
  // DECIMAL :4
  // NUMERIC: 4,
  // PASSWORD: 7,
  CURRENCY: 8
};
