import { FormFieldTypes } from "../../../../data/formFieldType";
import { GridColumnType } from "../../../../data/gridColumnType";

export const DiscretionaryTaskListSearchFilterFormData={
    name: "AdditionalInfo",
    initialState: {
        name: null,
        status: null
    },
    formFields: [
        {
            id: "name",
            lable: "Name/GMP Number",
            Field_Name: "NaName/GMAP Numberme",
            fieldType: FormFieldTypes.INPUT,
            dataField: "name",
            fieldSetting: {
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3",
            },
        },
        {
            id: "status",
            lable: "Status",
            Field_Name: "Status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "status",
            fieldSetting: {
                options: [],
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}


export const DiscretionaryApplicationTasklistTableConfig={
    columns: [
        {
            name: "Submission Date",
            fieldName: "submissionDate",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD MMM YYYY',
            }
        },
        {
            name: "Applicant Name",
            fieldName: "name",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Email Address",
            fieldName: "email",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "GMP Tier",
            fieldName: "applicationName",
            colType: GridColumnType.Text,
        },
        {
            name: "Status",
            fieldName: "statusName",
            colType: GridColumnType.Text,
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false
            },
            customAction: [
                {

                    name: "UPDATE",
                    // iconName: AppIcons.copyIcon,
                    onViewAction: (data) => {
                        alert("called permissions");
                    },
                },
            ],
        },
    ],
};
