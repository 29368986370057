import { FormFieldTypes } from "../../../../../data/formFieldType";

export const EventListFilterFormData = {
    name: "EventListFilterFormData",
    initialState: {
        eventName: '',
        status: '',
        hidden: '',
    },
    formFields: [
        {
            id: "eventName",
            lable: "Search by Event Name",
            Field_Name: "Search by Event Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "eventName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-4 mb-2",
            },
        },
        {
            id: "status",
            lable: "Status",
            Field_Name: "Status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "status",
            fieldSetting: {
                placeholder: "Select",
                options: [
                    { value: 1, label: "Workshop" },
                    { value: 2, label: "Flagship" },
                    { value: 3, label: "Expression of Interest" },
                ],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-4 mb-2",
            }
        },
        {
            id: "hidden",
            lable: "Hidden",
            Field_Name: "Hidden",
            fieldType: FormFieldTypes.SELECT,
            dataField: "hidden",
            fieldSetting: {
                placeholder: "Select",
                options: [
                    { value: 1, label: "Workshop" },
                    { value: 2, label: "Flagship" },
                    { value: 3, label: "Expression of Interest" },
                ],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-4 mb-2",
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}