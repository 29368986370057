import { createApi } from "@reduxjs/toolkit/query/react";
import { encryptQueryString, transformRequest } from '../../../utils/API/requestMiddleware';
import { defautBaseQuery } from '../../../utils/API/fetchBaseQuery';
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";


const greenMarkProjectApi = createApi({
    reducerPath: 'greenMarkProjectApi',
    baseQuery: defautBaseQuery,
    endpoints: (builder) => ({
        getGMAPGreenMarkProjectList: builder.query({
            query: (details) => ({
                url: encryptQueryString(`/GMAPAccount/GetGMAPGreenMarkProjectList?id=${Number(details)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        deleteGMAPGreenMarkProject: builder.mutation({
            query: (details) => ({
                url: '/GMAPAccount/DeleteGMAPGreenMarkProject',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        addEditGMAPGreenMarkProject: builder.mutation({
            query: (details) => ({
                url: '/GMAPAccount/AddEditGMAPGreenMarkProject',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useDeleteGMAPGreenMarkProjectMutation,
    useAddEditGMAPGreenMarkProjectMutation,
    useLazyGetGMAPGreenMarkProjectListQuery
} = greenMarkProjectApi;

export default greenMarkProjectApi;