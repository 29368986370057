import { GridColumnType } from "../../data/gridColumnType";


// Current Cpd Cycle Table GridConfig start
export const ViewResourcesGridConfig = {
  columns: [
    {
      name: "Resource Name",
      fieldName: "resourceName",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
      name: "Type",
      fieldName: "type",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
      name: "Date Posted",
      fieldName: "datePosted",
      colType: GridColumnType.Text,

    },
    {
      name: "Status",
      fieldName: "status",
      colType: GridColumnType.Text,
    },
    {
      name: "Posted by",
      fieldName: "postedby",
      colType: GridColumnType.Text,
    },
    
    {
      name: "Action",
      colType: GridColumnType.ACTION,
      defaultAction: {
        allowEdit: false,
        allowDelete: true,
      },
      customActions: [
        { name: "View/Edit", path: "/createEditResources" },
        { name: "Post Approval", path: "/" },
      ],
    },
  ],
};
