/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useRef, useImperativeHandle, useState, useContext } from "react";
import { certificateCourseData } from "./CertificateCourse.Data";
import CertificateCourseForm from "./component/CertficateCourseForm";
import { CreateIndividualMembersContext } from "../../../../../pages/gmap/viewIndividualMembers/createIndividualMembers";
import Buttons from "../../../../../components/ui/button/Buttons";
import { useAddEditGMAPCertificationCourseMutation, useDeleteGMAPCertificationCourseMutation, useLazyGetGMAPCertificationCourseListQuery } from "../../../../../app/services/GMAP/CertificateCourseApi";
import ToastService from "../../../../../services/toastService/ToastService";
import SwalAlert from "../../../../../services/swalService/SwalService";
import { useLazyGetPDFQuery, useUploadPDFMutation } from "../../../../../app/services/common/commonApi";
import { saveAs } from 'file-saver'
import { APIUrl } from "../../../../../utils/API/fetchBaseQuery";

const CertificateCourse = forwardRef((props, ref) => {
    const { newMemberData, setContextData } = useContext(CreateIndividualMembersContext);
    const certificateCourseRef = useRef();
    const [certificateCourseFormData, SetCertificateCourseFormData] = useState(certificateCourseData.initialState);
    const [isEdit, setIsEdit] = useState(false);
    const [certificateAttachment, setCertificateAttachment] = useState();
    const [CertificateList, setCertificateList] = useState();
    const { error, confirm } = SwalAlert();

    const [getPDF] = useLazyGetPDFQuery();

    const [addEditGMAPCertificate,
        { isSuccess: isAddGMAPCertificate,
            isLoading: isAddGMAPCertificateLoading,
            data: addEditCertificateData }
    ] = useAddEditGMAPCertificationCourseMutation();

    const [getGMAPCertificateList,
        { isSuccess: isGetGMAPCertificateFetched,
            isFetching: isGetGMAPCertificateFetching,
            data: CertificateListResponse },
    ] = useLazyGetGMAPCertificationCourseListQuery()

    const [deleteGMAPCertificateHistory,
        { isSuccess: isDeleteSuccess,
            data: deleteCertificateeHistory },
    ] = useDeleteGMAPCertificationCourseMutation();

    const [uploadPDF] = useUploadPDFMutation();


    useEffect(() => {
        if (isAddGMAPCertificate && addEditCertificateData) {
            ToastService.success("Add Item successfully")
            const data = responceValid(addEditCertificateData);
            if (data) {
                getCertificateList();
            }
        }
    }, [isAddGMAPCertificate, addEditCertificateData]);

    useEffect(() => {
        if (isDeleteSuccess && deleteCertificateeHistory) {
            ToastService.error("Delete Item successfully");
            getCertificateList();
        }
    }, [isDeleteSuccess && deleteCertificateeHistory])

    useEffect(() => {
        if (!isGetGMAPCertificateFetching && isGetGMAPCertificateFetched && CertificateListResponse) {
            let tempCertificateListResponse = CertificateListResponse
            tempCertificateListResponse = tempCertificateListResponse.map((obj) => {
                return { ...obj, certificateLink: `${APIUrl}/Common/getPDF?type=CertificateMarketRefresherCourse&fileName=${obj.certificateAttachment}` }
            })

            setCertificateList(tempCertificateListResponse);

            const tableIndex = certificateCourseData.formFields.findIndex(field => field.id === "certificateHistoryTable");
            if (tableIndex !== -1) {
                const updatedFormData = { ...certificateCourseData };
                updatedFormData.formFields[tableIndex].fieldSetting.dataSource = tempCertificateListResponse;
            }
        }
    }, [isGetGMAPCertificateFetching, isGetGMAPCertificateFetched, CertificateListResponse])

    const responceValid = (responce) => {
        if (responce !== null) {
            const data = responce;
            if (data.errorMessage === "") {
                return data;
            }
            else {
                alert("error")
            }
        }
    }
    const getCertificateList = () => {
        let request = [newMemberData.gMAPMemberID]
        getGMAPCertificateList(request);
    }
    const handleAdd = () => {
        let certficateDetails = certificateCourseRef.current.getFormData();
        if (certficateDetails) {
            let pathway = certficateDetails.gmaPathwayTypeID?.value || certficateDetails.gmaPathwayTypeID;
            let certificateValue = null;
            if (pathway === 1) {
                certificateValue = certficateDetails.gmapCertificateTypeP1Id;
            } else if (pathway === 2) {
                certificateValue = certficateDetails.gmapCertificateTypeP2Id;
            }

            let gmaCertificateType = certificateValue?.value || certificateValue;
            let gmapCertificateType = certficateDetails?.gmapCertificateTypeId?.value || certficateDetails?.gmapCertificateTypeId;
            let gmaapCertificateType = certficateDetails?.gmaapCertificateTypeId?.value || certficateDetails?.gmaapCertificateTypeId;

            let certficateObj = {
                gmaPathwayTypeID: pathway || certficateDetails.gmaPathwayTypeID,
                gmapCertificateTypeId: gmapCertificateType,
                gmaCertificateTypeId: gmaCertificateType,
                gmaapCertificateTypeId: gmaapCertificateType,
                certificateDate: certficateDetails.certificateDate,
                certificateNumber: certficateDetails.certificateNumber,
                certificateAttachment: certificateAttachment,
                base64Data: certficateDetails.certificateAttachment.base64Data,
                AppID: 114,
                gMAPMemberId: newMemberData.gMAPMemberID,
            }
            if (!certficateDetails.gmapCertificationCourseId && !isEdit) {
                addEditGMAPCertificate(certficateObj);
            } else if (certficateDetails.gmapCertificationCourseId && isEdit) {
                certficateObj.gmapCertificationCourseId = certficateDetails.gmapCertificationCourseId;
                addEditGMAPCertificate(certficateObj);
                resetForm();
            }
        }
    }
    const handleTableEdit = (data) => {
        setIsEdit(true);
        let EditFormData = {};

        EditFormData.gmapCertificateTypeP1Id = data.gmaCertificateTypeId;
        EditFormData.gmapCertificateTypeP2Id = data.gmaCertificateTypeId;
        let mergedData = {
            ...data, // Copy existing properties from data
            // Assign properties from EditFormData directly
            gmapCertificateTypeP1Id: EditFormData.gmapCertificateTypeP1Id,
            gmapCertificateTypeP2Id: EditFormData.gmapCertificateTypeP2Id,
        };

        SetCertificateCourseFormData(mergedData);
    }
    const handleTableDelete = (data) => {
        let key = data.gmapCertificationCourseId;
        let request = {
            Id: key,
            AppId: 114,
            DeletedBy: newMemberData.gMAPMemberID,
        }
        confirm("Delete?", "Are you sure you want to delete?", "Delete", "Cancel")
            .then((confirmed) => {
                if (confirmed) {
                    deleteGMAPCertificateHistory(request);
                    resetForm();
                }
            });
    }
    //** Reset Data */
    const resetForm = () => {
        let form = { ...certificateCourseFormData.initialState };
        SetCertificateCourseFormData(form);
        setIsEdit(false);
    };

    const handleOnChangeFileUpload = async (data) => {
        let formData = new FormData();
        formData.append("file", data)
        formData.append("storagePath", "CertificateMarketRefresherCourse");
        try {
            const response = await uploadPDF(formData);
            setCertificateAttachment(response.data.fileName);
            const updatedContext = { ...newMemberData, certificateMarketRefresherCourse: response.data.fileName };
            setContextData(updatedContext)
        } catch (e) {
            error(e, "Error uploading file:");
        }
    }

    const handleFormFieldChange = (dataField, value) => { // for file upload changes
        if (dataField === "certificateAttachment" && value) {
            handleOnChangeFileUpload(value)
        }
    };


    const handleDownload = async (data, dataField) => {
        const request = {
            type: "",
            fileName: newMemberData?.certificateMarketRefresherCourse,
        }
        if (dataField === "certificateAttachment") {
            request.type = "CertificateMarketRefresherCourse"
        }
        const response = await getPDF(request);
        saveAs(response?.data?.fileData, response?.data?.fileName);
    }

    return (
        <React.Fragment>
            <div className="col-lg-12">

                <ul className="text-danger">
                    {newMemberData.errorCertificateType?.length > 0 && <p className="text-danger">At least one of the following is compulsory:</p>}
                    {newMemberData.errorCertificateType?.map((item) => <li>{item}</li>)}
                </ul>
            </div>
            <CertificateCourseForm
                onDownload={handleDownload}
                ref={certificateCourseRef}
                initData={certificateCourseFormData}
                isEdit={isEdit}
                onReset={resetForm}
                onFormFieldValueChange={handleFormFieldChange}
                handleTableEdit={handleTableEdit} handleTableDelete={handleTableDelete}
            />
            <div className="col-md-12 mb-2 mt-2 d-flex justify-content-end">
                <Buttons
                    buttonText={isEdit ? "Update" : "+ Add"}
                    buttonTypeClassName="outline-btn"
                    onClick={handleAdd}
                />
            </div>
        </React.Fragment>
    )
})

export default CertificateCourse



// const link = `${APIURL}/Common/getPDF?type=${type}&fileName=${fileName}`;