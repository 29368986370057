import { EditGridColumnType } from "../../../components/FinalMolGrid/libs/data/editGridColumnType";
import { GridColumnType } from "../../../data/gridColumnType";

export const editableMolGridConfig = {
    columns: [
        {
            name: "Title",
            fieldName: "title",
            allowShort: false,
            colStyle: {
                width: "10%",
                textAlign: "center",
            }
        },
        {
            name: "Director",
            fieldName: "director",
            allowEditColumn: true,
            editColumn: {
                editColType: EditGridColumnType.INPUT,
                placeholder: "test",
                editColFieldName: "director",
                isDisable: false,
                colConfig: {
                    maxLength: 5,
                    isReadOnly: false,
                },
                editColValidation: [
                    { type: "required", message: "director is required." },
                ],
            },
            colStyle: {
                width: "30%",
                textAlign: "center",
            },
            allowShort: false
        },
        {
            name: "Release Date",
            fieldName: "releaseDate",
            colType: GridColumnType.DATE,
            colSettings: {
                format: "DD-MMM-YYYY"
            },
            allowEditColumn: true,
            editColumn: {
                editColType: EditGridColumnType.DATEPICKER,
                editColFieldName: "releaseDate",
                isDisable: false,
                colConfig: {
                    format: "DD/MM/YYYY"
                },
                editColValidation: [
                    { type: "required", message: "Release Date is required." },
                ],
            },
            allowShort: false,
        },
        {
            name: "Box Office",
            fieldName: "boxOffice",
            colType: GridColumnType.MONEY,
            allowEditColumn: true,
            editColumn: {
                editColType: EditGridColumnType.NUMERIC,
                editColFieldName: "boxOffice",
                isDisable: false,
                editColValidation: [
                    { type: "required", message: "Price is required." },
                ],
            },
            colStyle: {
                textAlign: "right",
            },
            allowShort: false
        },
        {
            name: "Lead Cast",
            fieldName: "leadCast",
            allowEditColumn: true,
            editColumn: {
                editColType: EditGridColumnType.DROPDOWN,
                editColFieldName: "leadCast",
                isDisable: false,
                isMultiSelect: false,
                options: [
                    { value: 1, label: "Alpha" },
                    { value: 2, label: "Beta" },
                    { value: 3, label: "Gamma" },
                    { value: 4, label: "Delta" },
                ],
                editColValidation: [
                    // { type: "required", message: "Lead Cast is required." },
                ],
            },
            colStyle: {
                textAlign: "right",
            },
            allowShort: false
        },
        // {
        //     name: "Active",
        //     fieldName: "isActive",
        //     colType: GridColumnType.TOGGLE,
        //     colSettings: {
        //         isChecked: false,
        //         isDisabled: false,
        //     },
        //     allowShort: false
        // },
        // {
        //     name: "Status",
        //     fieldName: "status",
        //     colType: GridColumnType.LABLE,
        //     colSettings: {
        //         valueField: "status",
        //         getLableClass: (value) => {
        //             switch (value) {
        //                 case true:
        //                     return "primary";
        //                 case false:
        //                 default:
        //                     return "secondary";
        //             }
        //         },
        //     },
        //     allowShort: false
        // },
        {
            name: "Status",
            fieldName: "status",
            colType: GridColumnType.CHECKBOX,
            colSettings: {
                isDisabled: false,
                allowEdit: true
            },
            allowEditColumn: true,
            editColumn: {
                editColType: EditGridColumnType.CHECKBOX,
                editColFieldName: "status",
                isDisable: false,
                editColValidation: [
                    { type: "required", message: "Price is required." },
                ],
            },
            allowShort: false
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: true,
            },
            allowShort: false
        },
    ],
    editSettings: {
        buttons: {
            save: true,
            cancel: true
        }
    },
    allowEdit: true,
    handleRowDataUpdate: null,
    OnColumnChangeEdit: null,
    hasChildGridTable: false,
}