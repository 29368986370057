/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import CareerHistoryForm from "./component/CareerHistoryForm";
import { careerHistoryData } from "./CareerHistory.data";
import Buttons from "../../../../../components/ui/button/Buttons";
import ToastService from "../../../../../services/toastService/ToastService";
import { HUNDREAD } from "../../../../../data/constantVariables";
import SwalAlert from "../../../../../services/swalService/SwalService";
import { getData } from "../../../../../utils/LocalStorage/LocalStorageManager";
import { useAddEditGMAPCareerHistoryMutation, useDeleteGMAPCareerHistoryMutation, useLazyGetGMAPCareerHistoryListQuery } from "../../../../../app/services/GMAP/careerHistoryApi";
import { decryptAESID } from "../../../../../utils/Encryption";
import { useParams } from "react-router-dom";
import { EditDiscretionaryApplicationDataContext } from "../../EditDiscretionaryRenewalTaskListApplication";
import { handleConditionBaseFields } from "./utils/CareerHistoryFormUtils";

const CareerHistory = forwardRef((props, ref) => {
    const { newMemberData, setContextData } = useContext(EditDiscretionaryApplicationDataContext);
    const { id } = useParams();
    const gmapMemberId = id ? decryptAESID(id, false) : 0;
    const formRefs = useRef({
        careerHistoryRef: null,
        currentEmpRef: null,
    });
    const [careerHistoryFormData, setCareerHistoryFormData] = useState(careerHistoryData.initialState);
    const [isEdit, setIsEdit] = useState(false);
    const [addEditGMAPCareerHistory,
        { isSuccess: isAddGMAPCareer,
            data: addEditCareerHistoryData }
    ] = useAddEditGMAPCareerHistoryMutation();

    const [getGMAPCareerHistoryList,
        { isSuccess: isGetGMAPCareerHistoryFetched,
            isFetching: isGetGMAPCareerHistoryFetching,
            data: CareerHistoryListResponse },
    ] = useLazyGetGMAPCareerHistoryListQuery();

    const [deleteGMAPCareerHistory,
        { isSuccess: isDeleteSuccess,
            data: deleteCareereHistory },
    ] = useDeleteGMAPCareerHistoryMutation();

    const { confirm, error } = SwalAlert();

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const currentEmpDetails = formRefs.current.currentEmpRef.getFormData();
                return currentEmpDetails;
            }
            return null;
        }
    }))

    useEffect(() => {
        if (isAddGMAPCareer && addEditCareerHistoryData) {
            ToastService.success("Add Item successfully")
            const data = responceValid(addEditCareerHistoryData);
            if (data) {
                getCareerHistoryList();
            }
        }
    }, [isAddGMAPCareer, addEditCareerHistoryData]);
    useEffect(() => {
        if (isDeleteSuccess && deleteCareereHistory) {
            ToastService.error("Delete Item successfully");
            getCareerHistoryList();
        }
    }, [isDeleteSuccess && deleteCareereHistory])

    useEffect(() => {
        if (!isGetGMAPCareerHistoryFetching && isGetGMAPCareerHistoryFetched && CareerHistoryListResponse) {

            const tableIndex = careerHistoryData.formFields.findIndex(field => field.id === "careerHistoryTable");
            if (tableIndex !== -1) {
                const updatedFormData = { ...careerHistoryData };
                updatedFormData.formFields[tableIndex].fieldSetting.dataSource = CareerHistoryListResponse;
            }
            const updatedContext = { ...newMemberData, careerHistory: HUNDREAD, careerHistoryData: CareerHistoryListResponse }
            setContextData(updatedContext);
        }
    }, [isGetGMAPCareerHistoryFetching, isGetGMAPCareerHistoryFetched, CareerHistoryListResponse])

    const responceValid = (responce) => {
        if (responce !== null) {
            const data = responce;
            if (data.errorMessage === "") {
                return data;
            }
            else {
                alert("error")
            }
        }
    }
    const getCareerHistoryList = async () => {
        let request = [gmapMemberId]
        getGMAPCareerHistoryList(request);
    }
    const handleAdd = () => {
        const careerDetails = formRefs.current.careerHistoryRef.getFormData();
        if (careerDetails) {
            let careerObjReq = {
                isUnemployed: false,
                companyName: careerDetails.companyName || "-",
                designation: careerDetails.designation || "-",
                periodFrom: careerDetails.periodFrom,
                periodTo: careerDetails.periodTo,
                isCurrent: false,
                gmapProfessionTypeId: careerDetails.gmapProfessionTypeId?.value || careerDetails.gmapProfessionTypeId,
                AppID: 114,
                gMAPMemberId: gmapMemberId,
                GMAPOtherProfession: careerDetails?.otherProfessionName || null
            };
            if (!careerDetails.gmapCareerHistoryId && !isEdit) {
                addEditGMAPCareerHistory(careerObjReq);
            } else if (careerDetails.gmapCareerHistoryId && isEdit) {
                careerObjReq.gmapCareerHistoryId = careerDetails.gmapCareerHistoryId;
                addEditGMAPCareerHistory(careerObjReq);
                resetForm();
            }
        }
    }
    const handleTableEdit = (data) => {
        setIsEdit(true);
        setCareerHistoryFormData(data);
        if (data?.otherProfessionName) {
            handleConditionBaseFields(data?.gmapProfessionTypeId, "pastOtherProfessionName");
        }
    }
    const handleTableDelete = (data) => {
        let key = data.gmapCareerHistoryId;
        let request = {
            Id: key,
            AppId: 114,
            DeletedBy: gmapMemberId,
        }
        confirm("Delete?", "Are you sure you want to delete?", "Delete", "Cancel")
            .then((confirmed) => {
                if (confirmed) {
                    deleteGMAPCareerHistory(request);
                    resetForm();
                }
            });


    }
    //** Reset Data */
    const resetForm = () => {
        let form = { ...careerHistoryData.initialState };
        setCareerHistoryFormData(form);
        setIsEdit(false);
    };

    return (
        <React.Fragment>
            <CareerHistoryForm
                ref={formRefs}
                initData={careerHistoryFormData}
                isEdit={isEdit}
                onReset={resetForm}
                handleTableEdit={handleTableEdit} handleTableDelete={handleTableDelete}
            />
            <div className="col-md-12 mb-2 d-flex justify-content-end">
                <Buttons
                    buttonText={isEdit ? "Update" : "+ Add"}
                    buttonTypeClassName="outline-btn"
                    onClick={handleAdd}
                />
            </div>
        </React.Fragment>
    )
})

export default CareerHistory