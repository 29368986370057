import React, { useEffect, useRef, useState } from 'react'
import FormCreator from '../../../components/Forms/FormCreator';
import { DiscretionaryRenewalRequestSearchFormData } from './DataFile/DiscretionaryRenewalRequestSearchForm.Data';
import DiscretionaryRenewalDataGridForm from './Components/DiscretionaryRenewalDataGridForm';
import Buttons from '../../../components/ui/button/Buttons';
import { useGetDiscretionaryRenewalRequestListMutation } from '../../../app/services/GMAP/DiscrertionaryRenewalApi';

const DiscretionaryRenewalRequest = () => {
    const searchFormRef = useRef();

    const [searchForm, setSearchForm] = useState(DiscretionaryRenewalRequestSearchFormData);
    const [dataSource, setDataSource] = useState([]);
    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 10,
        endPage: 100,
        searchText: "",
        totalCount: 0,
    });
    const [getDiscretionaryRequestList,
        { isSuccess: isGetDiscretionaryRequestListSuccess,
            isLoading: isGetDiscretionaryRequestListFetching,
            data: discretionaryRequestListResponse },
    ] = useGetDiscretionaryRenewalRequestListMutation();

    useEffect(() => {
        if (!isGetDiscretionaryRequestListFetching && isGetDiscretionaryRequestListSuccess && discretionaryRequestListResponse) {
            setPaginationRequest({ ...paginationRequest, totalCount: discretionaryRequestListResponse.totalCount })
            setDataSource(discretionaryRequestListResponse.itemList)
            // console.log({ discretionaryRequestListResponse })
        }
    }, [isGetDiscretionaryRequestListFetching, isGetDiscretionaryRequestListSuccess, discretionaryRequestListResponse]);

    const getDiscretionaryRenewalList = (requestData) => {
        getDiscretionaryRequestList({ ...paginationRequest, ...requestData })
    }

    const handleSearch = () => {
        if (!searchFormRef.current) {
            return;
        }
        const requestListFormData = searchFormRef.current.getFormData();
        const updatedPaginationRequest = {
            pageNo: 1,
            pageSize: 10,
            endPage: 100,
            searchText: requestListFormData.searchText,
        }; debugger
        setPaginationRequest(updatedPaginationRequest);
        getDiscretionaryRenewalList(updatedPaginationRequest);
    }

    return (
        <div>
            <div className='d-flex justify-content-between align-items-end'>
                <div >
                    <FormCreator
                        ref={searchFormRef}
                        {...searchForm}
                    ></FormCreator>
                </div>
                <div className='mt-2'>
                    <Buttons
                        buttonText="Search"
                        buttonTypeClassName="success-btn"
                        onClick={handleSearch}
                    />
                </div>
            </div>
            <br />
            <div>
                <DiscretionaryRenewalDataGridForm dataSource={dataSource} paginationRequest={paginationRequest} isGetDiscretionaryRequestListFetching={isGetDiscretionaryRequestListFetching} getDiscretionaryRequestList={getDiscretionaryRenewalList} />
            </div>
        </div >
    )
}

export default DiscretionaryRenewalRequest