import React, { Suspense, useEffect, useRef, useState } from 'react'
import CardSection from '../../../../components/ui/card/CardSection'
import DiscretionaryRenewalAccountInformation from './AccountInformation/DiscretionaryRenewalAccountInformation'
import { DiscretionaryRenewalMessageData } from '../DataFile/DiscretionaryRenewalMessage.Data'
import FormCreator from '../../../../components/Forms/FormCreator'
import Buttons from '../../../../components/ui/button/Buttons'
import { useLazyGetAllDropDownsForMemberDirectoryQuery } from '../../../../app/services/common/commonApi'
import { setAllDropDownFieldForDiscretionary } from '../utils/UtilsEditDiscretionaryRenewal'
import { useNavigate, useParams } from 'react-router-dom'
import DataLoader from '../../../../components/ui/dataLoader/DataLoader'
import { useApproveDiscretionaryRenewalRequestByIdMutation, useLazyGetDiscretionaryRenewalRequestByIdQuery, useRejectDiscretionaryRenewalRequestByIdMutation } from '../../../../app/services/GMAP/DiscrertionaryRenewalApi'
import { decryptAESID, encryptAESID } from '../../../../utils/Encryption'
import SwalAlert from '../../../../services/swalService/SwalService'
import CenterModel from '../../../../components/ui/centerModel/CenterModel'
import TinyEditor from '../../../../components/ui/inputs/TinyEditor/TinyEditor'

const EditDiscretionaryrenewalRequest = () => {
    const { memberId, requestId } = useParams();
    const { confirm, success, error } = SwalAlert();
    const discretionaryRenewalMessageFormRef = useRef();
    const [isModalOpenReject, setIsModalOpenReject] = useState(false);
    const [rejectComments, setRejectComments] = useState("");
    const discretionaryRenewalAccountInformationRef = useRef();
    const [discretionaryRenewalMessageData, setDiscretionaryRenewalMessageData] = useState(DiscretionaryRenewalMessageData);
    const navigate = useNavigate();
    const [getAllDropDowns,
        { isSuccess: isGetAllDropDownFetched,
            isLoading: isGetDropDownFetching,
            data: allDropDownResponse },
    ] = useLazyGetAllDropDownsForMemberDirectoryQuery();

    const [getDiscretionaryRenewalRequestById,
        { isSuccess: getDiscretionaryRenewalRequestByIdIsFetching,
            isLoading: getDiscretionaryRenewalRequestByIdIsLoading,
            data: getDiscretionaryRenewalRequestByIdResponse },
    ] = useLazyGetDiscretionaryRenewalRequestByIdQuery();

    const [rejectApplication,
        { isSuccess: isSuccessReject,
            isLoading: isFetchingAppReject,
            data: rejectApplicationResponse },
    ] = useRejectDiscretionaryRenewalRequestByIdMutation();

    const [approveRenewalRequestById,
        { isSuccess: approveRenewalRequestByIdIsFetching,
            isLoading: approveRenewalRequestByIdIsLoading,
            data: approveRenewalRequestByIdResponse },
    ] = useApproveDiscretionaryRenewalRequestByIdMutation();


    useEffect(() => {
        if (!isFetchingAppReject && isSuccessReject && rejectApplicationResponse) {
            setIsModalOpenReject(!isModalOpenReject)
            success("", "Application Rejected Successfully ").then(() => navigate(-1))
        }
    }, [isFetchingAppReject, isSuccessReject, rejectApplicationResponse]);


    useEffect(() => {
        if (!approveRenewalRequestByIdIsLoading && approveRenewalRequestByIdIsFetching && approveRenewalRequestByIdResponse) {
            success("", "Request Approved Successfully").then(() => navigate(-1))

        }
    }, [approveRenewalRequestByIdIsLoading, approveRenewalRequestByIdIsFetching, approveRenewalRequestByIdResponse]);

    useEffect(() => {
        if (!getDiscretionaryRenewalRequestByIdIsLoading && getDiscretionaryRenewalRequestByIdIsFetching && getDiscretionaryRenewalRequestByIdResponse) {
            let messageInitial = discretionaryRenewalMessageData.initialState;
            messageInitial.message = getDiscretionaryRenewalRequestByIdResponse.message;
            setDiscretionaryRenewalMessageData({ ...discretionaryRenewalMessageData, initialState: messageInitial })
        }
    }, [getDiscretionaryRenewalRequestByIdIsLoading, getDiscretionaryRenewalRequestByIdIsFetching, getDiscretionaryRenewalRequestByIdResponse]);

    useEffect(() => {
        getAllDropDowns();
    }, [])

    useEffect(() => {
        if (requestId && memberId) {
            let req = { requestId: decryptAESID(requestId), memberId: decryptAESID(memberId) }
            getDiscretionaryRenewalRequestById(req);
        }
    }, [memberId, requestId])

    useEffect(() => {
        if (!isGetDropDownFetching && isGetAllDropDownFetched && allDropDownResponse) {
            setAllDropDownFieldForDiscretionary(allDropDownResponse);
        }
    }, [isGetDropDownFetching, isGetAllDropDownFetched, allDropDownResponse]);

    const handleApprove = () => {
        let req = { MemberId: decryptAESID(memberId), TaskId: decryptAESID(requestId) };
        approveRenewalRequestById(req);
    }
    const handleRejectApp = () => {
        if (rejectComments === "" || rejectComments === undefined || rejectComments === null) {
            return error("The comments field for rejecting the application is mandatory and must be filled out.", "error")
        }
        let request = {
            GMAPMemberId: Number(decryptAESID(memberId)),
            RequestId: Number(decryptAESID(requestId)),
            SendRejectionReason: rejectComments
        }
        confirm("Reject Application?", "Are you sure you want to Reject?", "Yes", "No")
            .then((confirmed) => {
                if (confirmed) {
                    rejectApplication(request);
                }
            });
    }
    const handleTinyOnChange = (text, section) => {
        switch (section) {
            // case "Clarifications":
            //     setClarificationsComments(text);
            //     break;
            case "Reject":
                setRejectComments(text);
                break;
            // case "Appeal":
            //     setAppealComments(text);
            //     break;
            default:
                break;
        }

    };

    const openModalRejectApp = () => {
        setIsModalOpenReject(!isModalOpenReject);
    }
    return (
        <>
            <CardSection cardTitle="Account Information">
                <div className="row align-items-end mb-2">
                    <div className="col-10">
                        {isGetDropDownFetching ? <DataLoader /> :
                            <DiscretionaryRenewalAccountInformation ref={discretionaryRenewalAccountInformationRef} />
                        }
                    </div>
                    <div className="col-2 d-flex justify-content-end">
                        {/* <div className="reset-password-button">
                        <Buttons
                            buttonText="Reset Password"
                            buttonTypeClassName="success-btn"
                        />
                    </div> */}
                    </div>
                </div>
            </CardSection>
            <CardSection cardTitle="Message">
                <div className="row align-items-end mb-2">
                    <div className="col-lg-12">
                        <FormCreator
                            ref={discretionaryRenewalMessageFormRef}
                            {...discretionaryRenewalMessageData}
                        />
                    </div>
                </div>
            </CardSection>
            <div className='d-flex justify-content-between'>
                <Buttons
                    buttonText="Reject"
                    // isLoading={isFetchingAppUpdate}
                    buttonTypeClassName="red-btn btn-sm mx-2 "
                    onClick={openModalRejectApp}
                />
                <Buttons
                    buttonText="Approve"
                    buttonTypeClassName="success-btn btn-sm mx-2"
                    isLoading={approveRenewalRequestByIdIsLoading}
                    onClick={handleApprove}
                />
            </div>
            {isModalOpenReject &&
                <CenterModel
                    modelTitle={'Reject Application'}
                    show={"test"}
                    handleClose={openModalRejectApp}
                    onUpload={openModalRejectApp}
                >
                    <TinyEditor
                        onTinyMceEditorChange={(e) => handleTinyOnChange(e, "Reject")}
                    />
                    <Buttons
                        buttonText="Reject Application"
                        buttonTypeClassName="red-btn mt-5"
                        isLoading={isFetchingAppReject}
                        onClick={handleRejectApp}
                    />
                </CenterModel>
            }
        </>
    )
}

export default EditDiscretionaryrenewalRequest