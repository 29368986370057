import ApproveCpdSubmissionForm from "../../../features/cpd/verifyCpdSubmission/ApproveCpdSubmissionForm";
// import ViewResourcesTable from "../../../features/resources/components/ViewResourcesTable";
// const ApproveCpdSubmissionTableData = [
//   {
//     cpdNo: "122",
//     applicantName: "John Tan",
//     gmapTier: "GMAP",
//     cpdActivity: "Research Paper",
//     status: "Pending",
//     submittedDate: "8 Jan 2024",
//     receivedby: "Ming De",
//     actions: "Process",
//     website: "/CpdSubmission",
//   },
//   {
//     cpdNo: "123",
//     applicantName: "John Tan",
//     gmapTier: "GMAP",
//     cpdActivity: "SGBC e-Learning",
//     status: "Pending",
//     submittedDate: "18 Jan 2024",
//     receivedby: "Ming De",
//     actions: "Process",
//     website: "/CpdSubmission",
//   },
//   {
//     cpdNo: "124",
//     applicantName: "James Lim",
//     gmapTier: "GMA",
//     cpdActivity: "Involvement in Committee",
//     status: "Invalid",
//     submittedDate: "1 Feb 2024",
//     receivedby: "Open",
//     actions: "View",
//     website: "/CpdSubmission",
//   },
//   {
//     cpdNo: "125",
//     applicantName: "John Tan",
//     gmapTier: "GMAP",
//     cpdActivity: "SGBC e-Learning",
//     status: "Verified",
//     submittedDate: "8 Jan 2024",
//     receivedby: "Ming De",
//     actions: "View",
//     website: "/CpdSubmission",
//   },
//   {
//     cpdNo: "126",
//     applicantName: "Marge Lee",
//     gmapTier: "GMAAP",
//     cpdActivity: "SGBC e-Learning",
//     status: "Pending",
//     submittedDate: "30 Mar 2024",
//     receivedby: "Open",
//     actions: "Process",
//     website: "/CpdSubmission",
//   },
//   {
//     cpdNo: "127",
//     applicantName: "Tan Kim How",
//     gmapTier: "GMAP",
//     cpdActivity: "Involvement in Committee",
//     status: "Pending",
//     submittedDate: "1 Jun 2024",
//     receivedby: "Ming De",
//     actions: "Process",
//     website: "/CpdSubmission",
//   },
//   {
//     cpdNo: "128",
//     applicantName: "Mark Lim",
//     gmapTier: "GMAP",
//     cpdActivity: "Research Paper",
//     status: "Approved",
//     submittedDate: "10 Jun 2024",
//     receivedby: "Alvin",
//     actions: "Process",
//     website: "/CpdSubmission",
//   },
//   {
//     cpdNo: "129",
//     applicantName: "Aisha Ahmad",
//     gmapTier: "GMA",
//     cpdActivity: "Research Paper",
//     status: "Approved",
//     submittedDate: "10 Jun 2024",
//     receivedby: "Alvin",
//     actions: "Process",
//     website: "/CpdSubmission",
//   },

// ];

const ApproveCpdSubmission = () => {
  // const ApproveCpdSubmissionFormRef = useRef();
  // const [approveCpdSubmissionForm] = useState(ApproveCpdSubmissionData);

  return (
    <>

      <ApproveCpdSubmissionForm />
      {/* <div className="row align-items-end">
        <div className="col-10">
          <div className="row gx-5">
            <FormCreator
              ref={ApproveCpdSubmissionFormRef}
              {...approveCpdSubmissionForm}
            // onActionChange={formActionHandler}
            />
          </div>
        </div>
        <div className="col-2 d-flex justify-content-end">
          <Buttons
            buttonTypeClassName="success-btn"
            buttonText="Search"
          // onClick={handleLogin}
          // isLoading={loginLoading}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12">
          <div className="table-responsive green-head-table">
            <ApproveCpdSubmissionTable approveCpdSubmissionTableData={ApproveCpdSubmissionTableData} />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ApproveCpdSubmission;
