import React, { useEffect, useRef, useState } from 'react'
import MolGrid from '../../../../components/Grid/MolGrid'
import { DiscretionaryRenewalRequestTableConfig } from '../DataFile/DiscretionaryRenewalRequestSearchForm.Data'
import { useNavigate } from 'react-router-dom';
import { encryptAESID } from '../../../../utils/Encryption';

const DiscretionaryRenewalDataGridForm = (props) => {
    const dataGridRef = useRef();
    const navigate = useNavigate();

    useEffect(() => { getDiscretionaryRenewalList() }, [])

    const handleEditClick = (data) => {
        navigate(`/editDiscretionaryrenewalRequest/${encryptAESID(data.gmapMemberId, false)}/${encryptAESID(data.discretionaryRenewalRequestId, false)}`)
    }

    const getDiscretionaryRenewalList = (requestData) => {
        props.getDiscretionaryRequestList({ ...props.paginationRequest, ...requestData })
    }

    const actionHandler = {
        UPDATE: handleEditClick,
    }
    const handlePageChange = ({ pageNumber, pageSize }) => {
        getDiscretionaryRenewalList({ ...props.paginationRequest, pageNo: pageNumber, pageSize: pageSize })
    }

    return (
        <div>
            <MolGrid
                ref={dataGridRef}
                configuration={DiscretionaryRenewalRequestTableConfig}
                dataSource={props.dataSource}
                allowPagination={true}
                isLoading={props.isGetDiscretionaryRequestListFetching}
                pagination={props.paginationRequest}
                onPageChange={handlePageChange}
                onActionChange={actionHandler}
            />
        </div>
    )
}

export default DiscretionaryRenewalDataGridForm