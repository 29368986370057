import { certificateCourseData } from "../CertificateCourse.Data";

const defaultCss = "col-md-6 mb-2";
const hiddenCss = "col-md-6 mb-2 d-none";
const defaultValidation = [{ type: "require" }];
const removeValidation = [];

const getFieldById = (id) => certificateCourseData.formFields.find((item) => item.id === id);


const setFieldStyleAndValidation = (field, containerCss, validation) => {
    field.style.containerCss = containerCss;
    field.validation = validation;
};


export const handleConditionBaseFields = (value, section) => {
    const gmapCertificateTypeP1Field = getFieldById("gmapCertificateTypeP1Id");
    const gmapCertificateTypeP2Field = getFieldById("gmapCertificateTypeP2Id");
    const IHLCertificateTypeField = getFieldById("IHLCertificateTypeId");
    if (section === "gmaPathwayTypeID") {
        setFieldStyleAndValidation(gmapCertificateTypeP1Field, value === 1 ? defaultCss : hiddenCss, value === 1 ? defaultValidation : removeValidation);
        setFieldStyleAndValidation(IHLCertificateTypeField, value === 1 ? defaultCss : hiddenCss, value === 1 ? defaultValidation : removeValidation);
        setFieldStyleAndValidation(gmapCertificateTypeP2Field, value === 2 ? defaultCss : hiddenCss, value === 2 ? defaultValidation : removeValidation);
    }
};


