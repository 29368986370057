import React, { useRef, useState } from 'react'
import FormCreator from '../../../../../components/Forms/FormCreator';
import { gmapMembershipData } from './GmapMembership.Data';

const GmapMembership = () => {
    const GmapMembershipRef = useRef();
    const [GmapMembershipForm, SetgmapMembership] = useState(gmapMembershipData);

    return (
        <div className="common-cpd-submission-description-parent">
            <div className='common-project-info-main'>
                <div className='row gx-5'>
                    <FormCreator ref={GmapMembershipRef} {...GmapMembershipForm} />
                </div>
            </div>
        </div>
    )
}

export default GmapMembership