import { FormFieldTypes } from "../../../../data/formFieldType";

export const SgbcsectionFormData = {
    initialState: {
        processedBy: "",
        cpdPointsAwarded: "",
    },
    formFields: [
        {
            id: "processedBy",
            lable: "Processed by",
            Field_Name: "Processed by",
            fieldType: FormFieldTypes.SELECT,
            dataField: "processedBy",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6  "
            }
        },
        {
            id: "cpdPointsAwarded",
            lable: "CPD Points Awarded",
            Field_Name: "CPD Points Awarded ",
            fieldType: FormFieldTypes.SELECT,
            dataField: "cpdPointsAwarded",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6"
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}