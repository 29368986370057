import React, { useEffect, useState } from 'react'
import FormCreator from '../../../../components/Forms/FormCreator'
import { useRef } from 'react'
import MonthlyXeroBatchFormData, { MonthlyXeroBatchonfig } from '../DataFiles/MonthlyXeroBatchForm.Data';
import MolGrid from '../../../../components/Grid/MolGrid';
import { useGenerateXeroMonthlyReportMutation, useGetXeroBatchJobListMutation } from '../../../../app/services/Financials/XeroBatchExtractionApi';
import Buttons from '../../../../components/ui/button/Buttons';
import SwalAlert from '../../../../services/swalService/SwalService';
import { useLazyGetExcelSheetQuery } from '../../../../app/services/common/commonApi';
import { saveAs } from "file-saver";
import ToastService from '../../../../services/toastService/ToastService';

const MonthlyXeroBatchForm = () => {
    const MonthlyXeroBatchFormRef = useRef();
    const MonthlyXeroBatchGridRef = useRef();
    const [dataSource, setDataSource] = useState();
    const { warning } = SwalAlert()
    const [monthlyXeroBatchFormData, setMonthlyXeroBatchFormData] = useState(MonthlyXeroBatchFormData);
    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 10,
        endPage: 100,
        generateFromDate: null,
        totalCount: 0,
    });

    const [GetExcelSheet] = useLazyGetExcelSheetQuery();

    const [getXeroBatchList,
        { isSuccess: isXeroBatchListSuccess,
            isLoading: isXeroBatchListFetching,
            data: XeroBatchListResponse },
    ] = useGetXeroBatchJobListMutation();

    const [GenerateXeroMonthlyReport,
        { isSuccess: isGenerateXeroMonthlyReportSuccess,
            isLoading: isGenerateXeroMonthlyReportFetching,
            data: GenerateXeroMonthlyReportResponse },
    ] = useGenerateXeroMonthlyReportMutation();

    useEffect(() => {
        if (!isGenerateXeroMonthlyReportFetching && isGenerateXeroMonthlyReportSuccess && GenerateXeroMonthlyReportResponse) {
            console.log({ GenerateXeroMonthlyReportResponse })
            if (GenerateXeroMonthlyReportResponse.errorType === "EXISTS") {
                warning("", GenerateXeroMonthlyReportResponse.errorMessage)
            }
            const updatedPaginationRequest = {
                pageNo: 1,
                pageSize: 10,
                endPage: 100,
                generateFromDate: null,
                totalCount: 0,
            };
            setPaginationRequest(updatedPaginationRequest);
            getXeroBatchList(updatedPaginationRequest)
        }
    }, [isGenerateXeroMonthlyReportFetching, isGenerateXeroMonthlyReportSuccess, GenerateXeroMonthlyReportResponse]);

    useEffect(() => {
        if (!isXeroBatchListFetching && isXeroBatchListSuccess && XeroBatchListResponse) {
            setPaginationRequest({ ...paginationRequest, totalCount: XeroBatchListResponse.totalCount })
            setDataSource(XeroBatchListResponse.itemList)
            console.log({ XeroBatchListResponse })
        }
    }, [isXeroBatchListFetching, isXeroBatchListSuccess, XeroBatchListResponse]);

    useEffect(() => {
        getXeroBatchList(paginationRequest)
    }, [])

    const handlePageChange = ({ pageNumber, pageSize }) => {
        getXeroBatchList({ ...paginationRequest, pageNo: pageNumber, pageSize: pageSize })
    }

    const handleDownloadClick = async (data) => {
        console.log({ data })
        let request = {
            type: "FullFilePath",
            fileName: data.filePath,
        };
        const response = await GetExcelSheet(request);
        if (response?.data) {
            saveAs(response?.data?.fileData, data.batchNumber);
        }
        else {
            ToastService.error("Something Went Wrong");
        }
    }

    const actionHandler = {
        DOWNLOAD: handleDownloadClick,
    }

    const handleGenerateClick = () => {
        if (!MonthlyXeroBatchFormRef.current) {
            return;
        }

        const formData = MonthlyXeroBatchFormRef.current.getFormData();
        if (!formData) {
            return
        }

        const request = {
            SelectedDate: formData.generateFromDate
        }
        GenerateXeroMonthlyReport(request);
    };

    return (
        <div>
            <div className='d-flex justify-content-between  align-items-center'>
                <FormCreator
                    ref={MonthlyXeroBatchFormRef}
                    {...monthlyXeroBatchFormData} />
                <div className=''>
                    <Buttons
                        buttonTypeClassName="success-btn"
                        buttonText="Generate Last Month Batch Job"
                        onClick={handleGenerateClick}
                    />
                </div>
            </div>
            <MolGrid
                ref={MonthlyXeroBatchGridRef}
                configuration={MonthlyXeroBatchonfig}
                dataSource={dataSource}
                allowPagination={true}
                isLoading={isGenerateXeroMonthlyReportFetching || isXeroBatchListFetching}
                pagination={paginationRequest}
                onPageChange={handlePageChange}
                onActionChange={actionHandler}
            />
        </div>
    )
}

export default MonthlyXeroBatchForm
