import React from "react";
import Buttons from "../button/Buttons";
import SearchBar from "../../../components/ui/searchBar/SearchBar";

function CardSection({
  children,
  cardTitle,
  rightButton,
  buttonClassName,
  titleButtonClick,
  buttonText,
  searchInput,
  searchInputName,
  textWithIcon,
  iconImg,
  handleChange,
  isButtonVisible,
  isRightButtonComponent,
  rightButtonComponent
}) {
  return (
    <div
      className={`card-section ${cardTitle ? "card-section-left" : ""}${searchInput && rightButton ? "card-section-between" : ""
        }${rightButton ? "card-button-only" : ""}`}
    >
      {(cardTitle || rightButton) && (
        <div className="card-top-title-btn">
          {cardTitle && <div className="section-title">{cardTitle}</div>}
          {searchInput && (
            <div className="search-input">
              <SearchBar
                searchText={searchInputName}
                handleChange={handleChange}
              />
            </div>
          )}
          {
            isButtonVisible && (

              rightButton && (
                <div className="btn-right-sec">
                  {/* Button to open the Add Craft modal */}
                  <Buttons
                    buttonTypeClassName={buttonClassName}
                    onClick={titleButtonClick}
                    buttonText={buttonText}
                    textWithIcon={textWithIcon}
                    imagePath={iconImg}

                  />
                </div>
              )
            )
          }
          {
            isRightButtonComponent && <div className="btn-right-sec">
              {/* Button as component on right */}
              {rightButtonComponent}
            </div>
          }



        </div>
      )}
      <div className="card-body-sec">{children}</div>
    </div>
  );
}

export default CardSection;
