import React, { Suspense } from 'react'
import AppRoutes from './layouts/navigation/AppRoutes'
import { ComponentNavigation } from './layouts/navigation/ComponentNavigation'
import PageLoader from './components/ui/pageLoader/PageLoader'

const App = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <AppRoutes componentRoutes={ComponentNavigation} />
    </Suspense>
  )
}

export default App 