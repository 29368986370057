import React from 'react'
import GmapPricingTableForm from '../../../features/gmap/gmapPricingTable/GmapPricingTableForm'
import './GmapPricingTable.scss'

const GmapPricingTable = () => {
  return (
    <GmapPricingTableForm />
  )
}

export default GmapPricingTable