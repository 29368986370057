import React, { forwardRef } from 'react'
import CardSection from '../../../../components/ui/card/CardSection'

const Programme = forwardRef((props, ref) => {
    return (
        <React.Fragment>
            <CardSection cardTitle="Programme">
                <div className='row'>
                </div>
            </CardSection>
        </React.Fragment>
    )
})

export default Programme