import React, { useRef, useState } from "react";
import Buttons from "../../../components/ui/button/Buttons";
import ViewIndividualMemberTable from "./component/ViewIndividualMemberTable";
import { useNavigate } from "react-router-dom";
import FormCreator from "../../../components/Forms/FormCreator";
import { ViewIndividualMembersFormData } from "./component/ViewIndividualMembersForm.Data";
import { useLazyGetAllDropDownsQuery } from "../../../app/services/common/commonApi";
import { useEffect } from "react";
import MolGrid from "../../../components/Grid/MolGrid";
import { useGetIndividualMemberListMutation } from "../../../app/services/GMAP/gmapViewIndividualMember";
import { ViewIndividualMemberConfig } from "./component/ViewIndividualMembweTable.Data";
import { encryptAESID } from "../../../utils/Encryption";

const initState = {
    pageNo: 1,
    pageSize: 10,
    endPage: 100,
    SearchText: "",
    GMAPNumber: "",
    SubscriptionStatusId: null,
    searchByEmailAddress: "",
    GMAPApplicationTypeId: null,
    totalCount: 0,
}
const ViewIndividualMemberList = () => {
    const navigate = useNavigate();
    const molGridRef = useRef();
    const [dataSource, setDataSource] = useState();
    const [ViewIndividualMembersForm, SetViewIndividualMembersForm] = useState(ViewIndividualMembersFormData);
    const viewIndividualMembersRef = useRef();
    const [paginationRequest, setPaginationRequest] = useState(initState);
    const [getIndividualMemberList, { isSuccess: isSuccessList, isLoading: isFetchingList, data: individualMemberListResponse },] = useGetIndividualMemberListMutation();
    const [getAllDropDowns, { data: allDropDownResponse, isSuccess: isGetAllDropDownSuccess, isFetching: isGetDropDownFetching, isLoading: dropdownListLoading, },] = useLazyGetAllDropDownsQuery()
    useEffect(() => {
        getIndividualMemberList(paginationRequest)
    }, [])
    useEffect(() => {
        if (!isFetchingList && isSuccessList && individualMemberListResponse) {
            setPaginationRequest({ ...paginationRequest, totalCount: individualMemberListResponse.totalCount })
            setDataSource(individualMemberListResponse.itemList)
        }
    }, [isFetchingList, isSuccessList, individualMemberListResponse]);

    const handlePageChange = ({ pageNumber, pageSize }) => {
        getIndividualMemberList({ ...paginationRequest, pageNo: pageNumber, pageSize: pageSize })
    }
    const handleSearch = () => {
        debugger
        if (!viewIndividualMembersRef.current) {
            return;
        }
        const taskListFormData = viewIndividualMembersRef.current.getFormData();
        const updatedPaginationRequest = {
            pageNo: 1,
            pageSize: 10,
            endPage: 100,
            SearchText: taskListFormData?.name || null,
            GMAPNumber: taskListFormData?.gmapNumber || null,
            SubscriptionStatusId: taskListFormData?.status?.value || taskListFormData?.status,
            searchByEmailAddress: taskListFormData?.emailAddress || null,
            GMAPApplicationTypeId: taskListFormData?.gmapTier?.value || taskListFormData?.gmapTier,
            totalCount: 0,
        };
        debugger
        setPaginationRequest(updatedPaginationRequest);
        getIndividualMemberList(updatedPaginationRequest);
    };

    const handleAddIndividualMember = () => {
        navigate(`/createNewIndividualMember`)
    }
    const handleUpdateClick = (data) => {
        navigate(`/EditIndividualMembers/${encryptAESID(data.gmapMemberId)}`)
    }

    const actionHandler = {
        UPDATE: handleUpdateClick,
    };


    const filterDropDownList = (dropdownName) => {
        return allDropDownResponse.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name,
                });
            }
            return acc;
        }, []);
    };

    useEffect(() => {
        if (allDropDownResponse) {
            let gmapTier = filterDropDownList("Application Type");
            let subscriptionStatus = filterDropDownList("Subscription Status");
            let trmpFormField = { ...ViewIndividualMembersForm };
            trmpFormField = trmpFormField?.formFields?.map((obj) => {
                if (obj?.id === "gmapTier") {
                    return {
                        ...obj, fieldSetting: {
                            placeholder: "", options: gmapTier
                        }
                    }
                }
                else if (obj?.id === "status") {
                    return {
                        ...obj, fieldSetting: {
                            placeholder: "", options: subscriptionStatus
                        }
                    }
                }
                else {
                    return { ...obj }
                }
            }
            )
            SetViewIndividualMembersForm({ ...ViewIndividualMembersForm, formFields: trmpFormField })
        }
    }, [dropdownListLoading, isGetDropDownFetching, isGetAllDropDownSuccess, allDropDownResponse,]);

    useEffect(() => { getAllDropDowns() }, [])

    return (
        <div className="common-first-section">
            <div className="row align-items-end">
                <div className="col-10">
                    <div className="row gx-5">
                        <FormCreator ref={viewIndividualMembersRef} {...ViewIndividualMembersForm} />
                    </div>
                </div>
                <div className="col-2 d-flex justify-content-end">
                    <div className="two-button-fixes">
                        <div className="">
                            <Buttons
                                buttonText="Search"
                                buttonTypeClassName="success-btn"
                                onClick={handleSearch}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-1 d-flex justify-content-end mt-4">
                    <Buttons
                        buttonTypeClassName="outline-btn"
                        buttonText="Create New Individual"
                        onClick={handleAddIndividualMember}
                    />
                </div>
                <div>
                    <h4>Total Count :{paginationRequest.totalCount} </h4>
                </div>
                <div className="col-lg-12">
                    <div className="common-view-Individual-table">
                        <div className="table-responsive">
                            <MolGrid
                                ref={molGridRef}
                                configuration={ViewIndividualMemberConfig}
                                dataSource={dataSource}
                                allowPagination={true}
                                isLoading={isFetchingList}
                                pagination={paginationRequest}
                                onPageChange={handlePageChange}
                                onActionChange={actionHandler}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewIndividualMemberList;
