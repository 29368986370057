import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import FormCreator from '../../../../../components/Forms/FormCreator';
import { personalParticularsData } from './PersonalParticularsFormData';
import { useGMapMemberSignUpMutation, useSaveGMAPDocumentsMutation, useStoreBase64ImageMutation } from '../../../../../app/services/common/commonApi';
import ToastService from '../../../../../services/toastService/ToastService';
import { CreateIndividualMembersContext } from '../../../../../pages/gmap/viewIndividualMembers/createIndividualMembers';
import Buttons from '../../../../../components/ui/button/Buttons';

const PersonalParticularsForm = forwardRef((props, ref) => {
    const personalParticularsRef = useRef();
    const { newMemberData, setContextData } = useContext(CreateIndividualMembersContext);
    const [personalParticularsForm, SetPersonalParticularsForm] = useState(personalParticularsData);
    const [profilePhoto, setProfilePhoto] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [saveMainImage,
        { isSuccess: isSuccessImage,
            isLoading: isLoadingImage,
            data: profileImageUpload }
    ] = useStoreBase64ImageMutation();

    const [updateFileName,
        { isSuccess: isSuccessFile,
            isLoading: isLoadingFile,
            data: updateFileUpload }
    ] = useSaveGMAPDocumentsMutation();
    const [gMapMemberSignUp,
        { isSuccess: isNewMemberSignup,
            isLoading: isNewMemberLoading,
            data: addNewMember }
    ] = useGMapMemberSignUpMutation();

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const personalParticularData = personalParticularsRef.current.getFormData();
                return personalParticularData;
            }
            return null;
        }
    }))

    useEffect(() => {
        if (isSuccessFile && updateFileUpload) {
            ToastService.success("Profile Photo upload successfuly.")
        }
    }, [isSuccessFile, updateFileUpload]);
    useEffect(() => {
        if (!isNewMemberLoading && isNewMemberSignup && addNewMember) {
            if (!addNewMember.hasError && addNewMember.errorMessage === "") {
                setContextData(prevData => ({ ...prevData, gMAPMemberID: addNewMember.keyId }));
                ToastService.success("Personal Particulars Save Successfully");
            }
        }
    }, [isNewMemberLoading, isNewMemberSignup, addNewMember]);
    const handleClear = () => {
        const updatedForm = { ...personalParticularsForm };
        updatedForm.initialState.profilePhotoFile = null;
        SetPersonalParticularsForm(updatedForm);
    }

    // Function to download Employer Contract file
    const handleDownload = (data) => {
        if (props.onDownload) {
            props.onDownload(data);
        }
    }

    const handleViewImage = () => {
        setIsModalOpen(true);
    }
    const formActionHandler = {
        CLEAR: handleClear,
        DOWNLOAD: handleDownload,
        VIEW_IMAGE: handleViewImage,
    }
    const handleProfileImageUpload = async (data) => {
        const { base64Data, fileName } = data
        let saveFileRequest = {
            fileName: fileName,
            fileStr: base64Data,
            storagePath: "ProfessionalProfilePhoto",
        }

        const response = await saveMainImage(saveFileRequest)
        let updateFileRequest = {
            GMAPMemberId: newMemberData.gMAPMemberID,
            ColumnName: "ProfessionalProfilPhoto",
            FileName: response.data?.fileName,
        }
        newMemberData.profilePhotoFile = response.data.fileName;
        setProfilePhoto(response.data.fileName);
        setContextData(newMemberData);
        await updateFileName(updateFileRequest);
    }

    const handleFormFieldChange = (dataField, value) => {
        if (dataField === "profilePhotoFile" && value) {
            handleProfileImageUpload(value)
        }
    }
    const handleSave = () => {
        const personalParticularData = personalParticularsRef.current.getFormData();
        let request = {
            GMAPMemberId: newMemberData?.gMAPMemberID || null,
            GMAPApplicationTypeId: newMemberData?.applicationTypeID?.value || null,
            GMAPSalutationId: personalParticularData?.salutationsID?.value || null,
            FullName: personalParticularData?.fullName || null,
            PrimaryEmail: personalParticularData?.primaryEmail || null,
            SecondaryEmail: personalParticularData?.secondaryEmail || null,
            DateOfBirth: personalParticularData?.dateOfBirthDate || null,
            MobilePhone: personalParticularData?.mobilePhone || null,
            IsGenderMale: personalParticularData?.isGenderMale === "male" ? true : false || null,
            GMAPNationalityId: personalParticularData?.nationalityID?.value || null,
            AddressLine1: personalParticularData?.addressLine1 || null,
            AddressLine2: personalParticularData?.addressLine2 || null,
            AddressLine3: personalParticularData?.addressLine3 || null,
            PostalCode: personalParticularData?.postalCode || null,
            CountryofResidenceId: personalParticularData?.countryOfResID?.value || null,
            ProfessionalProfileDescription: personalParticularData?.comments || null,
            ProfessionalProfilPhoto: personalParticularData?.profilePhotoFile.fileName || null,
            LinkedInAddressOrProfessionalHomepage: personalParticularData?.linkedinAdd || null,
            GMAPCompetenciesId: personalParticularData?.competenciesID?.toString() || null,
            GMAPOtherProfessionalQualificationId: personalParticularData?.otherProfessionQualificationID?.toString() || null,
            StatusId: 1,
            SaveInto: "PersonalParticulars",
            AppId: 115,
            IsSave: true,
        }
        gMapMemberSignUp(request);
    }
    return (
        <React.Fragment>
            <FormCreator ref={personalParticularsRef}
                {...personalParticularsForm}
                onActionChange={formActionHandler}
                onFormFieldValueChange={handleFormFieldChange}
            />
            <div className='col-lg-12 d-flex justify-content-end mt-4'>
                <Buttons
                    buttonText="Save"
                    buttonTypeClassName="outline-btn"
                    isLoading={false}
                    onClick={handleSave}
                />
            </div>
        </React.Fragment>
    )
})

export default PersonalParticularsForm