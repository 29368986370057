import { createApi } from "@reduxjs/toolkit/query/react";
import { encryptQueryString, transformRequest } from '../../../utils/API/requestMiddleware';
import { defautBaseQuery } from '../../../utils/API/fetchBaseQuery';
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";


const adminDashBoardApi = createApi({
    reducerPath: 'adminDashBoardApi',
    baseQuery: defautBaseQuery,
    endpoints: (builder) => ({
        getAdminDashboardDetail: builder.query({
            query: () => ({
                url: encryptQueryString(`/Dashboard/GetAdminDashboardDetail`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useLazyGetAdminDashboardDetailQuery
} = adminDashBoardApi;

export default adminDashBoardApi;