import { AccountInformationFormDataDiscrationaryRenewal } from "../Components/AccountInformation/AccountInformationFormDiscrationaryRenewal.Data";

export const setAllDropDownFieldForDiscretionary = (apiResponse) => {

    const filterDropDownList = (dropdownName) => {
        return apiResponse.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name
                });
            }
            return acc;
        }, []);
    }

    const memberDropdownList = {
        "accountType": filterDropDownList("Other Qualification"),
        "status": filterDropDownList("SubscriptionStatus"),
        "accountstatus": filterDropDownList('Status'),
        "gmapStatus": filterDropDownList('SubscriptionStatus'),
        "billingCategory": filterDropDownList('BillingCategory'),
        "salutationsID": filterDropDownList("Title"),
    };


    const allDropDownMemberFromFields = [
        ...AccountInformationFormDataDiscrationaryRenewal.formFields
    ];

    allDropDownMemberFromFields.forEach(field => {
        const dropdownList = memberDropdownList[field.id];
        if (dropdownList) {
            field.fieldSetting.options = dropdownList;
        }
    });

};

export const setDiscretionaryAccountInformationInitials = (formData, apiResponse) => {
    console.log("form dataa", formData)
    formData.initialState.accountType = apiResponse.gmapApplicationTypeId;
    formData.initialState.joinedDate = apiResponse.submissionDate;
    formData.initialState.accountstatus = apiResponse.statusId;
    formData.initialState.gmapStatus = apiResponse.subscriptionStatusId
    formData.initialState.billingCategory = apiResponse.billingCategoryId;
    formData.initialState.gmapNumber = apiResponse.gmapNumber;
    formData.initialState.subscriptionStartDate = apiResponse.qualificationStartDate;
    formData.initialState.subscriptionEndDate = apiResponse.qualificationEndDate;
    formData.initialState.updatedBy = apiResponse.updatedBy;
    formData.initialState.updatedDate = apiResponse.updatedDate;
    formData.initialState.appeal = apiResponse.isAppeal;
    formData.initialState.qualify = apiResponse.isQualify;
    formData.initialState.voidLateFee = apiResponse.isvoidLateFee;
    formData.initialState.pendingExpiry = apiResponse.isPendingExpiry;

    return { ...formData }
}

export const setDiscretionaryAccountInformationOthers = (formData, apiResponse) => {
    debugger
    formData.initialState.salutationsID = apiResponse.salutationsID;
    formData.initialState.fullName = apiResponse.fullName;
    formData.initialState.primaryEmail = apiResponse.primaryEmail;
    formData.initialState.submissionDate = apiResponse.requestSubmissionDate;
    return { ...formData }
}

