import React, { useEffect } from 'react'
import MolGrid from '../../../../components/Grid/MolGrid'
import { useRef } from 'react'
import { CpdRenewalCycleConfig } from '../DataFile/CpdRenewalCycleForm.Data';
import { useState } from 'react';

const CpdRenewalCycleTable = (props) => {

    const CpdRenewalCycleTableRef = useRef();

    useEffect(() => { getList() }, [])

    const getList = (requestData) => {
        props.getCpdRenewalCycleList({ ...props.paginationRequest, ...requestData })
    }

    // const actionHandler = {
    //     UPDATE: handleEditClick,
    // }

    const handlePageChange = ({ pageNumber, pageSize }) => {
        getList({ ...props.paginationRequest, pageNo: pageNumber, pageSize: pageSize })
    }

    return (
        <>
            <MolGrid
                ref={CpdRenewalCycleTableRef}
                configuration={CpdRenewalCycleConfig}
                dataSource={props.dataSource}
                allowPagination={true}
                isLoading={props.isLoading}
                pagination={props.paginationRequest}
                onPageChange={handlePageChange}
            //onActionHandler={actionHandler}
            />
        </>
    )
}

export default CpdRenewalCycleTable