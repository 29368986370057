import { FormFieldTypes } from "../../../../data/formFieldType";

const DropdownManagementFormData = {
    name: "Dropdown Management Form",
    initialState: {
        Dropdown: null,
    },
    formFields: [
        {
            id: "Dropdown",
            lable: "Select Dropdown",
            Field_Name: "Dropdown",
            fieldType: FormFieldTypes.SELECT,
            dataField: "Dropdown",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            style: {
                containerCss: "col-lg-6 mb-4"
            }
        },
        
    ]
}

export default DropdownManagementFormData;

