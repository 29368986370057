import { FormFieldTypes } from "../../../../data/formFieldType";

export const gmapApplicationTasklistFormData={
    name: "AdditionalInfo",
    initialState: {
        name: null,
        gmapNumber: null,
        submissionDate: null,
        submissionDateEnd: null,
        status: null
    },
    formFields: [
        {
            id: "name",
            lable: "Name/GMP Number",
            Field_Name: "NaName/GMAP Numberme",
            fieldType: FormFieldTypes.INPUT,
            dataField: "name",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12",
            },
        },
        {
            id: "status",
            lable: "Status",
            Field_Name: "Status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "status",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12"
            }
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}