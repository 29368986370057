export const Menu = [
  {
    name: "Dashboard",
    id: "dashboard",
    to: "/",
    iconClass: "bi bi-speedometer2",
    subMenu: false,
    children: [],
  },
  {
    id: "GMAP",
    name: "GMAP",
    iconClass: "bi bi-people",
    subMenu: true,
    children: [
      {
        id: "gmapPricingTable",
        submenuName: "GMAP Pricing Table",
        to: "/gmapPricingTable",
      },
      {
        id: "gmapApplicationTasklist",
        submenuName: "GMAP Application Task List",
        to: "/gmapApplicationTasklist",
      },
      {
        id: "gmapDiscretionaryRenewalRequest",
        submenuName: "GMAP Discretionary Renewal Request",
        to: "/gmapDiscretionaryRenewalRequest",
      },
      {
        id: "gmapApplicationTaskListDiscretionaryRenewal",
        submenuName: "GMAP Discretionary Renewal Task List",
        to: "/gmapApplicationTaskListDiscretionaryRenewal",
      },
      {
        id: "viewIndividualMembers",
        submenuName: "View Individual Members",
        to: "/viewIndividualMembers",
      },
      {
        id: "gmaCourse",
        submenuName: "GMA - GMA Course Backend",
        to: "/gmaCourse",
      },
    ],
  },
  {
    id: "CPD",
    name: "CPD",
    iconClass: "bi bi-person",
    subMenu: true,
    children: [
      {
        id: "verifyCpdSubmission",
        submenuName: "Approve CPD Submission",
        to: "/approveCpdSubmission",
      },
      {
        id: "cpdUpload",
        submenuName: "CPD Upload",
        to: "/cpdUpload",
      },
      {
        id: "cpdRenewalCycle",
        submenuName: "CPD Renewal Cycle",
        to: "/cpdRenewalCycle",
      },
    ],
  },
  {
    id: "financial",
    name: "Financial",
    iconClass: "bi bi-financial",
    subMenu: true,
    children: [
      {
        id: "xeroBatchextraction",
        submenuName: "Xero Batch Extraction",
        to: "/xeroBatchExtraction",
      },
    ],
  },
  {
    name: "Resources",
    id: "viewResources",
    to: "/viewResources",
    iconClass: "bi bi-journal-text",
    subMenu: false,
    children: [],
  },
  {
    id: "fieldMaintenance",
    name: "Field Maintenance",
    iconClass: "bi bi-person",
    subMenu: true,
    children: [
      {
        id: "emailTemplateManagement",
        submenuName: "Email Template Management",
        to: "/emailTemplateManagement",
      },
      {
        id: "dropdownManagement",
        submenuName: "Dropdown Management",
        to: "/dropdownManagement",
      },
    ],
  },
  {
    id: "pageManagement",
    name: "Page Management",
    iconClass: "bi bi-card-checklist",
    subMenu: true,
    children: [
      {
        id: "createPages",
        submenuName: "Create Pages",
        to: "/createPages",
      },
      {
        id: "viewPages",
        submenuName: "View Pages",
        to: "/viewPages",
      },
    ],
  },
  {
    id: "EventManagement",
    name: "Event Management",
    iconClass: "bi bi-people",
    subMenu: true,
    children: [
      {
        id: "createEvent",
        submenuName: "Create Event",
        to: "/createEvent",
      },
      {
        id: "viewEditEvent",
        submenuName: "View/Edit Event",
        to: "/viewEditEvent",
      },
    ],
  },
];
