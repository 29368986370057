// import React from 'react'
import React, {
  useRef,
  useState,
} from "react";
import Buttons from "../../../components/ui/button/Buttons";
import FormCreator from "../../../components/Forms/FormCreator";
import CardSection from "../../../components/ui/card/CardSection";
import { createPagesData } from "./components/CreatePages.Data";
import { AppIcons } from "../../../data/appIcons";
import RichText from "./components/RichText";
import RichTextWithImage from "./components/RichTextWithImage";
import Listing from "./components/Listing";
import Images from "./components/Images";
import Object from "./components/Object";

const CreatePages = () => {
  const createPagesRef = useRef();
  const [bcaGreenMarkProjectForm, setbcaGreenMarkProjectForm] =
    useState(createPagesData);

  return (
    <div>
      <div className="mb-4">
        <CardSection cardTitle="Basic Information">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <FormCreator
                  ref={createPagesRef}
                  {...createPagesData}
                // onActionChange={formActionHandler}
                />
                <Buttons
                  buttonText="Add Component"
                  buttonTypeClassName="btn success-btn add-btn"
                  imagePath={AppIcons.arrowIcon}
                  onClick="{}"
                  titleText="Add Component"
                />
              </div>
              <RichText />
              <RichTextWithImage />
              <Listing />
              <Images />
              <Object />
            </div>

          </div>
        </CardSection>
      </div>

      <div className="row mb-3">
        <div className="common-custom-pagination mt-5">
          <div className="">
            <Buttons
              buttonText="Back"
              buttonTypeClassName="back-btn"
            // onClick={() => handleSearchMemberDirectory()}
            />
          </div>
          <div className="right-btn d-flex align-content-center">
            <Buttons
              buttonText="Preview"
              buttonTypeClassName="themecolor-hover-btn mr-5"
            // onClick={() => handlePreview()}
            />
            <Buttons
              buttonText="Save"
              buttonTypeClassName="success-btn"
            // onClick={() => handlePreview()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePages;
