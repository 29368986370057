import React from 'react'
import './CpdSubmission.scss'
import CpdSubmissionForm from '../../../features/cpd/cpdSubmission/CpdSubmissionForm'


const CpdSubmission = () => {
  return (
    <div className='viewadd-cpdpoint-page-main'>
      <div className='row'>
        <div className='col-12'>
          <CpdSubmissionForm />
        </div>
      </div>

    </div>
  )
}

export default CpdSubmission