import React from 'react'
import CurrentCpdCyclePointForm from './CurrentCpdCyclePointForm'


function CpdActivityModel(props) {
    return (
        <div className='add-edit-model-set'>
            <div className='row gx-5 '>
                <CurrentCpdCyclePointForm
                    isModalOpen={props.isModalOpen}
                    openCloseRejectModal={props.openCloseRejectModal}
                    openCloseCPDModal={props.openCloseCPDModal}
                />
            </div>
        </div>
    )
}

export default CpdActivityModel