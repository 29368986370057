
import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";
import { transformRequest } from "../../../utils/API/requestMiddleware";


const SaveCPDSubmissionApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'SaveCPDSubmissionApi',
    endpoints: (builder) => ({
        SGBCAccreditiedCPDEvents: builder.mutation({
            query: (details) => ({
                url: '/CPD/SGBCAccreditiedCPDEvents',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        AddEditBCAGreenMarkProject: builder.mutation({
            query: (details) => ({
                url: '/CPD/AddEditBCAGreenMarkProject',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        AddEditMastersDegreeProgrammes: builder.mutation({
            query: (details) => ({
                url: '/CPD/AddEditMastersDegreeProgrammes',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        AddEditPublicationOfResearchPaper: builder.mutation({
            query: (details) => ({
                url: '/CPD/AddEditPublicationOfResearchPaper',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        AddEditIndustryTechnicalCommittees: builder.mutation({
            query: (details) => ({
                url: '/CPD/AddEditIndustryTechnicalCommittees',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        AddEditCPDProgrammes: builder.mutation({
            query: (details) => ({
                url: '/CPD/AddEditCPDProgrammes',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        AddEditSGBCeLearning: builder.mutation({
            query: (details) => ({
                url: '/CPD/AddEditSGBCeLearning',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        AddEditSpeakingEngagement: builder.mutation({
            query: (details) => ({
                url: '/CPD/AddEditSpeakingEngagement',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

export const {
    useSGBCAccreditiedCPDEventsMutation,
    useAddEditBCAGreenMarkProjectMutation,
    useAddEditMastersDegreeProgrammesMutation,
    useAddEditPublicationOfResearchPaperMutation,
    useAddEditIndustryTechnicalCommitteesMutation,
    useAddEditCPDProgrammesMutation,
    useAddEditSGBCeLearningMutation,
    useAddEditSpeakingEngagementMutation
} = SaveCPDSubmissionApi;

export default SaveCPDSubmissionApi;