import { FormFieldTypes } from "../../../../data/formFieldType";

export const DiscretionaryRenewalMessageData = {
    name: 'DiscretionaryRenewalRequestSearchFormData',
    initialState: {
        message: null
    },
    formFields: [

        {
            id: "message",
            lable: "Message",
            Field_Name: "message",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "message",
            fieldSetting: {
                allowSpace: true,
                maxLength: 100,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "custom-grid",
            },
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}
