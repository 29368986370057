import { useGetGMAPMembersFinancialInformationByIdMutation } from "../../../../../app/services/GMAP/membershipFinancialService";
import Buttons from "../../../../../components/ui/button/Buttons";
import formatDate from "../../../../../lib/formatDate";
import { useEffect, useState } from "react";
import { useLazyGetPDFQuery } from "../../../../../app/services/common/commonApi";
import ToastService from "../../../../../services/toastService/ToastService";
import { decryptAESID } from "../../../../../utils/Encryption";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";

const FinancialRecord = (props) => {
  const { id } = useParams();
  const editID = id ? decryptAESID(id, false) : 0;
  const [invoiceFileName, setInvoiceFileName] = useState({});
  const [certificateFileName, setCertificateFileName] = useState({});
  const [financialData, setFinancialData] = useState({});
  const [
    getPDF,
    {
      isSuccess: isSuccessGetPDF,
      isLoading: isLoadingGetPDF,
      data: getPDFData,
    },
  ] = useLazyGetPDFQuery();

  const [
    getGMAPFinanceInfo,
    {
      data: financeInfoResponse,
      isSuccess: isFinanceInfoSuccess,
      isLoading: isFinanceInfoLoading,
    },
  ] = useGetGMAPMembersFinancialInformationByIdMutation();

  const getGMAPFinancialRecordDetails = () => {
    let financeInfoRequest = { GMAPMemberId: editID };

    getGMAPFinanceInfo(financeInfoRequest);
  };

  useEffect(() => {
    getGMAPFinancialRecordDetails();
  }, []);

  useEffect(() => {
    if (!isFinanceInfoLoading && isFinanceInfoSuccess && financeInfoResponse) {
      let getInvoiceArr = financeInfoResponse.invoicePath
        ? financeInfoResponse.invoicePath.split("\\")
        : [];
      let invoiceFilename =
        getInvoiceArr.length > 1 ? getInvoiceArr[getInvoiceArr.length - 1] : "";
      let getcertificateArr = financeInfoResponse.certificatePath
        ? financeInfoResponse.certificatePath.split("\\")
        : [];
      let certificateFilename =
        getcertificateArr.length > 1
          ? getcertificateArr[getcertificateArr.length - 1]
          : "";
      setInvoiceFileName(invoiceFilename);
      setCertificateFileName(certificateFilename);
      setFinancialData(financeInfoResponse);
    }
  }, [isFinanceInfoLoading, isFinanceInfoSuccess, financeInfoResponse]);

  // useEffect(() => {
  //     if (!isLoadingGetPDF && isSuccessGetPDF && getPDFData) {
  //         if (getPDFData) {
  //             saveAs(getPDFData?.fileData, getPDFData?.fileName);
  //         } else {
  //             ToastService.error("Something Went Wrong")
  //         }
  //     }
  // }, [isLoadingGetPDF, isSuccessGetPDF, getPDFData]);

  const onInvoiceReceiptFileDownload = async (flag, name, field) => {
    const fileName = name.split("\\").pop();
    let request = {
      type: flag,
      fileName: name,
    };
    const response = await getPDF(request);
    if (response?.data) {
      debugger;
      if (field === "invoice") {
        saveAs(response?.data?.fileData, fileName);
      } else if (field === "certificate") {
        saveAs(response?.data?.fileData, fileName);
      }
    } else {
      ToastService.error("Something Went Wrong");
    }
  };

  return (
    <>
      {financeInfoResponse?.map((item, key) => {
        return (
          <>
            <div className="financial-record">
              <div
                className="col-lg-12 position-relative financial-record-separator"
                key={key}
              >
                <div
                  className={`${item.paymentStatus === "Success"
                    ? "paid-lable"
                    : "pending-lable"
                    }`}
                >
                  <span className="fs-6">
                    {item.paymentStatus === "Success" ? "Paid" : "Pending"}
                  </span>
                </div>

                <div className="row financial-record-group">
                  <div className="col-lg-4 col-xl-4 col-md-4 col-sm-12 col-12 ">
                    <div>
                      <label>Invoice Number :</label>
                      <span> {item.membershipPaymentInvoiceNo}</span>
                    </div>
                    <div>
                      <label>Date :</label>
                      <span> {formatDate(item.paymentDate, "DD/MM/YYYY")}</span>
                    </div>
                    <div>
                      <label>Payment Status :</label>
                      <span>
                        {" "}
                        {item.paymentStatus ? item.paymentStatus : ""}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 d-flex justify-content-between">
                    <div className="mr-5">
                      <div>
                        <label>Promotion Code :</label>
                        <span> N.A</span>
                      </div>
                      <div>
                        <label>Amount(Billable) :</label>
                        <span>
                          S$
                          {parseFloat(item.totalAmount).toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </span>
                      </div>
                      <div>
                        <label>Amount(Paid) :</label>
                        <span>
                          S$
                          {item.paymentStatus === "Success"
                            ? parseFloat(item.totalAmount).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                            : parseFloat(0).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </span>
                      </div>
                    </div>
                    <div className="text-end record-btn">
                      <Buttons
                        buttonText="View Invoice/Receipt"
                        buttonTypeClassName="outline-btn"
                        disabled={!item?.invoicePath}
                        onClick={() =>
                          item?.invoicePath
                            ? onInvoiceReceiptFileDownload("FullFilePath", item?.invoicePath, "invoice")
                            : console.warn("Invoice is not existed")
                        }
                      />
                      <Buttons
                        buttonText="View Certificate"
                        buttonTypeClassName="outline-btn"
                        disabled={!item?.certificatePath}
                        onClick={() =>
                          item?.certificatePath
                            ? onInvoiceReceiptFileDownload("FullFilePath", item?.certificatePath, "certificate")
                            : console.warn("Certificate is not existed")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
export default FinancialRecord;
