import { FormFieldTypes } from "../../../data/formFieldType";


export const createEditResourcesData = {
    name: 'viewResourcesData',
    initialState: {
        
    },
    formFields: [
     
        {
            id: "resourceName",
            lable: "Resource Name",
            Field_Name: "Resource Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "resourceName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-7 col-sm-7 col-md-8 col-12 mb-3",
            },
        },
        

        {
            id: "resourceType",
            lable: "Resource Type",
            Field_Name: "Resource Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "resourceType",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-7 col-sm-7 col-md-8 col-12 mb-3"
            }
        },
        
        {
            id: "topics",
            lable: "Topics",
            Field_Name: "Topics",
            fieldType: FormFieldTypes.SELECT,
            dataField: "Topics",
            fieldSetting: {
                placeholder: " ",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-7 col-sm-7 col-md-8 col-12"
            }
        },
        
    ],
    formSetting: {
        isViewOnly: false
    }
}
export const createEditImageUploaderData = {
  name: 'createEditImageUploaderData',
  formFields: [
      
      {
        id: "uploadFeatureImage",
        lable: '(Dimension: 260px by 130px Maximum Size: 1MB)',
        Field_Name: 'file',
        fieldType: FormFieldTypes.FILE,
        dataField: 'uploadFeatureImage',
        fieldSetting: {
            // presentFileName: null,
            placeholder: '',
            isDownloadable: false,
            acceptedFiles: '.jpg, .png',
        },
        // validation: [{ type: "require" }],
        style: {
            containerCss: "col-md-7 col-sm-7 col-md-8 col-12 mb-3 file-uploader-img"
        }
    },
      
      
  ],
  formSetting: {
      isViewOnly: false
  }
}
export const resourcesDetailsData = {
    name: 'resourcesDetailsData',
    initialState: {
        
    },
    formFields: [
        {
            id: "descriptions",
            lable: "Description",
            Field_Name: "Description",
            fieldType: FormFieldTypes.SUNEDITOR,
            dataField: "description",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-10 col-sm-10 col-md-11 col-12 mb-3",
            },
        },
        {
            id: "format",
            label: "Format",
            Field_Name: "Format",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "format",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "Video", value: "video", isDisable: false, optionClass: "", },
                ]
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 mb-2",
            },
        },
        {
            id: "URL",
            lable: "URL",
            Field_Name: "URL",
            fieldType: FormFieldTypes.INPUT,
            dataField: "URL",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-5 col-sm-5 col-md-5 col-12 radio-input mb-3",
            },
        },
        {
            id: "",
            label: "",
            Field_Name: "",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "report",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "Report", value: "report", isDisable: false, optionClass: "", },
                ]
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 mb-2",
            },
        },
        {
            id: "",
            label: "",
            Field_Name: "",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "submission",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "Submission", value: "submission", isDisable: false, optionClass: "", },
                ]
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 mb-2",
            },
        },
        {
            id: "publishDate",
            lable: "Publish Date",
            Field_Name: "Publish Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "publishDate",
            fieldSetting: {
                placeholder: "Select",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-5 col-xl-5 col-md-5 col-sm-12 col-12 mb-3",
            },
        },
        {
            id: "publishTime",
            lable: "Publish Time",
            Field_Name: "Publish Time",
            fieldType: FormFieldTypes.TIME,
            dataField: "publishTime",
            fieldSetting: {
                placeholder: "Select",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-5 col-xl-5 col-md-5 col-sm-12 col-12 mb-3",
            },
        },
        {
            id: "unpublishDate",
            lable: "Unpublish Date",
            Field_Name: "Unpublish Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "unpublishDate",
            fieldSetting: {
                placeholder: "Select",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-5 col-xl-5 col-md-5 col-sm-12 col-12 mb-3",
            },
        },
        {
            id: "unpublishTime",
            lable: "Unpublish Time",
            Field_Name: "Unpublish Time",
            fieldType: FormFieldTypes.TIME,
            dataField: "unpublishTime",
            fieldSetting: {
                placeholder: "Select",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-5 col-xl-5 col-md-5 col-sm-12 col-12 mb-3",
            },
        },

        
    ],
    formSetting: {
        isViewOnly: false
    }
}

export const resourcesDetailsDataImageUploaderData = {
    name: 'resourcesDetailsDataImageUploaderData',
    formFields: [
        
        {
          id: "uploadFeatureImage",
          lable: '(Dimension: 260px by 130px Maximum Size: 1MB)',
          Field_Name: 'file',
          fieldType: FormFieldTypes.FILE,
          dataField: 'uploadFeatureImage',
          fieldSetting: {
              // presentFileName: null,
              placeholder: '',
              isDownloadable: false,
              acceptedFiles: '.jpg, .png',
          },
          // validation: [{ type: "require" }],
          style: {
              containerCss: "col-md-7 col-sm-7 col-md-8 col-12 mb-3 file-uploader-img"
          }
      },
        
        
    ],
    formSetting: {
        isViewOnly: false
    }
  }