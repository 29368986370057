import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'
import CardSection from '../../../../components/ui/card/CardSection'
import FormCreator from '../../../../components/Forms/FormCreator';
import { BasicInformationFormData } from './data/BasicInformationForm.Data';
import BasicDetailContext from '../../utils/BasicDetailContext';

const BasicInformationForm = forwardRef((props, ref) => {
    const basicInformationRef = useRef();
    const [basicInformationForm, setBasicInformationForm] = useState(BasicInformationFormData);
    const { entryType, setEntryType } = useContext(BasicDetailContext);

    // Expose method `getFormData` to parent components to retrieve form data from `FormCreator`
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const basicInformationFormData = basicInformationRef.current.getFormData();
                return basicInformationFormData;
            }
            return null;
        }
    }))

    const handleDropdownChange = (dropdownData, dropdownName) => {
        if (dropdownName === "entryType") {
            return setEntryType(dropdownData.value);
        }
        return false
    }

    const formActionHandler = {
        DDL_CHANGED: handleDropdownChange
    }

    return (
        <React.Fragment>
            <CardSection cardTitle="Basic Information">
                <div className='row'>
                    <FormCreator ref={basicInformationRef} {...basicInformationForm} onActionChange={formActionHandler} />
                </div>
            </CardSection>
            {entryType === 2 &&
                <React.Fragment>
                    <CardSection cardTitle="Additional Description">
                        <div className='row'>
                            <h1>Additional Description</h1>
                        </div>
                    </CardSection>
                    <CardSection cardTitle="Speaker">
                        <div className='row'>
                            <h1>Speaker</h1>
                        </div>
                    </CardSection>
                    <CardSection cardTitle="Moderator">
                        <div className='row'>
                            <h1>Moderator</h1>
                        </div>
                    </CardSection>
                    <CardSection cardTitle="Guest Of Honour">
                        <div className='row'>
                            <h1>Guest Of Honour</h1>
                        </div>
                    </CardSection>
                    <CardSection cardTitle="Partnership">
                        <div className='row'>
                            <h1>Partnership</h1>
                        </div>
                    </CardSection>
                </React.Fragment>
            }
        </React.Fragment>
    )
})

export default BasicInformationForm