import React, { useEffect } from 'react'
import Buttons from '../../../../components/ui/button/Buttons'
import { saveAs } from 'file-saver'
import { useParams } from 'react-router-dom'
import { decryptAESID } from '../../../../utils/Encryption'
import { useLazyGeneratePDFForGMAQuery, useLazyGeneratePDFForGMAPQuery, useLazyGeneratePDFForGMAAPQuery } from '../../../../app/services/GMAP/gmapApplicationTaskListApi'


const GMAGenratePDF = (props) => {
  const { id, taskListId } = useParams();
  const [getPDF,
    { isSuccess: isSuccessGetPDF,
      isLoading: isLoadingGetPDF,
      data: getPDFData }
  ] = useLazyGeneratePDFForGMAQuery();

  const [generatePDFForGMAP,
    { isSuccess: isSuccessGetPDFGMAP,
      isLoading: isLoadingGetPDFGMAP,
      data: getPDFDataGMAP }
  ] = useLazyGeneratePDFForGMAPQuery();

  const [generatePDFForGMAAP,
    { isSuccess: isSuccessGetPDFGMAAP,
      isLoading: isLoadingGetPDFGMAAP,
      data: getPDFDataGMAAP }
  ] = useLazyGeneratePDFForGMAAPQuery();

  // download pdf
  const handleDownloadPDF = async () => {
    let memberIdDecrypted = decryptAESID(id);
    let taskIdDecrypted = decryptAESID(taskListId);
    let requestType = props.type;
    let response;
    let requet = { id: memberIdDecrypted.toString(), taskListId: taskIdDecrypted.toString() }
    if (requestType === 1) {
      response = await getPDF(requet);
    } else if (requestType === 2) {
      response = await generatePDFForGMAP(requet);
    } else if (requestType === 3) {
      response = await generatePDFForGMAAP(requet);
    }

    if (response && response.data) {
      const { fileData, fileName } = response.data;
      const blob = new Blob([fileData], { type: 'application/pdf' });
      let tempfileName = 'Application.pdf';
      if (requestType === 1) {
        tempfileName = 'GMA.pdf';
      } else if (requestType === 2) {
        tempfileName = 'GMAP.pdf';
      } else if (requestType === 3) {
        tempfileName = 'GMAAP.pdf';
      }
      saveAs(blob, tempfileName);
    }
  }

  useEffect(() => {
    if (!isLoadingGetPDF && isSuccessGetPDF && getPDFData) {
    }
  }, [isLoadingGetPDF, isSuccessGetPDF, getPDFData])

  useEffect(() => {
    if (!isLoadingGetPDFGMAP && isSuccessGetPDFGMAP && getPDFDataGMAP) {
    }
  }, [isLoadingGetPDFGMAP, isSuccessGetPDFGMAP, getPDFDataGMAP])

  useEffect(() => {
    if (!isLoadingGetPDFGMAAP && isSuccessGetPDFGMAAP && getPDFDataGMAAP) {
    }
  }, [isLoadingGetPDFGMAAP, isSuccessGetPDFGMAAP, getPDFDataGMAAP])

  return (

    <React.Fragment>
      {props.date ? (
        <React.Fragment>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Buttons
              buttonText={`Application submitted on: ${new Date(props.date).toLocaleDateString()}`}
              buttonTypeClassName="btn-link bg-light"
              onClick={handleDownloadPDF}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Buttons
            buttonText="Generate PDF"
            buttonTypeClassName="btn-link bg-light"
            onClick={handleDownloadPDF}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default GMAGenratePDF