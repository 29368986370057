import { GridColumnType } from "../../../../data/gridColumnType";

export const viewPageGridConfig = {
  columns: [
    {
      name: "Main Menu",
      fieldName: "cpdNo",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
        name: "Sub Menu",
        fieldName: "cpdNo",
        allowShort: false,
        colType: GridColumnType.Text,
      },
      {
        name: "Page Title",
        fieldName: "cpdNo",
        allowShort: false,
        colType: GridColumnType.Text,
      },
      {
        name: "Page Type",
        fieldName: "cpdNo",
        allowShort: false,
        colType: GridColumnType.Text,
      },
      {
          name: "Is Form",
          fieldName: "cpdNo",
          allowShort: false,
          colType: GridColumnType.Text,
        },
        {
            name: "Action",
            fieldName: "actions",
            colType: GridColumnType.LINK,
            colSettings: {
              isOpenNewTab: false,
              url: "$website",
            },
          },
  ],
};
