import { FormFieldTypes } from "../../../data/formFieldType";

const loginFormData = {
    name: "Login From",
    initialState: {
        emailAddress: "",
        password: "",
    },
    formFields: [
        {
            id: "emailAddress",
            lable: "Email address",
            Field_Name: "email address",
            fieldType: FormFieldTypes.INPUT,
            dataField: "emailAddress",
            fieldSetting: {
                placeholder: "Email Address",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12 mb-3"
            }
        },
        {
            id: "password",
            lable: "Password",
            Field_Name: "password",
            fieldType: FormFieldTypes.PASSWORD,
            dataField: "password",
            fieldSetting: {
                placeholder: "Password",
            },
            style: {
                containerCss: "col-md-12"
            },
            validation: [{ type: "require" }]
        },
    ],
};
export default loginFormData;