import { FormFieldTypes } from "../../../../../data/formFieldType"
import { GridColumnType } from "../../../../../data/gridColumnType"

export const AccountInformationFormData = {
    name: 'accountInformation',
    initialState: {
        gmapNumber: '',
        joinedDate: '',
        status: null,

    },
    formFields: [

        {
            id: "gmapNumber",
            lable: "GMAP Number",
            Field_Name: "gmapNumber",
            fieldType: FormFieldTypes.INPUT,
            dataField: "gmapNumber",
            fieldSetting: {
                placeholder: "",
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            },
        },
        {
            id: "joinedDate",
            lable: "Joined Date",
            Field_Name: "Joined Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "joinedDate",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "status",
            lable: "Status",
            Field_Name: "Status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "status",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true,

            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12"
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}

