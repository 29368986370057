import { FormFieldTypes } from "../../../../../data/formFieldType";

export const TextBoxComponentData = {
    name: "TextBoxComponentData",
    initialState: {
        required: false,
        textbox: '',
    },
    formFields: [
        {
            id: "required",
            lable: "Required",
            Field_Name: "Required",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "required",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-12 text-end mb-2",
            },
        },
        {
            id: "textbox",
            lable: "Textbox",
            Field_Name: "Textbox",
            fieldType: FormFieldTypes.INPUT,
            dataField: "textbox",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-12 mb-2",
            },
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}