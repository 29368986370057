import React, { useEffect, useRef, useState } from 'react'
import MolGrid from '../../../components/Grid/MolGrid';
import { EmailTemplateDataConfig } from './DataFiles/EmailTemplateDataConfig';
import { useDeleteEmailTemplateMutation, useGetEmailTemplateListMutation } from '../../../app/services/FieldManagement/EmailTemplateManagement/EmailTemplateManagementApi';
import DataLoader from '../../../components/ui/dataLoader/DataLoader';
import { useNavigate } from 'react-router-dom';
import SwalAlert from '../../../services/swalService/SwalService';
import SearchFilterForm from './searchFilterForm';

const EmailTemplateManage = () => {

    const [emialList, setEmailList] = useState()
    const EmailTemplateManageRef = useRef(null);
    const navigate = useNavigate();
    const { confirm, success, error } = SwalAlert()
    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 10,
        endPage: 100,
        SearchText: null,
        ProjectTypeId: null,
        totalCount: 0,
    });


    const [getEmailTemplateList, {
        isSuccess: isSuccessEmailList,
        isFetching: isFetchingEmailList,
        data: emailListResponse
    }] = useGetEmailTemplateListMutation()

    const [deleteEmailTemplate, {
        isSuccess: isSuccessdeleteEmailTemplate,
        data: deleteEmailTemplateResponse
    }] = useDeleteEmailTemplateMutation()


    useEffect(() => {
        getEmailList()
    }, [])

    useEffect(() => {
        if (isSuccessdeleteEmailTemplate && deleteEmailTemplateResponse) {
            if (deleteEmailTemplateResponse.hasError === false) {
                success("Email Deleted Successfully");
                getEmailList();
            }
            else {
                error(deleteEmailTemplateResponse.errorMessage)
            }
        }
    }, [isSuccessdeleteEmailTemplate, deleteEmailTemplateResponse]);

    useEffect(() => {
        if (!isFetchingEmailList && isSuccessEmailList && emailListResponse) {
            setPaginationRequest({ ...paginationRequest, totalCount: emailListResponse.totalCount })
            setEmailList(emailListResponse.itemList);

        }
    }, [isFetchingEmailList, isSuccessEmailList, emailListResponse]);


    const getEmailList = (request) => {
        const req = {
            pageNo: 1,
            pageSize: 10,
            endPage: 100,
            SearchText: null,
            ProjectTypeId: null,
            ...request
        }
        getEmailTemplateList(req)
    }

    const handleDelete = (data) => {
        confirm('Delete', "Are you sure you want to delete", 'Yes', 'Cancel')
            .then(value => {
                if (value) {
                    const request = {
                        id: data.emailTemplateId,
                        deletedBy: 115,
                        appId: 1
                    }
                    // deleteEmailTemplate(request)
                }
            })
    }

    const handleEdit = (data) => {
        
        navigate(`/emailTemplateManagement/${data.emailTemplateId}`);
    }

    const handlePageChange = ({ pageNumber, pageSize }) => {
        getEmailList({ ...paginationRequest, pageNo: pageNumber, pageSize: pageSize })
    }

    const actionHandler = {
        DELETE: handleDelete,
        EDIT: handleEdit
    };
    return (
        <div>
            <SearchFilterForm getEmailList={getEmailList} />
            {emialList ?
                <MolGrid
                    ref={EmailTemplateManageRef}
                    configuration={EmailTemplateDataConfig}
                    dataSource={emialList}
                    allowPagination={true}
                    onActionChange={actionHandler}
                    isLoading={isFetchingEmailList}
                    pagination={paginationRequest}
                    onPageChange={handlePageChange}
                />
                : <DataLoader />
            }
        </div>
    )
}

export default EmailTemplateManage