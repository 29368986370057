import React, { useRef, useState } from "react";
import  { RichTextData } from "./AddComponent.Data";
import FormCreator from "../../../../components/Forms/FormCreator";


const RichText = () => {
  const richTextRef = useRef();
  const [bcaGreenMarkProjectForm, setcaGreenMarkProjectForm] =
    useState(RichTextData);

  return (
    <div>
      <div className="reach-text">
        <div className="grid grid-cols-12 gap-6 mb-10 border border-[#757575] mt-5 component-table">
          <div className="filter-types col-span-12 font-bold border-b border-[#757575] bg-[#f0f0f0] px-7 py-0 relative">
            <div className="table-header col-span-12">
              <h2 className="filter-lable font-medium theme-color component-table-title">
                Rich Text
              </h2>

              {/* {props.pageTypeId === 3 ? */}
              <div className="close-btn">
                <button
                  className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575]"
                  //   onClick={() => props.removeComponent()}
                >
                  <div className="sr-only"></div>
                  <svg className="w-4 h-4 fill-current">
                    <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                  </svg>
                </button>
              </div>
            </div>
            {/* : null} */}
            <div className="table-data">
              <div className="filter-types col-span-12 p-8">
                <div className="row">
                  <FormCreator
                    ref={richTextRef}
                    {...RichTextData}
                    // onActionChange={formActionHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RichText;
