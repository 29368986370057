import React from "react";
import EditApplicationTaskList from "../../../../features/gmap/gmapApplicationTasklist/EditApplicationTaskList";

export const EditApplicationDataContext = React.createContext(null);

const GmapApplicationTaskListNewApplication = () => {
  return <EditApplicationTaskList />
};

export default GmapApplicationTaskListNewApplication;
