
import { createSlice } from '@reduxjs/toolkit';
import { getAuthProps, setAuthProps, signOut } from '../../lib/authenticationLibrary';

const authData = getAuthProps();

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: (authData != null),
        user: (authData?.user), //Set user data from cookie.
        token: (authData?.token), //Set token data from cookie.
        authData,
        isLogedin: false,
    },
    reducers: {
        authentication: (state, action) => {
            state.isLogedin = true;
            setAuthProps(action.payload);
        },
        logout: (state) => {
            state.isLogedin = false;
            signOut();
        },
    },
});

export const { authentication, logout } = authSlice.actions;

export default authSlice.reducer;
