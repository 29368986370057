import { createApi } from "@reduxjs/toolkit/query/react";
import { encryptQueryString, transformRequest } from '../../../utils/API/requestMiddleware';
import { defautBaseQuery } from '../../../utils/API/fetchBaseQuery';
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";


const careerHistoryApi = createApi({
    reducerPath: 'careerHistoryApi',
    baseQuery: defautBaseQuery,
    endpoints: (builder) => ({
        deleteGMAPCareerHistory: builder.mutation({
            query: (details) => ({
                url: '/GMAPAccount/DeleteGMAPCareerHistory',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        addEditGMAPCareerHistory: builder.mutation({
            query: (details) => ({
                url: '/GMAPAccount/AddEditGMAPCareerHistory',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getGMAPCareerHistoryList: builder.query({
            query: (details) => ({
                url: encryptQueryString(`/GMAPAccount/GetGMAPCareerHistoryList?id=${Number(details)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        UpdateGMAPCurrentCareerHistory: builder.mutation({
            query: (details) => ({
                url: '/GMAPAccount/UpdateGMAPCurrentCareerHistory',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useDeleteGMAPCareerHistoryMutation,
    useAddEditGMAPCareerHistoryMutation,
    useLazyGetGMAPCareerHistoryListQuery,
    useUpdateGMAPCurrentCareerHistoryMutation
} = careerHistoryApi;

export default careerHistoryApi;