import React, { useEffect, useRef, useState } from "react";
import Buttons from "../../components/ui/button/Buttons";
import MemberDirectoryTable from "./component/MemberDirectoryTable";
import { useDeleteMemberDirectoryMutation, useGetMemberDirectoryListMutation } from "../../app/services/GMAP/gmapMemberDirectoryApi";
import MemberDirectoryForm from "./component/MemberDirectoryForm";
import CenterModel from '../../components/ui/centerModel/CenterModel';
import AddEditMemberModel from './component/AddEditMemberModel';
import SwalAlert from "../../services/swalService/SwalService";
import ToastService from "../../services/toastService/ToastService";
import { setFieldParameter } from "../gmap/gmapApplicationTasklist/editComponent/utils/setEditFieldsUtils";
import { useLazyGetAllDropDownsForMemberDirectoryQuery } from "../../app/services/common/commonApi";
import { setAllDropDownField } from "../gmap/gmapApplicationTasklist/editComponent/utils/dropdownUtils";

const MemberDirectoryList = () => {

    const memberDirectoryref = useRef(null)
    const [memberDirectoryList, setMemberDirectoryList] = useState();
    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 10,
        endPage: 100,
        name: "",
        gmapNumber: "",
        otherProfessionQualificationID: "",
        competenciesID: "",
        totalCount: 0
    });

    // modal open close 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { confirm, error } = SwalAlert();
    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const [getMemberDirectoryList,
        { isSuccess: isGetListSuccess,
            isFetching: isFetFecthingList,
            data: memberDirectoryDataResponse },
    ] = useGetMemberDirectoryListMutation();

    const [getAllDropDowns,
        { isSuccess: isGetAllDropDownFetched,
            isFetching: isGetDropDownFetching,
            data: allDropDownResponse },
    ] = useLazyGetAllDropDownsForMemberDirectoryQuery()

    useEffect(() => {
        getAllDropDowns();
        getMemberDirectoryList(paginationRequest)
    }, [])

    useEffect(() => {
        if (!isGetDropDownFetching && isGetAllDropDownFetched && allDropDownResponse) {
            setAllDropDownField(allDropDownResponse);
        }
    }, [isGetDropDownFetching, isGetAllDropDownFetched, allDropDownResponse]);

    useEffect(() => {
        if (!isFetFecthingList && isGetListSuccess && memberDirectoryDataResponse) {
            setPaginationRequest({ ...paginationRequest, totalCount: memberDirectoryDataResponse.totalCount })
            setMemberDirectoryList(memberDirectoryDataResponse.itemList)
        }
    }, [isFetFecthingList, isGetListSuccess, memberDirectoryDataResponse]);


    const handlePageChange = ({ pageNumber, pageSize }) => {
        setPaginationRequest(previous => {
            const tempPagination = { ...previous, pageNo: pageNumber, pageSize: pageSize }
            getMemberDirectoryList(tempPagination)
            return tempPagination
        })
    }
    const handleSearchApplicationTask = () => {
        if (!memberDirectoryref.current) return;
        const { name, gmapNumber, otherProfessionQualificationID, competenciesID } = memberDirectoryref.current.getFormData();
        const tempPagination = {
            ...paginationRequest,
            pageNo: 1,
            pageSize: 10,
            name,
            gmapNumber,
            otherProfessionQualificationID: otherProfessionQualificationID?.value || null,
            competenciesID: competenciesID?.value || null
        };

        getMemberDirectoryList(tempPagination)
            .then(response => {
                if (response) {
                    setPaginationRequest(prev => ({ ...prev, ...tempPagination, totalCount: response.totalCount }));
                    setMemberDirectoryList(response.itemList);
                } else {
                    ToastService.error("Error while fetching member directory list");
                }
            })
            .catch(error => console.error("Error while fetching member directory list:", error));
    };


    const [deleteMemberDirectory,
        { isSuccess: isDeleteSuccess,
            data: deleteMemberDirectoryResponse },
    ] = useDeleteMemberDirectoryMutation();

    useEffect(() => {
        if (isDeleteSuccess && deleteMemberDirectoryResponse) {
            ToastService.error("Delete Item successfully");
            getMemberDirectoryList(paginationRequest);
        }
    }, [isDeleteSuccess && deleteMemberDirectoryResponse])

    const handleDeleteMember = (data) => {
        confirm("Delete?", "Are you sure you want to delete?", "Delete", "Cancel")
            .then((confirmed) => {
                if (confirmed) {
                    let request = {
                        Id: data,
                        AppId: 115,
                        DeletedBy: 115,
                    }
                    deleteMemberDirectory(request);
                }
            });
    }

    const handleViewMember = (data) => {
        setFieldParameter(data, "viewMemberDirectory");

        setIsModalOpen(!isModalOpen);
    }
    return (
        <div className="common-first-section">
            <div className="row align-items-end">
                <div className="col-10">
                    <div className="row gx-5">
                        {isGetAllDropDownFetched && <MemberDirectoryForm ref={memberDirectoryref} />}
                    </div>
                </div>
                <div className="col-2 d-flex justify-content-end">
                    <div className="two-button-fixes">
                        <div className="">
                            <Buttons
                                buttonText="Search adsasdsa"
                                buttonTypeClassName="success-btn"
                                onClick={() => handleSearchApplicationTask()}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="common-view-Individual-table">
                        <div className="table-responsive">
                            {memberDirectoryList && <MemberDirectoryTable
                                datasource={memberDirectoryList}
                                pagination={paginationRequest}
                                onPageChange={handlePageChange}
                                handleViewMember={handleViewMember}
                                handleDeleteMember={handleDeleteMember}
                            />
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className='add-edit-member-popup'>
                {isModalOpen &&
                    <CenterModel
                        modelTitle={'Add/Edit Member'}
                        show={"test"}
                        handleClose={openModal}
                        onUpload={openModal}
                    >
                        <AddEditMemberModel />
                    </CenterModel>
                }
            </div>
        </div>
    );
};

export default MemberDirectoryList;
