
import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";


const ApproveCPDSubmissionApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'ApproveCPDSubmissionApi',
    endpoints: (builder) => ({
        getCPDSubmissionTaskList: builder.mutation({
            query: (details) => ({
                url: '/CPD/GetApproveCPDSubmissionList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getCPDSubmissionById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/CPD/GetCPDMemberDetailsByMemberId?id=${id}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getCPDMemberDetailsListByMemberId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/CPD/GetCPDMemberDetailsListByMemberId?id=${id}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getPastCPDMemberDetailsListByMemberId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/CPD/GetPastCPDMemberDetailsListByMemberId?id=${id}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

export const {
    useGetCPDSubmissionTaskListMutation,
    useLazyGetCPDSubmissionByIdQuery,
    useLazyGetCPDMemberDetailsListByMemberIdQuery,
    useLazyGetPastCPDMemberDetailsListByMemberIdQuery,
} = ApproveCPDSubmissionApi;

export default ApproveCPDSubmissionApi;