import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { eLearningData } from './SgbcElearning.Data';
import { useLazyGetPDFQuery, useUploadPDFMutation } from '../../../../../app/services/common/commonApi';
import FormCreator from '../../../../../components/Forms/FormCreator';
import Buttons from '../../../../../components/ui/button/Buttons';
import { saveAs } from 'file-saver';
import { useLazyGetSgbcElearningByIdQuery } from '../../../../../app/services/CPD/CpdSubmissionApi';
import { CurrentCPDCycleDataContext } from '../../CpdSubmissionForm';
import DataLoader from '../../../../../components/ui/dataLoader/DataLoader';
import SwalAlert from '../../../../../services/swalService/SwalService';
import { useAddEditSGBCeLearningMutation } from '../../../../../app/services/CPD/SaveCPDSubmissionApi';
const SgbcElearning = forwardRef((props, ref) => {
  const eLearbubfRef = useRef();
  const [eLearbubfForm, setELeaaringForm] = useState(eLearningData);
  const { cpdSubmissionData, setCpdSubmissionData } = useContext(CurrentCPDCycleDataContext);
  const { success, error } = SwalAlert();

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = eLearbubfRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));

  const [getPDF,
    { isSuccess: isSuccessGetPDF,
      isLoading: isLoadingGetPDF,
      data: getPDFData }] = useLazyGetPDFQuery();

  const [uploadPDF,
    { isSuccess: isSuccess,
      isLoading: isLoading,
      data: fileUploadData }
  ] = useUploadPDFMutation();

  const [
    getSgbcElearningById,
    {
      isSuccess: getSgbcElearningByIdIsSuccess,
      isLoading: getSgbcElearningByIdIsLoading,
      data: getSgbcElearningByIdData,
    }
  ] = useLazyGetSgbcElearningByIdQuery();

  const [handleSubmitForm,
    { isSuccess: isSuccessForm,
      isFetching: isLoadingForm,
      data: formResponse }
  ] = useAddEditSGBCeLearningMutation();

  useEffect(() => {
    if (!isLoadingForm && isSuccessForm && formResponse) {
      success("Save successfuly.", "Sucessful");
      props.formActionHandler["SAVE_SUCCESS"]();
    }
  }, [isLoadingForm, isSuccessForm, formResponse]);

  useEffect(() => {
    if (getSgbcElearningByIdIsSuccess && !getSgbcElearningByIdIsLoading && getSgbcElearningByIdData) {
      console.log({ getSgbcElearningByIdData });
      let temp = { ...eLearbubfForm }
      let tempInitials = temp.initialState;
      tempInitials.typeofeLearningCourse = getSgbcElearningByIdData.typeofELearningCourse;
      tempInitials.dateofCompletion = getSgbcElearningByIdData.dateofCompletion;
      tempInitials.attendanceCertorOtherwise = getSgbcElearningByIdData.attendanceCertificate;
      tempInitials.CpdPoints = getSgbcElearningByIdData.cpdPoints;
      tempInitials.remarks = getSgbcElearningByIdData.remarks;
      tempInitials.processedBy = getSgbcElearningByIdData.cpdReceivedById;
      tempInitials.cpdPointsAwarded = getSgbcElearningByIdData.cpdPointsAwardedId;
      setELeaaringForm(temp);
    }
  }, [getSgbcElearningByIdIsSuccess, getSgbcElearningByIdIsLoading, getSgbcElearningByIdData]);

  useEffect(() => {
    getSgbcElearningById(cpdSubmissionData.cpdSubmissionId);
  }, []);


  const handleDownload = async (data, dataField) => {
    const request = {
      type: "AttendanceCert",
      fileName: fileUploadData?.fileName ? fileUploadData?.fileName : getSgbcElearningByIdData.attendanceCertificate,
    }
    const response = await getPDF(request);
    saveAs(response?.data?.fileData, response?.data?.fileName);
  }


  const formActionHandler = {
    DOWNLOAD: handleDownload,
  };
  const handleFormFieldChange = (dataField, value) => {
    if (dataField === "attendanceCertorOtherwise" && value) {
      handleOnChangeFileUpload(value)
    }
  };

  const handleOnChangeFileUpload = async (data) => {
    let formData = new FormData();
    formData.append("file", data)
    formData.append("storagePath", "AttendanceCert");
    uploadPDF(formData);
  }


  const handleSaveButton = () => {
    const formData = eLearbubfRef.current.getFormData();
    if (!formData) {
      return error("Please fill all mandatory fields.", "")
    }
    let request = {
      SGBCeLearningId: getSgbcElearningByIdData.cpdElearningId,
      GMAPMemberId: getSgbcElearningByIdData.gmapMemberId,
      CPDPoints: formData.CpdPoints,
      Remarks: formData?.remarks,
      CPDActivityTypeId: getSgbcElearningByIdData.cpdActivityTypeId,
      TypeofElearningCourse: formData?.typeofeLearningCourse,
      DateOfCompletion: formData?.dateofCompletion,
      AttendenceCert: fileUploadData?.fileName ? fileUploadData?.fileName : getSgbcElearningByIdData.attendanceCertificate,
      CPDSubmissionId: getSgbcElearningByIdData.cpdSubmissionId,
      CreatedBy: 115,
      CreatedAppId: 1,
      CPDReceivedById: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      CPDPointsAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
    }
    handleSubmitForm(request)
  };

  const handleRejectbutton = () => {
    const request = {
      cpdSubmissionId: getSgbcElearningByIdData.cpdSubmissionId,
      gmapMemberId: getSgbcElearningByIdData.gmapMemberId,
      isRejected: true,
      rejectCPDSubmissionReason: null,
    }
    if (props.formActionHandler) {
      props.formActionHandler['REJECT'](request);
    }
  };

  const handleApproveButton = () => {
    const formData = eLearbubfRef.current.getFormData();
    if (!formData) return;
    const request = {
      cpdSubmissionId: getSgbcElearningByIdData.cpdSubmissionId,
      gmapMemberId: getSgbcElearningByIdData.gmapMemberId,
      ProcessedId: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      cpdPointAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
      isApprove: true,
      Notes: formData?.notes || null
    }
    if (props.formActionHandler) {
      props.formActionHandler['APPROVE'](request);
    }
  };

  return (
    <React.Fragment>
      {getSgbcElearningByIdData ?
        <div className="speaking-teaching-engagement">
          {/* <div className="cpd-submission-description">
          <p>SGBC e-Learning refers to e-learning modules created by SGBC in the SGBC Digital Academy.</p>
          <p>
            Certificate of Completion must be submitted to claim the CPD points indicated in the module description.
          </p>
          <p>CPD Points are awarded only once per event (Attendance / eLearning).</p>
        </div> */}

          <div className="common-project-info-main card-section card-section-left">
            <div className="row gx-5">
              <FormCreator ref={eLearbubfRef} {...eLearbubfForm} onActionChange={formActionHandler} onFormFieldValueChange={handleFormFieldChange} />

            </div>
          </div>
          <div className='row'>
            <div className='common-custom-pagination mt-5'>
              <div>
                <Buttons
                  buttonText="Save"
                  buttonTypeClassName="outline-btn"
                  onClick={handleSaveButton}
                  isDisable={props?.isPastCPD}
                />
              </div>
              <div className='d-flex column-gap-3'>
                <Buttons
                  buttonText="Reject"
                  buttonTypeClassName="red-btn"
                  onClick={handleRejectbutton}
                  isDisable={props?.isPastCPD}

                />
                <Buttons
                  buttonText="Approve"
                  buttonTypeClassName="success-btn"
                  onClick={handleApproveButton}
                  isDisable={props?.isPastCPD}
                  isLoading={cpdSubmissionData?.approveIsLoading}
                />
              </div>
            </div>
          </div>
          <div className='center-button'>
            {/* <div className='col-lg-12 d-flex justify-content-center'>
            <Buttons
              buttonText="Submit"
              buttonTypeClassName="success-btn mx-4"
            // onClick={handleSubmit}
            />
          </div> */}
          </div>

        </div>
        :
        <DataLoader />
      }
    </React.Fragment>
  );
}
)
export default SgbcElearning;
