import React from 'react'
import EditEmailTemp from '../../../features/FieldManagement/EmailTempManagement/EditEmailTemp';

const EditEmailTemplate = () => {

    return (
        <div>
            <><EditEmailTemp /></>
        </div>
    )

}

export default EditEmailTemplate