import React, { useEffect, useRef, useState } from 'react'
import { eventListTableConfig } from './data/EventListTable.Data';
import MolGrid from '../../../../components/Grid/MolGrid';

const EventListTable = ({ paginationReq }) => {
    const molGridRef = useRef();
    const [dataSource, setDataSource] = useState([]);
    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 10,
        endPage: 100,
        SearchText: "",
        submissionStartDate: "",
        submissionEndDate: "",
        totalCount: 0,
        StatusId: null
    });

    const actionHandler = {
        // UPDATE: handleEditClick,
    }
    return (
        <>
            <div>
                <h4>Total Count :{paginationRequest.totalCount} </h4>
            </div>
            <MolGrid
                ref={molGridRef}
                configuration={eventListTableConfig}
                dataSource={dataSource}
                allowPagination={true}
                pagination={paginationRequest}
            />
        </>
    )
}

export default EventListTable