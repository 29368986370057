import { FormFieldTypes } from "../../../../../data/formFieldType";
import { GridColumnType } from "../../../../../data/gridColumnType";

export const careerHistoryConfig = {
    columns: [
        {
            name: "Company Name",
            fieldName: "companyName",
            allowShort: false
        },
        {
            name: "Designation",
            fieldName: "designation",
            allowShort: false
        },
        {
            name: "Period From",
            fieldName: "periodFrom",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD/MM/YYYY',
            }
        },
        {
            name: "Period To",
            fieldName: "periodTo",
            allowShort: false,
            colType: GridColumnType.CUSTOM,
            renderCustomCol: (rowData) => {
                if (rowData?.["periodTo"]) {
                    const [year, month, day] = rowData?.["periodTo"] && rowData?.["periodTo"].substring(0, 10).split("-");
                    return (rowData?.["periodTo"] && !rowData?.['isCurrent']) ? `${day}/${month}/${year}` : "Current";
                }
            },
        },
        {
            name: "Profession",
            fieldName: "professionName",
            allowShort: false
        },
        {
            name: "Other Profession Name",
            fieldName: "otherProfessionName",
            allowShort: false
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: true
            }

        },
    ]
}

export const careerHistoryData = {
    name: 'CareerHistory',
    initialState: {
        companyName: '',
        designation: "",
        periodFrom: "",
        periodTo: "",
        isCurrent: false,
        gmapProfessionTypeId: "",
        otherProfessionName: ""
    },
    formFields: [
        {
            id: "careerHistoryTitle",
            lable: "",
            Field_Name: "careerHistoryTitle",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "careerHistoryTitle",
            fieldSetting: {
                formTitle: "Past Employment",
                subLabel: ""
            },
            style: {
                containerCss: "col-lg-12 mb-2 mt-5 text-left",
            },
        },

        {
            id: "careerHistoryTable",
            fieldType: FormFieldTypes.TABLE,
            dataField: "careerHistoryTable",
            fieldSetting: {
                header: careerHistoryConfig,
                dataSource: [],
            },
            style: {
                containerCss: "col-lg-12 mb-2 text-left",
            },
        },
        {
            id: "companyName",
            lable: "Company Name",
            Field_Name: "Company Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "companyName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "designation",
            lable: "Designation",
            Field_Name: "Designation",
            fieldType: FormFieldTypes.INPUT,
            dataField: "designation",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "periodFrom",
            lable: "Period From",
            Field_Name: "Period From",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "periodFrom",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "gmapProfessionTypeId",
            lable: "Profession",
            Field_Name: "Profession",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmapProfessionTypeId",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "otherProfessionName",
            lable: "Other Profession Name",
            Field_Name: "Other Profession Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "otherProfessionName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-6 mb-3 d-none",
            },
        },
        {
            id: "periodTo",
            lable: "Period To",
            Field_Name: "Period To",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "periodTo",
            fieldSetting: {
                placeholder: "",
                isDisable: false,
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-2"
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}

export const CurrentEmpData = {
    name: 'CareerHistory',
    initialState: {
        isUnemployed: false,
        CurrentCompanyName: '',
        CurrentPeriodFrom: "",
        CurrentPeriodTo: "",
        CurrentDesignation: '',
        GMAPProfessionTypeId: "",
        CurrentOtherProfessionName: ""
    },
    formFields: [

        {
            id: "careerHistoryTitle",
            lable: "",
            Field_Name: "careerHistoryTitle",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "careerHistoryTitle",
            fieldSetting: {
                formTitle: "Current Employment",
                subLabel: ""
            },
            style: {
                containerCss: "col-lg-12 mb-2 mt-0  text-left",
            },
        },
        {
            id: "isUnemployed",
            lable: "Currently Unemployed",
            Field_Name: "isUnemployed",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "isUnemployed",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-12 mb-3",
            },
        },
        {
            id: "CurrentCompanyName",
            lable: "Company Name",
            Field_Name: "Company Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "CurrentCompanyName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "CurrentDesignation",
            lable: "Designation",
            Field_Name: "Designation",
            fieldType: FormFieldTypes.INPUT,
            dataField: "CurrentDesignation",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "CurrentPeriodFrom",
            lable: "Period From",
            Field_Name: "Period From",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "CurrentPeriodFrom",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "GMAPProfessionTypeId",
            lable: "Profession",
            Field_Name: "Profession",
            fieldType: FormFieldTypes.SELECT,
            dataField: "GMAPProfessionTypeId",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "CurrentOtherProfessionName",
            lable: "Other Profession Name",
            Field_Name: "Other Profession Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "CurrentOtherProfessionName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}

