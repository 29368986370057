import React from "react";
import './Footer.scss'
import { AppIcons } from "../../../data/appIcons"
import Image from "../../../components/image/Image";

const Footer = () => {
    return (
        <div className="footer-section">
            <div className="custom-width-foot">
                <div className="d-flex column-gap-4">
                    <div className="footer-logo-sec">
                        {/* <Image
                            alt="Footer Logo"
                            imagePath={AppIcons.footerLogo.src}
                        /> */}
                        <Image
                            imagePath={AppIcons.footerLogo}
                            imgCustomClassName="open-bar"
                            altText="Icon"
                        ></Image>
                    </div>
                    <div className="footer-link-and-copyright-sec">
                        <div className="footer-copyright">
                            <span> ©&nbsp;2015 Singapore Green Building </span>{" "}
                            <span className="mr-2">
                                UEN:T09SS0112
                            </span>
                        </div>
                        <div className="footer-list">
                            <ul className="footer-link-and-icon">
                                <li className="lg:list-none md:list-none list-disc text-[#838383]">
                                    <a
                                        href="#"
                                        rel="noopener noreferrer"
                                    >
                                        Terms &amp; Conditions of Use of Website
                                    </a>
                                </li>
                                <li className="list-disc ">
                                    <a
                                        href="#"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Footer;
