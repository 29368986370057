
import { createApi } from "@reduxjs/toolkit/query/react";
import { transformSucessResponse, transformErrorResponse } from '../../../utils/API/responseMiddleware'
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";


const gmapViewIndividualMemberListApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'GmapViewIndividualMemberListApi',
    endpoints: (builder) => ({
        getIndividualMemberList: builder.mutation({
            query: (details) => ({
                url: '/GMAPIndividualMember/GetIndividualMemberList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getGMAPAccountInformationInIndividualByMemberId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/GMAPIndividualMember/GetGMAPAccountInformationInIndividualByMemberId?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

    }),
});

export const {
    useGetIndividualMemberListMutation,
    useLazyGetGMAPAccountInformationInIndividualByMemberIdQuery
} = gmapViewIndividualMemberListApi;

export default gmapViewIndividualMemberListApi;