import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import * as yup from "yup";
import SwalAlert from "../../../services/swalService/SwalService";
import CenterModel from "../../../components/ui/centerModel/CenterModel";
import Buttons from "../../../components/ui/button/Buttons";
import Image from "../../../components/image/Image";
import { AppIcons } from "../../../data/appIcons";
import { useAddEditGMACourseDetailsBatchMutation } from "../../../app/services/GMAP/gmaApplicationApi";


const UploadGMAMember = ({ handleReloadList }) => {
    const { error, success } = SwalAlert();
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState([]);
    const [successExcelResponse, setSuccessExcelResponse] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isUploadSuccess, setIsUploadSuccess] = useState(false);
    const fileInputRef = useRef(null);

    const [uploadMember, { data: gmaUploadResponse, isSuccess: isSuccess, isLoading: isLoading },] = useAddEditGMACourseDetailsBatchMutation();

    const schema = yup.object().shape({
        Full_Name: yup.string().required("Name is required"),
        Email_Address: yup
            .string()
            .email("Invalid email")
            .required("Email is required"),
    });

    useEffect(() => {
        if (!isLoading && isSuccess && gmaUploadResponse) {
            setIsUploadSuccess(true);
            success("", "GMA Member Added Successfully");
            setSuccessExcelResponse(gmaUploadResponse);
        }
    }, [!isLoading, isSuccess, gmaUploadResponse])

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setFileName(file?.name);
        setIsUploadSuccess(false);
        if (!file) {
            error("No file selected", "");
            return;
        }
        setFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
            const arrayBuffer = e.target.result;
            const workbook = XLSX.read(arrayBuffer, { type: "array" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            setData(jsonData);
            validateData(jsonData);
            setShowModal(true);
        };
        reader.onerror = (e) => {
            console.error("Error reading file:", e);
        };
        reader.readAsArrayBuffer(file);
    };

    const validateData = async (jsonData) => {
        const validationErrors = [];
        for (let i = 0; i < jsonData.length; i++) {
            try {
                await schema.validate(jsonData[i], { abortEarly: false });
            } catch (err) {
                validationErrors.push({ row: i + 1, errors: err.errors });
            }
        }
        setErrors(validationErrors);
    };

    const handleSubmit = async () => {
        if (errors.length > 0) {
            return error("Please fix the errors before submitting.", "");
        }

        const request = {
            GmaMembersDetails: data.map((item) => ({
                GmaCourseMemberId: null,
                FullName: item.Full_Name || null,
                PrimaryEmail: item.Email_Address || null,
            }))
        }

        const successHandler = (response, successMessage) => {
            if (response?.data?.length > 0) {
                setIsUploadSuccess(true);
                success("", successMessage);
                setSuccessExcelResponse(response.data);
            }
        };
        uploadMember(request);
        successHandler(gmaUploadResponse, "Employee Added Successfully");
        setFile(null); // Reset file state
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Reset file input value
        }
    };

    const handleModalClose = () => {
        setShowModal(false);
        setFile(null); // Reset file state
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Reset file input value
        }
        handleReloadList();
    };


    // Dynamically get headers from the first item in the data array
    const headers = data.length > 0 ? Object.keys(data[0]) : [];

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };


    const convertedHeaders = headers.map(header =>
        header
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/\s*\(\s*/g, ' (') // Handle spacing before opening parenthesis
            .replace(/\s*\)\s*/g, ')') // Handle spacing after closing parenthesis
    );

    return (
        <div className="row">
            <div className='col-lg-12 d-flex'>
                <div className="file-upload-wrapper">
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileUpload}
                        className="file-input"
                    />
                    <label
                        htmlFor="file-input"
                        className="file-upload-label"
                        onClick={triggerFileInput}
                    >
                        Upload File
                    </label>
                </div>
                <a className="btn outline-btn ml-3" href={`${process.env.PUBLIC_URL}/GMAMemberTemplate/GMAMemberTemplate.xlsx`} download>
                    Download Template
                </a>
            </div>
            {showModal && (
                <CenterModel
                    modelTitle={`${fileName}`}
                    show={showModal}
                    handleClose={handleModalClose}
                    onUpload={handleModalClose}
                >
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-lg-12 mt-4 table-responsive mol-grid">
                                <table className="mt-2">
                                    <thead className="">
                                        <tr>
                                            {convertedHeaders.map((header) => {
                                                return <th key={header}>{header}</th>
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, rowIndex) => {
                                            const rowErrors = errors.find(
                                                (error) => error.row === rowIndex + 1
                                            );
                                            const hasError = rowErrors ? "bg-danger" : "";
                                            return (
                                                <tr key={rowIndex} className={hasError}>
                                                    {headers.map((header) => {
                                                        const cellError =
                                                            rowErrors &&
                                                            rowErrors.errors.some((err) =>
                                                                err.includes(header)
                                                            );
                                                        return (
                                                            <td
                                                                key={header}
                                                                className={cellError ? "text-danger" : ""}
                                                            >
                                                                {item[header]}
                                                                {cellError && (
                                                                    <div className="small text-danger">
                                                                        {rowErrors.errors.join(", ")}
                                                                    </div>
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {isUploadSuccess && (
                                    <React.Fragment>
                                        <h4 className="mt-5">Status:</h4>
                                        <hr />
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Full Name</th>
                                                    <th>Email Address</th>
                                                    <th>Message</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {successExcelResponse?.map((item, index) => {
                                                    const Status = item.errorType === "Exists" ? "Exists" : "Success";
                                                    return (
                                                        <tr
                                                            key={index}
                                                        >
                                                            <td>{item?.fullName}</td>
                                                            <td>{item?.primaryEmail}</td>
                                                            <td>{item?.errorMessage}</td>
                                                            <td>{Status || "-"}</td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </React.Fragment>
                                )}
                            </div>
                            <div className="col-lg-12">
                                {errors.length > 0 && (
                                    <div className="mt-3">
                                        <h5>Errors:</h5>
                                        <ul>
                                            {errors.map((error, index) => (
                                                <li key={index}>
                                                    Row {error.row}: {error.errors.join(", ")}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            {!isUploadSuccess && (
                                <div className="col-lg-12 d-flex justify-content-end mt-4">
                                    <div>
                                        <Buttons
                                            buttonTypeClassName="outline-btn"
                                            buttonText="Cancel"
                                            onClick={handleModalClose}
                                        />
                                    </div>
                                    <div className="ml-2">
                                        <Buttons
                                            buttonTypeClassName="success-btn"
                                            buttonText="Submit"
                                            onClick={handleSubmit}
                                            isLoading={isLoading}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </CenterModel>
            )}
        </div>
    )
}

export default UploadGMAMember