import React, { useContext, useEffect, useRef, useState } from 'react';
import EventTabRenders from '../../../components/ui/tabs/EventRenderTab';
import SwalAlert from '../../../services/swalService/SwalService';
import BasicInformationForm from './BasicInformation/BasicInformationForm';
import BasicDetailContext from '../utils/BasicDetailContext';
import AvailabilityAndSchedule from './AvailabilityAndSchedule/AvailabilityAndSchedule';
import Pricing from './Pricing/Pricing';
import Others from './Others/Others';
import Programme from './Programme/Programme';
import Questions from './Questions/Questions';

const EventTabWrapper = () => {
    // Refs to access child components for form data
    const basicInformationRef = useRef(null);
    const availblityScheduleRef = useRef(null);

    const { tabs, setTabs, activeTab, setActiveTab, entryType, setEntryType } = useContext(BasicDetailContext);
    const { error } = SwalAlert();

    const tabStructures = {
        1: [
            { id: 0, label: "Basic Information", component: <BasicInformationForm ref={basicInformationRef} />, saveInto: "BasicInformation", isDisable: false },
            { id: 1, label: "Availability & Schedule", component: <AvailabilityAndSchedule />, saveInto: "AvailabilitySchedule", isDisable: false },
            { id: 3, label: "Pricing", component: <Pricing />, saveInto: "Pricing", isDisable: false },
            { id: 4, label: "Others", component: <Others />, saveInto: "Others", isDisable: false },
        ],
        2: [
            { id: 0, label: "Basic Information", component: <BasicInformationForm ref={basicInformationRef} />, saveInto: "BasicInformation", isDisable: false },
            { id: 1, label: "Availability & Schedule", component: <AvailabilityAndSchedule />, saveInto: "AvailabilitySchedule", isDisable: false },
            { id: 2, label: "Programme", component: <Programme />, saveInto: "Programme", isDisable: false },
            { id: 3, label: "Pricing", component: <Pricing />, saveInto: "Pricing", isDisable: false },
            { id: 4, label: "Others", component: <Others />, saveInto: "Others", isDisable: false },
        ],
        3: [
            { id: 0, label: "Basic Information", component: <BasicInformationForm ref={basicInformationRef} />, saveInto: "BasicInformation", isDisable: false },
            { id: 5, label: "Questions", component: <Questions />, saveInto: "Questions", isDisable: false },
        ],
    };

    // Effect to initialize tabs on component mount
    useEffect(() => {
        if (entryType && tabStructures[entryType]) {
            setTabs(tabStructures[entryType]);
        }
    }, [entryType]);

    // Function to handle tab navigation
    const handleBack = (tab) => setActiveTab(tab);
    const handleRedirectTabs = (tab) => setActiveTab(tab);

    // Function to handle next tab navigation
    const handleNextTab = (tabName) => {
        // Validate form data for Basic Information tab
        if (tabName === "BasicInformation") {
            const basicInformationData = basicInformationRef.current.getFormData();
            if (basicInformationData) {
                // Add API request logic for Basic Information here
            } else {
                error("Please fill all mandatory fields.", "");
            }
        }
        // Validate form data for Availability Schedule tab
        else if (tabName === "AvailabilitySchedule") {
            const basicInformationData = basicInformationRef.current.getFormData();
            const availabilityScheduleData = availblityScheduleRef.current.getFormData();
            if (basicInformationData && availabilityScheduleData) {
                // Add API request logic for Availability Schedule here
            } else {
                error("Please fill all mandatory fields.", "");
            }
        }
    };

    return (
        <EventTabRenders
            activeTab={activeTab}
            tabs={tabs}
            showBackButtonIndex={0}
            handleBack={handleBack}
            handleNextTab={handleNextTab}
            handleRedirectTabs={handleRedirectTabs}
        />
    );
};

export default EventTabWrapper;
