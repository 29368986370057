import { FormFieldTypes } from "../../../../../data/formFieldType";
import { GridColumnType } from "../../../../../data/gridColumnType";

export const careerHistoryConfig = {
    columns: [
        {
            name: "Company Name",
            fieldName: "companyName",
            allowShort: false
        },
        {
            name: "Designation",
            fieldName: "designation",
            allowShort: false
        },
        {
            name: "Period From",
            fieldName: "periodFrom",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD/MM/YYYY',
            }
        },
        {
            name: "Period To",
            fieldName: "periodTo",
            allowShort: false,
            colType: GridColumnType.CUSTOM,
            renderCustomCol: (rowData) => {
                if (rowData?.["periodTo"]) {
                    const [year, month, day] = rowData?.["periodTo"] && rowData?.["periodTo"].substring(0, 10).split("-");
                    return (rowData?.["periodTo"] && !rowData?.['isCurrent']) ? `${day}/${month}/${year}` : "Current";
                }
            },
        },
        {
            name: "Profession",
            fieldName: "professionName",
            allowShort: false
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: true
            }

        },
    ]
}

export const careerHistoryData = {
    name: 'CareerHistory',
    initialState: {
        isUnemployed: false,
        companyName: '',
        designation: "",
        periodFrom: "",
        periodTo: "",
        isCurrent: false,
        gmapProfessionTypeId: "",
    },
    formFields: [
        {
            id: "isUnemployed",
            lable: "Currently Unemployed",
            Field_Name: "IsUnemployed",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "isUnemployed",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-12 mb-3",
            },
        },
        {
            id: "careerHistoryTable",
            fieldType: FormFieldTypes.TABLE,
            dataField: "careerHistoryTable",
            fieldSetting: {
                header: careerHistoryConfig,
                dataSource: [],
            },
            style: {
                containerCss: "col-lg-12 mb-2 text-left",
            },
        },
        {
            id: "companyName",
            lable: "Company Name",
            Field_Name: "Company Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "companyName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "designation",
            lable: "Designation",
            Field_Name: "Designation",
            fieldType: FormFieldTypes.INPUT,
            dataField: "designation",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "periodFrom",
            lable: "Period From",
            Field_Name: "Period From",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "periodFrom",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "gmapProfessionTypeId",
            lable: "Profession",
            Field_Name: "Profession",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmapProfessionTypeId",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "periodTo",
            lable: "Period To",
            Field_Name: "Period To",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "periodTo",
            fieldSetting: {
                placeholder: "",
                isDisable: false,
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-2"
            }
        },
        {
            id: "isCurrent",
            lable: "Currently Employed",
            Field_Name: "IsCurrent",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "isCurrent",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 d-none mb-0 ",
            },
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}

