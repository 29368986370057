import { createApi } from "@reduxjs/toolkit/query/react";
import { transformErrorResponse, transformSucessResponse } from "../../../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../../../utils/API/requestMiddleware";
import { customFetchBase, defautBaseQuery } from "../../../../utils/API/fetchBaseQuery";

const EmailTemplateManagementApi = createApi({
    reducerPath: 'EmailTemplateManagementApi',
    baseQuery: defautBaseQuery,
    endpoints: (builder) => ({
        getEmailTemplateList: builder.mutation({
            query: (details) => ({
                url: '/FieldManagement/GetEmailTemplateList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getEmailTemplateById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/FieldManagement/GetEmailTemplateById?id=${parseInt(id)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getAllProjectTypeDropdown: builder.query({
            query: (id) => ({
                url: `/FieldManagement/GetAllProjectTypeDropdown`,
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        addEditEmailTemplate: builder.mutation({
            query: (details) => ({
                url: '/FieldManagement/AddEditEmailTemplate',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        deleteEmailTemplate: builder.mutation({
            query: (details) => ({
                url: '/FieldManagement/DeleteEmailTemplate',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useGetEmailTemplateListMutation,
    useLazyGetEmailTemplateByIdQuery,
    useLazyGetAllProjectTypeDropdownQuery,
    useAddEditEmailTemplateMutation,
    useDeleteEmailTemplateMutation
} = EmailTemplateManagementApi;

export default EmailTemplateManagementApi;