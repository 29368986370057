import React, { useEffect, useRef, useState } from 'react'
import MolGrid from '../../../../components/Grid/MolGrid'
import { gmapApplicationTasklistTableConfig } from '../component/GmapApplicationTasklistTable.Data';
import { useGmapApplicationTaskListApiMutation } from '../../../../app/services/GMAP/gmapApplicationTaskListApi';
import { useNavigate } from 'react-router-dom';
import { encryptAESID } from '../../../../utils/Encryption';
import { useDownloadSheetForGMAPApplicationTaskListMutation } from '../../../../app/services/GMAP/gmapApplicationTaskListApi';
const GmapApplicationTasklistTable = ({ paginationReq }) => {
  const navigate = useNavigate();
  const molGridRef = useRef();
  const [dataSource, setDataSource] = useState();
  const [paginationRequest, setPaginationRequest] = useState({
    pageNo: 1,
    pageSize: 10,
    endPage: 100,
    SearchText: "",
    gmapNumber: "",
    submissionStartDate: "",
    submissionEndDate: "",
    totalCount: 0,
    StatusId: null
  });

  const [getApplicationTaskList,
    { isSuccess: isGetApplicationTaskListSuccess,
      isLoading: isGetApplicationTaskListFetching,
      data: applicationTaskListResponse },
  ] = useGmapApplicationTaskListApiMutation();


  useEffect(() => {
    getApplicationTaskList(paginationReq)
  }, [])

  useEffect(() => {
    setDataSource([]);
    getApplicationTaskList(paginationReq)
  }, [paginationReq])

  useEffect(() => {
    if (!isGetApplicationTaskListFetching && isGetApplicationTaskListSuccess && applicationTaskListResponse) {
      setPaginationRequest({ ...paginationRequest, totalCount: applicationTaskListResponse.totalCount })
      setDataSource(applicationTaskListResponse.itemList)
    }
  }, [isGetApplicationTaskListFetching, isGetApplicationTaskListSuccess, applicationTaskListResponse]);

  const handlePageChange = ({ pageNumber, pageSize }) => {
    getApplicationTaskList({ ...paginationRequest, pageNo: pageNumber, pageSize: pageSize })
  }

  // Function to handle Edit click
  const handleEditClick = (data) => {
    // navigate(`/EditApplicationTaskList/${encryptAESID(data.applicationTaskListId, false)}`)
    navigate(`/EditApplicationTaskList/${encryptAESID(data.gmapMemberId, false)}/${encryptAESID(data.applicationTaskListId, false)}`)
  }

  const actionHandler = {
    UPDATE: handleEditClick,
  }
  return (
    <>
     <div>
         <h4>Total Count :{paginationRequest.totalCount} </h4>
     </div>  
      <MolGrid
        ref={molGridRef}
        configuration={gmapApplicationTasklistTableConfig}
        dataSource={dataSource}
        allowPagination={true}
        isLoading={isGetApplicationTaskListFetching}
        pagination={paginationRequest}
        onPageChange={handlePageChange}
        onActionChange={actionHandler}
      />
    </>
  )
}

export default GmapApplicationTasklistTable