
import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";
import { transformRequest } from "../../../utils/API/requestMiddleware";


const CPDApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'CPDApi',
    endpoints: (builder) => ({
        acceptCpdSubmission: builder.mutation({
            query: (details) => ({
                url: '/CPD/ApproveCPDActivity',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        rejectCpdSubmission: builder.mutation({
            query: (details) => ({
                url: '/CPD/RejectCPDSubmissionApplication',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        UploadCpdBatch: builder.mutation({
            query: (details) => ({
                url: '/CPD/UploadCpdBatch',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetUploadedCPDList: builder.mutation({
            query: (details) => ({
                url: '/CPD/GetUploadedCPDList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetCPDRenewalCycleList: builder.mutation({
            query: (details) => ({
                url: '/CPD/GetCPDRenewalCycleList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

export const {
    useAcceptCpdSubmissionMutation,
    useRejectCpdSubmissionMutation,
    useUploadCpdBatchMutation,
    useGetUploadedCPDListMutation,
    useGetCPDRenewalCycleListMutation
} = CPDApi;

export default CPDApi;