import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";
import { encryptAES } from "../../../services/CryptoService";


const DiscrertionaryRenewalApi = createApi({
    reducerPath: 'DiscrertionaryRenewalApi',
    baseQuery: defautBaseQuery,
    endpoints: (builder) => ({
        getDiscretionaryRenewalRequestById: builder.query({
            query: (details) => ({
                url: `/Dashboard/GetDiscretionaryRenewalRequestById?DiscretionaryRenewalRequestId=${encryptAES(details.requestId)}&GMAPMemberId=${encryptAES(details.memberId)}`,
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetDiscretionaryRenewalRequestList: builder.mutation({
            query: (details) => ({
                url: '/Dashboard/GetDiscretionaryRenewalRequestList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        ApproveDiscretionaryRenewalRequestById: builder.mutation({
            query: (details) => ({
                url: `/Dashboard/ApproveDiscretionaryRenewalRequestById?MemberId=${encryptAES(details.MemberId)}&TaskId=${encryptAES(details.TaskId)}`,
                method: 'POST',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetGMAPDiscretionaryRenewalTaskList: builder.mutation({
            query: (details) => ({
                url: `/GMAPMembership/GetGMAPDiscretionaryRenewalTaskList`,
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        RejectDiscretionaryRenewalRequestById: builder.mutation({
            query: (details) => ({
                url: `/Dashboard/RejectDiscretionaryRenewalRequestById`,
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useGetDiscretionaryRenewalRequestListMutation,
    useLazyGetDiscretionaryRenewalRequestByIdQuery,
    useApproveDiscretionaryRenewalRequestByIdMutation,
    useGetGMAPDiscretionaryRenewalTaskListMutation,
    useRejectDiscretionaryRenewalRequestByIdMutation
} = DiscrertionaryRenewalApi;

export default DiscrertionaryRenewalApi;