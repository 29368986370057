/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useRef, useImperativeHandle, useState, useContext } from "react";
import FormCreator from "../../../../../components/Forms/FormCreator";
import { academicQualificationData } from "./AcademicQualification.Data";
import { EditApplicationDataContext } from "../../../../../pages/gmap/gmapApplicationTasklist/gmapApplicationTaskListNewApplication/GmapApplicationTaskListNewApplication";
import { useLazyGetPDFQuery, useUploadPDFMutation } from "../../../../../app/services/common/commonApi";
import { saveAs } from "file-saver";

const AcademicQualification = forwardRef((props, ref) => {

    const academicQualificationRef = useRef();
    const [academicQualificationForm, SetAcademicQualificationForm] = useState(academicQualificationData);
    const { newMemberData, setContextData } = useContext(EditApplicationDataContext)
    const [getPDF] = useLazyGetPDFQuery();
    const [uploadPDF] = useUploadPDFMutation()
    // const { newMemberData, setContextData } = useContext(NewApplicationDataContext)

    // const [getPDF] = useLazyGetPDFQuery();

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const academicData = academicQualificationRef.current.getFormData();
                return academicData;
            }
            return null;
        }
    }))

    // const [uploadPDF,
    //     { isSuccess: isSuccess,
    //         data: fileUploadData }
    // ] = useUploadPDFMutation();


    const handleClear = () => {
        const updatedForm = { ...academicQualificationForm };
        updatedForm.initialState.academicQualificationCertificationFile = null;
        SetAcademicQualificationForm(updatedForm);
    }


    const handleDownload = async (data, dataField) => {
        const request = {
            type: "",
            fileName: newMemberData?.academicCertificate,
        }
        if (dataField === "academicQualificationCertificationFile") {
            request.type = "AcademincQualification"
        }
        const response = await getPDF(request);
        saveAs(response?.data?.fileData, response?.data?.fileName);
    }

    const formActionHandler = {
        CLEAR: handleClear,
        DOWNLOAD: handleDownload,
    }

    const handleOnChangeFileUpload = async (data) => {
        let formData = new FormData();
        formData.append("file", data)
        formData.append("storagePath", "AcademincQualification");
        try {
            const response = await uploadPDF(formData);
            setContextData({
                ...newMemberData,
                academicCertificate: response.data.fileName,
                academicQualificationCertificationFileName: response.data.uploadedFileName

            });
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    }
    // for file upload changes
    const handleFormFieldChange = (dataField, value) => {
        if (dataField === "academicQualificationCertificationFile" && value) {
            handleOnChangeFileUpload(value)
        }
    };

    return (<FormCreator ref={academicQualificationRef}
        {...academicQualificationForm}
        onActionChange={formActionHandler}
        onFormFieldValueChange={handleFormFieldChange}
    />)
})

export default AcademicQualification