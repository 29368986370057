// dropdownUtils.js

import { academicQualificationData } from "../AcademicQualification/AcedemicQualification.Data";
import { careerHistoryData } from "../CareerHistory/CareerHistory.data";
import { certificateCourseData } from "../CertificateCourse/CertificateCourse.Data";
import { gMapQualificationData } from "../GMAPQualification/GMapQualification.data";
import { greenMarkProjectData } from "../GreenMarkProject/GreenMarkProject.Data";
import { personalParticularsData } from "../personalParticulars/PersonalParticularsFormData";

// import { addEditMemberModelData } from "../../../../membership/component/AddEditMemberModel.Data";
// import { memberDirectoryData } from "../../../../membership/component/MemberDirectoryForm.Data";
// import { AccountInformationFormData } from "../AccountInformation/AccountInformation.Data";
// import { careerHistoryData } from "../CareerHistory/CareerHistory.data";
// import { certificateCourseData } from "../CertificateCourse/CertificateCourse.Data";
// import { gmapMembershipData } from "../GmapMembership/GmapMembership.Data";
// import { greenMarkProjectData } from "../GreenMarkProject/GreenMarkProject.Data";

export const setAllDropDownField = (apiResponse) => {

    const filterDropDownList = (dropdownName) => {
        return apiResponse.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name
                });
            }
            return acc;
        }, []);
    }

    const dropdownLists = {
        "applicationTypeID": filterDropDownList("Application Type"),
        "salutationsID": filterDropDownList("Title"),
        "nationalityID": filterDropDownList("Nationality"),
        "countryOfResID": filterDropDownList("Nationality"),
        "competenciesID": filterDropDownList("CompetenciesType"),
        "otherProfessionQualificationID": filterDropDownList("Other Professional Qualification"),
        "highestQualificationID": filterDropDownList("Highest Relevant Qualification"),
        "graduateYearID": filterDropDownList("Graduate Year"),
        "gmapProfessionTypeId": filterDropDownList("Profession"),
        "gmaPathwayTypeID": filterDropDownList("GMAPathways"),
        "gmapCertificateTypeId": filterDropDownList("GMAP Certificate Type"),
        "gmaapCertificateTypeId": filterDropDownList("GMAAP Certificate Type"),
        "gmapCertificateTypeP1Id": filterDropDownList("GMA Certificate Type p1"),
        "gmapCertificateTypeP2Id": filterDropDownList("GMA Certificate Type p2"),
        "gmapAwardRatingId": filterDropDownList("GMAP Award Rating"),
        "gmapGreenMarkCategoryId": filterDropDownList("GMAP Green Mark Category"),
        "gmapPathwayTypeID": filterDropDownList("GMAPPathways"),
        "gmaapPathwayTypeID": filterDropDownList("GMAAPPathways"),
        "gmaapAwardRatingP1Id": filterDropDownList("GMAAP Award Rating P1"),
        "gmaapAwardRatingP2Id": filterDropDownList("GMAAP Award Rating P2"),
        "gmaapAwardRatingP3Id": filterDropDownList("GMAAP Award Rating P3"),
        "gmapGreenMarkCategoryP1Id": filterDropDownList("GMAP Green Mark Category P1"),
        "gmapGreenMarkCategoryP2Id": filterDropDownList("GMAP Green Mark Category P2"),
        "gmaapGreenMarkCategoryP1Id": filterDropDownList("GMAAP Green Mark Category P1"),
        "gmaapGreenMarkCategoryP2Id": filterDropDownList("GMAAP Green Mark Category P2"),
        "gmaapGreenMarkCategoryP3Id": filterDropDownList("GMAAP Green Mark Category P3"),
        "accountType": filterDropDownList("Application Type"),
        "status": filterDropDownList("Status"),
        "areaofCompetencies": filterDropDownList('CompetenciesType'),
        "typeofProfessionalQualification": filterDropDownList('Other Professional Qualification'),
        // Add more dropdowns as needed
    };

    const formFields = [
        ...personalParticularsData.formFields,
        ...gMapQualificationData.formFields,
        ...academicQualificationData.formFields,
        ...careerHistoryData.formFields,
        ...certificateCourseData.formFields,
        ...greenMarkProjectData.formFields,
        // ...gmapMembershipData.formFields,
        // ...AccountInformationFormData.formFields,
        // ...addEditMemberModelData.formFields,
        // ...memberDirectoryData.formFields
    ];

    formFields.forEach(field => {
        const dropdownList = dropdownLists[field.id];
        if (dropdownList) {
            field.fieldSetting.options = dropdownList;
        }
    });
};
