import React, { useContext, useEffect, useRef, useState } from "react";
import { PastCpdCycleGridConfig } from "../ViewAddCpdPoints.Data";
import MolGrid from "../../../../components/Grid/MolGrid";
import { useLazyGetPastCPDMemberDetailsListByMemberIdQuery } from "../../../../app/services/CPD/ApproveCPDSubmissionApi";
import { useParams } from "react-router-dom";
import { decryptAESID } from "../../../../utils/Encryption";
import formatDate from "../../../../lib/formatDate";
import { CurrentCPDCycleDataContext } from "../CpdSubmissionForm";
import SGBCAccreditedCpdEvents from "../AllActivityComponent/SGBCAccreditedCpdEvents/SGBCAccreditedCpdEvents";
import BcaGreenMarkProject from "../AllActivityComponent/BcaGreenMarkProject/BcaGreenMarkProject";
import MastersDegreeDoctorProgrammes from "../AllActivityComponent/MastersDegreeDoctorProgrammes/MastersDegreeDoctorProgrammes";
import PublicationofResearchPaper from "../AllActivityComponent/PublicationofResearchPaper/PublicationofResearchPaper";
import IndustryTechnicalCommittees from "../AllActivityComponent/IndustryTechnicalCommittees/IndustryTechnicalCommittees";
import CpdprogrammesbyOverseasgreenbuildingCouncils from "../AllActivityComponent/CpdprogrammesbyOverseasgreenbuildingCouncils/CpdprogrammesbyOverseasgreenbuildingCouncils";
import SgbcElearning from "../AllActivityComponent/SgbcElearning/SgbcElearning";
import SpeakingTeachingEngagement from "../AllActivityComponent/SpeakingTeachingEngagement/SpeakingTeachingEngagement";
import CenterModel from "../../../../components/ui/centerModel/CenterModel";

export const PastcpdCycleTable = () => {
    const PastcpdCycleTableRef = useRef();

    const { cpdSubmissionData, setCpdSubmissionData } = useContext(
        CurrentCPDCycleDataContext
    );

    const SGBCAccreditedCpdEventsRef = useRef();
    const BcaGreenMarkProjectRef = useRef();
    const MastersDegreeDoctorProgrammesRef = useRef();
    const PublicationofResearchPaperRef = useRef();
    const IndustryTechnicalCommitteesRef = useRef();
    const CpdprogrammesbyOverseasgreenbuildingCouncilsRef = useRef();
    const SgbcElearningRef = useRef();
    const SgbcEventRef = useRef();
    const SpeakingTeachingEngagementRef = useRef();
    const rejectReasonRef = useRef();
    const [isModelOpen, setIsModalOpen] = useState(false);
    const [pastCPDCycle, setPastCPDCycle] = useState();
    const { id } = useParams();

    const [modelDetail, setModelDetail] = useState({
        value: 0,
        label: "",
        component: null,
    });

    const [
        getPastCPDListByMemberId,
        {
            isLoading: getPastCPDListByMemberIdIsLoading,
            data: getPastCPDListByMemberIdData,
            isSuccess: getPastCPDListByMemberIdIsSuccess,
        },
    ] = useLazyGetPastCPDMemberDetailsListByMemberIdQuery();

    const openCloseModal = () => {
        setIsModalOpen(!isModelOpen);
    };

    useEffect(() => {
        if (
            !getPastCPDListByMemberIdIsLoading &&
            getPastCPDListByMemberIdData &&
            getPastCPDListByMemberIdIsSuccess
        ) {
            let tempData = getPastCPDListByMemberIdData.map((obj) => {
                return {
                    ...obj,
                    submittedDate: formatDate(obj.submittedDate, "MM/DD/YYYY"),
                    dateOfActivity: formatDate(obj.dateOfActivity, "MM/DD/YYYY"),
                    verficationDate: formatDate(obj.verficationDate, "MM/DD/YYYY"),
                    typeOfActivity: <u>{obj.typeOfActivity}</u>,
                };
            });
            setPastCPDCycle(tempData);
        }
    }, [
        getPastCPDListByMemberIdIsLoading,
        getPastCPDListByMemberIdData,
        getPastCPDListByMemberIdIsSuccess,
    ]);

    const getPastCPDListById = () => {
        const gmapNumberId = decryptAESID(id);
        getPastCPDListByMemberId(gmapNumberId);
    };
    useEffect(() => {
        if (id) {
            getPastCPDListById();
        }
    }, [id]);

    // const getCurrentPageObject = () => {
    //     if (PastcpdCycleTableRef.current) {
    //         // Access the current page object through the ref
    //         // eslint-disable-next-line no-unused-vars
    //         const currentPageObject = PastcpdCycleTableRef.current.getCurrentPageObject();
    //     }
    // };

    // useEffect(() => {
    //     getCurrentPageObject();
    // }, [])

    const formActionHandler = {
        // CLEAR: handleClear,
        // DOWNLOAD: handleDownload,
        //  REJECT: handleRejectbutton,
        //  APPROVE: handleApproveButton,
        //  REJECT_SUCCESS: rejectSuccess,
        //  SAVE_SUCCESS: saveSuccess,
    };

    const modelComponent = [
        {
            value: 1,
            label: "SGBC- Accredited CPD Events",
            component: (
                <SGBCAccreditedCpdEvents ref={SGBCAccreditedCpdEventsRef} formActionHandler={formActionHandler} isPastCPD={true} />
            ),
        },
        {
            value: 2,
            label: "BCA Green Mark Project",
            component: (
                <BcaGreenMarkProject ref={BcaGreenMarkProjectRef} formActionHandler={formActionHandler} isPastCPD={true} />
            ),
        },
        {
            value: 3,
            label: "Masters Degree/Doctorate (Ph.D) programmes",
            component: (
                <MastersDegreeDoctorProgrammes ref={MastersDegreeDoctorProgrammesRef} formActionHandler={formActionHandler} isPastCPD={true} />
            ),
        },
        {
            value: 4,
            label: "Publication of Research Paper",
            component: (
                <PublicationofResearchPaper ref={PublicationofResearchPaperRef} formActionHandler={formActionHandler} isPastCPD={true} />
            ),
        },
        {
            value: 5,
            label: "Industry Technical Committees",
            component: (
                <IndustryTechnicalCommittees ref={IndustryTechnicalCommitteesRef} formActionHandler={formActionHandler} isPastCPD={true} />
            ),
        },
        {
            value: 6,
            label: "CPD Programmes by Overseas Green Building Councils",
            component: (
                <CpdprogrammesbyOverseasgreenbuildingCouncils ref={CpdprogrammesbyOverseasgreenbuildingCouncilsRef} formActionHandler={formActionHandler} isPastCPD={true} />
            ),
        },
        {
            value: 7,
            label: "SGBC e-Learning",
            component: (
                <SgbcElearning ref={SgbcElearningRef} formActionHandler={formActionHandler} isPastCPD={true} />
            ),
        },
        {
            value: 8,
            label: "Speaking/Teaching Engagement",
            component: (
                <SpeakingTeachingEngagement ref={SpeakingTeachingEngagementRef} formActionHandler={formActionHandler} isPastCPD={true} />
            ),
        },
    ];

    const handleRowClick = (data) => {
        if (data.cpdActivityTypeId === 9) return;

        setCpdSubmissionData({
            ...cpdSubmissionData,
            cpdSubmissionId: data.cpdSubmissionId,
        });
        const modelObj = modelComponent.find(
            (obj) => obj.value === data.cpdActivityTypeId
        );
        setModelDetail({ ...modelObj });
        openCloseModal();
    };

    const actionHandler = {
        ROW_CLICK: handleRowClick,
    };
    return (
        <>
            <MolGrid
                ref={PastcpdCycleTableRef}
                configuration={PastCpdCycleGridConfig}
                dataSource={pastCPDCycle}
                onActionChange={actionHandler}
                isAllowRowClick={true}
                allowPagination={false}
            />
            {isModelOpen && (
                <CenterModel
                    modelTitle={modelDetail.label}
                    show={"test"}
                    handleClose={openCloseModal}
                    onUpload={openCloseModal}
                >
                    {modelDetail.component}
                </CenterModel>
            )}
        </>
    );
};

export default PastcpdCycleTable;
