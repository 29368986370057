import React from "react";
import ViewResources from "../../pages/resources/ViewResources";
import CreateEditResources from "../../features/resources/CreateEditResources";
import CpdSubmission from "../../pages/cpd/cpdSubmission/CpdSubmission";
import ApproveCpdSubmission from "../../pages/cpd/verifyCpdSubmission/ApproveCpdSubmission";
import PreviewResources from "../../features/resources/PreviewResources";
import GmapPricingTable from "../../pages/gmap/gmapPricingTable/GmapPricingTable";
import ViewIndividualMembers from "../../pages/gmap/viewIndividualMembers/ViewIndividualMembers";
import GmapApplicationTasklist from "../../pages/gmap/gmapApplicationTasklist/GmapApplicationTasklist";
import GmapApplicationTaskListNewApplication from "../../pages/gmap/gmapApplicationTasklist/gmapApplicationTaskListNewApplication/GmapApplicationTaskListNewApplication";
import MemberDirectory from "../../pages/membership/memberDirectory/MemberDirectory";
import EditIndividualMembers from "../../pages/gmap/viewIndividualMembers/EditIndividualMembers";
import EditEmailTemplate from "../../pages/fieldMaintenance/emailTemplateManagement/EditEmailTemplate";
import DropdownManagement from "../../pages/fieldMaintenance/dropdownManagement/DropdownManagement";
import AddEmailTemplate from "../../pages/fieldMaintenance/emailTemplateManagement/AddEmailTemplate";
import EmailTemplateManagement from "../../pages/fieldMaintenance/emailTemplateManagement";
import CreateIndividualMembers from "../../pages/gmap/viewIndividualMembers/createIndividualMembers";
import CreatePages from "../../pages/pageManagement/createPages/CreatePages";
import ViewPages from "../../pages/pageManagement/viewPages/ViewPages";
import EditableGridPage from "../../pages/EditableGrid/EditableGridPage";
import GmapDiscretionaryRenewalTaskList from "../../pages/gmap/gmapDiscretionaryRenewalTaskList/GmapDiscretionaryRenewalTaskList";
import gmapDiscretionaryRenewalRequest from "../../pages/gmap/gmapDiscretionaryRenewalRequest/gmapDiscretionaryRenewalRequest";
import EditDiscretionaryrenewalRequest from "../../features/gmap/DiscretionaryRenewalRequest/Components/EditDiscretionaryrenewalRequest";
import EditDiscretionaryRenewalTaskListApplication from "../../features/gmap/DiscretionaryRenewalTaskList/EditDiscretionaryRenewalTaskListApplication";
import GMACourse from "../../pages/gmaCourse/gmaCourse";
import cpdUpload from "../../pages/cpd/cpdUpload/cpdUpload";
import CKEditorForm from "../../pages/CkEditor/CKEditorForm";
import CpdRenewalCycle from "../../pages/cpd/cpdRenewalCycle/CpdRenewalCycle";
import XeroBatchExtraction from "../../pages/Financials/xeroBatchExtraction/XeroBatchExtraction";
import EventManagement from "../../pages/eventManagement/EventManagement";
import ViewEditEventManagement from "../../pages/eventManagement/ViewEditEventManagement";

const NotFound = React.lazy(() => import("../../pages/errors/NotFound"));
const Dashboard = React.lazy(() => import('../../pages/dashboard/Dashboard'));


export const ComponentNavigation = [
  {
    id: 'dashboard',
    path: '/',
    exact: true,
    title: 'Dashboard',
    component: Dashboard,
    hasParams: false,
    text: 'Dashboard',
    securityKey: ""
  },
  {
    id: "pageNotFound",
    path: "pageNotFound",
    exact: true,
    title: "",
    component: NotFound,
    text: "Page not found",
    securityKey: ""
  },
  {
    id: 'gmapPricingTable',
    path: '/gmapPricingTable',
    exact: true,
    title: 'GMAP Pricing Table',
    component: GmapPricingTable,
    hasParams: false,
    text: 'GMAP Pricing Table',
    securityKey: ""
  },
  {
    id: 'createNewIndividualMember',
    path: '/createNewIndividualMember',
    exact: true,
    title: 'Create Individual Member',
    component: CreateIndividualMembers,
    hasParams: false,
    text: 'Create Individual Member',
    securityKey: ""
  },
  {
    id: 'viewIndividualMembers',
    path: '/viewIndividualMembers',
    exact: true,
    title: 'View Individual Members',
    component: ViewIndividualMembers,
    hasParams: false,
    text: 'View Individual Members',
    securityKey: ""
  },
  {
    id: 'gmaCourse',
    path: '/gmaCourse',
    exact: true,
    title: 'GMA - GMA Course Backend',
    component: GMACourse,
    hasParams: false,
    text: 'GMA - GMA Course Backend',
    securityKey: ""
  },
  {
    id: 'editIndividualMembers',
    path: '/EditIndividualMembers/:id',
    exact: true,
    title: 'Edit Individual Member',
    component: EditIndividualMembers,
    hasParams: false,
    text: 'Edit Individual Member',
    securityKey: ""
  },
  {
    id: 'gmapApplicationTasklist',
    path: '/gmapApplicationTasklist',
    exact: true,
    title: 'GMAP Application Task List',
    component: GmapApplicationTasklist,
    hasParams: false,
    text: 'GMAP Application Task List',
    securityKey: ""
  },
  {
    id: 'gmapApplicationTaskListNewApplication',
    path: '/gmapApplicationTaskListNewApplication',
    exact: true,
    title: 'GMAP Application Task List (New Application)',
    component: GmapApplicationTaskListNewApplication,
    hasParams: false,
    text: 'GMAP Application Task List (New Application)',
    securityKey: ""
  },
  {
    id: 'editApplicationTaskList',
    path: '/EditApplicationTaskList/:id/:taskListId',
    exact: true,
    title: 'Edit GMAP Application Task List',
    component: GmapApplicationTaskListNewApplication,
    hasParams: true,
    text: 'Edit GMAP Application Task List',
    securityKey: ""
  },
  {
    id: 'gmapApplicationTaskListDiscretionaryRenewal',
    path: '/gmapApplicationTaskListDiscretionaryRenewal',
    exact: true,
    title: 'GMAP Discretionary Renewal Task List (Renewal)',
    component: GmapDiscretionaryRenewalTaskList,
    hasParams: false,
    text: 'GMAP Discretionary Renewal Task List (Renewal)',
    securityKey: ""
  },
  {
    id: 'editApplicationTaskListDiscretionaryRenewal',
    path: '/editDiscretionaryRenewalTaskListApplication/:id/:taskListId',
    exact: true,
    title: 'Edit GMAP Discretionary Renewal Task List (Renewal)',
    component: EditDiscretionaryRenewalTaskListApplication,
    hasParams: false,
    text: 'Edit GMAP Discretionary Renewal Task List (Renewal)',
    securityKey: ""
  },
  {
    id: 'gmapDiscretionaryRenewalRequest',
    path: '/gmapDiscretionaryRenewalRequest',
    exact: true,
    title: 'GMAP Discretionary Renewal Request',
    component: gmapDiscretionaryRenewalRequest,
    hasParams: false,
    text: 'GMAP Discretionary Renewal Request',
    securityKey: ""
  },
  {
    id: 'editDiscretionaryRenewalRequest',
    path: '/editDiscretionaryRenewalRequest/:memberId/:requestId',
    exact: true,
    title: 'Edit GMAP Discretionary Renewal Request',
    component: EditDiscretionaryrenewalRequest,
    hasParams: false,
    text: 'Edit GMAP Discretionary Renewal Request',
    securityKey: ""
  },
  // cpd dropdown start
  {
    id: 'approveCpdSubmission',
    path: '/approveCpdSubmission',
    exact: true,
    title: 'Approve CPD Submission',
    component: ApproveCpdSubmission,
    hasParams: false,
    text: 'Approve CPD Submission',
    securityKey: ""
  },
  {
    id: 'cpdSubmission',
    path: '/cpdSubmission/:id',
    exact: true,
    title: 'CPD Submission',
    component: CpdSubmission,
    hasParams: false,
    text: 'CPD Submission',
    securityKey: ""

  },
  {
    id: 'cpdUpload',
    path: '/cpdUpload',
    exact: true,
    title: 'CPD Upload',
    component: cpdUpload,
    hasParams: false,
    text: 'CPD Upload',
    securityKey: ""
  },
  {
    id: 'cpdRenewalCycle',
    path: '/cpdRenewalCycle',
    exact: true,
    title: 'CPD Renewal Cycle',
    component: CpdRenewalCycle,
    hasParams: false,
    text: 'CPD Renewal Cycle',
    securityKey: ""
  },
  // cpd dropdown end
  {
    id: 'viewResources',
    path: '/viewResources',
    exact: true,
    title: 'View Resources',
    component: ViewResources,
    hasParams: false,
    text: 'View Resources',
    securityKey: ""
  },
  {
    id: 'createEditResources',
    path: '/createEditResources',
    exact: true,
    title: 'Create/Edit Resources',
    component: CreateEditResources,
    hasParams: false,
    text: 'Create/Edit Resources',
    securityKey: ""
  },
  {
    id: 'previewResources',
    path: '/previewResources',
    exact: true,
    title: 'Preview',
    component: PreviewResources,
    hasParams: false,
    text: 'Preview',
    securityKey: ""
  },
  // Resources Pages End
  // membership dropdown start
  {
    id: 'memberDirectory',
    path: '/memberDirectory',
    exact: true,
    title: 'Member Directory',
    component: MemberDirectory,
    hasParams: false,
    text: 'Member Directory',
    securityKey: ""
  },

  //Field Maintanance
  {
    id: 'emailTemplateManagement',
    path: '/emailTemplateManagement',
    exact: true,
    title: 'Email Template Management',
    component: EmailTemplateManagement,
    hasParams: false,
    text: 'Email Template Management',
    securityKey: ""
  },
  {
    id: 'editEmailTemplateManagement',
    path: '/emailTemplateManagement/:id',
    exact: true,
    title: 'Email Template Management',
    component: EditEmailTemplate,
    hasParams: false,
    text: 'Edit Email Template Management',
    securityKey: ""
  },
  {
    id: 'addEmailTemplate',
    path: '/emailTemplateManagement/addEmailTemplate',
    exact: true,
    title: 'Add Email Template',
    component: AddEmailTemplate,
    hasParams: false,
    text: 'Add Email Template',
    securityKey: ""
  },
  {
    id: 'dropdownManagement',
    path: '/dropdownManagement',
    exact: true,
    title: 'Dropdown Management',
    component: DropdownManagement,
    hasParams: false,
    text: 'Dropdown Management',
    securityKey: ""
  },
  {
    id: 'editableGridPage',
    path: '/editableGridPage',
    exact: true,
    title: 'Editable Grid Pages',
    component: EditableGridPage,
    hasParams: false,
    text: 'Editable Grid Pages',
    securityKey: ""
  },



  //Field Maintanance end

  // page Management

  {
    id: 'createPages',
    path: '/createPages',
    exact: true,
    title: 'Create Pages',
    component: CreatePages,
    hasParams: false,
    text: 'Create Pages',
    securityKey: ""
  },
  {
    id: 'viewPages',
    path: '/viewPages',
    exact: true,
    title: 'View Pages',
    component: ViewPages,
    hasParams: false,
    text: 'View Pages',
    securityKey: ""
  },
  {
    id: 'ckEditorTest',
    path: '/ckEditorTest',
    exact: true,
    title: 'CK Classic Editor Test',
    component: CKEditorForm,
    hasParams: false,
    text: 'CK Classic Editor Test',
    securityKey: ""
  },

  //Financials
  {
    id: 'xeroBatchExtraction',
    path: '/xeroBatchExtraction',
    exact: true,
    title: 'Xero Batch Extraction',
    component: XeroBatchExtraction,
    hasParams: false,
    text: 'Xero Batch Extraction',
    securityKey: ""
  },
  {
    id: 'createEvent',
    path: '/createEvent',
    exact: true,
    title: 'Create Events',
    component: EventManagement,
    hasParams: false,
    text: 'Create Events',
    securityKey: ""
  },
  {
    id: 'viewEditEvent',
    path: '/viewEditEvent',
    exact: true,
    title: 'View / Edit Event',
    component: ViewEditEventManagement,
    hasParams: false,
    text: 'View Edit Event',
    securityKey: ""
  },
];
