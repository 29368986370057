import React from 'react'
import CreateIndividualForm from '../../../features/gmap/createIndividualMemberForm/CreateIndividualForm'

export const CreateIndividualMembersContext = React.createContext(null);
const CreateIndividualMembers = () => {
    return (
        <CreateIndividualForm />
    )
}

export default CreateIndividualMembers