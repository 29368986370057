import React, { useEffect, useRef, useState } from 'react';


import { editableMolGrid } from './config/editableMolGrid.data';
import { editableMolGridConfig } from './config/editableMolGridConfig.data';
import FinalMolGrid from '../../components/FinalMolGrid/FinalMolGrid';

const EditableGridPage = () => {

  const molGridRef = useRef();

  const [gridConfig, setGridConfig] = useState(editableMolGridConfig)
  const [dataSource, setDataSource] = useState(editableMolGrid);

  useEffect(() => {
    if (editableMolGrid) {
      setDataSource(editableMolGrid)
    }
  }, []);

  const handleEditClick = (data, rowIndex) => {

    let newGridData = [...dataSource]
    newGridData[rowIndex] = { ...dataSource[rowIndex], ...data };
    setDataSource(newGridData);
  }

  // useState(() => {
  //   let configuration = { ...editableMolGridConfig }
  //   configuration.handleRowDataUpdate = handleEditClick;
  //   setGridConfig(configuration);
  // }, [])


  const handleDeleteClick = () => {
    alert("DELETE")
  }

  const actionHandler = {
    EDIT: handleEditClick,
    DELETE: handleDeleteClick,
  }

  return (
    <div>
      <div className="page-title">
        <h3>Editable Mol Grid</h3>
      </div>
      <FinalMolGrid
        ref={molGridRef}
        dataSource={dataSource}
        configuration={gridConfig}
        onRowDataUpdate={handleEditClick}
        allowPagination={false}
        onActionChange={actionHandler}
      />
    </div>
  )
}

export default EditableGridPage