/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import FormCreator from "../../../../../components/Forms/FormCreator";
import { AccountInformationFormData } from './AccountInformation.Data'

const AccountInformation = forwardRef((props, ref) => {
    const AccountInformationFormRef = useRef();
    const [accountInformationForm, SetAccountInformationForm] = useState(
        AccountInformationFormData
    );

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const AccountInformationFormData =
                    AccountInformationFormRef.current.getFormData();
                return AccountInformationFormData;
            }
            return null;
        },
    }));

    return (
        <div className="row">
            <FormCreator
                ref={AccountInformationFormRef}
                {...accountInformationForm}
            />
        </div>
    );
});

export default AccountInformation;
