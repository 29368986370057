import { FormFieldTypes } from "../../../../../data/formFieldType";

export const publicationofResearchPaperData = {
    name: 'PublicationofResearchPaper',
    initialState: {
        titeofResearchPaper: '',
        nameofDistinguishedProfessionalPublicationorResearchJournal: '',
        dateofPublication: "",
        researchPaper: "",
        CpdPoints: "",
        remarks: "",
        processedBy: "",
        cpdPointsAwarded: "",
        notes: "",
    },
    formFields: [
        {
            id: "publicationInformation",
            lable: "",
            Field_Name: "publicationInformation",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "publicationInformation",
            fieldSetting: {
                formTitle: "Publication Information",
            },
            style: {
                containerCss: "col-lg-12 mb-4 text-left",
            },
        },
        {
            id: "titeofResearchPaper",
            lable: "Tite of Research Paper",
            Field_Name: "Tite of Research Paper",
            fieldType: FormFieldTypes.INPUT,
            dataField: "titeofResearchPaper",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        {
            id: "nameofDistinguishedProfessionalPublicationorResearchJournal",
            lable: "Name of distinguished professional publication or research journal",
            Field_Name: "Name of distinguished professional publication or research journal",
            fieldType: FormFieldTypes.INPUT,
            dataField: "nameofDistinguishedProfessionalPublicationorResearchJournal",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "dateofPublication",
            lable: "Date of Publication",
            Field_Name: "Date of Publication",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "dateofPublication",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        {
            id: "researchPaper",
            lable: 'Research Paper',
            subLable: '(please upload in PDF format max 2mb)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'researchPaper',
            fieldSetting: {
                // presentFileName: null,
                isDownloadable: true,
                acceptedFiles: '.pdf',
                maxFileSizeInKB: 2048,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        // {
        //     fieldType: FormFieldTypes.SEPARATOR,
        //     style: {
        //         containerCss: "separation-line pl-0 pr-0",
        //     },
        // },
        {
            id: "CpdPoints",
            lable: "CPD Points",
            Field_Name: "CPD Points",
            fieldType: FormFieldTypes.INPUT,
            dataField: "CpdPoints",
            fieldSetting: {
                placeholder: "",
                isDisable: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "remarks",
            lable: "Remarks",
            Field_Name: "Remarks",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "remarks",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                maxLength: 4000,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-12 col-12 input-margin-space",
            },
        },
        {
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line pl-0 pr-0",
            },
        },
        {
            id: "sgbcSection",
            lable: "",
            Field_Name: "sgbcSection",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "sgbcSection",
            fieldSetting: {
                formTitle: "SGBC Section",
            },
            style: {
                containerCss: "col-lg-12 mb-4 text-left",
            },
        },
        {
            id: "processedBy",
            lable: "Processed by",
            Field_Name: "Processed by",
            fieldType: FormFieldTypes.SELECT,
            dataField: "processedBy",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6  "
            }
        },
        {
            id: "cpdPointsAwarded",
            lable: "CPD Points Awarded",
            Field_Name: "CPD Points Awarded ",
            fieldType: FormFieldTypes.SELECT,
            dataField: "cpdPointsAwarded",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6"
            }
        },
        {
            id: "notes",
            lable: "Notes",
            Field_Name: "Notes",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "notes",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                maxLength: 4000,
            },
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3",
            },
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}