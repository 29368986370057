import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";

const commonApi = createApi({
    reducerPath: 'commonApi',
    baseQuery: defautBaseQuery,
    endpoints: (builder) => ({
        getAllDropDownsForMemberDirectory: builder.query({
            query: () => ({
                url: encryptQueryString('/GMAPAccount/GetAllDropDownsForGMAPMembers'),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getAllDropDowns: builder.query({
            query: () => ({
                url: encryptQueryString('/GMAPAccount/GetSignUpDropDownsForGMAPMembers'),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getPDF: builder.query({
            query: (details) => ({
                url: `/Common/getPDF?type=${details.type}&fileName=${details.fileName}`,
                method: 'GET',
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        storeBase64Image: builder.mutation({
            query: (details) => ({
                url: '/Common/StoreBase64Image',
                method: 'POST',
                body: details,
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        SaveGMAPDocuments: builder.mutation({
            query: (details) => ({
                url: '/GMAPAccount/SaveGMAPDocuments',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        uploadPDF: builder.mutation({
            query: (details) => ({
                url: '/Common/UploadPDF',
                method: 'POST',
                body: details,
                formData: true
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        gMapMemberSignUp: builder.mutation({
            query: (details) => ({
                url: '/GMAPAccount/GMAPMemberSignUp',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getExcelSheet: builder.query({
            query: (details) => ({
                url: `/Common/GetExcelSheet?type=${details.type}&fileName=${details.fileName}`,
                method: 'GET',
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useLazyGetAllDropDownsForMemberDirectoryQuery,
    useLazyGetAllDropDownsQuery,
    useStoreBase64ImageMutation,
    useSaveGMAPDocumentsMutation,
    useUploadPDFMutation,
    useLazyGetPDFQuery,
    useGMapMemberSignUpMutation,
    useLazyGetExcelSheetQuery
} = commonApi;

export default commonApi;