import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'
import { TextBoxComponentData } from './TextBoxComponent.Data';
import FormCreator from '../../../../../components/Forms/FormCreator';

const TextBoxComponent = forwardRef((props, ref) => {
    const textboxRef = useRef();
    const [textBoxForm, setTextBoxForm] = useState(TextBoxComponentData);

    // Expose method `getFormData` to parent components to retrieve form data from `FormCreator`
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const textBoxFormData = textboxRef.current.getFormData();
                return textBoxFormData;
            }
            return null;
        }
    }))

    const handleFormFieldChange = (fieldName, fieldValue) => {
        // switch (fieldName) {
        //     case "isRedirection":
        //         setRedirectionFlag(fieldValue === "yes");
        //         break;
        //     case "scheduleType":
        //         setScheduleType(fieldValue);
        //         break;
        //     default:
        //         return false;
        // }
    };

    return (
        <div className='container-fluid'>
            <div className='row'>
                <FormCreator ref={textboxRef} {...textBoxForm} onFormFieldValueChange={handleFormFieldChange} />
            </div>
        </div>
    )
})

export default TextBoxComponent