import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Buttons from '../../../../components/ui/button/Buttons'
import { useGetSubmittedOnPdfListByIdMutation } from '../../../../app/services/GMAP/gmapApplicationTaskListApi'
import { useLazyGetPDFQuery } from '../../../../app/services/common/commonApi'
import { decryptAESID } from '../../../../utils/Encryption'
import { saveAs } from 'file-saver'


const PendingApplications = (props) => {
    const { id, taskListId } = useParams();
    const [getPDF,
        { isSuccess: isSuccessGetPDF,
            isLoading: isLoadingGetPDF,
            data: getPDFData }
    ] = useLazyGetPDFQuery();

    const [
        GetSubmittedOnPdfListById,
        {
            data: GetSubmittedOnPdfListByIdData,
            isSuccess: GetSubmittedOnPdfListByIdisSuccess,
            isLoading: GetSubmittedOnPdfListByIdisLoading,
        },
    ] = useGetSubmittedOnPdfListByIdMutation();

    useEffect(() => {
        if (GetSubmittedOnPdfListByIdData && GetSubmittedOnPdfListByIdisSuccess && !GetSubmittedOnPdfListByIdisLoading) {
        }
    }, [GetSubmittedOnPdfListByIdData, GetSubmittedOnPdfListByIdisSuccess, GetSubmittedOnPdfListByIdisLoading])

    useEffect(() => {
        if (id) {
            GetSubmittedOnPdfListById(decryptAESID(id));
        }
    }, [id]);


    const handleDownloadPDF = async (obj) => {
        let request = {
            type: "FullFilePath",
            fileName: obj?.submittedOnPdfPath
        }
        const response = await getPDF(request)
        if (response && response.data) {
            const { fileData } = response.data;
            //const blob = new Blob([fileData], { type: 'application/pdf' });
            let tempfileName = "GMP_Application_Submitted_On: " + new Date(obj?.submittedOn).toLocaleDateString()
            saveAs(fileData, tempfileName);
        }
    }

    return (
        <div className="profession-qualification-btn">
            <div className='mb-3'>
                {GetSubmittedOnPdfListByIdData?.map((obj, index) => {
                    return <Buttons
                        buttonText={"Submitted On: " + new Date(obj?.submittedOn).toLocaleDateString()}
                        buttonTypeClassName="btn-link bg-light"
                        onClick={() => handleDownloadPDF(obj)}
                        isDisable={props.type?.subscriptionStatusId === 4}
                    />
                }
                )}
                {/* <GMAGenratePDF
                  type={memberData?.gmapApplicationTypeId}
                  buttonText={"Submitted On: " + new Date(memberData.submissionDate).toLocaleDateString()}
                  subscriptionStatusId={memberData?.subscriptionStatusId}
                /> */}
            </div>
        </div>

    )
}

export default PendingApplications