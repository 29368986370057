import React from 'react';
import './eventRenderTabs.css'; // Import custom CSS file
import Buttons from '../button/Buttons';

const EventTabRenders = ({ activeTab, tabs, handleNextTab, handleBack, showBackButtonIndex, handleRedirectTabs }) => {
    const handleNext = (id, tabName) => {
        handleNextTab(id, tabName);
    }
    const handleBackTab = () => {
        let tabNumber = activeTab - 1;
        handleBack(tabNumber);
    }

    return (
        <>
            {tabs && tabs.length > 0 && (
                <div className='container-fluid'>
                    <div className="row">
                        <div className="col-lg-12  m-0 p-0">
                            <div className="tab-section">
                                <div className="tab-navigation d-flex justify-content-between mb-3">
                                    {tabs.map((tab, index) => (
                                        <div
                                            key={tab.id}
                                            className={`tab-item ${activeTab === index ? 'active' : ''} d-flex align-items-center`}
                                            onClick={() => handleRedirectTabs(index)}
                                        >
                                            <div className={`tab-circle ${activeTab === index ? 'active' : ''} `}>{index + 1}</div>
                                            <div className="tab-label ms-2">{tab.label}</div>
                                        </div>
                                    ))}
                                </div>
                                <div className="tab-content">
                                    {tabs.map((tab, index) => (
                                        <React.Fragment key={tab.id}>
                                            <div className={`tab-pane ${activeTab === index ? 'active' : ''}`}>
                                                <div className="row tab-body-section">
                                                    {tab.component} {/* Assuming tab.component holds the content of the tab */}
                                                </div>
                                                <div className='row'>
                                                    <div className={`col-lg-12 d-flex justify-content-${activeTab > showBackButtonIndex ? 'between' : 'end'} mt-4`}>
                                                        {(activeTab === index && activeTab < tabs.length - 1) && (
                                                            <>
                                                                {activeTab > showBackButtonIndex && (
                                                                    <Buttons
                                                                        buttonText="Back"
                                                                        buttonTypeClassName="black-btn"
                                                                        onClick={handleBackTab}
                                                                    />
                                                                )}

                                                                <div className='d-flex'>
                                                                    <Buttons
                                                                        buttonText="Save as Draft"
                                                                        buttonTypeClassName="outline-btn"
                                                                    // onClick={() => handleNext(tab.saveInto)}
                                                                    />
                                                                    <Buttons
                                                                        buttonText="Next"
                                                                        buttonTypeClassName="success-btn mx-4"
                                                                    // onClick={() => handleNext(tab.saveInto)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                        {(activeTab === tabs.length - 1 && index === tabs.length - 1) && (
                                                            <div className={`d-flex justify-content-center mt-4`}>
                                                                <Buttons
                                                                    buttonText="Submit"
                                                                    buttonTypeClassName="success-btn mx-4"
                                                                // onClick={() => handleNext(tab.saveInto)}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default EventTabRenders;
