// dropdownUtils.js

import { addEditMemberModelData } from "../../../../membership/component/AddEditMemberModel.Data";
import { memberDirectoryData } from "../../../../membership/component/MemberDirectoryForm.Data";
import { academicQualificationData } from "../AcademicQualification/AcademicQualification.Data";
import { AccountInformationFormData } from "../AccountInformation/AccountInformation.Data";
import { CurrentEmpData, careerHistoryData } from "../CareerHistory/CareerHistory.data";
import { certificateCourseData } from "../CertificateCourse/CertificateCourse.Data";
import { gmapMembershipData } from "../GmapMembership/GmapMembership.Data";
import { greenMarkProjectData } from "../GreenMarkProject/GreenMarkProject.Data";
import { personalParticularsData } from "../PersonalParticulars/PersonalParticulars.Data";

export const setAllDropDownField = (apiResponse, type) => {

    const filterDropDownList = (dropdownName) => {
        return apiResponse.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name
                });
            }
            return acc;
        }, []);
    }

    const signupDropDownList = {
        "accountType": filterDropDownList("Application Type")
    };

    const memberDropdownList = {
        "applicationTypeID": filterDropDownList("Application Type"),
        "salutationsID": filterDropDownList("Title"),
        "nationalityID": filterDropDownList("Nationality"),
        "countryOfResID": filterDropDownList("Nationality"),
        "competenciesID": filterDropDownList("CompetenciesType"),
        "otherProfessionQualificationID": filterDropDownList("Other Professional Qualification"),
        "highestQualificationID": filterDropDownList("Highest Relevant Qualification"),
        "graduateYearID": filterDropDownList("Graduate Year"),
        "gmapProfessionTypeId": filterDropDownList("Profession"),
        "gmaPathwayTypeID": filterDropDownList("GMAPathways"),
        "gmapCertificateTypeId": filterDropDownList("GMAP Certificate Type"),
        "gmaapCertificateTypeId": filterDropDownList("GMAAP Certificate Type"),
        "gmapCertificateTypeP1Id": filterDropDownList("GMA Certificate Type p1"),
        "gmapCertificateTypeP2Id": filterDropDownList("GMA Certificate Type p2"),
        "gmapAwardRatingId": filterDropDownList("GMAP Award Rating"),
        "gmapGreenMarkCategoryId": filterDropDownList("GMAP Green Mark Category"),
        "gmapPathwayTypeID": filterDropDownList("GMAPPathways"),
        "gmaapPathwayTypeID": filterDropDownList("GMAAPPathways"),
        "gmaapAwardRatingP1Id": filterDropDownList("GMAAP Award Rating P1"),
        "gmaapAwardRatingP2Id": filterDropDownList("GMAAP Award Rating P2"),
        "gmaapAwardRatingP3Id": filterDropDownList("GMAAP Award Rating P3"),
        "gmapGreenMarkCategoryP1Id": filterDropDownList("GMAP Green Mark Category P1"),
        "gmapGreenMarkCategoryP2Id": filterDropDownList("GMAP Green Mark Category P2"),
        "gmaapGreenMarkCategoryP1Id": filterDropDownList("GMAAP Green Mark Category P1"),
        "gmaapGreenMarkCategoryP2Id": filterDropDownList("GMAAP Green Mark Category P2"),
        "gmaapGreenMarkCategoryP3Id": filterDropDownList("GMAAP Green Mark Category P3"),
        "status": filterDropDownList("SubscriptionStatus"),
        "areaofCompetencies": filterDropDownList('CompetenciesType'),
        "typeofProfessionalQualification": filterDropDownList('Other Professional Qualification'),
        "accountstatus": filterDropDownList('Status'),
        "gmapStatus": filterDropDownList('SubscriptionStatus'),
        "billingCategory": filterDropDownList('BillingCategory'),
        "GMAPProfessionTypeId": filterDropDownList("Profession"),
        "IHLCertificateTypeId": filterDropDownList("IHL Certificate Type"),
        // Add more dropdowns as needed
    };

    const signUpFormFields = [
        ...personalParticularsData.formFields,
        ...academicQualificationData.formFields,
        ...careerHistoryData.formFields,
        ...certificateCourseData.formFields,
        ...greenMarkProjectData.formFields,
        ...gmapMembershipData.formFields,
        ...AccountInformationFormData.formFields,
        ...addEditMemberModelData.formFields,
        ...memberDirectoryData.formFields,

    ];
    const allDropDownMemberFromFields = [
        ...personalParticularsData.formFields,
        ...academicQualificationData.formFields,
        ...careerHistoryData.formFields,
        ...certificateCourseData.formFields,
        ...greenMarkProjectData.formFields,
        ...gmapMembershipData.formFields,
        ...AccountInformationFormData.formFields,
        ...addEditMemberModelData.formFields,
        ...memberDirectoryData.formFields,
        ...CurrentEmpData.formFields
    ];

    const dropdownLists = type === "signupDropdown" ? signupDropDownList : memberDropdownList
    if (type === "signupDropdown") {
        signUpFormFields.forEach(field => {
            const dropdownList = dropdownLists[field.id];
            if (dropdownList) {
                field.fieldSetting.options = dropdownList;
            }
        });
    } else {
        allDropDownMemberFromFields.forEach(field => {
            const dropdownList = dropdownLists[field.id];
            if (dropdownList) {
                field.fieldSetting.options = dropdownList;
            }
        });
    }
};
