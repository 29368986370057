import { FormFieldTypes } from "../../../../../data/formFieldType";

export const gMapQualificationData = {
    name: "GMapQualification",
    initialState: {
        applicationTypeID: null,
    },
    formFields: [
        {
            id: "applicationTypeID",
            lable: "Application Type",
            Field_Name: "Application Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "applicationTypeID",
            fieldSetting: {
                placeholder: "Please Select Application Type",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}