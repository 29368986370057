import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { careerHistoryData } from '../CareerHistory.data';
import FormCreator from '../../../../../../components/Forms/FormCreator';
import { CreateIndividualMembersContext } from '../../../../../../pages/gmap/viewIndividualMembers/createIndividualMembers';

const CareerHistoryForm = forwardRef((props, ref) => {
    const careerHistoryRef = useRef();
    const [isUnemployed, setIsUnemployed] = useState(false);
    const [isCurrent, setIsCurrent] = useState(false);
    const [formData, setFormData] = useState(careerHistoryData);
    const { newMemberData, setContextData } = useContext(CreateIndividualMembersContext);
    const resetForm = () => {
        let form = { ...careerHistoryData }
        form.initialState = { ...props.initData }
        setFormData(form);
        setIsUnemployed(false);
        setIsCurrent(false);
    }

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            let careerHistoryInfo = careerHistoryRef.current.getFormData();

            if (ref?.current && careerHistoryInfo) {
                resetForm();
                return careerHistoryInfo;
            }
            // return null;
        },
    }));

    const updateContainerCssForPeriodTo = (formData, newCssValueForPeriodTo, newCssValueForIsCurrent, newValidationValueForPeriodTo) => {
        // Find the index of the periodTo field in formFields array
        const companyNameIndex = formData.formFields.findIndex(field => field.id === "companyName");
        const designationIndex = formData.formFields.findIndex(field => field.id === "designation");
        const periodFromIndex = formData.formFields.findIndex(field => field.id === "periodFrom");
        const periodToIndex = formData.formFields.findIndex(field => field.id === "periodTo");
        const isCurrentToIndex = formData.formFields.findIndex(field => field.id === "isCurrent");
        if (periodToIndex !== -1) {
            // Create a copy of formData to avoid mutating state directly
            const updatedFormData = { ...formData };

            // Update the containerCss value for periodTo field
            updatedFormData.formFields[periodToIndex].style.containerCss = newCssValueForPeriodTo;
            updatedFormData.formFields[isCurrentToIndex].style.containerCss = newCssValueForIsCurrent;

            updatedFormData.formFields[companyNameIndex].validation = newValidationValueForPeriodTo;
            updatedFormData.formFields[designationIndex].validation = newValidationValueForPeriodTo;
            updatedFormData.formFields[periodFromIndex].validation = newValidationValueForPeriodTo;
            updatedFormData.formFields[periodToIndex].validation = isCurrent ? [] : newValidationValueForPeriodTo;
            updatedFormData.formFields[periodToIndex].fieldSetting.isDisable = isCurrent ? true : false;

            setFormData(updatedFormData);
        } else {
            return careerHistoryData;
        }
    };
    useEffect(() => {
        resetForm();
        if (props.isEdit && props.initData) {
            // alert("hello edit calling")
        }
    }, [props.isEdit, props.initData]);
    useEffect(() => {
        // Define CSS values based on the state of isUnemployed
        const newCssValueForPeriodTo = isUnemployed ? "col-lg-6 mb-2" : "col-md-6 mb-2";
        const newCssValueForIsCurrent = isUnemployed ? "col-lg-4 d-none mb-2 mt-4" : "col-lg-4 mb-2 mt-4 custom-checkbox";
        const newValidationValueForPeriodTo = isUnemployed ? [] : [{ type: "require" }];
        updateContainerCssForPeriodTo(formData, newCssValueForPeriodTo, newCssValueForIsCurrent, newValidationValueForPeriodTo);
    }, [isUnemployed, isCurrent]); // This effect runs when isUnemployed or formData changes

    const handleStateChange = (dataField) => {
        setIsUnemployed(dataField.isUnemployed);
        setIsCurrent(dataField.isCurrent);
        setContextData({ ...newMemberData, isUnemployed: dataField.isUnemployed });
    };
    return (<FormCreator ref={careerHistoryRef} {...formData} onFormDataChange={handleStateChange} handleTableEdit={props.handleTableEdit} handleTableDelete={props.handleTableDelete} />)
})

export default CareerHistoryForm;