import { FormFieldTypes } from "../../../../data/formFieldType";

export const createPagesData = {
    name: 'CreatePages',
    initialState: {
        projectName: '',
        greenMarkProjectNumber: '',
        dateofLetterofAward: '',
        // isGenderMale: true,
        awardRating: "",
        gmapGreenMarkCategoryId: "",
        developerBuildingOwner: "",
        describeYourRoleintheProject: '',
        uploadEndorsementAttached: "",
        uploadGreenMarkCategory: "",
        CpdPoints: "",
        remarks: "",
        processedBy: "",
        cpdPointsAwarded: "",
    },
    formFields: [
        
        {
            id: "pageType",
            lable: "Page Type",
            Field_Name: "Independent Page",
            fieldType: FormFieldTypes.SELECT,
            dataField: "pageType",
            fieldSetting: {
                placeholder: "Independent Page                ",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        {
            id: "metaTitle",
            lable: "Meta Title",
            Field_Name: "Green Mark Project Number",
            fieldType: FormFieldTypes.INPUT,
            dataField: "metaTitle",
            fieldSetting: {
                placeholder: "Meta Title",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "metaKeywords",
            lable: "Meta Keywords",
            Field_Name: "Meta Keywords",
            fieldType: FormFieldTypes.INPUT,
            dataField: "metaKeywords",
            fieldSetting: {
                placeholder: "Meta Keywords",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "metaDescription",
            lable: "Meta Description",
            Field_Name: "Meta Description",
            fieldType: FormFieldTypes.INPUT,
            dataField: "metaDescription",
            fieldSetting: {
                placeholder: "Meta Description",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },      
        {
            id: "pageTitle",
            lable: "Page Title",
            Field_Name: "Page Title",
            fieldType: FormFieldTypes.INPUT,
            dataField: "pageTitle",
            fieldSetting: {
                placeholder: "Page Title",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "pageURL",
            lable: "Page URL",
            Field_Name: "Page URL",
            fieldType: FormFieldTypes.INPUT,
            dataField: "pageURL",
            fieldSetting: {
                placeholder: "Page URL",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },

        {
            id: "showChapters/CommitteeBanner",
            lable: "Show Chapters/Committee Banner",
            Field_Name: "ShowChapters/CommitteeBanner",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "showChapters/CommitteeBanner",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-12 mb-2 mt-4",
            },
        },
        {
            id: "formSettings",
            lable: "Form Settings",
            Field_Name: "formSettings",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "formSettings",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-12 mb-2",
            },
        },
        {
            id: "firstName",
            lable: "First Name",
            Field_Name: "First Name",
            fieldType: FormFieldTypes.SELECT,
            dataField: "firstName",
            fieldSetting: {
                placeholder: "Select",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-5 col-xl-5 col-lg-5"
            }
        },
        {
            id: "lastName",
            lable: "Last Name",
            Field_Name: "Last Name",
            fieldType: FormFieldTypes.SELECT,
            dataField: "lastName",
            fieldSetting: {
                placeholder: "Select",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-5 col-xl-5 col-lg-5"
            }
        },
        {
            id: "email",
            lable: "Email",
            Field_Name: "Email",
            fieldType: FormFieldTypes.SELECT,
            dataField: "email",
            fieldSetting: {
                placeholder: "Select",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-5 col-xl-5 col-lg-5"
            }
        },
        {
            id: "contactNumber",
            lable: "Contact Number",
            Field_Name: "Contact Number",
            fieldType: FormFieldTypes.SELECT,
            dataField: "contactNumber",
            fieldSetting: {
                placeholder: "Select",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-5 col-xl-5 col-lg-5"
            }
        },
        {
            id: "companyName",
            lable: "Company Name",
            Field_Name: "Company Name",
            fieldType: FormFieldTypes.SELECT,
            dataField: "companyName",
            fieldSetting: {
                placeholder: "Select",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-5 col-xl-5 col-lg-5"
            }
        },
        {
            id: "companyUEN",
            lable: "Company UEN",
            Field_Name: "Company UEN",
            fieldType: FormFieldTypes.SELECT,
            dataField: "companyUEN",
            fieldSetting: {
                placeholder: "Select",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-5 col-xl-5 col-lg-5"
            }
        },
        {
            id: "designation",
            lable: "Designation",
            Field_Name: "Designation",
            fieldType: FormFieldTypes.SELECT,
            dataField: "designation",
            fieldSetting: {
                placeholder: "Select",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-5 col-xl-5 col-lg-5"
            }
        },
        {
            id: "membershipStatus",
            lable: "Membership Status",
            Field_Name: "Membership Status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "membershipStatus",
            fieldSetting: {
                placeholder: "Select",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-5 col-xl-5 col-lg-5"
            }
        },
        {
            id: "uploadFeatureImage",
            lable: 'PDF Upload',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'uploadFeatureImage',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                isDownloadable: false,
                acceptedFiles: '.jpg, .png',
                isDisable: false,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-2xl-6 col-xl-6 col-lg-6 col-md-6 col-12 mb-3 file-uploader-img pdf-upload"
            }
        },
        // {
        //     fieldType: FormFieldTypes.SEPARATOR,
        //     style: {
        //         containerCss: "separation-line pl-0 pr-0",
        //     },
        // },
       
      
        // {
        //     fieldType: FormFieldTypes.SEPARATOR,
        //     style: {
        //         containerCss: "separation-line pl-0 pr-0",
        //     },
        // },
        {
            id: "addComponents",
            lable: "Add Components",
            Field_Name: "projectInformation",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "addComponents",
            fieldSetting: {
                formTitle: "Add Components",
            },
            style: {
                containerCss: "col-lg-12 mb-4 text-left form-title",
            },
        },

        {
            id: "selectComponentType",
            lable: "Select Component Type",
            Field_Name: "Select Component Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "selectComponentType",
            fieldSetting: {
                placeholder: "Select",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6  "
            }
        },
        

    ],
    formSetting: {
        isViewOnly: false
    }
}