import React, { forwardRef } from 'react'
import CardSection from '../../../../components/ui/card/CardSection'

const Questions = forwardRef((props, ref) => {
    return (
        <React.Fragment>
            <CardSection cardTitle="Form Questions (Interest to Participate)">
                <div className='row'>
                </div>
            </CardSection>
        </React.Fragment>
    )
})

export default Questions