import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { greenMarkProjectData } from '../GreenMarkProject.Data';
import { handleConditionBaseFields } from '../utils/ValidationBaseFields';
import FormCreator from '../../../../../../components/Forms/FormCreator';

const GreenMarkProjectForm = forwardRef((props, ref) => {
    const greenMarkProjectRef = useRef();
    const [formData, setFormData] = useState(greenMarkProjectData);
    const resetForm = () => {
        let form = { ...greenMarkProjectData }
        form.initialState = { ...props.initData }
        setFormData(form);
    }

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            const projectData = greenMarkProjectRef.current.getFormData();
            if (ref?.current && projectData) {
                resetForm();
                return projectData;
            }
            return null;
        }
    }))
    //** UseEffect */   
    useEffect(() => {
        resetForm();
        if (props.isEdit && props.initData) {
            // alert("hello edit calling")
        }
    }, [props.isEdit, props.initData]);

    const handleClear = () => {
        const updatedForm = { ...formData };
        updatedForm.initialState.greenMarkProjectLetterOfAward = null;
        updatedForm.initialState.clientLetterOfEndorsement = null;
        setFormData(updatedForm);
    }

    const handleDropdownChange = (data, dataField) => {
        if (dataField === "gmaapPathwayTypeID" || dataField === "gmapPathwayTypeID") {
            handleConditionBaseFields(data.value, dataField)
        }
    }
    const handleDownload = (data, dataField) => {
        if (props.onDownload) {
            props.onDownload(data, dataField);
        }
    }

    const formActionHandler = {
        CLEAR: handleClear,
        DOWNLOAD: handleDownload,
        DDL_CHANGED: handleDropdownChange
    }

    const handleFormFieldChange = (dataField, value) => {
        if (props.onFormFieldValueChange) {
            props.onFormFieldValueChange(dataField, value)
        }
    };

    return (
        <React.Fragment>
            <FormCreator ref={greenMarkProjectRef} {...formData} onActionChange={formActionHandler} onFormFieldValueChange={handleFormFieldChange} handleTableEdit={props.handleTableEdit} handleTableDelete={props.handleTableDelete} />
        </React.Fragment>
    )
})

export default GreenMarkProjectForm