import { FormFieldTypes } from "../../../data/formFieldType";


export const viewResourcesData = {
    name: 'viewResourcesData',
    initialState: {
        salutationsID: '',
        fullName: '',
        primaryEmail: '',
        secondaryEmail: '',
        dateOfBirthDate: '',
        isGenderMale: true,
        mobilePhone: '',
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        postalCode: "",
        comments: '',
        nationalityID: '',
        countryOfResID: '',
        profilePhotoFile: "",
        linkedinAdd: '',
        competenciesID: '',
        otherProfessionQualificationID: '',
    },
    formFields: [

        {
            id: "searchByName",
            lable: "Search by Name",
            Field_Name: "Search by Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "searchByName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-12 col-sm-12 col-md-12 col-11 mb-3",
            },
        },


        {
            id: "type",
            lable: "Type",
            Field_Name: "Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "TypeId",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12"
            }
        },

        {
            id: "Status",
            lable: "Status",
            Field_Name: "Status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "Status",
            fieldSetting: {
                placeholder: " ",
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12"
            }
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}