import { FormFieldTypes } from "../../../../../data/formFieldType";

export const AvailabilityAndScheduleFormData = {
    name: "AvailabilityAndScheduleFormData",
    initialState: {
        isRedirection: "no",
        redirectionUrl: '',
        scheduleType: 'physical'
    },
    formFields: [
        {
            id: "isRedirection",
            label: "Redirection",
            Field_Name: "Redirection",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "isRedirection",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "Yes", value: "yes", isDisable: false, optionClass: "", },
                    { label: "No", value: "no", isDisable: false, optionClass: "ml-3", },
                ]
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-2 mb-2",
            },
        },
        {
            id: "redirectionUrl",
            lable: "Redirection Url",
            Field_Name: "Redirection Url",
            fieldType: FormFieldTypes.INPUT,
            dataField: "redirectionUrl",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [],
            style: {
                containerCss: "col-lg-4 mb-2 d-none",
            },
        },
        {
            id: "scheduleType",
            label: "",
            Field_Name: "Schedule Type",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "scheduleType",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "Physical", value: "physical", isDisable: false, optionClass: "", },
                    { label: "Zoom", value: "zoom", isDisable: false, optionClass: "ml-3", },
                    { label: "Hybrid", value: "hybrid", isDisable: false, optionClass: "ml-3", },
                ]
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-12 mb-2",
            },
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}