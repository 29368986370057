
import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from '../../../utils/API/fetchBaseQuery';
import { transformSucessResponse, transformErrorResponse } from '../../../utils/API/responseMiddleware'
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";
import { encryptAES } from "../../../services/CryptoService";


const editApplicationTaskListApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'EditApplicationTaskListApi',
    endpoints: (builder) => ({
        getGMAPMemberDetailByMemberId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/GMAPAccount/GetGMAPMemberDetailByMemberId?id=${parseInt(id)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getIndividualDetailByMemberId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/GMAPAccount/GetEditGMAPMemberDetailByMemberId?id=${parseInt(id)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getGMAPAccountInformationByMemberId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/GMAPAccount/GetGMAPAccountInformationByMemberId?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getGMAPMembershipByMemberId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/GMAPMembership/GetGMAPMembershipSubscriptionByMemberId?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        setApplicationStatus: builder.mutation({
            query: (details) => ({
                url: '/GMAPMembership/SetGMAPCertificateQualificationDate',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        UpdateGMAPMemberDetails: builder.mutation({
            query: (details) => ({
                url: '/GMAPAccount/UpdateGMAPMemberDetails',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        UpdateGMAPMembershipDetails: builder.mutation({
            query: (details) => ({
                url: '/GMAPMembership/AddEditGMAPMembershipSubscription',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        RejectGMAPMemberApplication: builder.mutation({
            query: (details) => ({
                url: '/GMAPMembership/RejectGMAPMemberApplication',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        SaveGMAPMemberClarificationDetails: builder.mutation({
            query: (details) => ({
                url: '/GMAPMembership/SaveGMAPMemberClarificationDetails',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        LockUnockMemberInformation: builder.mutation({
            query: (details) => ({
                url: '/GMAPMembership/LockUnockMemberInformation',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        appealGMAPMemberApplication: builder.mutation({
            query: (details) => ({
                url: '/GMAPMembership/AppealGMAPMemberApplication',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        GetLockUnockMemberInformationById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/GMAPMembership/GetLockUnockMemberInformationById?id=${parseInt(id)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

export const {
    useLazyGetGMAPMemberDetailByMemberIdQuery,
    useLazyGetIndividualDetailByMemberIdQuery,
    useLazyGetGMAPAccountInformationByMemberIdQuery,
    useLazyGetGMAPMembershipByMemberIdQuery,
    useSetApplicationStatusMutation,
    useUpdateGMAPMemberDetailsMutation,
    useUpdateGMAPMembershipDetailsMutation,
    useRejectGMAPMemberApplicationMutation,
    useSaveGMAPMemberClarificationDetailsMutation,
    useLockUnockMemberInformationMutation,
    useAppealGMAPMemberApplicationMutation,
    useLazyGetLockUnockMemberInformationByIdQuery,
} = editApplicationTaskListApi;

export default editApplicationTaskListApi;