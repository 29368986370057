import { FormFieldTypes } from "../../../../data/formFieldType";
import { GridColumnType } from "../../../../data/gridColumnType";

export const CpdRenewalCycleConfig = {
    columns: [

        {
            name: "Name",
            fieldName: "name",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "GMP Number",
            fieldName: "gmapNumber",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Qualification Type",
            fieldName: "gmapApplicationType",
            colType: GridColumnType.Text,

        },
        {
            name: "Qualification End Date",
            fieldName: "qualificationEndDate",
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD-MMMM-YYYY',
            }
        },
        {
            name: "Renewal Cycle",
            fieldName: "renewalCycle",
            colType: GridColumnType.Text,
        },
        {
            name: "Verified CPD",
            fieldName: "verifiedCPD",
            colType: GridColumnType.Text,
        },
        {
            name: "Pending CPD",
            fieldName: "pendingCPD",
            colType: GridColumnType.Text,
        },
        {
            name: "Renewal Application And Payment Status",
            fieldName: "renewalApplicatioAndPaymentStatus",
            colType: GridColumnType.Text,
        },


        // {
        //     name: "Action",
        //     colType: GridColumnType.ACTION,
        //     defaultAction: {
        //         allowEdit: false,
        //         allowDelete: false
        //     },
        //     customAction: [
        //         {
        //             name: "UPDATE",
        //             onViewAction: (data) => {
        //                 alert("called permissions");
        //             },
        //         },
        //     ],
        // },
    ],
};


export const CpdRenewalForm = {
    name: "AdditionalInfo",
    initialState: {
        searchText: '',
        appilcationType: '',
        renewalCycle: '',
        renewalApplicationAndPaymentStatus: '',
        qualificationEndDate: ""
    },
    formFields: [
        {
            id: "searchText",
            lable: "Name/GMP Number",
            Field_Name: "Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "searchText",
            fieldSetting: {
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-4 col-xl-4 col-md-4 col-sm-12 col-12 mb-3",
            },
        },
        {
            id: "appilcationType",
            lable: "Qualification Type",
            Field_Name: "Application Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "appilcationType",
            fieldSetting: {
                options: []
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-4 col-xl-4 col-md-4 col-sm-12 col-12 mb-3",
            },
        },
        {
            id: "renewalCycle",
            lable: "Renewal Cycle",
            Field_Name: "Renewal Cycle",
            fieldType: FormFieldTypes.SELECT,
            dataField: "renewalCycle",
            fieldSetting: {
                options: [
                    { label: "30-June-2024", value: "30-June-2024" },
                    { label: "30-June-2025", value: "30-June-2025" },
                    { label: "30-June-2026", value: "30-June-2026" },
                    { label: "30-June-2027", value: "30-June-2027" }
                ]
            },
            style: {
                containerCss: "col-lg-4 col-xl-4 col-md-4 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "renewalApplicationAndPaymentStatus",
            lable: "Renewal Application And Payment Status",
            Field_Name: "Renewal Application And Payment Status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "renewalApplicationAndPaymentStatus",
            fieldSetting: {
                options: [
                    { label: "Pending", value: "Pending" },
                    { label: "Updated", value: "Updated" },
                ]
            },
            style: {
                containerCss: "col-lg-5 col-xl-5 col-md-5 col-sm-12 col-12"
            }
        },
        {
            id: "qualificationEndDate",
            lable: "Qualification End Date",
            Field_Name: "Qualification End Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "qualificationEndDate",
            fieldSetting: {
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-5 col-xl-5 col-md-5 col-sm-12 col-12",
            },
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}