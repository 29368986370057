import { FormFieldTypes } from "../../../../../data/formFieldType";

export const AdditionalGuestFormData = {
    name: "AdditionalGuestFormData",
    initialState: {
        maxGuest: 0,
        isQualityAllow: false
    },
    formFields: [
        {
            id: "maxGuest",
            lable: "Max guest",
            Field_Name: "Max guest",
            fieldType: FormFieldTypes.SELECT,
            dataField: "maxGuest",
            fieldSetting: {
                placeholder: "Select Max guest",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-4 mb-2",
            }
        },
        {
            id: "isQualityAllow",
            lable: "Quantity Allow",
            Field_Name: "Quantity Allow",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "isQualityAllow",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            style: {
                containerCss: "col-md-4 mb-2",
            },
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}