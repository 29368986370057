import React, { useRef, useState, useImperativeHandle, forwardRef, useContext, useEffect } from "react";
import { personalParticularsData } from "./PersonalParticulars.Data";
import FormCreator from "../../../../../components/Forms/FormCreator";
import CenterModel from "../../../../../components/ui/centerModel/CenterModel";
import { APIUrl } from "../../../../../utils/API/fetchBaseQuery";
import { useSaveGMAPDocumentsMutation, useStoreBase64ImageMutation } from "../../../../../app/services/common/commonApi";
import { decryptAESID } from "../../../../../utils/Encryption";
import { useParams } from "react-router-dom";
import ToastService from "../../../../../services/toastService/ToastService";
import { EditDiscretionaryApplicationDataContext } from "../../EditDiscretionaryRenewalTaskListApplication";

const PersonalParticulars = forwardRef((props, ref) => {
    const personalParticularsRef = useRef();
    const [PersonalParticularsForm, SetPersonalParticulars] = useState(personalParticularsData);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [profilePhoto, setProfilePhoto] = useState(null)
    const { newMemberData, setContextData } = useContext(EditDiscretionaryApplicationDataContext)
    const { id } = useParams();
    const editID = id ? decryptAESID(id, false) : 0
    const [saveMainImage,
        { isSuccess: isSuccessImage,
            isLoading: isLoadingImage,
            data: profileImageUpload }
    ] = useStoreBase64ImageMutation();

    const [updateFileName,
        { isSuccess: isSuccessFile,
            isLoading: isLoadingFile,
            data: updateFileUpload }
    ] = useSaveGMAPDocumentsMutation();

    useEffect(() => {
        if (isSuccessFile && updateFileUpload) {
            ToastService.success("Profile Photo upload successfuly.")
        }
    }, [isSuccessFile, updateFileUpload]);

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const personalParticularData = personalParticularsRef.current.getFormData();
                return personalParticularData;
            }
            return null;
        }
    }))

    const handleClear = () => {
        const updatedForm = { ...PersonalParticularsForm };
        updatedForm.initialState.profilePhotoFile = null;
        SetPersonalParticulars(updatedForm); // Update the component state with the cleared form
    }

    const handleDownload = (data) => {
        if (props.onDownload) {
            props.onDownload(data);
        }
    };

    const handleViewImage = () => {
        setIsModalOpen(true);
    }

    const formActionHandler = {
        CLEAR: handleClear,
        DOWNLOAD: handleDownload,
        VIEW_IMAGE: handleViewImage,

    };
    const handleProfileImageUpload = async (data) => {
        const { base64Data, fileName } = data
        let saveFileRequest = {
            fileName: fileName,
            fileStr: base64Data,
            storagePath: "ProfessionalProfilePhoto",
        }

        const response = await saveMainImage(saveFileRequest)
        let updateFileRequest = {
            GMAPMemberId: editID,
            ColumnName: "ProfessionalProfilPhoto",
            FileName: response.data?.fileName,
        }

        setProfilePhoto(response.data.fileName);
        setContextData({ ...newMemberData, profilePhotoFile: response.data?.fileName, profilePhotoFileName: response.data.uploadedFileName });
        await updateFileName(updateFileRequest);
    }
    const openModal = () => {
        setIsModalOpen(!isModalOpen);
    }
    const handleFormFieldChange = (dataField, value) => {
        if (dataField === "profilePhotoFile" && value) {
            handleProfileImageUpload(value)
        }
    }
    return (
        <React.Fragment>
            <div className="common-cpd-submission-description-parent">
                <div className="common-project-info-main">
                    <div className="row gx-5">
                        <FormCreator
                            ref={personalParticularsRef}
                            {...personalParticularsData}
                            onActionChange={formActionHandler}
                            onFormFieldValueChange={handleFormFieldChange}
                        />
                    </div>
                </div>
            </div>
            {isModalOpen &&
                <CenterModel
                    modelTitle={'View Image'}
                    show={"test"}
                    handleClose={openModal}
                    onUpload={openModal}
                >
                    <img
                        src={`${APIUrl}/Common/getImage?type=professionalprofilephoto&fileName=${newMemberData?.profilePhotoFile ? newMemberData?.profilePhotoFile : PersonalParticularsForm?.initialState?.profilePhotoFile}`}
                        alt="Uploaded Image"
                    />
                </CenterModel>
            }
        </React.Fragment>
    );
})

export default PersonalParticulars;
