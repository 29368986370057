import React, { useEffect, useRef } from 'react'
import { CurrentCpdCycleTable } from './component/CurrentCpdCycleTable';
import PastcpdCycleTable from './component/PastcpdCycleTable';
import CenterModel from '../../../components/ui/centerModel/CenterModel';
import { useState } from 'react';
import CpdActivityModel from './component/CpdActivityModel';
import RejectReason from './component/RejectReason';
import { useParams } from 'react-router-dom';
import { decryptAESID } from '../../../utils/Encryption';
import { useLazyGetCPDSubmissionByIdQuery, useLazyGetPastCPDMemberDetailsListByMemberIdQuery } from '../../../app/services/CPD/ApproveCPDSubmissionApi';
import DataLoader from '../../../components/ui/dataLoader/DataLoader';
import formatDate from '../../../lib/formatDate';
import SwalAlert from '../../../services/swalService/SwalService';
import { useLazyGetAllDropDownsForMemberDirectoryQuery } from '../../../app/services/common/commonApi';
import { bcaGreenMarkProjectData } from './AllActivityComponent/BcaGreenMarkProject/BcaGreenMarkProject.Data';
import { cpdprogrammesbyOverseasgreenbuildingCouncilsData } from './AllActivityComponent/CpdprogrammesbyOverseasgreenbuildingCouncils/CpdprogrammesbyOverseasgreenbuildingCouncils.Data';
import { industryTechnicalCommitteesData } from './AllActivityComponent/IndustryTechnicalCommittees/IndustryTechnicalCommittees.Data';
import { mastersDegreeDoctorProgrammesData } from './AllActivityComponent/MastersDegreeDoctorProgrammes/MastersDegreeDoctorProgrammes.Data';
import { publicationofResearchPaperData } from './AllActivityComponent/PublicationofResearchPaper/PublicationofResearchPaper.Data';
import { accreditedCpdEventsData } from './AllActivityComponent/SGBCAccreditedCpdEvents/SGBCAccreditedCpdEvents.Data';
import { eLearningData } from './AllActivityComponent/SgbcElearning/SgbcElearning.Data';
import { speakingTeachingEngagementData } from './AllActivityComponent/SpeakingTeachingEngagement/SpeakingTeachingEngagement.Data';

export const CurrentCPDCycleDataContext = React.createContext(null);

function CpdSubmissionForm() {

  const [cpdSubmissionData, setCpdSubmissionData] = useState(
    {
      cpdSubmissionId: null,
      rejectRequestData: null,
      approveIsLoading: false,
      uploadGreenMarkCategory: "",
      clientLetterOfEndorsement: "",
    }
  );

  const contextValue = {
    cpdSubmissionData,
    setCpdSubmissionData: (data) =>
      setCpdSubmissionData(data)
  }
  const { success, error } = SwalAlert()
  const [isCPDModal, setIsCPDModal] = useState(false);
  const [isModalOpen, setIsOpenModal] = useState(false)

  const [cpdMemberData, setCPDMemberData] = useState()

  const rejectReasonRef = useRef()

  const { id } = useParams()

  const [getCPDByMemberId, {
    isLoading: getCPDByMemberIdIsLoading,
    data: getCPDByMemberIdData,
    isSuccess: getCPDByMemberIdIsSuccess,
  }] = useLazyGetCPDSubmissionByIdQuery()


  const [getAllDropdownData, {
    isLoading: getDropdownDataIsLoading,
    data: getDropdownData,
    isSuccess: getAllDropdownDataIsSuccess,
  }] = useLazyGetAllDropDownsForMemberDirectoryQuery()

  useEffect(() => {
    if (!getDropdownDataIsLoading && getDropdownData && getAllDropdownDataIsSuccess) {
      setAllDropDownField()
    }
  }, [getDropdownDataIsLoading, getDropdownData, getAllDropdownDataIsSuccess])

  const filterDropDownList = (dropdownName) => {
    return getDropdownData.reduce((acc, item) => {
      if (item.dropdownName === dropdownName) {
        acc.push({
          value: item.id,
          label: item.name
        });
      }
      return acc;
    }, []);
  }

  const setAllDropDownField = () => {
    const dropdownLists = {
      "processedBy": filterDropDownList("CPD ReceivedBy"),
      "cpdPointsAwarded": filterDropDownList("CPD Points Awarded"),
      "awardRating": filterDropDownList("CPD Award Rating"),
      "gmapGreenMarkCategoryId": filterDropDownList("CPD Green Mark Category"),
      "eventOrg": filterDropDownList("Event Organiser Name"),
    };

    const formFields = [
      ...bcaGreenMarkProjectData.formFields,
      ...cpdprogrammesbyOverseasgreenbuildingCouncilsData.formFields,
      ...industryTechnicalCommitteesData.formFields,
      ...mastersDegreeDoctorProgrammesData.formFields,
      ...publicationofResearchPaperData.formFields,
      ...accreditedCpdEventsData.formFields,
      ...eLearningData.formFields,
      ...speakingTeachingEngagementData.formFields,
    ];

    formFields.forEach(field => {
      const dropdownList = dropdownLists[field.id];
      if (dropdownList) {
        field.fieldSetting.options = dropdownList;
      }
    });
  }

  const getMemberDetailById = () => {
    const gmapNumberId = decryptAESID(id);
    getCPDByMemberId(gmapNumberId);
  }
  useEffect(() => {
    if (id) {
      getMemberDetailById()
    }
  }, [id])

  useEffect(() => {
    getAllDropdownData()
  }, [])


  useEffect(() => {
    if (!getCPDByMemberIdIsLoading && getCPDByMemberIdData && getCPDByMemberIdIsSuccess) {
      let qualificationStartDate = formatDate(getCPDByMemberIdData.qualificationStartDate, 'MM/DD/YYYY');
      let qualificationEndDate = formatDate(getCPDByMemberIdData.qualificationEndDate, 'MM/DD/YYYY');
      setCPDMemberData({ ...getCPDByMemberIdData, qualificationStartDate: qualificationStartDate, qualificationEndDate: qualificationEndDate });
    }
  }, [getCPDByMemberIdIsLoading, getCPDByMemberIdData, getCPDByMemberIdIsSuccess,])

  const openCloseCPDModal = () => {
    setIsCPDModal(!isCPDModal);
  };

  const openCloseRejectModal = () => {
    setIsOpenModal(!isModalOpen)
    setIsCPDModal(false)
  };

  const rejectSuccess = () => {
    success("Reject Success fully.", 'Success')
    setCpdSubmissionData({ ...cpdSubmissionData, rejectRequestData: null })
  };

  const formActionHandler = {
    // CLEAR: handleClear,
    // DOWNLOAD: handleDownload,
    // REJECT: handleRejectbutton,
    // APPROVE: handleApproveButton,
    REJECT_SUCCESS: rejectSuccess,
  }

  return (
    <>
      <div>
        {cpdMemberData ? <div className='view-cpdpoints-data'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
              <div className='view-cpd-profile'>
                <h6>{cpdMemberData.fullName}</h6>
                <p>{cpdMemberData.applicationName}</p>
              </div>
              <div className='view-cpdpoints-descprition'>
                <div className='view-cpdpoint-child'>
                  <span>GMAP No:</span>
                  <span>{cpdMemberData.gmapNumber}</span>
                </div>
                <div className='view-cpdpoint-child'>
                  <span>Certification Validity: </span>
                  <span>{`${cpdMemberData.qualificationStartDate} - ${cpdMemberData.qualificationEndDate}`}</span>
                </div>
                <div className='view-cpdpoint-child'>
                  <span>Status: </span>
                  <span>{cpdMemberData.subscriptionStatusName}</span>
                </div>
                {/* <div className='viewaddpoint-button'>
                  <Buttons
                    buttonText="Add CPD Points"
                    buttonTypeClassName="success-btn"
                    onClick={openCloseCPDModal}
                  />
                </div> */}

              </div>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
              <div className='cpd-summary-table'>
                <div className='target-and-summary-table-main'>
                  <div className='target-and-summary-table-child'>
                    <span>Target CPD (What you need)</span>
                    <p className='text-danger-color'>{cpdMemberData.targetCPD}</p>
                  </div>
                  <div className='target-and-summary-table-child'>
                    <span>Total Verified CPD</span>
                    <p className='theme-color'>{cpdMemberData.totalVerifiedCPD}</p>
                  </div>
                </div>
              </div>
              <div className='cpd-summary-table'>
                <h6>CPD Summary Table</h6>
                <div className='target-and-summary-table-main'>
                  <div className='target-and-summary-table-child'>
                    <span>Expect Total CPD</span>
                    <p className='black-color'>{cpdMemberData.totalVerifiedCPD + cpdMemberData.pending}</p>
                  </div>
                  <div className='target-and-summary-table-child'>
                    <span>Total Verified</span>
                    <p className='black-color'>{cpdMemberData.totalVerifiedCPD}</p>
                  </div>
                  <ul className='mb-0'>
                    <li>
                      <div className='target-and-summary-table-child'>
                        <span>Carried Forward</span>
                        <p className='black-color'>{cpdMemberData.carriedForward}</p>
                      </div>
                    </li>
                    <li>
                      <div className='target-and-summary-table-child'>
                        <span>Current Cycle</span>
                        <p className='black-color'>{cpdMemberData.verified}</p>
                      </div>
                    </li>
                  </ul>
                  <div className='target-and-summary-table-child'>
                    <span>Pending</span>
                    <p className='black-color'>{cpdMemberData.pending}</p>
                  </div>
                  <div className='target-and-summary-table-child'>
                    <span>Invalid</span>
                    <p className='black-color'>{cpdMemberData.invalid}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
          : <DataLoader />
        }
      </div>
      <CurrentCPDCycleDataContext.Provider value={contextValue}>

        <div className='row'>
          <div className='current-cpd-cycle-main'>
            <div className='col-lg-12'>
              <h6>Current CPD Cycle</h6>
              <div className='table-responsive current-cpd-cycle-table'>
                <CurrentCpdCycleTable
                  // openModal={openCloseCPDModal}
                  getMemberDetailById={getMemberDetailById} />
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className='row'>
          <div className='current-cpd-cycle-main'>
            <div className='col-lg-12'>
              <h6>Past CPD Cycle</h6>
              <div className='table-responsive current-cpd-cycle-table'>
                <PastcpdCycleTable />
              </div>
            </div>
          </div>

        </div>

        <div className='add-edit-model-main'>
          {isCPDModal &&
            <CenterModel
              modelTitle={'CPD Activity: Publication of Research Paper'}
              show={"test"}
              handleClose={openCloseCPDModal}
              onUpload={openCloseCPDModal}
            >
              <CpdActivityModel
                isModalOpen={isModalOpen}
                openCloseRejectModal={openCloseRejectModal}
                openCloseCPDModal={openCloseCPDModal}
              />
            </CenterModel>
          }
        </div>

        <div className='rejection-reson-modal'>
          {isModalOpen &&
            <CenterModel
              modelTitle='Rejection Reason'
              show={"test"}
              handleClose={openCloseRejectModal}
              className='reject-model'
            // onUpload={props.openCloseRejectModal}
            >
              <RejectReason
                ref={rejectReasonRef}
                isModalOpen={isModalOpen}
                openCloseRejectModal={openCloseRejectModal}
                formActionHandler={formActionHandler}
              />
            </CenterModel>
          }
        </div>
      </CurrentCPDCycleDataContext.Provider>
    </>
  )
}
export default CpdSubmissionForm