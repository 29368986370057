import React from "react";
import "./Header.scss";
import Image from "../../../components/image/Image";
import { AppIcons } from "../../../data/appIcons";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../app/slice/authSlice";

function Header() {
  const dispatch = useDispatch();
  return (
    <div
      className="header-section d-flex justify-content-end"
    >
      <button
        className="btn btn-link"
        onClick={() => { dispatch(logout()); }}
      >
        <Image imagePath={AppIcons.logoutIcon} altText="Logout Icon" />
        <span
          className="logout-text"
        >
          Logout
        </span>
      </button>
    </div>
  );
}
export default Header;
