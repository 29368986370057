import React, { useRef, useState } from "react";
// import FormCreator from "../../components/Forms/FormCreator";
// import Buttons from "../../components/ui/button/Buttons";
// import { viewResourcesData } from "../../features/resources/components/viewResourcesData";
// import ViewResourcesTable from "../../features/resources/components/ViewResourcesTable";
import Buttons from "../../../components/ui/button/Buttons";
import { ViewPagesData } from "../createPages/components/AddComponent.Data";
import FormCreator from "../../../components/Forms/FormCreator";
import SearchBar from "../../../components/ui/searchBar/SearchBar";
import MolGrid from "../../../components/Grid/MolGrid";
import { viewPageGridConfig } from "./components/viewPageGridConfig.data";
// import { useNavigate } from "react-router-dom";

const ViewPages = () => {

  const molGridRef = useRef();
  const viewPagesRef = useRef();
  
  const [viewResourcesFilterForm] = useState(ViewPagesData);
  const [dataSource, setDataSource] = useState(null);


  // const navigate = useNavigate();

  // const handleCreateEdit = () => {
  //   navigate("/createEditResources");
  // };
  return (
    <>
      <div>
        <div className="row align-items-end">
          <div className="col-10">
            <div className="row">
              <FormCreator
                ref={viewPagesRef}
                {...ViewPagesData}
                // onActionChange={formActionHandler}
              />
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12 mb-4">
            <div className="section-main-title">
              <h4 className="title-txt">
                <span className="counts"></span>
              </h4>
              <div className="right-btn">
                <Buttons
                  buttonTypeClassName="outline-btn"
                  buttonText="+ Create New"
                  // onClick={handleCreateEdit}
                />
              </div>
            </div>
          </div>
          <div className="col-12 green-head-table">
            {/* <ViewResourcesTable ViewResourcesTableData={ViewResourcesTableData} /> */}
          </div>
        </div>
        <MolGrid
          ref={molGridRef}
          configuration={viewPageGridConfig}
          dataSource={dataSource}
          allowPagination={false}
          // onActionChange={actionHandler}
        />
      </div>
    </>
  );
};

export default ViewPages;
