import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { createContext } from 'react';

const BasicDetailContext = createContext();

export default BasicDetailContext;

export const BasicDetailContextProvider = ({ children }) => {
    // State variables to manage tab data and active tab
    const [tabs, setTabs] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    //Basic Information Page - State
    const [entryType, setEntryType] = useState(1);
    //Availability And Schedule Page - State
    const [redirectionFlag, setRedirectionFlag] = useState(false);
    const [scheduleType, setScheduleType] = useState("physical");
    //Pricing Page - state
    const [isPricingApplicable, setIsPricingApplicable] = useState("cost");

    return (
        <BasicDetailContext.Provider value={{
            tabs, setTabs,
            activeTab, setActiveTab,
            entryType, setEntryType,
            redirectionFlag, setRedirectionFlag,
            scheduleType, setScheduleType,
            isPricingApplicable, setIsPricingApplicable
        }}>
            {children}
        </BasicDetailContext.Provider>
    );
};