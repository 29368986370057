import { FormFieldTypes } from "../../../../data/formFieldType";

export const ViewIndividualMembersFormData = {
    name: "AdditionalInfo",
    initialState: {
        name: '',
        emailAddress: '',
        gmapTier: '',
        gmapNumber: ""

    },
    formFields: [
        {
            id: "name",
            lable: "Name",
            Field_Name: "Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "name",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3",
            },
        },
        {
            id: "emailAddress",
            lable: "Email Address",
            Field_Name: "Email Address",
            fieldType: FormFieldTypes.INPUT,
            dataField: "emailAddress",
            fieldSetting: {
                //placeholder: "",
                //options: []
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mb-3"
            }
        },
        {
            id: "gmapTier",
            lable: "GMP Tier",
            Field_Name: "GMAP Tier",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmapTier",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12"
            }
        },
        {
            id: "gmapNumber",
            lable: "GMP Number",
            Field_Name: "Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "gmapNumber",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12",
            },
        },
        {
            id: "status",
            lable: "Status",
            Field_Name: "Status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "status",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12 mt-3"
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}