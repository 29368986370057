import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import "./TinyEditor.scss";

const TinyEditor = ({
  onTinyMceEditorChange,
  isToolbarNotRequired,
  isMenubarNotRequired,
  data,
  key,
  onValidation,
  dataField,
  error,
  formSetting,
  isDisable,
  ...editorProps
}) => {
  const handleInputChange = (content) => {
    if (onTinyMceEditorChange) {
      // const plainText = new DOMParser().parseFromString(content, "text/html")
      //   .body.textContent;
      const text = content
      onTinyMceEditorChange(text);
    }
  };

  const initialValue = data;

  return (
    <>

      <div className="tiny-editor-sec">
        <Editor
          initialValue={initialValue}
          value={data}
          key={key ? key : "TinyMCE"}
          onEditorChange={handleInputChange}
          {...editorProps}
          disabled={isDisable}
          init={{
            menubar:
              isMenubarNotRequired && isMenubarNotRequired === true
                ? false
                : true,
            plugins: "link image code lists wordcount table",
            toolbar:
              isToolbarNotRequired && isToolbarNotRequired === true
                ? ""
                : "undo redo | bold italic underline linethrough | alignleft aligncenter alignright | code | bullist numlist checklist outdent indent | wordcount | table",
            statusbar: false,
            visual: false,
            // content_css: "index.css",
            selector: "textarea",
            powerpaste_allow_local_images: true,
            paste_as_text: false,
            content_style:
              " body{font-family: Roboto, sans-serif !important; color:#848484;}#tinymce{color:#848484; border:none; } table tr th{padding:5px;}",
          }}
          apiKey="y1n61ivlxxzxxf58vakoaj7d3qiol043t915p0snzyupn84g"
        />
      </div>
    </>
  );
};

export default TinyEditor;
