import { CpdRenewalForm } from "../DataFile/CpdRenewalCycleForm.Data";

export const setDropdownFieldsCpdRenewalCycle = (apiResponse) => {
    const filterDropDownList = (dropdownName) => {
        return apiResponse.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name
                });
            }
            return acc;
        }, []);
    }

    const memberDropdownList = {
        "appilcationType": filterDropDownList("Application Type"),
    };


    const allDropDownMemberFromFields = [
        ...CpdRenewalForm.formFields
    ];

    allDropDownMemberFromFields.forEach(field => {
        const dropdownList = memberDropdownList[field.id];
        if (dropdownList) {
            field.fieldSetting.options = dropdownList;
        }
    });

}