import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'
import { ZoomAccountFormData } from '../data/ZoomAccountForm.Data';
import FormCreator from '../../../../../components/Forms/FormCreator';
import Buttons from '../../../../../components/ui/button/Buttons';

const ZoomAccount = forwardRef((props, ref) => {
    const zoomAccountRef = useRef();
    const [zoomAccountForm, setZoomAccountForm] = useState(ZoomAccountFormData);

    // Expose method `getFormData` to parent components to retrieve form data from `FormCreator`
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const zoomAccountFormData = zoomAccountRef.current.getFormData();
                return zoomAccountFormData;
            }
            return null;
        }
    }))

    const handleFormFieldChange = (fieldName, fieldValue) => {
        switch (fieldName) {
            case "isRedirection":
                // setRedirectionFlag(fieldValue === "yes");
                break;
            default:
                return false;
        }
    };

    return (
        <React.Fragment>
            <div className='row'>
                <FormCreator ref={zoomAccountRef} {...zoomAccountForm} onFormFieldValueChange={handleFormFieldChange} />
            </div>
            <div className='row mt-5'>
                <div className='col-lg-12'>
                    <Buttons
                        buttonText="Generate"
                        buttonTypeClassName="success-btn"
                    // onClick={() => handleNext(tab.saveInto)}
                    />
                </div>
            </div>

        </React.Fragment>
    )
})

export default ZoomAccount