import { FormFieldTypes } from "../../../../../data/formFieldType";

export const gmapMembershipData = {
    name: "AcademicQualification",
    initialState: {
        accountType: '',
        billingCategory: '',
        subscriptionStartDate: '',
        subscriptionEndDate: '',
        updatedBy: '',
        lastUpdatedDate: '',
    },
    formFields: [
        {
            id: "accountType",
            lable: "Account Type",
            Field_Name: "Account Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "accountType",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12  mb-3"
            }
        },
        {
            id: "billingCategory",
            lable: "Billing Category",
            Field_Name: "Billing Category",
            fieldType: FormFieldTypes.SELECT,
            dataField: "billingCategory",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12  mb-3"
            }
        },
        {
            id: "subscriptionStartDate",
            lable: "Subscription Start Date",
            Field_Name: "Subscription Start Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "subscriptionStartDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12  mb-3",
            },
        },
        {
            id: "subscriptionEndDate",
            lable: "Subscription End Date",
            Field_Name: "Subscription End Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "subscriptionEndDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12  mb-3",
            },
        },
        {
            id: "updatedBy",
            lable: "Updated By",
            Field_Name: "Updated By",
            fieldType: FormFieldTypes.INPUT,
            dataField: "updatedBy",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12  mb-3",
            },
        },

        {
            id: "lastUpdatedDate",
            lable: "Last Updated Date",
            Field_Name: "Last Updated Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "lastUpdatedDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12  mb-3",
            },
        },


    ],
    formSetting: {
        isViewOnly: true
    }
}