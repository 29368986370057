import { FormFieldTypes } from "../../../../../data/formFieldType"

export const AccountInformationFormData={
    name: 'accountInformation',
    initialState: {
        accountType: null,
        joinedDate: '',
        accountstatus: null,
        billingCategory: null,
        gmapStatus: null,
        gmapNumber: '',
        subscriptionStartDate: '',
        subscriptionEndDate: '',
        updatedBy: '',
        updatedDate: '',
        appeal: null,
        qualify: null,
        voidLateFee: null,
        pendingExpiry: null

    },
    formFields: [



        {
            id: "accountType",
            lable: "Account Type",
            Field_Name: "Account Type",
            fieldType: FormFieldTypes.SELECT,
            dataField: "accountType",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true,
            },
            style: {
                containerCss: "col-md-6 mb-3"
            }
        },
        {
            id: "joinedDate",
            lable: "Joined Date",
            Field_Name: "Joined Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "joinedDate",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3"
            }
        },
        {
            id: "accountstatus",
            lable: "Account Status",
            Field_Name: "Account Status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "accountstatus",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true,

            },
            style: {
                containerCss: "col-md-6 mb-3"
            }
        },
        {
            id: "billingCategory",
            lable: "Billing Category",
            Field_Name: "Billing Category",
            fieldType: FormFieldTypes.SELECT,
            dataField: "billingCategory",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3"
            }
        },
        {
            id: "gmapStatus",
            lable: "GMP Status",
            Field_Name: "GMAP Status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "gmapStatus",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true,

            },
            style: {
                containerCss: "col-md-6 mb-3"
            }
        },
        {
            id: "gmapNumber",
            lable: "GMP Number",
            Field_Name: "gmapNumber",
            fieldType: FormFieldTypes.INPUT,
            dataField: "gmapNumber",
            fieldSetting: {
                placeholder: "",
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3"
            },
        },
        {
            id: "subscriptionStartDate",
            lable: "Subscription Start Date",
            Field_Name: "Subscription Start Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "subscriptionStartDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            //validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "subscriptionEndDate",
            lable: "Subscription End Date",
            Field_Name: "Subscription End Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "subscriptionEndDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            //validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "updatedBy",
            lable: "Updated By",
            Field_Name: "Updated By",
            fieldType: FormFieldTypes.INPUT,
            dataField: "updatedBy",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },

        {
            id: "updatedDate",
            lable: "Updated Date",
            Field_Name: "Updated Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "updatedDate",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "appeal",
            lable: "Appeal",
            Field_Name: "Appeal",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "appeal",
            fieldSetting: {
                isDisable: false,
            },
            style: {
                containerCss: "col-md-3 my-3"
            }
        },
        {
            id: "qualify",
            lable: "Qualify",
            Field_Name: "Qualify",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "qualify",
            fieldSetting: {
                isDisable: false,
            },
            style: {
                containerCss: "col-md-3 my-3"
            }
        },
        {
            id: "voidLateFee",
            lable: "Void Late Fee",
            Field_Name: "Void Late Fee",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "voidLateFee",
            fieldSetting: {
                isDisable: false,
            },
            style: {
                containerCss: "col-md-3 my-3"
            }
        },
        {
            id: "pendingExpiry",
            lable: "Pending Expiry",
            Field_Name: "Pending Expiry",
            fieldType: FormFieldTypes.CHECKBOX,
            dataField: "pendingExpiry",
            fieldSetting: {
                isDisable: false,
            },
            style: {
                containerCss: "col-md-3 my-3"
            }
        }
    ],
    formSetting: {
        isViewOnly: false
    }
}

