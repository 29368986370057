import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { careerHistoryData, CurrentEmpData } from '../CareerHistory.data';
import FormCreator from '../../../../../../components/Forms/FormCreator';
import { EditApplicationDataContext } from '../../../../../../pages/gmap/gmapApplicationTasklist/gmapApplicationTaskListNewApplication/GmapApplicationTaskListNewApplication';
import { decryptAESID } from '../../../../../../utils/Encryption';
import { useParams } from 'react-router-dom';
import { useUpdateGMAPCurrentCareerHistoryMutation } from '../../../../../../app/services/GMAP/careerHistoryApi';
import SwalAlert from '../../../../../../services/swalService/SwalService';
import Buttons from '../../../../../../components/ui/button/Buttons';
import { handleConditionBaseFields } from '../utils/CareerHistoryFormUtils';


const CareerHistoryForm = forwardRef((props, ref) => {
    const careerHistoryRef = useRef();
    const currentEmpRef = useRef();
    const { id } = useParams();
    const gmapMemberId = id ? decryptAESID(id, false) : 0;
    const [isUnemployed, setIsUnemployed] = useState(false);
    const [formData, setFormData] = useState(careerHistoryData);
    const [currentEmpFormData, setCurrentEmpFormData] = useState(CurrentEmpData);
    const { newMemberData, setContextData } = useContext(EditApplicationDataContext);
    const { error, success } = SwalAlert();
    const resetForm = () => {
        let form = { ...careerHistoryData }
        form.initialState = { ...props.initData }
        const fieldIndex = form.formFields.findIndex(field => field.id === "otherProfessionName");
        form.formFields[fieldIndex].style.containerCss = form.initialState?.otherProfessionName ? "col-md-6 mb-3" : "col-md-6 mb-3 d-none";
        setFormData(form);
        setIsUnemployed(false);
    }
    const [gMapMemberSignUp,
        { isSuccess: isNewMemberSignup,
            isLoading: isNewMemberLoading,
            data: addNewMember }
    ] = useUpdateGMAPCurrentCareerHistoryMutation();

    useEffect(() => {
        if (!isNewMemberLoading && isNewMemberSignup && addNewMember) {
            success("Your Current Company Details has been Save.", "");
        }
    }, [isNewMemberLoading, isNewMemberSignup, addNewMember]);

    useImperativeHandle(ref, () => ({
        careerHistoryRef: {
            getFormData: () => {
                const careerHistoryInfo = careerHistoryRef.current.getFormData();
                if (careerHistoryInfo) {
                    resetForm();
                    return careerHistoryInfo;
                }
                return null;
            },
        },
        // currentEmpRef: {
        //     getFormData: () => {
        //         const currentEmpInfo = currentEmpRef.current.getFormData();
        //         if (currentEmpInfo) {
        //             return currentEmpInfo;
        //         }
        //         return null;
        //     },
        // },
    }));

    const updateContainerCssForPeriodTo = (formData) => {
        // Find the index of the periodTo field in formFields array
        const companyNameIndex = formData.formFields.findIndex(field => field.id === "CurrentCompanyName");
        const designationIndex = formData.formFields.findIndex(field => field.id === "CurrentDesignation");
        const periodFromIndex = formData.formFields.findIndex(field => field.id === "CurrentPeriodFrom");
        // const periodToIndex = formData.formFields.findIndex(field => field.id === "CurrentPeriodTo");
        const professionIndex = formData.formFields.findIndex(field => field.id === "GMAPProfessionTypeId");
        const validationRule = isUnemployed ? [] : [{ type: "require" }]
        if (companyNameIndex !== -1 || designationIndex !== -1 || periodFromIndex !== -1 || professionIndex !== -1) {
            // Create a copy of formData to avoid mutating state directly
            const updatedFormData = { ...formData };

            // Update the containerCss value for periodTo field

            updatedFormData.formFields[companyNameIndex].validation = validationRule;
            updatedFormData.formFields[designationIndex].validation = validationRule;
            updatedFormData.formFields[periodFromIndex].validation = validationRule;
            // updatedFormData.formFields[periodToIndex].validation = validationRule;
            updatedFormData.formFields[professionIndex].validation = validationRule;

            updatedFormData.formFields[companyNameIndex].fieldSetting.isDisable = isUnemployed ? true : false;
            updatedFormData.formFields[designationIndex].fieldSetting.isDisable = isUnemployed ? true : false;
            updatedFormData.formFields[periodFromIndex].fieldSetting.isDisable = isUnemployed ? true : false;
            // updatedFormData.formFields[periodToIndex].fieldSetting.isDisable = isUnemployed ? true : false;
            updatedFormData.formFields[professionIndex].fieldSetting.isDisable = isUnemployed ? true : false;
            setCurrentEmpFormData(updatedFormData);
        } else {
            return careerHistoryData;
        }
    };
    useEffect(() => {
        resetForm();
        if (props.isEdit && props.initData) {
            // alert("hello edit calling")
        }
    }, [props.isEdit, props.initData]);
    useEffect(() => {
        updateContainerCssForPeriodTo(currentEmpFormData);
    }, [isUnemployed]); // This effect runs when isUnemployed or formData changes

    const handleStateChange = (dataField) => {
        setIsUnemployed(dataField.isUnemployed);
        setContextData({ ...newMemberData, isUnemployed: dataField.isUnemployed });
    };

    const handleSubmit = () => {
        let currentEmpData = currentEmpRef?.current?.getFormData();
        if (!currentEmpData) {
            return error("Please fill all mandatory fields.", "")
        }
        let request = {
            GMAPMemberId: gmapMemberId,
            StatusId: 1,
            AppId: 114,
            IsSave: true,
            IsCurrentlyEmployed: currentEmpData?.isUnemployed || false,
            CurrentCompanyName: currentEmpData?.CurrentCompanyName || null,
            CurrentDesignation: currentEmpData?.CurrentDesignation || null,
            CurrentPeriodFrom: currentEmpData?.CurrentPeriodFrom || null,
            CurrentPeriodTo: currentEmpData?.CurrentPeriodTo || null,
            IsCurrent: true || null,
            GMAPProfessionTypeId: currentEmpData?.GMAPProfessionTypeId?.value || currentEmpData?.GMAPProfessionTypeId,
            OtherProfessionName: currentEmpData?.CurrentOtherProfessionName || null,
        }
        gMapMemberSignUp(request);
    }

    const handleDropdownChange = (data, dataField) => {
        const otherProfessionNameId = dataField === "gmapProfessionTypeId" ? "otherProfessionName" : "CurrentOtherProfessionName";
        if (dataField === "GMAPProfessionTypeId" || dataField === "gmapProfessionTypeId") {
            handleConditionBaseFields(data.value, otherProfessionNameId);
        }
    }
    const formActionHandler = {
        DDL_CHANGED: handleDropdownChange
    }


    return <React.Fragment>
        <div className='row'>
            <FormCreator ref={currentEmpRef} {...currentEmpFormData} onActionChange={formActionHandler} onFormDataChange={handleStateChange} />
            <div className="col-md-12 mb-2 d-flex justify-content-end">
                <Buttons
                    buttonText={"Save"}
                    buttonTypeClassName="outline-btn"
                    onClick={handleSubmit}
                />
            </div>
            <FormCreator ref={careerHistoryRef} onActionChange={formActionHandler} {...formData} handleTableEdit={props.handleTableEdit} handleTableDelete={props.handleTableDelete} />
        </div>
    </React.Fragment>
})

export default CareerHistoryForm;