import React from "react";
import "../../pages/resources/Resources.scss";
import Buttons from "../../components/ui/button/Buttons";
import Image from "../../components/image/Image";
import { AppIcons } from "../../data/appIcons";
import { Link } from "react-router-dom";

const PreviewResources = () => {
  return (
    <div>
      <div className="common-custom-pagination">
        <div className="">
          <Buttons
            buttonText="Back to Edit"
            buttonTypeClassName="back-btn"
            // onClick={() => handleSearchMemberDirectory()}
          />
        </div>
        <div className="right-btn d-flex align-content-center">
          <Buttons
            buttonText="Save"
            buttonTypeClassName="success-btn"
            // onClick={() => handlePreview()}
          />
        </div>
      </div>

      <div className="middle-preview-section">
        <div className="title-text">
          <h3>SGBC Gala Dinner 2023</h3>
          <p>Published 8 January 2024</p>
        </div>
        <div className="img-desc-sec">
          <div className="row">
            <div className="col-11">
              <div className="resource-img">
                <Image imagePath={AppIcons.DefaultImg} />
              </div>
              <div className="resource-desc">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <div className="title-text">
                  <h3>Subheader</h3>
                </div>
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua
                  </li>
                  <li>
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                    irure dolor in reprehenderit in voluptate velit esse cillum
                    dolore eu fugiat nulla pariatur.
                  </li>
                  <li>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </li>
                </ul>
                <ul className="list-number">
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </li>
                  <li>
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat.
                  </li>
                  <li>
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur.
                  </li>
                  <li>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </li>
                </ul>
                <p className="mt-4">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. More info
                  email at <Link to=""> loremipsum@gmail.com</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="common-custom-pagination mt-4">
        <div className="">
          <Buttons
            buttonText="Back to Edit"
            buttonTypeClassName="back-btn"
            // onClick={() => handleSearchMemberDirectory()}
          />
        </div>
        <div className="right-btn d-flex align-content-center">
          <Buttons
            buttonText="Save"
            buttonTypeClassName="success-btn"
            // onClick={() => handlePreview()}
          />
        </div>
      </div>
    </div>
  );
};

export default PreviewResources;
