import { createApi } from "@reduxjs/toolkit/query/react";
import { transformErrorResponse, transformSucessResponse } from "../../../../utils/API/responseMiddleware";
import { encryptQueryString, transformRequest } from "../../../../utils/API/requestMiddleware";
import { customFetchBase, defautBaseQuery } from "../../../../utils/API/fetchBaseQuery";

const DropdownManagementApi = createApi({
    reducerPath: 'DropdownManagementApi',
    baseQuery: defautBaseQuery,
    endpoints: (builder) => ({
        getDropdownRecordList: builder.mutation({
            query: () => ({
                url: '/FieldManagement/GetDropdownRecordList',
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getDropdownDatabyId: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/FieldManagement/GetDropdownDatabyId?id=${parseInt(id)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        deleteDropdowndata: builder.mutation({
            query: (details) => ({
                url: '/FieldManagement/Deletedropdowndata',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        addEditDropdownData: builder.mutation({
            query: (details) => ({
                url: '/FieldManagement/AddEditDropdownData',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useGetDropdownRecordListMutation,
    useLazyGetDropdownDatabyIdQuery,
    useDeleteDropdowndataMutation,
    useAddEditDropdownDataMutation,
} = DropdownManagementApi;

export default DropdownManagementApi;