import { FormFieldTypes } from "../../../../data/formFieldType";
import { GridColumnType } from "../../../../data/gridColumnType";


export const DiscretionaryRenewalRequestSearchFormData={
    name: 'DiscretionaryRenewalRequestSearchFormData',
    initialState: {
        searchText: ""
    },
    formFields: [
        {
            id: "searchText",
            lable: "Name/GMP Number",
            Field_Name: "Name/GMP Number",
            fieldType: FormFieldTypes.INPUT,
            dataField: "searchText",
            fieldSetting: {
                allowSpace: false,
            },
            style: {
                //containerCss: "",
            },
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}


export const DiscretionaryRenewalRequestTableConfig={
    columns: [
        {
            name: "Submission Date",
            fieldName: "submittedOn",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD MMM YYYY',
            }
        },
        {
            name: "GMP Name",
            fieldName: "fullName",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "GMP Number",
            fieldName: "gmapNumber",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "GMP Qualification Type",
            fieldName: "applicationName",
            colType: GridColumnType.Text,
        },
        {
            name: "GMP Expire Date",
            fieldName: "qualificationEndDate",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD MMM YYYY',
            }
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false
            },
            customAction: [
                {
                    name: "UPDATE",
                    // iconName: AppIcons.copyIcon,
                    onViewAction: (data) => {
                        alert("called permissions");
                    },
                },
            ],
        },
    ],
};

