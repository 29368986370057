import { createApi } from "@reduxjs/toolkit/query/react";
import { encryptQueryString, transformRequest } from '../../../utils/API/requestMiddleware';
import { defautBaseQuery } from '../../../utils/API/fetchBaseQuery';
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";


const certificateCourseApi = createApi({
    reducerPath: 'certificateCourseApi',
    baseQuery: defautBaseQuery,
    endpoints: (builder) => ({
        getGMAPCertificationCourseList: builder.query({
            query: (details) => ({
                url: encryptQueryString(`/GMAPAccount/GetGMAPCertificationCourseList?id=${Number(details)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        deleteGMAPCertificationCourse: builder.mutation({
            query: (details) => ({
                url: '/GMAPAccount/DeleteGMAPCertificationCourse',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        addEditGMAPCertificationCourse: builder.mutation({
            query: (details) => ({
                url: '/GMAPAccount/AddEditGMAPCertificationCourse',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getByIdIHLCertificateType: builder.query({
            query: (request) => ({
                url: encryptQueryString(`/GMAPAccount/GetByIdIHLCertificateType?id=${Number(request)}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
})

export const {
    useDeleteGMAPCertificationCourseMutation,
    useAddEditGMAPCertificationCourseMutation,
    useLazyGetGMAPCertificationCourseListQuery,
    useLazyGetByIdIHLCertificateTypeQuery
} = certificateCourseApi;

export default certificateCourseApi;