import { GridColumnType } from "../../../data/gridColumnType";


// Current Cpd Cycle Table GridConfig start
export const MemberDirectoryTableDataConfig = {
  columns: [
    {
      name: "Name",
      fieldName: "gmapContactName",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
      name: "GMAP Number",
      fieldName: "mobilePhone",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
      name: "GMAP Tier",
      fieldName: "gmapApplicationType",
      colType: GridColumnType.Text,

    },
    {
      name: "Company Name",
      fieldName: "companyName",
      colType: GridColumnType.Text,
    },
    {
      name: "Action",
      colType: GridColumnType.ACTION,
      defaultAction: {
        allowEdit: false,
        allowDelete: true,
      },
      customAction: [
        {
          name: "View",
          // iconName: AppIcons.arrowIcon,
          onViewAction: () => {

          },
        },
      ],
      actionHandler: {
        onEditAction: () => {
          alert("edit called"); //Edit button Action
        },
        onDeleteAction: () => {
          alert("delete called"); //Delete button Action
        },
      },
    },
  ],
};
