import React from "react";

const FormMainTitle = (props) => {

  return (
    <div className={`section-title  ${props.containerCss}`}>
      <h4 className="common-subtitle">{props.formTitle}</h4>
    </div>
  );
};

export default FormMainTitle;
