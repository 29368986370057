import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageLoader from "../../components/ui/pageLoader/PageLoader";
// import ProtectedRoute from "../Routes/ProtectedRoute";
import Layout from "../../layouts/Layout";
import LoginPage from "../../pages/login/Login";
import ProtectedRoute from "../Routes/ProtectedRoute";

const NotFound = React.lazy(() => import("../../pages/errors/NotFound"));

const AppRoutes = (props) => {
  return (
    <Router>
      <React.Suspense fallback={<PageLoader />}>
        <Routes>
          <Route
            name="Layout"
            path="/"
            element={
              <ProtectedRoute>
                <Layout componentRoutes={props.componentRoutes}></Layout>
              </ProtectedRoute>
            }
          >
            {props.componentRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                name={"test"}
                element={<route.component />}
              />
            ))}
          </Route>
          <Route exact name="Login" path="/login" element={<LoginPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </Router>
  );
};
export default AppRoutes;
