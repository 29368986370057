export const editableMolGrid = [
    {
        movieId: 1,
        title: "Inception",
        director: "Christopher Nolan",
        releaseDate: "2010-07-16",
        boxOffice: 829895144,
        leadCast: "Alpha",
        status: true,
        isActive: true,
        link: "https://www.imdb.com/title/tt1375666/"
    },
    {
        movieId: 2,
        title: "The Shawshank Redemption",
        director: "Frank Darabont",
        releaseDate: "1994-09-23",
        boxOffice: 28341469,
        leadCast: "Beta",
        status: true,
        isActive: true,
        link: "https://www.imdb.com/title/tt0111161/"
    },
    {
        movieId: 3,
        title: "The Godfather",
        director: "Francis Ford Coppola",
        releaseDate: "1972-03-24",
        boxOffice: 246120974,
        leadCast: "Alpha",
        status: false,
        isActive: false,
        link: "https://www.imdb.com/title/tt0068646/"
    },
    {
        movieId: 4,
        title: "The Dark Knight",
        director: "Christopher Nolan",
        releaseDate: "2008-07-18",
        boxOffice: 1004558444,
        leadCast: "Beta",
        status: true,
        isActive: true,
        link: "https://www.imdb.com/title/tt0468569/"
    },
    {
        movieId: 5,
        title: "Pulp Fiction",
        director: "Quentin Tarantino",
        releaseDate: "1994-10-14",
        boxOffice: 213928762,
        leadCast: "Delta",
        status: true,
        isActive: false,
        link: "https://www.imdb.com/title/tt0110912/"
    },
    {
        movieId: 6,
        title: "Fight Club",
        director: "David Fincher",
        releaseDate: "1999-10-15",
        boxOffice: 101209702,
        leadCast: "Alpha",
        status: false,
        isActive: true,
        link: "https://www.imdb.com/title/tt0137523/"
    },
    {
        movieId: 7,
        title: "Forrest Gump",
        director: "Robert Zemeckis",
        releaseDate: "1994-07-06",
        boxOffice: 678226133,
        leadCast: "Gamma",
        status: false,
        isActive: false,
        link: "https://www.imdb.com/title/tt0109830/"
    },
    {
        movieId: 8,
        title: "The Matrix",
        director: "Lana Wachowski, Lilly Wachowski",
        releaseDate: "1999-03-31",
        boxOffice: 466364845,
        leadCast: "Gamma",
        status: "Active",
        isActive: true,
        link: "https://www.imdb.com/title/tt0133093/"
    },
    {
        movieId: 9,
        title: "The Lord of the Rings: The Fellowship of the Ring",
        director: "Peter Jackson",
        releaseDate: "2001-12-19",
        boxOffice: 871530324,
        leadCast: "Delta",
        status: true,
        isActive: true,
        link: "https://www.imdb.com/title/tt0120737/"
    },
    {
        movieId: 10,
        title: "Star Wars: Episode V - The Empire Strikes Back",
        director: "Irvin Kershner",
        releaseDate: "1980-05-21",
        boxOffice: 538375067,
        leadCast: "Gamma",
        status: false,
        isActive: false,
        link: "https://www.imdb.com/title/tt0080684/"
    }
]


