import React, { useRef, useState } from "react";
import FormCreator from "../../components/Forms/FormCreator";
import Buttons from "../../components/ui/button/Buttons";
import { viewResourcesData } from "../../features/resources/components/viewResourcesData";
import ViewResourcesTable from "../../features/resources/components/ViewResourcesTable";
import { useNavigate } from "react-router-dom";
const ViewResourcesTableData = [
  {
    resourceName: "SGBC Gala Dinner 2023",
    type: "Articles",
    datePosted: "8 Jan 2024",
    status: "Approved",
    postedby: "Ming De",
  },
  {
    resourceName: "SGBC Gala Dinner 2023",
    type: "Press Release",
    datePosted: "8 Jan 2024",
    status: "Pending Approval",
    postedby: "Ming De",
  },
  {
    resourceName: "SGBC Gala Dinner 2023",
    type: "e-Newsletter",
    datePosted: "1 Feb 2024",
    status: "Draft",
    postedby: "Ming De",
  },
];
const ViewResources = () => {
  const ViewResourcesFilterFormRef = useRef();
  const [viewResourcesFilterForm] = useState(viewResourcesData);

  const navigate = useNavigate();

  const handleCreateEdit = () => {
    navigate("/createEditResources");
  };
  return (
    <>
      <div className="row align-items-end">
        <div className="col-10">
          <div className="row">
            <FormCreator
              ref={ViewResourcesFilterFormRef}
              {...viewResourcesFilterForm}
            // onActionChange={formActionHandler}
            />
          </div>
        </div>
        <div className="col-2 d-flex justify-content-end">
          <Buttons
            buttonTypeClassName="success-btn"
            buttonText="Search"
          // onClick={handleLogin}
          // isLoading={loginLoading}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 mb-4">
          <div className="section-main-title">
            <h4 className="title-txt">
              <span className="counts">3</span> Resources
            </h4>
            <div className="right-btn">
              <Buttons
                buttonTypeClassName="outline-btn"
                buttonText="+ Create New"
                onClick={handleCreateEdit}
              />
            </div>
          </div>
        </div>
        <div className="col-12 green-head-table">
          <ViewResourcesTable ViewResourcesTableData={ViewResourcesTableData} />
        </div>
      </div>
    </>
  );
};

export default ViewResources;
