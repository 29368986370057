/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useRef, useImperativeHandle, useState, useContext, useEffect } from "react";
import { academicQualificationData } from "./AcedemicQualification.Data";
import { saveAs } from 'file-saver'
import FormCreator from "../../../../../components/Forms/FormCreator";
import { useGMapMemberSignUpMutation, useLazyGetPDFQuery, useUploadPDFMutation } from "../../../../../app/services/common/commonApi";
import { CreateIndividualMembersContext } from "../../../../../pages/gmap/viewIndividualMembers/createIndividualMembers";
import Buttons from "../../../../../components/ui/button/Buttons";
import ToastService from "../../../../../services/toastService/ToastService";

const AcademicQualification = forwardRef((props, ref) => {
    const academicQualificationRef = useRef();
    const [academicQualificationForm, SetAcademicQualificationForm] = useState(academicQualificationData);
    const { newMemberData, setContextData } = useContext(CreateIndividualMembersContext)
    const [getPDF] = useLazyGetPDFQuery();
    const [gMapMemberSignUp,
        { isSuccess: isNewMemberSignup,
            isLoading: isNewMemberLoading,
            data: addNewMember }
    ] = useGMapMemberSignUpMutation();
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const academicData = academicQualificationRef.current.getFormData();
                return academicData;
            }
            return null;
        }
    }))

    const [uploadPDF,
        { isSuccess: isSuccess,
            data: fileUploadData }
    ] = useUploadPDFMutation();

    useEffect(() => {
        if (!isNewMemberLoading && isNewMemberSignup && addNewMember) {
            if (!addNewMember.hasError && addNewMember.errorMessage === "") {
                setContextData(prevData => ({ ...prevData, gMAPMemberID: addNewMember.keyId }));
                ToastService.success("Academic Qualification Save Successfully");
            }
        }
    }, [isNewMemberLoading, isNewMemberSignup, addNewMember]);

    const handleClear = () => {
        const updatedForm = { ...academicQualificationForm };
        updatedForm.initialState.academicQualificationCertificationFile = null;
        SetAcademicQualificationForm(updatedForm);
    }

    const handleDownload = async (data, dataField) => {
        const request = {
            type: "",
            fileName: newMemberData?.academicQualificationCertificationFile,
        }
        if (dataField === "academicQualificationCertificationFile") {
            request.type = "AcademincQualification"
        }

        const response = await getPDF(request);
        saveAs(response?.data?.fileData, response?.data?.fileName);
    }

    const formActionHandler = {
        CLEAR: handleClear,
        DOWNLOAD: handleDownload,
    }

    const handleOnChangeFileUpload = async (data) => {
        let formData = new FormData();
        formData.append("file", data)
        formData.append("storagePath", "AcademincQualification");
        try {
            const response = await uploadPDF(formData);
            // const newData = { ...newMemberData }
            // newData.academicQualificationCertificationFile = response.data.fileName;
            setContextData({ ...newMemberData, academicQualificationCertificationFile: response.data.fileName });
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    }
    // for file upload changes
    const handleFormFieldChange = (dataField, value) => {
        if (dataField === "academicQualificationCertificationFile" && value) {
            handleOnChangeFileUpload(value)
        }
    };
    const handleSave = () => {
        const academicData = academicQualificationRef.current.getFormData();
        let request = {
            GMAPMemberId: newMemberData?.gMAPMemberID || null,
            FieldOfStudy: academicData?.fieldOfStudy || null,
            GMAPHighestRelevantQualificationId: academicData?.highestQualificationID?.value || null,
            GraduateYearId: academicData?.graduateYearID?.value || null,
            AcademicInstitution: academicData?.academicQualification || null,
            AcademicCertificate: academicData?.academicQualificationCertificationFile.name || null,
            StatusId: 1,
            SaveInto: "AcademicQualification",
            AppId: 115,
            IsSave: true,
        }
        gMapMemberSignUp(request);
    }
    return (<React.Fragment>
        <FormCreator ref={academicQualificationRef}
            {...academicQualificationForm}
            onActionChange={formActionHandler}
            onFormFieldValueChange={handleFormFieldChange}
        />
        <div className="col-lg-6 d-flex justify-content-end mt-5">
            <Buttons
                buttonText="Save"
                buttonTypeClassName="outline-btn"
                isLoading={false}
                onClick={handleSave}
            />
        </div>
    </React.Fragment>)
})

export default AcademicQualification