import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useLazyGetAllDropDownsForMemberDirectoryQuery } from '../../../../app/services/common/commonApi';
import FormCreator from '../../../../components/Forms/FormCreator';
import { DiscretionaryTaskListSearchFilterFormData } from '../DataFile/DiscretionaryTaskListSearchFilterForm.Data';

const DiscretionaryTaskListSearchFilterForm = forwardRef((props, ref) => {
    const [discretionaryApplicationTasklistForm, setDiscretionaryTaskApplicationTasklist] = useState(DiscretionaryTaskListSearchFilterFormData);
    const discretionaryApplicationTasklistRef = useRef();
    const [getAllDropDowns, {
        data: allDropDownResponse,
        isSuccess: isGetAllDropDownSuccess,
        isFetching: isGetDropDownFetching,
        isLoading: dropdownListLoading,
    },] = useLazyGetAllDropDownsForMemberDirectoryQuery()

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const ViewIndividualMembersFormData = discretionaryApplicationTasklistRef.current.getFormData();
                return ViewIndividualMembersFormData;
            }
            return null;
        }
    }));

    const filterDropDownList = (dropdownName) => {
        return allDropDownResponse.reduce((acc, item) => {
            if (item.dropdownName === dropdownName) {
                acc.push({
                    value: item.id,
                    label: item.name,
                });
            }
            return acc;
        }, []);
    };

    useEffect(() => {
        if (allDropDownResponse) {
            let statusDropdown = filterDropDownList("Status");
            statusDropdown = statusDropdown.filter(obj => obj.label !== 'Draft' && obj.label !== 'Completed' && obj.label !== 'Pending Approval')
            let trmpFormField = { ...discretionaryApplicationTasklistForm };
            trmpFormField = trmpFormField?.formFields?.map((obj) =>
                obj?.id === "status" ? {
                    ...obj, fieldSetting: {
                        placeholder: "", options: statusDropdown
                    }
                }
                    : obj
            )
            setDiscretionaryTaskApplicationTasklist({ ...discretionaryApplicationTasklistForm, formFields: trmpFormField })
        }
    }, [dropdownListLoading, isGetDropDownFetching, isGetAllDropDownSuccess, allDropDownResponse,]);



    useEffect(() => { getAllDropDowns() }, [])

    return (
        <FormCreator ref={discretionaryApplicationTasklistRef} {...discretionaryApplicationTasklistForm} />
    )

})

export default DiscretionaryTaskListSearchFilterForm