
import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";


const CpdSubmissionApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'CpdSubmissionApi',
    endpoints: (builder) => ({
        getSGBCAccreditedCpdEventDetailById: builder.mutation({
            query: (id) => ({
                url: encryptQueryString(`/CPD/GetByIdSGBCAccreditiedCPDEvents?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getBcaGreenMarkProjectById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/CPD/GetByIdAddEditBCAGreenMarkProject?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getMastersDegreeDoctorProgrammesById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/CPD/GetByIdAddEditMastersDegreeProgrammes?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getPublicationofResearchPaperById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/CPD/GetByIdAddEditPublicationOfResearchPaper?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getIndustryTechnicalCommitteesById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/CPD/GetByIdAddEditIndustryTechnicalCommittees?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getCpdprogrammesbyOverseasgreenbuildingCouncilsById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/CPD/GetByIdAddEditCPDProgrammes?id=${id}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getSgbcElearningById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/CPD/GetByIdAddEditSGBCeLearning?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getSpeakingTeachingEngagementById: builder.query({
            query: (id) => ({
                url: encryptQueryString(`/CPD/GetByIdAddEditSpeakingEngagement?id=${id.toString()}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

export const {
    useGetSGBCAccreditedCpdEventDetailByIdMutation,
    useLazyGetBcaGreenMarkProjectByIdQuery,
    useLazyGetMastersDegreeDoctorProgrammesByIdQuery,
    useLazyGetPublicationofResearchPaperByIdQuery,
    useLazyGetIndustryTechnicalCommitteesByIdQuery,
    useLazyGetCpdprogrammesbyOverseasgreenbuildingCouncilsByIdQuery,
    useLazyGetSgbcElearningByIdQuery,
    useLazyGetSpeakingTeachingEngagementByIdQuery,
} = CpdSubmissionApi;

export default CpdSubmissionApi;