import { FormFieldTypes } from "../../../../../data/formFieldType";

export const mastersDegreeDoctorProgrammesData = {
    name: 'MastersDegreeDoctorProgrammees',
    initialState: {
        titleofDegreeProgramme: '',
        institutionofferingtheMasters: '',
        degreeCertificate: "",
        dateofConferment: "",
        CpdPoints: "",
        remarks: "",
        processedBy: "",
        cpdPointsAwarded: "",
        notes: "",
    },
    formFields: [
        {
            id: "programmeInformation",
            lable: "",
            Field_Name: "programmeInformation",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "programmeInformation",
            fieldSetting: {
                formTitle: "Programme Information",
            },
            style: {
                containerCss: "col-lg-12 mb-4 text-left",
            },
        },
        {
            id: "titleofDegreeProgramme",
            lable: "Title of Degree Programme",
            Field_Name: "Project Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "titleofDegreeProgramme",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        {
            id: "institutionofferingtheMasters",
            lable: "Institution offering the Masters / PhD Degree Programme",
            Field_Name: "Institution offering the Masters / PhD Degree Programme",
            fieldType: FormFieldTypes.INPUT,
            dataField: "institutionofferingtheMasters",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "dateofConferment",
            lable: "Date of Conferment",
            Field_Name: "Date of Conferment",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "dateofConferment",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        {
            id: "degreeCertificate",
            lable: 'Degree Certificate',
            subLable: "(please upload in pdf/jpg format max 2mb)",
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'degreeCertificate',
            fieldSetting: {
                // presentFileName: null,
                placeholder: 'Select Profile Photo',
                isDownloadable: true,
                acceptedFiles: '.pdf',
                maxFileSizeInKB: 2048,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space"
            }
        },
        // {
        //     fieldType: FormFieldTypes.SEPARATOR,
        //     style: {
        //         containerCss: "separation-line pl-0 pr-0",
        //     },
        // },
        {
            id: "CpdPoints",
            lable: "CPD Points",
            Field_Name: "CPD Points",
            fieldType: FormFieldTypes.INPUT,
            dataField: "CpdPoints",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 input-margin-space",
            },
        },
        {
            id: "remarks",
            lable: "Remarks",
            Field_Name: "Remarks",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "remarks",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                maxLength: 4000,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-12 col-12 input-margin-space",
            },
        },
        {
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line pl-0 pr-0",
            },
        },
        {
            id: "sgbcSection",
            lable: "",
            Field_Name: "sgbcSection",
            fieldType: FormFieldTypes.MAINFORMTITLE,
            dataField: "sgbcSection",
            fieldSetting: {
                formTitle: "SGBC Section",
            },
            style: {
                containerCss: "col-lg-12 mb-4 text-left",
            },
        },
        {
            id: "processedBy",
            lable: "Processed by",
            Field_Name: "Processed by",
            fieldType: FormFieldTypes.SELECT,
            dataField: "processedBy",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6  "
            }
        },
        {
            id: "cpdPointsAwarded",
            lable: "CPD Points Awarded",
            Field_Name: "CPD Points Awarded ",
            fieldType: FormFieldTypes.SELECT,
            dataField: "cpdPointsAwarded",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6"
            }
        },
        {
            id: "notes",
            lable: "Notes",
            Field_Name: "Notes",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "notes",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                maxLength: 4000,
            },
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mt-3",
            },
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}