
import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from '../../../utils/API/fetchBaseQuery';
import { transformSucessResponse, transformErrorResponse } from '../../../utils/API/responseMiddleware'
import { transformRequest } from "../../../utils/API/requestMiddleware";


const gmapPricingTableApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'GmapPricingTableApi',
    endpoints: (builder) => ({
        getGmapPricingTable: builder.query({
            query: () => ({
                url: '/GMAPAccount/GetMembershipPricing',
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        addEditMembershipPricing: builder.mutation({
            query: (details) => ({
                url: '/GMAPAccount/AddEditMembershipPricing',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

export const {
    useLazyGetGmapPricingTableQuery,
    useAddEditMembershipPricingMutation,
} = gmapPricingTableApi;

export default gmapPricingTableApi;