import { FormFieldTypes } from "../../../../data/formFieldType";
import { GridColumnType } from "../../../../data/gridColumnType";

const MonthlyXeroBatchFormData = {
    name: "Monthly Xero Batch FormData",
    initialState: {
        generateFromDate: null,
    },
    formFields: [
        {
            id: "generateFromDate",
            lable: "Generate from date",
            Field_Name: "Generate from date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "generateFromDate",
            fieldSetting: {

            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-5 mb-4"
            }
        },

    ]
}


export default MonthlyXeroBatchFormData;



export const MonthlyXeroBatchonfig = {
    columns: [
        {
            name: "Generate Date",
            fieldName: "dateOfGeneration",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD MMM YYYY',
            }
        },
        {
            name: "Report ID",
            fieldName: "batchNumber",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Period Start",
            fieldName: "startDate",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD/MM/YYYY',
            }
        },
        {
            name: "Period End",
            fieldName: "endDate",
            allowShort: false,
            colType: GridColumnType.DATE,
            colSettings: {
                format: 'DD/MM/YYYY',
            }
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: false,
                allowDelete: false
            },
            customAction: [
                {
                    name: "DOWNLOAD",
                    // iconName: AppIcons.copyIcon,
                    onViewAction: (data) => {
                        alert("called permissions");
                    },
                },
            ],
        },
    ],
};
