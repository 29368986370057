
import { createApi } from "@reduxjs/toolkit/query/react";
import { defautBaseQuery } from '../../../utils/API/fetchBaseQuery';
import { transformSucessResponse, transformErrorResponse } from '../../../utils/API/responseMiddleware'
import { transformRequest } from "../../../utils/API/requestMiddleware";
import { encryptQueryString } from "../../../utils/API/requestMiddleware";
import { encryptAES } from "../../../services/CryptoService";

const gmapApplicationTaskListApi = createApi({
    baseQuery: defautBaseQuery,
    reducerPath: 'GmapApplicationApi',
    endpoints: (builder) => ({
        gmapApplicationTaskListApi: builder.mutation({
            query: (details) => ({
                url: '/GMAPIndividualMember/GetApplicationTaskList',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        taskListDataSnapshot: builder.mutation({
            query: (details) => ({
                url: '/GMAPMembership/TaskListDataSnapshot',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        generatePDFForGMA: builder.query({
            query: (details) => ({
                url: (`/GMAPIndividualMember/GeneratePDFForGMA?id=${encryptAES(details.id)}&TaskListId=${encryptAES(details.taskListId)}`),
                method: 'GET',
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        generatePDFForGMAP: builder.query({
            query: (details) => ({
                url: (`/GMAPIndividualMember/GeneratePDFForGMAP?id=${encryptAES(details.id)}&TaskListId=${encryptAES(details.taskListId)}`),
                method: 'GET',
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        generatePDFForGMAAP: builder.query({
            query: (details) => ({
                url: (`/GMAPIndividualMember/GeneratePDFForGMAAP?id=${encryptAES(details.id)}&TaskListId=${encryptAES(details.taskListId)}`),
                method: 'GET',
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        downloadSheetForGMAPApplicationTaskList: builder.mutation({
            query: (details) => ({
                url: '/GMAPIndividualMember/DownloadSheetForGMAPApplicationTaskList',
                method: 'POST',
                body: transformRequest(details),
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getSubmittedOnPdfListById: builder.mutation({
            query: (id) => ({
                url: encryptQueryString(`/Dashboard/GetSubmittedOnPdfListById?id=${id}`),
                method: 'GET',
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
        getPDF: builder.query({
            query: (details) => ({
                url: `/Common/getPDF?type=${details.type}&fileName=${details.fileName}`,
                method: 'GET',
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

export const {
    useGmapApplicationTaskListApiMutation,
    useTaskListDataSnapshotMutation,
    useLazyGeneratePDFForGMAQuery,
    useLazyGeneratePDFForGMAPQuery,
    useLazyGeneratePDFForGMAAPQuery,
    useDownloadSheetForGMAPApplicationTaskListMutation,
    useGetSubmittedOnPdfListByIdMutation,
    useGetPDFQuery

} = gmapApplicationTaskListApi;

export default gmapApplicationTaskListApi;