import { GridColumnType } from "../../../../data/gridColumnType";


// ViewIndividual Member Config
export const ViewIndividualMemberConfig = {
  columns: [
    {
      name: "Applicant Name",
      fieldName: "name",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
      name: "Email Address",
      fieldName: "email",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
      name: "GMP Tier",
      fieldName: "applicationName",
      colType: GridColumnType.Text,

    },
    {
      name: "GMP Number",
      fieldName: "gmapNumber",
      colType: GridColumnType.Text,
    },
    {
      name: "Status",
      fieldName: "subscriptionStatusName",
      colType: GridColumnType.Text,
    },
    {
      name: "Action",
      colType: GridColumnType.ACTION,
      defaultAction: {
        allowEdit: false,
        allowDelete: false
      },
      customAction: [
        {

          name: "UPDATE",
          // iconName: AppIcons.copyIcon,
          onViewAction: (data) => {
            alert("called permissions");
          },
        },
      ],
    },
  ],
};
