import React, { useEffect, useRef } from 'react'
import MolGrid from '../../../../components/Grid/MolGrid'
import { uploadCPDConfig } from '../dataFile/UploadCpdSearchForm.Data';

const UploadCpdDataGrid = (props) => {
    const molGridRef = useRef();

    useEffect(() => { getCPDList() }, [])

    const getCPDList = (requestData) => {
        props.getUploadedCpdList({ ...props.paginationRequest, ...requestData })
    }

    // const actionHandler = {
    //     UPDATE: handleEditClick,
    // }
    const handlePageChange = ({ pageNumber, pageSize }) => {
        getCPDList({ ...props.paginationRequest, pageNo: pageNumber, pageSize: pageSize })
    }

  return (
    <div className='row mt-4'>
                <div className='col-lg-12'>
                    <MolGrid
                        ref={molGridRef}
                        configuration={uploadCPDConfig}
                        dataSource={props.dataSource}
                        allowPagination={true}
                        isLoading={props.isLoading}
                        pagination={props.paginationRequest}
                        onPageChange={handlePageChange}
                       // onActionChange={actionHandler}
                    />
                </div>
            </div>
  )
}

export default UploadCpdDataGrid
