import { defautBaseQuery } from "../../../utils/API/fetchBaseQuery";
import { encryptQueryString, transformRequest } from "../../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSucessResponse } from "../../../utils/API/responseMiddleware";
import { createApi } from "@reduxjs/toolkit/query/react";

const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: defautBaseQuery,
    endpoints: (builder) => ({
        userLogin: builder.mutation({
            query: (details) => ({
                url: '/Account/AdminLogin',
                method: 'POST',
                body: transformRequest(details)
            }),
            transformResponse: transformSucessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),

})

export const {
    useUserLoginMutation,
} = authApi;

export default authApi;