import React, { useEffect, useRef } from 'react'
import FormCreator from '../../../components/Forms/FormCreator';
import { useNavigate } from 'react-router-dom';
import { useAddEditEmailTemplateMutation, useLazyGetAllProjectTypeDropdownQuery } from '../../../app/services/FieldManagement/EmailTemplateManagement/EmailTemplateManagementApi';
import DataLoader from '../../../components/ui/dataLoader/DataLoader';
import Buttons from '../../../components/ui/button/Buttons';
import SwalAlert from '../../../services/swalService/SwalService';
import AddEmailTempData from './DataFiles/AddEmailTemplate.Data';

const AddNewEmailTemplate = () => {
    const addEmailTempRef = useRef();
    const navigate = useNavigate();

    const { success, error } = SwalAlert()

    const [getAllProjectTypeDropdown, {
        isSuccess: isSuccessDropdown,
        isFetching: isFetchingDropdown,
        data: dropdownResponse
    }] = useLazyGetAllProjectTypeDropdownQuery()

    const [addEditEmailTemplate, {
        isSuccess: isSuccessAddEditEmail,
        isFetching: isFetchingAddEditEmail,
        data: addEditEmailResponse
    }] = useAddEditEmailTemplateMutation()

    useEffect(() => {
        if (!isFetchingAddEditEmail && isSuccessAddEditEmail && addEditEmailResponse) {
            if (addEditEmailResponse.hasError === false) {
                success("Email Added Successfully");
                handleBack();
            }
            else {
                error(addEditEmailResponse.errorMessage)
            }
        }
    }, [isFetchingAddEditEmail, isSuccessAddEditEmail, addEditEmailResponse]);


    useEffect(() => {
        if (!isFetchingDropdown && isSuccessDropdown && dropdownResponse) {
            let field = AddEmailTempData?.formFields?.find(obj => obj.id === "project")
            field.fieldSetting.options = dropdownResponse?.map((obj) => ({ value: obj.id, label: obj.name }))
        }
    }, [isFetchingDropdown, isSuccessDropdown, dropdownResponse]);

    useEffect(() => {
        getAllProjectTypeDropdown();
    }, [])

    const handleaAdd = () => {
        const formData = addEmailTempRef.current.getFormData();
        const request = {
            emailTemplateId: 0,
            EmailName: formData.emailName,
            Subject: formData.subject,
            WhereUsed: formData.WhereUsed,
            ProjectTypeId: formData.projectType.value ? formData.projectType.value : formData.projectType,
            Description: formData.body,
            CreatedBy: 115,
            CreatedAppId: 1
        }

        addEditEmailTemplate(request)
    }

    const handleBack = () => { navigate('/emailTemplateManagement') }

    return (
        <>

            {dropdownResponse ?
                <div className='d-flex flex-wrap'>
                    <div className='row'>
                        <FormCreator
                            ref={addEmailTempRef}
                            {...AddEmailTempData}
                        >
                        </FormCreator>
                    </div>

                </div>
                :
                <DataLoader />
            }

            <div className='d-flex justify-content-between'>
                <div>
                    <Buttons
                        buttonText="Back"
                        buttonTypeClassName="outline-btn"
                        onClick={() => handleBack()}
                    />
                </div>
                <div>
                    <Buttons
                        buttonText="+ Add"
                        buttonTypeClassName="outline-btn"
                        onClick={() => handleaAdd()}
                    />
                </div>
            </div>
        </>
    )
}

export default AddNewEmailTemplate