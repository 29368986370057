import React, { useRef } from "react";
import MolGrid from "../../../components/Grid/MolGrid";
import { ViewResourcesGridConfig } from "../viewResourcesTableData";

const ViewResourcesTable = ({ ViewResourcesTableData }) => {
  const ViewResourcesTableRef = useRef();
  const actionHandler = {};
  return (
    <div>
      <MolGrid
        ref={ViewResourcesTableRef}
        configuration={ViewResourcesGridConfig}
        dataSource={ViewResourcesTableData}
        allowPagination={true}
        onActionHandler={actionHandler}
      />
    </div>
  );
};

export default ViewResourcesTable;
