import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { mastersDegreeDoctorProgrammesData } from './MastersDegreeDoctorProgrammes.Data';
import { useLazyGetPDFQuery, useUploadPDFMutation } from '../../../../../app/services/common/commonApi';
import { saveAs } from 'file-saver'
import FormCreator from '../../../../../components/Forms/FormCreator';
import Buttons from '../../../../../components/ui/button/Buttons';
import { useLazyGetMastersDegreeDoctorProgrammesByIdQuery } from '../../../../../app/services/CPD/CpdSubmissionApi';
import { CurrentCPDCycleDataContext } from '../../CpdSubmissionForm';
import DataLoader from '../../../../../components/ui/dataLoader/DataLoader';
import SwalAlert from '../../../../../services/swalService/SwalService';
import { useAddEditMastersDegreeProgrammesMutation } from '../../../../../app/services/CPD/SaveCPDSubmissionApi';


const MastersDegreeDoctorProgrammes = forwardRef((props, ref) => {
  const mastersDegreeDoctorProgrammesRef = useRef();
  const [mastersDegreeDoctorProgrammesForm, setMastersDegreeDoctorProgrammes] = useState(mastersDegreeDoctorProgrammesData);
  const { success, error } = SwalAlert();

  const { cpdSubmissionData, setCpdSubmissionData } = useContext(CurrentCPDCycleDataContext);
  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = mastersDegreeDoctorProgrammesRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));

  const [getPDF] = useLazyGetPDFQuery();

  const [uploadPDF,
    { isSuccess: isSuccess,
      isLoading: isLoading,
      data: fileUploadData }
  ] = useUploadPDFMutation();



  const [handleSubmitForm,
    { isSuccess: isSuccessForm,
      isLoading: isLoadingForm,
      data: formResponse }
  ] = useAddEditMastersDegreeProgrammesMutation();

  useEffect(() => {
    if (!isLoadingForm && isSuccessForm && formResponse) {
      success("Save successfuly.", "Sucessful");
      props.formActionHandler["SAVE_SUCCESS"]();
    }
  }, [isLoadingForm, isSuccessForm, formResponse]);

  const [
    getMastersDegreeDoctorProgrammesById,
    {
      isSuccess: getMastersDegreeDoctorProgrammesByIdIsSuccess,
      isLoading: getMastersDegreeDoctorProgrammesByIdIsLoading,
      data: getMastersDegreeDoctorProgrammesByIdData,
    }
  ] = useLazyGetMastersDegreeDoctorProgrammesByIdQuery();

  useEffect(() => {
    if (getMastersDegreeDoctorProgrammesByIdIsSuccess && !getMastersDegreeDoctorProgrammesByIdIsLoading && getMastersDegreeDoctorProgrammesByIdData) {
      console.log({ getMastersDegreeDoctorProgrammesByIdData });
      let temp = { ...mastersDegreeDoctorProgrammesForm }
      let tempInitials = temp.initialState;
      tempInitials.titleofDegreeProgramme = getMastersDegreeDoctorProgrammesByIdData.titleofDegreeProgramme;
      tempInitials.institutionofferingtheMasters = getMastersDegreeDoctorProgrammesByIdData.institutionofferingtheMasters;
      tempInitials.degreeCertificate = getMastersDegreeDoctorProgrammesByIdData.degreeCertificate;
      tempInitials.dateofConferment = getMastersDegreeDoctorProgrammesByIdData.dateofConferment;
      tempInitials.CpdPoints = getMastersDegreeDoctorProgrammesByIdData.cpdPoints;
      tempInitials.remarks = getMastersDegreeDoctorProgrammesByIdData.remarks;
      tempInitials.processedBy = getMastersDegreeDoctorProgrammesByIdData.cpdReceivedById;
      tempInitials.cpdPointsAwarded = getMastersDegreeDoctorProgrammesByIdData.cpdPointsAwardedId;
      setMastersDegreeDoctorProgrammes({ ...mastersDegreeDoctorProgrammesForm, ...temp });
    }
  }, [getMastersDegreeDoctorProgrammesByIdIsSuccess, getMastersDegreeDoctorProgrammesByIdIsLoading, getMastersDegreeDoctorProgrammesByIdData]);

  useEffect(() => {
    getMastersDegreeDoctorProgrammesById(cpdSubmissionData.cpdSubmissionId);
  }, []);

  const handleDownload = async (data, dataField) => {
    const request = {
      type: "DegreeCertificateFile",
      fileName: fileUploadData?.fileName ? fileUploadData?.fileName : getMastersDegreeDoctorProgrammesByIdData?.degreeCertificate,
    }
    const response = await getPDF(request);
    saveAs(response?.data?.fileData, response?.data?.fileName);
  }


  const formActionHandler = {
    DOWNLOAD: handleDownload,
  };

  const handleFormFieldChange = (dataField, value) => {
    if (dataField === "degreeCertificate" && value) {
      handleOnChangeFileUpload(value)
    }
  };

  const handleOnChangeFileUpload = async (data) => {
    let formData = new FormData();
    formData.append("file", data)
    formData.append("storagePath", "DegreeCertificateFile");
    uploadPDF(formData);
  }


  const handleSaveButton = () => {
    const formData = mastersDegreeDoctorProgrammesRef.current.getFormData();
    if (!formData) {
      return error("Please fill all mandatory fields.", "")
    }
    let request = {
      CPDMastersDegreeId: getMastersDegreeDoctorProgrammesByIdData.cpdMastersDegreeId,
      GMAPMemberId: getMastersDegreeDoctorProgrammesByIdData.gmapMemberId,
      CPDPoints: formData.CpdPoints,
      Remarks: formData?.remarks,
      CPDActivityTypeId: getMastersDegreeDoctorProgrammesByIdData.cpdActivityTypeId,
      TitleOfDegreeProgramme: formData?.titleofDegreeProgramme,
      InstitutionOfferingtheMasters: formData?.institutionofferingtheMasters || null,
      DateOfConferment: formData?.dateofConferment,
      DegreeCertificate: fileUploadData?.fileName ? fileUploadData?.fileName : getMastersDegreeDoctorProgrammesByIdData.degreeCertificate,
      CPDSubmissionId: getMastersDegreeDoctorProgrammesByIdData.cpdSubmissionId,
      CreatedBy: 115,
      CreatedAppId: 1,
      CPDReceivedById: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      CPDPointsAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
    }
    handleSubmitForm(request)
  };

  const handleRejectbutton = () => {
    const request = {
      cpdSubmissionId: getMastersDegreeDoctorProgrammesByIdData.cpdSubmissionId,
      gmapMemberId: getMastersDegreeDoctorProgrammesByIdData.gmapMemberId,
      isRejected: true,
      rejectCPDSubmissionReason: null,
    }
    if (props.formActionHandler) {
      props.formActionHandler['REJECT'](request);
    }
  };


  const handleApproveButton = () => {
    const formData = mastersDegreeDoctorProgrammesRef.current.getFormData();
    if (!formData) return;
    const request = {
      cpdSubmissionId: getMastersDegreeDoctorProgrammesByIdData.cpdSubmissionId,
      gmapMemberId: getMastersDegreeDoctorProgrammesByIdData.gmapMemberId,
      ProcessedId: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      cpdPointAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
      isApprove: true,
      Notes: formData?.notes || null
    }
    if (props.formActionHandler) {
      props.formActionHandler['APPROVE'](request);
    }
  };

  return (
    <React.Fragment>
      {getMastersDegreeDoctorProgrammesByIdData ?
        <div className="speaking-teaching-engagement">
          {/* <div className="cpd-submission-description">
          <p>Masters Degree/ Doctorate(PhD) programme must relate to green building or sustainability of the built environment and conferred by an accredited local university or institution. </p>
          <p>
            The programme must be completed (with documentary proof or degree awarded) within 1 year of the renewal deadline. (e.g. For renewal of certification due by 30 June 2022, date of completion must not be earlier than 30 June 2021)
          </p>
          <p>6 CPD points will be awarded for the completion of relevant programmes.</p>
        </div> */}

          <div className="common-project-info-main card-section card-section-left">
            <div className="row gx-5">
              <FormCreator ref={mastersDegreeDoctorProgrammesRef} {...mastersDegreeDoctorProgrammesForm} onActionChange={formActionHandler} onFormFieldValueChange={handleFormFieldChange} />

            </div>
          </div>
          <div className='row'>
            <div className='common-custom-pagination mt-5'>
              <div>
                <Buttons
                  buttonText="Save"
                  buttonTypeClassName="outline-btn"
                  onClick={handleSaveButton}
                  isDisable={props?.isPastCPD}
                />
              </div>
              <div className='d-flex column-gap-3'>
                <Buttons
                  buttonText="Reject"
                  buttonTypeClassName="red-btn"
                  onClick={handleRejectbutton}
                  isDisable={props?.isPastCPD}

                />
                <Buttons
                  buttonText="Approve"
                  buttonTypeClassName="success-btn"
                  onClick={handleApproveButton}
                  isDisable={props?.isPastCPD}
                  isLoading={cpdSubmissionData?.approveIsLoading}
                />
              </div>
            </div>
          </div>
          <div className='center-button'>
            {/* <div className='col-lg-12 d-flex justify-content-center'>
            <Buttons
              buttonText="Submit"
              buttonTypeClassName="success-btn mx-4"
            // onClick={handleSubmit}
            />
          </div> */}
          </div>

        </div>
        : <DataLoader />}
    </React.Fragment>
  );
})

export default MastersDegreeDoctorProgrammes;
