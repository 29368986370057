import { GridColumnType } from "../../../../data/gridColumnType";

export const EmailTemplateDataConfig = {
    columns: [
        {
            name: "Email Name",
            fieldName: "emailName",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Email Subject",
            fieldName: "subject",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Email Project",
            fieldName: "projectName",
            allowShort: false,
            colType: GridColumnType.Text,

        },
        {
            name: "Email Where Used",
            fieldName: "whereUsed",
            allowShort: false,
            colType: GridColumnType.Text,
        },
        {
            name: "Action",
            colType: GridColumnType.ACTION,
            defaultAction: {
                allowEdit: true,
                allowDelete: true
            }
        },
    ],
};