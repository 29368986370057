import React, { useEffect, useRef, useState } from 'react'
import FormCreator from '../../../components/Forms/FormCreator'
import SearchFilterFormData from './DataFiles/SearchFilterForm.Data';
import { useLazyGetAllProjectTypeDropdownQuery } from '../../../app/services/FieldManagement/EmailTemplateManagement/EmailTemplateManagementApi';
import Buttons from '../../../components/ui/button/Buttons';
import Input from '../../../components/ui/inputs/input/Input';
import Label from '../../../components/ui/label/Label';
import { useNavigate } from 'react-router-dom';

const SearchFilterForm = ({ getEmailList }) => {
    const SearchFilterFormRef = useRef();
    const navigate = useNavigate()
    const [searchRequest, setSearchRequest] = useState({
        ProjectTypeId: null,
        SearchText: null,
    });

    const [getAllProjectTypeDropdown, {
        isSuccess: isSuccessDropdown,
        isFetching: isFetchingDropdown,
        data: dropdownResponse
    }] = useLazyGetAllProjectTypeDropdownQuery()

    useEffect(() => {
        getAllProjectTypeDropdown();
    }, [])

    useEffect(() => {
        if (!isFetchingDropdown && isSuccessDropdown && dropdownResponse) {
            let field = SearchFilterFormData.formFields.find(obj => obj.id === "ProjectTypeId")
            field.fieldSetting.options = dropdownResponse.map((obj) => ({ value: obj.id, label: obj.name }))
        }
    }, [isFetchingDropdown, isSuccessDropdown, dropdownResponse]);

    const handleaAddNew = () => {
        navigate("/emailTemplateManagement/addEmailTemplate")
    }

    const onDropDownChange = (data) => {
        const { value } = data.ProjectTypeId
        let temp = { ...searchRequest }
        temp["ProjectTypeId"] = value
        setSearchRequest(temp)
        getEmailList(temp);
    }
    const handleInputChange = (e) => {
        let temp = { ...searchRequest }
        temp["SearchText"] = e.target.value
        setSearchRequest(temp)
        getEmailList(temp);
    }

    return (
        <div>



            {dropdownResponse &&
                <div className='row'>
                    <FormCreator
                        ref={SearchFilterFormRef}
                        {...SearchFilterFormData}
                        onFormDataChange={onDropDownChange}
                    >
                    </FormCreator>
                </div>
            }
            <div className='d-flex flex-wrap justify-content-between'>
                <div>
                    <Buttons
                        buttonText="+ Add New"
                        buttonTypeClassName="outline-btn"
                        onClick={() => handleaAddNew()}
                    />
                </div>

                <div className='row'>
                    <div className='col-lg-3 mb-3 my-3 px-2'>
                        <Label labelName="Search:" />
                    </div>
                    <div className='col-lg-9 mb-3 my-2'>
                        <Input
                            id="searchText"
                            name='SearchText'
                            value={searchRequest.SearchText}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SearchFilterForm