import { FormFieldTypes } from "../../../../data/formFieldType";

export const RejectReasonData = {
    initialState: {
        rejectReason: "",
    },
    formFields: [
        {
            id: "rejectReason",
            lable: "",
            Field_Name: "",
            fieldType: FormFieldTypes.SUNEDITOR,
            dataField: "rejectReason",
            fieldSetting: {
                placeholder: "",
                options: [],
                value: "Invalid PDF.",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12"
            }
        }
    ],
    formSetting: {
        isViewOnly: false
    }
}