/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useLazyGetAllDropDownsForMemberDirectoryQuery } from "../../../app/services/common/commonApi";
import FormCreator from "../../../components/Forms/FormCreator";
import { memberDirectoryData } from "./MemberDirectoryForm.Data";

const MemberDirectoryForm = forwardRef((props, ref) => {
    const memberDirectoryref = useRef();
    const [formData, setFormData] = useState(memberDirectoryData);

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            let memberDirectoryInfo = memberDirectoryref.current.getFormData();

            if (ref?.current && memberDirectoryInfo) {
                return memberDirectoryInfo;
            }
            // return null;
        },
    }));

    return (
        <FormCreator ref={memberDirectoryref} {...formData} />
    )

})

export default MemberDirectoryForm