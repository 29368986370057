import React, { useRef } from 'react'
import ckEditorFormData from './CKEditorForm.Data'
import FormCreator from '../../components/Forms/FormCreator';
import Buttons from '../../components/ui/button/Buttons';

const CKEditorForm = () => {
    const testRef = useRef();
    const handleSubmit = () => {
        const formData = testRef.current.getFormData();
        if (formData) {
            console.log(formData?.test)
        }
    }
    return (
        <div className='container'>
            <div className='row'>
                <FormCreator
                    ref={testRef}
                    {...ckEditorFormData}
                />
            </div>
            <div className='col-lg-12'>
                <Buttons
                    buttonText="Submit"
                    buttonTypeClassName="outline-btn"
                    onClick={() => handleSubmit()}
                />
            </div>
        </div>
    )
}

export default CKEditorForm