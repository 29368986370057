import React, { useEffect, useRef, useState } from 'react'
import ApproveCpdSubmissionTable from './ApproveCpdSubmissionTable'
import Buttons from '../../../components/ui/button/Buttons'
import FormCreator from '../../../components/Forms/FormCreator'
import { ApproveCpdSubmissionData } from './component/ApproveCpdSubmissionData'
import { useLazyGetAllDropDownsForMemberDirectoryQuery } from '../../../app/services/common/commonApi'
import { bcaGreenMarkProjectData } from '../cpdSubmission/AllActivityComponent/BcaGreenMarkProject/BcaGreenMarkProject.Data'
import { cpdprogrammesbyOverseasgreenbuildingCouncilsData } from '../cpdSubmission/AllActivityComponent/CpdprogrammesbyOverseasgreenbuildingCouncils/CpdprogrammesbyOverseasgreenbuildingCouncils.Data'
import { industryTechnicalCommitteesData } from '../cpdSubmission/AllActivityComponent/IndustryTechnicalCommittees/IndustryTechnicalCommittees.Data'
import { mastersDegreeDoctorProgrammesData } from '../cpdSubmission/AllActivityComponent/MastersDegreeDoctorProgrammes/MastersDegreeDoctorProgrammes.Data'
import { publicationofResearchPaperData } from '../cpdSubmission/AllActivityComponent/PublicationofResearchPaper/PublicationofResearchPaper.Data'
import { accreditedCpdEventsData } from '../cpdSubmission/AllActivityComponent/SGBCAccreditedCpdEvents/SGBCAccreditedCpdEvents.Data'
import { eLearningData } from '../cpdSubmission/AllActivityComponent/SgbcElearning/SgbcElearning.Data'
import { speakingTeachingEngagementData } from '../cpdSubmission/AllActivityComponent/SpeakingTeachingEngagement/SpeakingTeachingEngagement.Data'
import DataLoader from '../../../components/ui/dataLoader/DataLoader'


const initState = {
  pageNo: 1,
  pageSize: 10,
  endPage: 100,
  SearchText: "",
  gmapNumber: "",
  submissionStartDate: "",
  submissionEndDate: "",
  totalCount: 0,
  StatusId: null
}

const ApproveCpdSubmissionForm = () => {
  const ApproveCpdSubmissionFormRef = useRef();
  const [approveCpdSubmissionForm] = useState(ApproveCpdSubmissionData);
  const [paginationRequest, setPaginationRequest] = useState(initState);

  const handleSearchApplicationTask = () => {
    if (!ApproveCpdSubmissionFormRef.current) {
      return;
    }

    const cpdSearchData = ApproveCpdSubmissionFormRef.current.getFormData();
    const updatedPaginationRequest = {
      pageNo: 1,
      pageSize: 10,
      endPage: 100,
      SearchText: cpdSearchData.name,
      gmapNumber: cpdSearchData.gmapNumber,
      StatusId: cpdSearchData.cpdStatusFilter?.value || null,
      submissionStartDate: cpdSearchData.submissionDate,
      submissionEndDate: cpdSearchData.submissionDateEnd,
    };
    setPaginationRequest(updatedPaginationRequest);
  };


  const [getAllDropdownData, {
    isLoading: getDropdownDataIsLoading,
    data: getDropdownData,
    isSuccess: getAllDropdownDataIsSuccess,
  }] = useLazyGetAllDropDownsForMemberDirectoryQuery()

  useEffect(() => {
    if (!getDropdownDataIsLoading && getDropdownData && getAllDropdownDataIsSuccess) {
      setAllDropDownField()
    }
  }, [getDropdownDataIsLoading, getDropdownData, getAllDropdownDataIsSuccess])

  const filterDropDownList = (dropdownName) => {
    return getDropdownData.reduce((acc, item) => {
      if (item.dropdownName === dropdownName) {
        acc.push({
          value: item.id,
          label: item.name
        });
      }
      return acc;
    }, []);
  }

  const setAllDropDownField = () => {
    const dropdownLists = {
      "cpdStatusFilter": filterDropDownList("CPD Status"),
    };

    const formFields = [
      ...ApproveCpdSubmissionData.formFields
    ];

    formFields.forEach(field => {
      const dropdownList = dropdownLists[field.id];
      if (dropdownList) {
        field.fieldSetting.options = dropdownList;
      }
    });
  }
  useEffect(() => {
    getAllDropdownData()
  }, [])


  return (
    <>
      <div className="row align-items-end">
        <div className="col-10">
          <div className="row gx-5">
            {getDropdownDataIsLoading ?
              <DataLoader /> : <FormCreator
                ref={ApproveCpdSubmissionFormRef}
                {...approveCpdSubmissionForm}
              // onActionChange={formActionHandler}
              />
            }
          </div>
        </div>
        <div className="col-2 d-flex justify-content-end">
          <Buttons
            buttonTypeClassName="success-btn"
            buttonText="Search"
            onClick={handleSearchApplicationTask}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <div className="table-responsive green-head-table">
            <ApproveCpdSubmissionTable paginationReq={paginationRequest} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ApproveCpdSubmissionForm