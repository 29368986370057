import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'
import CardSection from '../../../../components/ui/card/CardSection';
import { PricingTypeFormData } from './data/PricingTypeForm.Data';
import BasicDetailContext from '../../utils/BasicDetailContext';
import FormCreator from '../../../../components/Forms/FormCreator';
import { AdditionalGuestFormData } from './data/AdditionalGuestForm.Data';

const Pricing = forwardRef((props, ref) => {
    const priceTypeRef = useRef();
    const priceDetailsRef = useRef();
    const additionGuestRef = useRef();
    const additionalAddOnsRef = useRef();
    const { isPricingApplicable, setIsPricingApplicable } = useContext(BasicDetailContext);
    const [pricingTypeForm, setPricingTypeForm] = useState(PricingTypeFormData);
    const [AdditionalGuestForm, setAdditionalGuestForm] = useState(AdditionalGuestFormData);

    const handleFormFieldChange = (fieldName, fieldValue) => {
        switch (fieldName) {
            case "isPriceApplicable":
                setIsPricingApplicable(fieldValue);
                break;
            default:
                return false;
        }
    };

    return (
        <React.Fragment>
            <CardSection cardTitle="Pricing">
                <div className='row'>
                    <FormCreator ref={priceTypeRef} {...pricingTypeForm} onFormFieldValueChange={handleFormFieldChange} />
                </div>
            </CardSection>
            <CardSection cardTitle="Pricing Details (Enter Price Excluding GST)">
                <div className='row'>
                </div>
            </CardSection>
            <CardSection cardTitle="Additional Guest/Attendee">
                <div className='row'>
                    <FormCreator ref={additionGuestRef} {...AdditionalGuestForm} onFormFieldValueChange={handleFormFieldChange} />
                </div>
            </CardSection>
            <CardSection cardTitle="Additional Add-Ons">
                <div className='row'>
                </div>
            </CardSection>
        </React.Fragment>
    )
})

export default Pricing