import React, { Fragment, forwardRef, useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import CardSection from "../../../components/ui/card/CardSection";
import Buttons from "../../../components/ui/button/Buttons";
import { useLazyGetAllDropDownsForMemberDirectoryQuery } from "../../../app/services/common/commonApi";
import PersonalParticularsForm from "./component/personalParticulars/PersonalParticularsForm";
import { CreateIndividualMembersContext } from "../../../pages/gmap/viewIndividualMembers/createIndividualMembers";
import { setAllDropDownField } from "../createIndividualMemberForm/component/utils/dropdownUtils";
import GMapQualificationForm from "./component/GMAPQualification/GMAPQualification";
import AcademicQualification from "./component/AcademicQualification/AcademicQualification";
import CareerHistory from "./component/CareerHistory/CareerHistory";
import CertificateCourse from "./component/CertificateCourse/CertificateCourse";
import GreenMarkProject from "./component/GreenMarkProject/GreenMarkProject";
import { certificateCourseData } from "./component/CertificateCourse/CertificateCourse.Data";
import { greenMarkProjectData } from "./component/GreenMarkProject/GreenMarkProject.Data";
import AccountInformation from "./component/AccountInformation/AccountInformation";
import ContinuingProfessionalDevelopment from "./component/ContinuingProfessionalDevelopment/ContinuingProfessionalDevelopment";
import GmapMembership from "./component/GmapMembership/GmapMembership";
import { useNavigate } from "react-router-dom";

const CreateIndividualForm = () => {
    const navigate = useNavigate();
    const [newMemberData, setNewMemberData] = useState({
        gMAPMemberID: 0,
        applicationTypeId: null,
    });
    const [accordionItems, setAccordionItems] = useState();

    const contextValue = {
        newMemberData,
        setContextData: (data) =>
            setNewMemberData(data)

    }
    const [getAllDropDowns,
        { isSuccess: isGetAllDropDownFetched,
            isFetching: isGetDropDownFetching,
            data: allDropDownResponse },
    ] = useLazyGetAllDropDownsForMemberDirectoryQuery();

    useEffect(() => {
        setAccordionItems(getTabs());
        getAllDropDowns();
    }, [])

    useEffect(() => {
        if (!isGetDropDownFetching && isGetAllDropDownFetched && allDropDownResponse) {
            setAllDropDownField(allDropDownResponse);
        }
    }, [isGetDropDownFetching, isGetAllDropDownFetched, allDropDownResponse]);

    const handleDropdownChange = (data, dataField) => {
        if (dataField !== "applicationTypeID") return;
        const { value } = data;
        updateTabsAndRemovePathways(value);
    }
    const updateTabsAndRemovePathways = (value) => {

        switch (value) {
            case 1:
                setAccordionItems(getTabs().filter(obj => obj.id !== 5));
                break;
            case 2:
                setAccordionItems(getTabs());
                break;
            case 3:
                setAccordionItems(getTabs());
                break;
        }
        removeGMAPathWays(value);
        // setCourseRequirementGrid(value);
    }
    const removeGMAPathWays = (value) => {
        const pathWayField = certificateCourseData.formFields.find((item) => item.id === "gmaPathwayTypeID");
        const gmapCertificateTypeField = certificateCourseData.formFields.find((item) => item.id === "gmapCertificateTypeId");
        const gmaapCertificateTypeField = certificateCourseData.formFields.find((item) => item.id === "gmaapCertificateTypeId");
        const gmapPathwayTypeIDField = greenMarkProjectData.formFields.find((item) => item.id === "gmapPathwayTypeID");
        const gmaapPathwayTypeIDField = greenMarkProjectData.formFields.find((item) => item.id === "gmaapPathwayTypeID");
        const defaultCss = "col-md-4 my-4";
        const hiddenCss = "col-md-4 mb-2 d-none";
        //hide and show fields  
        pathWayField.style.containerCss = (value === 1) ? defaultCss : hiddenCss;
        gmapCertificateTypeField.style.containerCss = (value === 2) ? "col-md-6 mb-2" : hiddenCss;
        gmaapCertificateTypeField.style.containerCss = (value === 3) ? "col-md-6 mb-2" : hiddenCss;
        gmapPathwayTypeIDField.style.containerCss = (value === 2) ? defaultCss : hiddenCss;
        gmaapPathwayTypeIDField.style.containerCss = (value === 3) ? defaultCss : hiddenCss;

        //add and remove validation on fields
        pathWayField.validation = (value === 1) ? [{ type: "require" }] : [];
        gmapCertificateTypeField.validation = (value === 2) ? [{ type: "require" }] : [];
        gmaapCertificateTypeField.validation = (value === 3) ? [{ type: "require" }] : [];
        gmapPathwayTypeIDField.validation = (value === 2) ? [{ type: "require" }] : [];
        gmaapPathwayTypeIDField.validation = (value === 3) ? [{ type: "require" }] : [];
    }
    const getTabs = () => [
        { id: 0, header: "GMAP Qualification", component: <GMapQualificationForm handleDropdownChange={handleDropdownChange} />, locked: false },
        { id: 1, header: "Personal Particulars", component: <PersonalParticularsForm />, locked: false },
        { id: 2, header: "Academic Qualification", component: <AcademicQualification />, locked: false },
        { id: 3, header: "Career History", component: <CareerHistory />, locked: false },
        { id: 4, header: "Certification Course/ Green Mark Refresher Course", component: <CertificateCourse />, locked: false },
        { id: 5, header: "Green Mark Project Information", component: <GreenMarkProject />, locked: false },
        { id: 6, header: "GMAP Membership", component: <GmapMembership />, locked: false },
        { id: 7, header: "Continuing Professional Development", component: <ContinuingProfessionalDevelopment />, locked: false }
    ];
    const handleBack = () => {
        navigate(`/viewIndividualMembers`)
    }
    const tabs = newMemberData.applicationTypeId === 1 ? accordionItems?.filter(obj => obj.id !== 5) : accordionItems;

    return (
        <>
            <CardSection cardTitle="Account Information">
                <div className="row align-items-end mb-2">
                    <div className="col-10">
                        <AccountInformation />
                    </div>
                </div>
            </CardSection>
            <div className="common-accordian-view-design">
                <Accordion alwaysOpen>
                    {tabs?.map((item, index) => (
                        <Accordion.Item key={index} eventKey={index.toString()}>
                            <Accordion.Header>
                                {item.header}
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="row">
                                    <CreateIndividualMembersContext.Provider value={contextValue}>
                                        {item.component}
                                    </CreateIndividualMembersContext.Provider>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
                <div className="common-custom-pagination">
                    <Buttons
                        buttonText="Back"
                        buttonTypeClassName="back-btn"
                        onClick={handleBack}
                    />
                    <Buttons
                        buttonText="Save"
                        buttonTypeClassName="outline-btn"
                        isLoading={false}
                    // onClick={handleUpdate}
                    />
                </div>
            </div >
        </>
    );
};

export default forwardRef(CreateIndividualForm);
