/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useRef, useState, useContext } from "react";
import { greenMarkProjectData } from "./GreenMarkProject.Data";
import GreenMarkProjectForm from "./component/GreenMarkProjectForm";
import { saveAs } from 'file-saver'
import Buttons from "../../../../../components/ui/button/Buttons";
import SwalAlert from "../../../../../services/swalService/SwalService";
import { useLazyGetPDFQuery, useUploadPDFMutation } from "../../../../../app/services/common/commonApi";
import { useAddEditGMAPGreenMarkProjectMutation, useDeleteGMAPGreenMarkProjectMutation, useLazyGetGMAPGreenMarkProjectListQuery } from "../../../../../app/services/GMAP/GreenMarkProjectApi";
import ToastService from "../../../../../services/toastService/ToastService";
import { CreateIndividualMembersContext } from "../../../../../pages/gmap/viewIndividualMembers/createIndividualMembers";
import { APIUrl } from "../../../../../utils/API/fetchBaseQuery";

const GreenMarkProject = forwardRef((props, ref) => {
    const { newMemberData, setContextData } = useContext(CreateIndividualMembersContext);
    const greenMarkProjectRef = useRef();
    const [greenMarkProjectFormData, SetGreenMarkProjectFormData] = useState(greenMarkProjectData.initialState);
    const [isEdit, setIsEdit] = useState(false);
    const [gMProjectList, setGMProjectList] = useState();
    const { confirm, error } = SwalAlert();
    const [uploads, setUploads] = useState({
        greenMarkProjectLetterOfAward: null,
        clientLetterOfEndorsement: null,
        greenMarkProjectJobDesc: null,
        greenMarkProjectSignificantFile: null
    });

    const [getPDF] = useLazyGetPDFQuery();

    const [addEditGMProject,
        { isSuccess: isAddEditSuccess,
            data: addEditGMProjectData }
    ] = useAddEditGMAPGreenMarkProjectMutation()

    const [getGMProjectList,
        { isSuccess: isListFetched,
            isFetching: isListFetching,
            data: gMProjectListData },
    ] = useLazyGetGMAPGreenMarkProjectListQuery();

    const [deleteGMProject,
        { isSuccess: isDeleteSuccess,
            data: deleteGMProjectData },
    ] = useDeleteGMAPGreenMarkProjectMutation();

    const [uploadPDF] = useUploadPDFMutation();


    useEffect(() => {
        if (isAddEditSuccess && addEditGMProjectData) {
            ToastService.success("Add Item successfully")
            const data = responceValid(addEditGMProjectData);
            if (data) {
                getGMProjectDataList();
            }
        }
    }, [isAddEditSuccess, addEditGMProjectData]);

    useEffect(() => {
        if (isDeleteSuccess && deleteGMProjectData) {
            ToastService.error("Delete Item successfully");
            getGMProjectDataList();
        }
    }, [isDeleteSuccess && deleteGMProjectData])

    useEffect(() => {
        if (!isListFetching && isListFetched && gMProjectListData) {
            let tempGMProjectListData = gMProjectListData
            tempGMProjectListData = tempGMProjectListData.map((obj) => {
                return {
                    ...obj,
                    clientLetterOfEndorsementLink: `${APIUrl}/Common/getPDF?type=LetterofEndorsement&fileName=${obj.clientLetterOfEndorsement}`,
                    projectLetterOfAwardLink: `${APIUrl}/Common/getPDF?type=GreenMarkProjectLetterOfAward&fileName=${obj.greenMarkProjectLetterOfAward}`
                }
            })
            if (newMemberData.applicationTypeID === 3) {
                tempGMProjectListData = tempGMProjectListData.map((obj) => {
                    return {
                        ...obj,
                        proofofJobDescriptionLink: `${APIUrl}/Common/getPDF?type=ProofOfJobDesc&fileName=${obj.proofofJobDescription}`,
                        proofofsignificantinvolvementLink: `${APIUrl}/Common/getPDF?type=ProofOfSignificantInvolvement&fileName=${obj.proofofsignificantinvolvement}`
                    }
                })
            }
            const updatedContext = { ...newMemberData, greenMarkProjectListData: gMProjectListData };
            setContextData(updatedContext);
            setGMProjectList(tempGMProjectListData);
            const tableIndex = greenMarkProjectData.formFields.findIndex(field => field.id === "greenMarkProjectTable");
            if (tableIndex !== -1) {
                const updatedFormData = { ...greenMarkProjectData };
                updatedFormData.formFields[tableIndex].fieldSetting.dataSource = tempGMProjectListData;
            }

        }
    }, [isListFetching, isListFetched, gMProjectListData])

    const getGMProjectDataList = async () => {
        let request = [newMemberData.gMAPMemberID]
        const response = await getGMProjectList(request);
        if (response.data?.length > 0) {
            const updatedContext = { ...newMemberData, greenMarkProject: 100 };
            setContextData(updatedContext);
        }
    }
    const responceValid = (responce) => {
        if (responce !== null) {
            const data = responce;
            if (data.errorMessage === "") {
                return data;
            }
            else {
                error("api response not valid ", "Error")
            }
        }
    }
    const handleAdd = () => {
        const projectDetails = greenMarkProjectRef.current.getFormData();
        if (projectDetails) {
            const gmapPathway = projectDetails?.gmapPathwayTypeID.value || projectDetails?.gmapPathwayTypeID;
            const gmaapPathway = projectDetails?.gmaapPathwayTypeID.value || projectDetails?.gmaapPathwayTypeID;
            let gmapAwardRating = projectDetails?.gmapAwardRatingId;
            let gmaapAwardRating = projectDetails?.gmaapAwardRatingP1Id;
            let gmapGreenMarkCategory;
            let gmaapGreenMarkCategory;
            if (parseInt(gmapPathway) === 1 || parseInt(gmaapPathway) === 1) {
                gmapAwardRating = projectDetails?.gmapAwardRatingId;
                gmaapAwardRating = projectDetails?.gmaapAwardRatingP1Id;
                gmapGreenMarkCategory = projectDetails?.gmapGreenMarkCategoryP1Id;
                gmaapGreenMarkCategory = projectDetails?.gmaapGreenMarkCategoryP1Id;
            }
            else if (parseInt(gmapPathway) === 2 || parseInt(gmaapPathway) === 2) {

                gmapAwardRating = projectDetails?.gmapAwardRatingId;
                gmaapAwardRating = projectDetails?.gmaapAwardRatingP2Id;
                gmapGreenMarkCategory = projectDetails?.gmapGreenMarkCategoryP2Id;

                gmaapGreenMarkCategory = projectDetails?.gmaapGreenMarkCategoryP2Id;
            }
            else if (parseInt(gmaapPathway) === 3) {
                gmaapAwardRating = projectDetails?.gmaapAwardRatingP3Id;
                gmaapGreenMarkCategory = projectDetails?.gmaapGreenMarkCategoryP3Id;
            }

            const projectObjReq = {
                gmapPathwayTypeID: gmapPathway?.value || gmapPathway,
                gmaapPathwayTypeID: gmaapPathway?.value || gmaapPathway,
                projectName: projectDetails.projectName,
                dateOfLetterOfAward: projectDetails.dateOfLetterOfAward,
                gmapAwardRatingId: gmapAwardRating?.value || gmapAwardRating,
                gmaapAwardRatingId: gmaapAwardRating?.value || gmaapAwardRating,
                gmapGreenMarkCategoryId: gmapGreenMarkCategory?.value || gmapGreenMarkCategory,
                gmaapGreenMarkCategoryId: gmaapGreenMarkCategory?.value || gmaapGreenMarkCategory,
                developerOrbuildingOwner: projectDetails.developerOrbuildingOwner,
                describeRoleInProject: projectDetails.describeRoleInProject,
                greenMarkProjectLetterOfAward: uploads.greenMarkProjectLetterOfAward,
                clientLetterOfEndorsement: uploads.clientLetterOfEndorsement,
                ProofofJobDescription: uploads.greenMarkProjectJobDesc,
                Proofofsignificantinvolvement: uploads.greenMarkProjectSignificantFile,
                AppID: 114,
                gMAPMemberId: newMemberData.gMAPMemberID,
            };


            if (!projectDetails.gmapGreenMarkProjectId && !isEdit) {
                addEditGMProject(projectObjReq);
            } else if (projectDetails.gmapGreenMarkProjectId && isEdit) {
                projectObjReq.gmapGreenMarkProjectId = projectDetails.gmapGreenMarkProjectId;
                addEditGMProject(projectObjReq);
                resetForm();
            }
        }
    }

    const handleTableEdit = (data) => {
        setIsEdit(true);
        let EditFormData = {};
        EditFormData.gmaapAwardRatingP1Id = data.gmaapAwardRatingId;
        EditFormData.gmaapAwardRatingP2Id = data.gmaapAwardRatingId;
        EditFormData.gmaapAwardRatingP3Id = data.gmaapAwardRatingId;
        EditFormData.gmapGreenMarkCategoryP1Id = data.gmapGreenMarkCategoryId;
        EditFormData.gmapGreenMarkCategoryP2Id = data.gmapGreenMarkCategoryId;
        EditFormData.gmaapGreenMarkCategoryP1Id = data.gmaapGreenMarkCategoryId;
        EditFormData.gmaapGreenMarkCategoryP2Id = data.gmaapGreenMarkCategoryId;
        EditFormData.gmaapGreenMarkCategoryP3Id = data.gmaapGreenMarkCategoryId;
        EditFormData.greenMarkProjectJobDesc = data.proofofJobDescription;
        EditFormData.greenMarkProjectSignificantFile = data.proofofsignificantinvolvement;

        let mergedData = {
            ...data, // Copy existing properties from data
            // Assign properties from EditFormData directly
            gmaapAwardRatingP1Id: EditFormData.gmaapAwardRatingP1Id,
            gmaapAwardRatingP2Id: EditFormData.gmaapAwardRatingP2Id,
            gmaapAwardRatingP3Id: EditFormData.gmaapAwardRatingP3Id,
            gmapGreenMarkCategoryP1Id: EditFormData.gmapGreenMarkCategoryP1Id,
            gmapGreenMarkCategoryP2Id: EditFormData.gmapGreenMarkCategoryP2Id,
            gmaapGreenMarkCategoryP1Id: EditFormData.gmaapGreenMarkCategoryP1Id,
            gmaapGreenMarkCategoryP2Id: EditFormData.gmaapGreenMarkCategoryP2Id,
            gmaapGreenMarkCategoryP3Id: EditFormData.gmaapGreenMarkCategoryP3Id,
            greenMarkProjectJobDesc: EditFormData.greenMarkProjectJobDesc,
            greenMarkProjectSignificantFile: EditFormData.greenMarkProjectSignificantFile
        };
        SetGreenMarkProjectFormData(mergedData);
    }
    const handleTableDelete = (data) => {
        let key = data.gmapGreenMarkProjectId;
        let request = {
            Id: key,
            AppId: 114,
            DeletedBy: newMemberData.gMAPMemberID,
        }

        confirm("Delete?", "Are you sure you want to delete?", "Delete", "Cancel")
            .then((confirmed) => {
                if (confirmed) {
                    deleteGMProject(request);
                    resetForm();
                }
            });
    }
    const resetForm = () => {
        let form = { ...greenMarkProjectData.initialState };
        SetGreenMarkProjectFormData(form);
        setIsEdit(false);
    };

    const handleOnChangeFileUpload = async (data, storagePath) => {
        let formData = new FormData();
        formData.append("file", data)
        formData.append("storagePath", storagePath);
        try {
            const response = await uploadPDF(formData);
            let tempUploads = { ...uploads };
            switch (storagePath) {
                case "GreenMarkProjectLetterOfAward": {
                    tempUploads.greenMarkProjectLetterOfAward = response.data.fileName;
                    setContextData({ ...newMemberData, greenMarkProjectLetterOfAward: response.data.fileName })
                    break;
                }
                case "LetterofEndorsement": {
                    tempUploads.clientLetterOfEndorsement = response.data.fileName;
                    setContextData({ ...newMemberData, clientLetterOfEndorsement: response.data.fileName })
                    break;
                }
                case "ProofOfJobDesc": {
                    tempUploads.greenMarkProjectJobDesc = response.data.fileName;
                    setContextData({ ...newMemberData, greenMarkProjectJobDesc: response.data.fileName })
                    break;
                }
                case "ProofOfSignificantInvolvement": {
                    tempUploads.greenMarkProjectSignificantFile = response.data.fileName;
                    setContextData({ ...newMemberData, greenMarkProjectSignificantFile: response.data.fileName })
                    break;
                }
            }
            setUploads(tempUploads)
        } catch (e) {
            error(e, "Error");
        }
    }
    // for file upload changes
    const handleFormFieldChange = (dataField, value) => {
        if (dataField === "greenMarkProjectLetterOfAward" && value) {
            handleOnChangeFileUpload(value, "GreenMarkProjectLetterOfAward")
        }
        else if (dataField === "clientLetterOfEndorsement" && value) {
            handleOnChangeFileUpload(value, "LetterofEndorsement")
        }
        else if (dataField === "greenMarkProjectJobDesc" && value) {
            handleOnChangeFileUpload(value, "ProofOfJobDesc")
        }
        else if (dataField === "greenMarkProjectSignificantFile" && value) {
            handleOnChangeFileUpload(value, "ProofOfSignificantInvolvement")
        }
    };

    //file download
    const handleDownload = async (data, dataField) => {
        const request = {
            type: "",
            fileName: ""
        }
        switch (dataField) {
            case "greenMarkProjectLetterOfAward":
                {
                    request.type = "GreenMarkProjectLetterOfAward"
                    request.fileName = newMemberData.greenMarkProjectLetterOfAward
                    break;
                }
            case "clientLetterOfEndorsement":
                {
                    request.type = "LetterofEndorsement"
                    request.fileName = newMemberData.clientLetterOfEndorsement
                    break;
                }
            case "greenMarkProjectJobDesc":
                {
                    request.type = "ProofOfJobDesc"
                    request.fileName = newMemberData.greenMarkProjectJobDesc
                    break;
                }
            case "greenMarkProjectSignificantFile":
                {
                    request.type = "ProofOfSignificantInvolvement"
                    request.fileName = newMemberData.greenMarkProjectSignificantFile
                    break;
                }
        }
        const response = await getPDF(request);
        saveAs(response?.data?.fileData, response?.data?.fileName);
    }

    return (
        <React.Fragment>
            <GreenMarkProjectForm
                ref={greenMarkProjectRef} initData={greenMarkProjectFormData} isEdit={isEdit} onReset={resetForm} onFormFieldValueChange={handleFormFieldChange}
                onDownload={handleDownload}
                handleTableEdit={handleTableEdit} handleTableDelete={handleTableDelete}
            />
            <div className="col-md-12 mb-2 d-flex justify-content-end">
                <Buttons
                    buttonText={isEdit ? "Update" : "+ Add"}
                    buttonTypeClassName="outline-btn"
                    onClick={handleAdd}
                />
            </div>
        </React.Fragment>
    )
})

export default GreenMarkProject