import { FormFieldTypes } from "../../../../../data/formFieldType";

export const PricingTypeFormData = {
    name: "PricingTypeFormData",
    initialState: {
        isPriceApplicable: "cost",
    },
    formFields: [
        {
            id: "isPriceApplicable",
            label: "",
            Field_Name: "Event Price Type",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "isPriceApplicable",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "Attendance is free", value: "free", isDisable: false, optionClass: "", },
                    { label: "There is a cost to attend", value: "cost", isDisable: false, optionClass: "ml-3", },
                ]
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-2 mb-2",
            },
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}