import React from 'react'

const ContinuingProfessionalDevelopment = () => {
    return (
        <div className='row'>
            <div className='col-lg-12'>
                <div className='continuing-prodessional-developement'>
                    <div>
                        <p>Total SGBC -GMAP CPD Points</p>
                    </div>
                    <div>
                        <h6>12</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContinuingProfessionalDevelopment