import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { industryTechnicalCommitteesData } from './IndustryTechnicalCommittees.Data'
import { useLazyGetPDFQuery, useUploadPDFMutation } from '../../../../../app/services/common/commonApi';
import { saveAs } from 'file-saver'
import Buttons from '../../../../../components/ui/button/Buttons';
import FormCreator from '../../../../../components/Forms/FormCreator';
import { useLazyGetIndustryTechnicalCommitteesByIdQuery } from '../../../../../app/services/CPD/CpdSubmissionApi';
import { CurrentCPDCycleDataContext } from '../../CpdSubmissionForm';
import DataLoader from '../../../../../components/ui/dataLoader/DataLoader';
import SwalAlert from '../../../../../services/swalService/SwalService';
import { useAddEditIndustryTechnicalCommitteesMutation } from '../../../../../app/services/CPD/SaveCPDSubmissionApi';

const IndustryTechnicalCommittees = forwardRef((props, ref) => {
  const industryTechnicalCommitteesRef = useRef();
  const { cpdSubmissionData, setCpdSubmissionData } = useContext(CurrentCPDCycleDataContext);
  const [industryTechnicalCommitteesForm, setindustryTechnicalCommittees] = useState(industryTechnicalCommitteesData);
  const { success, error } = SwalAlert();

  useImperativeHandle(ref, () => ({
    getFormData: () => {
      if (ref?.current) {
        const data = industryTechnicalCommitteesRef.current.getFormData();
        return data;
      }
      return null;
    }
  }));

  const [getPDF,
    { isSuccess: isSuccessGetPDF,
      isLoading: isLoadingGetPDF,
      data: getPDFData }] = useLazyGetPDFQuery();

  const [uploadPDF,
    { isSuccess: isSuccess,
      isLoading: isLoading,
      data: fileUploadData }
  ] = useUploadPDFMutation();


  const [handleSubmitForm,
    { isSuccess: isSuccessForm,
      isFetching: isLoadingForm,
      data: formResponse }
  ] = useAddEditIndustryTechnicalCommitteesMutation();


  useEffect(() => {
    if (!isLoadingForm && isSuccessForm && formResponse) {
      success("Save successfuly.", "Sucessful");
      props.formActionHandler["SAVE_SUCCESS"]();
    }
  }, [isLoadingForm, isSuccessForm, formResponse]);

  const [
    getIndustryTechnicalCommitteesById,
    {
      isSuccess: getIndustryTechnicalCommitteesByIdIsSuccess,
      isLoading: getIndustryTechnicalCommitteesByIdIsLoading,
      data: getIndustryTechnicalCommitteesByIdData,
    }
  ] = useLazyGetIndustryTechnicalCommitteesByIdQuery();

  useEffect(() => {
    console.log({ getIndustryTechnicalCommitteesByIdData });
    if (getIndustryTechnicalCommitteesByIdIsSuccess && !getIndustryTechnicalCommitteesByIdIsLoading && getIndustryTechnicalCommitteesByIdData) {
      let temp = { ...industryTechnicalCommitteesForm }
      let tempInitials = temp.initialState;
      tempInitials.nameofIndustryTechnicalCommittee = getIndustryTechnicalCommitteesByIdData.nameofIndustry;
      tempInitials.instituteThatCommitteeResidesin = getIndustryTechnicalCommitteesByIdData.institute;
      tempInitials.dateofAppointment = getIndustryTechnicalCommitteesByIdData.dateOfAppointment;
      tempInitials.EmailLetterofAppointment = getIndustryTechnicalCommitteesByIdData.emailAppointment;
      tempInitials.CpdPoints = getIndustryTechnicalCommitteesByIdData.cpdPoints;
      tempInitials.remarks = getIndustryTechnicalCommitteesByIdData.remarks;
      tempInitials.processedBy = getIndustryTechnicalCommitteesByIdData.cpdReceivedById;
      tempInitials.cpdPointsAwarded = getIndustryTechnicalCommitteesByIdData.cpdPointsAwardedId;
      setindustryTechnicalCommittees(temp);
    }
  }, [getIndustryTechnicalCommitteesByIdIsSuccess, getIndustryTechnicalCommitteesByIdIsLoading, getIndustryTechnicalCommitteesByIdData]);

  useEffect(() => {
    getIndustryTechnicalCommitteesById(cpdSubmissionData.cpdSubmissionId);
  }, []);



  const handleDownload = async (data, dataField) => {
    const request = {
      type: "EmailLetterofAppointment",
      fileName: fileUploadData?.fileName ? fileUploadData?.fileName : getIndustryTechnicalCommitteesByIdData.emailAppointment,
    }
    const response = await getPDF(request);
    saveAs(response?.data?.fileData, response?.data?.fileName);
  }


  const formActionHandler = {
    DOWNLOAD: handleDownload,
  };


  const handleFormFieldChange = (dataField, value) => {
    if (dataField === "EmailLetterofAppointment" && value) {
      handleOnChangeFileUpload(value)
    }
  };

  const handleOnChangeFileUpload = async (data) => {
    let formData = new FormData();
    formData.append("file", data)
    formData.append("storagePath", "EmailLetterofAppointment");
    uploadPDF(formData);
  }

  const handleSaveButton = () => {
    const formData = industryTechnicalCommitteesRef.current.getFormData();
    if (!formData) {
      return error("Please fill all mandatory fields.", "")
    }
    let request = {
      CPDIndustryTechnicalCommitteesId: getIndustryTechnicalCommitteesByIdData.cpdIndustryTechnicalCommitteesId,
      GMAPMemberId: getIndustryTechnicalCommitteesByIdData.gmapMemberId,
      CPDPoints: formData.CpdPoints,
      Remarks: formData?.remarks,
      CPDActivityTypeId: getIndustryTechnicalCommitteesByIdData.cpdActivityTypeId,
      NameofIndustry: formData?.nameofIndustryTechnicalCommittee,
      Institute: formData?.instituteThatCommitteeResidesin,
      DateOfAppointment: formData?.dateofAppointment,
      EmailAppointment: fileUploadData?.fileName ? fileUploadData?.fileName : getIndustryTechnicalCommitteesByIdData.emailAppointment,
      CPDSubmissionId: getIndustryTechnicalCommitteesByIdData.cpdSubmissionId,
      CreatedBy: 115,
      CreatedAppId: 1,
      CPDReceivedById: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      CPDPointsAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
    }

    handleSubmitForm(request)
  };

  const handleRejectbutton = () => {
    const request = {
      cpdSubmissionId: getIndustryTechnicalCommitteesByIdData.cpdSubmissionId,
      gmapMemberId: getIndustryTechnicalCommitteesByIdData.gmapMemberId,
      isRejected: true,
      rejectCPDSubmissionReason: null,
    }
    if (props.formActionHandler) {
      props.formActionHandler['REJECT'](request);
    }
  };

  const handleApproveButton = () => {
    const formData = industryTechnicalCommitteesRef.current.getFormData();
    if (!formData) return;
    const request = {
      cpdSubmissionId: getIndustryTechnicalCommitteesByIdData.cpdSubmissionId,
      gmapMemberId: getIndustryTechnicalCommitteesByIdData.gmapMemberId,
      ProcessedId: formData?.processedBy?.value ? formData?.processedBy?.value : formData?.processedBy,
      cpdPointAwardedId: formData?.cpdPointsAwarded?.value ? formData?.cpdPointsAwarded?.value : formData?.cpdPointsAwarded,
      isApprove: true,
      Notes: formData?.notes || null
    }
    if (props.formActionHandler) {
      props.formActionHandler['APPROVE'](request);
    }
  };
  return (
    <React.Fragment>
      {getIndustryTechnicalCommitteesByIdData ?
        <div className="speaking-teaching-engagement">
          {/* <div className="cpd-submission-description">
          <p>Participation in relevant technical committees convened by government agencies, professional bodies and trade associations will qualify for 2 CPD points.</p>
          <p>
            Only 1 submission under this category may be claimed per renewal cycle and 2 CPD points will be awarded.
          </p>
        </div> */}
          <div className="common-project-info-main card-section card-section-left">
            <div className="row gx-5">

              <FormCreator ref={industryTechnicalCommitteesRef} {...industryTechnicalCommitteesForm} onActionChange={formActionHandler} onFormFieldValueChange={handleFormFieldChange} />

            </div>
          </div>
          <div className='row'>
            <div className='common-custom-pagination mt-5'>
              <div>
                <Buttons
                  buttonText="Save"
                  buttonTypeClassName="outline-btn"
                  onClick={handleSaveButton}
                  isDisable={props?.isPastCPD}
                />
              </div>
              <div className='d-flex column-gap-3'>
                <Buttons
                  buttonText="Reject"
                  buttonTypeClassName="red-btn"
                  onClick={handleRejectbutton}
                  isDisable={props?.isPastCPD}
                />
                <Buttons
                  buttonText="Approve"
                  buttonTypeClassName="success-btn"
                  onClick={handleApproveButton}
                  isDisable={props?.isPastCPD}
                  isLoading={cpdSubmissionData?.approveIsLoading}
                />
              </div>
            </div>
          </div>
          <div className='center-button'>
            {/* <div className='col-lg-12 d-flex justify-content-center'>
            <Buttons
              buttonText="Submit"
              buttonTypeClassName="success-btn mx-4"
              onClick={handleSubmit}
            />
          </div> */}
          </div>

        </div>
        : <DataLoader />}
    </React.Fragment>
  );
})

export default IndustryTechnicalCommittees;
