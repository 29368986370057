import { FormFieldTypes } from "../../../../../data/formFieldType";

export const academicQualificationData = {
    name: "AcademicQualification",
    initialState: {
        graduateYearID: '',
        highestQualificationID: '',
        academicQualification: '',
        fieldOfStudy: '',
        academicQualificationCertificationFile: '',
    },
    formFields: [
        {
            id: "highestQualificationID",
            lable: "Highest Relevant Qualification",
            Field_Name: "Highest Relevant Qualification",
            fieldType: FormFieldTypes.SELECT,
            dataField: "highestQualificationID",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "fieldOfStudy",
            lable: "Field of Study",
            Field_Name: "Field of Study",
            fieldType: FormFieldTypes.INPUT,
            dataField: "fieldOfStudy",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "graduateYearID",
            lable: "Graduate Year",
            Field_Name: "Graduate Year",
            fieldType: FormFieldTypes.SELECT,
            dataField: "graduateYearID",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
        {
            id: "academicQualification",
            lable: "Academic Institution",
            Field_Name: "Academic Institution",
            fieldType: FormFieldTypes.INPUT,
            dataField: "academicQualification",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-3",
            },
        },
        {
            id: "academicQualificationCertificationFile",
            lable: 'Upload Academic Certificate (please upload in pdf format max 2mb)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'academicQualificationCertificationFile',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                isDownloadable: true,
                acceptedFiles: '.pdf,',
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-6 mb-2"
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}