import React, { useEffect, useState } from 'react'
import Buttons from '../../../../../components/ui/button/Buttons'
import { useParams } from 'react-router-dom';
import { decryptAESID } from '../../../../../utils/Encryption';
import { useNavigate } from "react-router-dom";
import { useLazyGetCPDSubmissionByIdQuery } from '../../../../../app/services/CPD/ApproveCPDSubmissionApi';
import DataLoader from '../../../../../components/ui/dataLoader/DataLoader';
const ContinuingProfessionalDevelopment = (props) => {

    const navigate = useNavigate()
    const { id } = useParams();
    const [cpdMemberData, setCPDMemberData] = useState();

    const [getCPDByMemberId, {
        isLoading: getCPDByMemberIdIsLoading,
        data: getCPDByMemberIdData,
        isSuccess: getCPDByMemberIdIsSuccess,
    }] = useLazyGetCPDSubmissionByIdQuery();

    const handleRedirectCPD = () => {
        // navigate(`/cpdSubmission/${id}`)
        window.open(`${window.location.origin}/cpdSubmission/${id}`)
    }
    const getMemberDetailById = () => {
        const gmapNumberId = decryptAESID(id);
        getCPDByMemberId(gmapNumberId);
    }
    useEffect(() => {
        if (id) {
            getMemberDetailById()
        }
    }, [id])

    useEffect(() => {
        if (!getCPDByMemberIdIsLoading && getCPDByMemberIdData && getCPDByMemberIdIsSuccess) {
            console.log({ getCPDByMemberIdData })
            setCPDMemberData({ ...getCPDByMemberIdData });
        }
    }, [getCPDByMemberIdIsLoading, getCPDByMemberIdData, getCPDByMemberIdIsSuccess,])



    return (
        <div>
            <div className='row'>
                {/* {JSON.stringify(cpdMemberData)} */}
                {cpdMemberData ?
                    <div className='col-lg-12'>
                        <div className='continuing-professional-development row d-flex flex-wrap'>
                            <div className='col-lg-4 col-md-6 mb-3'>
                                <div>
                                    <p>Target CPD</p>
                                </div>
                                <div>
                                    <h6>{cpdMemberData.targetCPD}</h6>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 mb-3'>
                                <div>
                                    <p>Total Verified CPD</p>
                                </div>
                                <div>
                                    <h6>{cpdMemberData.totalVerifiedCPD}</h6>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 mb-3'>
                                <div>
                                    <p>Total Pending CPD</p>
                                </div>
                                <div>
                                    <h6>{cpdMemberData.pending}</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    : <DataLoader />
                }
            </div>
            <div>{props.isViewButton === false ? <></> :
                <Buttons
                    buttonText="View"
                    buttonTypeClassName="outline-btn"
                    onClick={handleRedirectCPD}
                />
            }
            </div>
        </div>
    )
}

export default ContinuingProfessionalDevelopment