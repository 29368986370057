import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'
import { CourseLocationFormData } from '../data/CourseLocationForm.Data';
import FormCreator from '../../../../../components/Forms/FormCreator';
import CardSection from '../../../../../components/ui/card/CardSection';
import Buttons from '../../../../../components/ui/button/Buttons';

const CourseLocation = forwardRef((props, ref) => {
    const courseLocationRef = useRef();
    const [courseLocationForm, setCourseLocationForm] = useState(CourseLocationFormData);

    // Expose method `getFormData` to parent components to retrieve form data from `FormCreator`
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const courseLocationFormData = courseLocationRef.current.getFormData();
                return courseLocationFormData;
            }
            return null;
        }
    }))

    const handleFormFieldChange = (fieldName, fieldValue) => {
        switch (fieldName) {
            case "isRedirection":
                // setRedirectionFlag(fieldValue === "yes");
                break;
            default:
                return false;
        }
    };

    return (
        <React.Fragment>
            <div className='row'>
                <FormCreator ref={courseLocationRef} {...courseLocationForm} onFormFieldValueChange={handleFormFieldChange} />
            </div>
            <div className='row mt-5'>
                <div className='col-lg-12'>
                    <Buttons
                        buttonText="Generate"
                        buttonTypeClassName="success-btn"
                    // onClick={() => handleNext(tab.saveInto)}
                    />
                </div>
            </div>
        </React.Fragment>
    )
})

export default CourseLocation