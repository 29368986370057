import { GridColumnType } from "../../../../data/gridColumnType";


// Current Approve CPD Submission Table GridConfig start
export const ApproveCpdSubmissionConfig={
  columns: [
    {
      name: "CPD No.",
      fieldName: "cpdNo",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
      name: "Applicant Name",
      fieldName: "name",
      allowShort: false,
      colType: GridColumnType.Text,
    },
    {
      name: "GMP Tier",
      fieldName: "applicationName",
      colType: GridColumnType.Text,

    },
    {
      name: "CPD Activity",
      fieldName: "cpdActivityName",
      colType: GridColumnType.Text,
    },
    {
      name: "Status",
      fieldName: "statusName",
      colType: GridColumnType.Text,
    },
    {
      name: "Submitted Date",
      fieldName: "submittedDate",
      colType: GridColumnType.DATE,
      colSettings: {
        format: 'DD MMM YYYY',
      }
    },
    {
      name: "Received by",
      fieldName: "receivedByName",
      colType: GridColumnType.CUSTOM,
      renderCustomCol: (rowData) => {
        if(rowData?.['receivedByName']===null||rowData?.['receivedByName']==="") {
          return "Open"
        }
        else {
          return rowData?.['receivedByName']
        }
      }
    },
    {
      name: "Action",
      colType: GridColumnType.ACTION,
      defaultAction: {
        allowEdit: false,
        allowDelete: false
      },
      customAction: [
        {
          name: "UPDATE",
          onViewAction: (data) => {
            alert("called permissions");
          },
        },
      ],
    },
  ],
};
