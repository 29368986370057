import { FormFieldTypes } from "../../../../data/formFieldType";


const AddEmailTempData = {
    name: "Add Email Temp",
    initialState: {
        emailName: null,
        subject: null,
        WhereUsed: null,
        projectType: null,
        body: null
    },
    formFields: [
        {
            id: "emailName",
            lable: "Email Name",
            Field_Name: "emailName",
            fieldType: FormFieldTypes.INPUT,
            dataField: "emailName",
            fieldSetting: {
                placeholder: "Email Name",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 mb-3"
            }
        },
        {
            id: "emailSubject",
            lable: "Email Subject",
            Field_Name: "emailSubject",
            fieldType: FormFieldTypes.INPUT,
            dataField: "subject",
            fieldSetting: {
                placeholder: "Email Subject",
            },
            style: {
                containerCss: "col-lg-6 mb-3"
            },
            validation: [{ type: "require" }]
        },
        {
            id: "emailWhereUse",
            lable: "Email Where Use",
            Field_Name: "emailWhereUse",
            fieldType: FormFieldTypes.INPUT,
            dataField: "WhereUsed",
            fieldSetting: {
                placeholder: "Email Where Use",
            },
            style: {
                containerCss: "col-lg-6 mb-3"
            },
            validation: [{ type: "require" }]
        },
        {
            id: "project",
            lable: "Project",
            Field_Name: "project",
            fieldType: FormFieldTypes.SELECT,
            dataField: "projectType",
            fieldSetting: {
                placeholder: "Project",
                options: []
            },
            style: {
                containerCss: "col-lg-6 mb-3"
            },
            validation: [{ type: "require" }]
        },
        // {
        //     id: "templateDescription",
        //     lable: "Template Description",
        //     Field_Name: "templateDescription",
        //     fieldType: FormFieldTypes.TINYEDITOR,
        //     dataField: "body",
        //     fieldSetting: {
        //     },
        //     style: {
        //         containerCss: "col-lg-12 mb-3"
        //     },
        // },
        {
            id: "templateDescription",
            lable: "Template Description",
            Field_Name: "Template Description",
            fieldType: FormFieldTypes.CKEDITOR,
            dataField: "body",
            fieldSetting: {
                // placeholder: "Enter Details",
                allowSpace: true,
                // maxLength: 1000,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-xxl-12 col-xl-12 col-md-12 col-12  mb-3",
            },
        },
    ],
};

export default AddEmailTempData;