import React, { useEffect, useRef, useState } from 'react'
import DiscretionaryTaskListSearchFilterForm from './Components/DiscretionaryTaskListSearchFilterForm'
import Buttons from '../../../components/ui/button/Buttons'
import { DiscretionaryApplicationTasklistTableConfig } from './DataFile/DiscretionaryTaskListSearchFilterForm.Data';
import { useNavigate } from 'react-router-dom';
import MolGrid from '../../../components/Grid/MolGrid';
import { encryptAESID } from '../../../utils/Encryption';
import { useGetGMAPDiscretionaryRenewalTaskListMutation } from '../../../app/services/GMAP/DiscrertionaryRenewalApi';


const DiscretionaryRenewalTaskList = () => {
    const discretionaryApplicationTasklistFilterRef = useRef();
    const discretionaryApplicationTasklistRef = useRef();
    const navigate = useNavigate();
    const [dataSource, setDataSource] = useState([]);
    const [paginationRequest, setPaginationRequest] = useState({
        pageNo: 1,
        pageSize: 10,
        endPage: 100,
        searchText: "",
        totalCount: 0,
        statusId: null
    });
    const [getDiscretionaryApplicationTaskList,
        { isSuccess: isGetDiscretionaryApplicationTaskListSuccess,
            isLoading: isGetDiscretionaryApplicationTaskListFetching,
            data: discretionaryApplicationTaskListResponse },
    ] = useGetGMAPDiscretionaryRenewalTaskListMutation();

    useEffect(() => {
        getDiscretionaryApplicationTaskList(paginationRequest)
    }, [])

    useEffect(() => {
        if (!isGetDiscretionaryApplicationTaskListFetching && isGetDiscretionaryApplicationTaskListSuccess && discretionaryApplicationTaskListResponse) {
            setPaginationRequest({ ...paginationRequest, totalCount: discretionaryApplicationTaskListResponse.totalCount })
            setDataSource(discretionaryApplicationTaskListResponse.itemList)
        }
    }, [isGetDiscretionaryApplicationTaskListFetching, isGetDiscretionaryApplicationTaskListSuccess, discretionaryApplicationTaskListResponse]);

    const handleSearchApplicationTask = () => {
        debugger
        if (!discretionaryApplicationTasklistFilterRef.current) {
            return;
        }
        const taskListFormData = discretionaryApplicationTasklistFilterRef.current.getFormData();
        const updatedPaginationRequest = {
            pageNo: 1,
            pageSize: 10,
            endPage: 100,
            searchText: taskListFormData.name,
            statusId: taskListFormData.status?.value || null,
        };
        setPaginationRequest(updatedPaginationRequest);
        getDiscretionaryApplicationTaskList(updatedPaginationRequest)
    };

    const handlePageChange = ({ pageNumber, pageSize }) => {
        getDiscretionaryApplicationTaskList({ ...paginationRequest, pageNo: pageNumber, pageSize: pageSize })
    }

    // Function to handle Edit click
    const handleEditClick = (data) => {
        debugger
        navigate(`/editDiscretionaryRenewalTaskListApplication/${encryptAESID(data.gmapMemberId, false)}/${encryptAESID(data.applicationTaskListId, false)}`)
    }

    const actionHandler = {
        UPDATE: handleEditClick,
    }
    return (
        <div className="common-first-section">
            <div className="row align-items-end">
                <div className="col-10">
                    <div className="row gx-5">
                        <DiscretionaryTaskListSearchFilterForm ref={discretionaryApplicationTasklistFilterRef} />
                    </div>
                </div>
                <div className="col-2 d-flex justify-content-end">
                    <div className="two-button-fixes">
                        <div className="">
                            <Buttons
                                buttonText="Search"
                                buttonTypeClassName="success-btn"
                                onClick={handleSearchApplicationTask}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="common-view-Individual-table">
                        <div className="table-responsive">
                            <MolGrid
                                ref={discretionaryApplicationTasklistRef}
                                configuration={DiscretionaryApplicationTasklistTableConfig}
                                dataSource={dataSource}
                                allowPagination={true}
                                isLoading={isGetDiscretionaryApplicationTaskListFetching}
                                pagination={paginationRequest}
                                onPageChange={handlePageChange}
                                onActionChange={actionHandler}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DiscretionaryRenewalTaskList