import React, { useContext, useEffect, useRef, useState } from "react";
import { CurrentCpdCycleGridConfig } from "../ViewAddCpdPoints.Data";
import MolGrid from "../../../../components/Grid/MolGrid";
import SGBCAccreditedCpdEvents from "../AllActivityComponent/SGBCAccreditedCpdEvents/SGBCAccreditedCpdEvents";
import BcaGreenMarkProject from "../AllActivityComponent/BcaGreenMarkProject/BcaGreenMarkProject";
import MastersDegreeDoctorProgrammes from "../AllActivityComponent/MastersDegreeDoctorProgrammes/MastersDegreeDoctorProgrammes";
import PublicationofResearchPaper from "../AllActivityComponent/PublicationofResearchPaper/PublicationofResearchPaper";
import IndustryTechnicalCommittees from "../AllActivityComponent/IndustryTechnicalCommittees/IndustryTechnicalCommittees";
import CpdprogrammesbyOverseasgreenbuildingCouncils from "../AllActivityComponent/CpdprogrammesbyOverseasgreenbuildingCouncils/CpdprogrammesbyOverseasgreenbuildingCouncils";
import SgbcElearning from "../AllActivityComponent/SgbcElearning/SgbcElearning";
import SpeakingTeachingEngagement from "../AllActivityComponent/SpeakingTeachingEngagement/SpeakingTeachingEngagement";
import CenterModel from "../../../../components/ui/centerModel/CenterModel";

import { useAcceptCpdSubmissionMutation } from "../../../../app/services/CPD/CPDApi";
import RejectReason from "./RejectReason";
import { CurrentCPDCycleDataContext } from "../CpdSubmissionForm";
import SwalAlert from "../../../../services/swalService/SwalService";
import { useLazyGetCPDMemberDetailsListByMemberIdQuery } from "../../../../app/services/CPD/ApproveCPDSubmissionApi";
import { useParams } from "react-router-dom";
import { decryptAESID } from "../../../../utils/Encryption";
import formatDate from "../../../../lib/formatDate";

export const CurrentCpdCycleTable = (props) => {
    const CurrentCpdCycleTablRef = useRef();
    const { cpdSubmissionData, setCpdSubmissionData } = useContext(
        CurrentCPDCycleDataContext
    );

    const { success, error } = SwalAlert();

    const SGBCAccreditedCpdEventsRef = useRef();
    const BcaGreenMarkProjectRef = useRef();
    const MastersDegreeDoctorProgrammesRef = useRef();
    const PublicationofResearchPaperRef = useRef();
    const IndustryTechnicalCommitteesRef = useRef();
    const CpdprogrammesbyOverseasgreenbuildingCouncilsRef = useRef();
    const SgbcElearningRef = useRef();
    const SpeakingTeachingEngagementRef = useRef();
    const SgbcEventRef = useRef();
    const rejectReasonRef = useRef();
    const [isRejectModalOpen, setIsRejectOpenModal] = useState(false);
    const [currentCPDCycle, setCurrentCPDCycle] = useState();

    const [isModelOpen, setIsModalOpen] = useState(false);

    const [modelDetail, setModelDetail] = useState({
        value: 0,
        label: "",
        component: null,
    });

    const { id } = useParams();

    const getcurrentListById = () => {
        const gmapNumberId = decryptAESID(id);
        getCPDMemberDetailsListByMemberId(gmapNumberId);
    };
    useEffect(() => {
        if (id) {
            getcurrentListById();
        }
    }, [id]);

    const [
        getCPDMemberDetailsListByMemberId,
        {
            isLoading: getCPDByMemberDetailListIsLoading,
            data: getCPDByMemberDetailListData,
            isSuccess: getCPDByMemberDetailListIsSuccess,
        },
    ] = useLazyGetCPDMemberDetailsListByMemberIdQuery();

    const [
        approveCpdSubmission,
        {
            isLoading: approveIsLoading,
            data: approveCpdData,
            isSuccess: approveIsSuccess,
        },
    ] = useAcceptCpdSubmissionMutation();

    useEffect(() => {
        if (!approveIsLoading && approveCpdData && approveIsSuccess) {
            console.log({ approveCpdData });
            setCpdSubmissionData({ ...cpdSubmissionData, approveIsLoading: false });
            success("CPD Activity Approve Successfully", "Success");
            props.getMemberDetailById();
            getcurrentListById();
            setIsModalOpen(false);
        }
    }, [approveIsLoading, approveCpdData, approveIsSuccess]);

    useEffect(() => {
        if (
            !getCPDByMemberDetailListIsLoading &&
            getCPDByMemberDetailListData &&
            getCPDByMemberDetailListIsSuccess
        ) {
            let tempData = getCPDByMemberDetailListData.map((obj) => {
                return {
                    ...obj,
                    submittedDate: formatDate(obj.submittedDate, "MM/DD/YYYY"),
                    dateOfActivity: formatDate(obj.dateOfActivity, "MM/DD/YYYY"),
                    verficationDate: formatDate(obj.verficationDate, "MM/DD/YYYY"),
                    typeOfActivity: <u>{obj.typeOfActivity}</u>,
                };
            });
            setCurrentCPDCycle(tempData);
        }
    }, [
        getCPDByMemberDetailListIsLoading,
        getCPDByMemberDetailListData,
        getCPDByMemberDetailListIsSuccess,
    ]);

    const openCloseRejectModal = () => {
        setIsRejectOpenModal(!isRejectModalOpen);
        setIsModalOpen(false);
    };
    const openCloseModal = () => {
        setIsModalOpen(!isModelOpen);
    };

    const handleRejectbutton = (request) => {
        setCpdSubmissionData({ ...cpdSubmissionData, rejectRequestData: request });
        openCloseRejectModal();
    };

    const handleApproveButton = (request) => {
        approveCpdSubmission(request);
        setCpdSubmissionData({ ...cpdSubmissionData, approveIsLoading: true });
    };
    const saveSuccess = (request) => {
        props.getMemberDetailById();
        getcurrentListById();
    };

    const rejectSuccess = () => {
        props.getMemberDetailById();
        success("Reject Success fully.", "Success");
        setCpdSubmissionData({ ...cpdSubmissionData, rejectRequestData: null });
        openCloseRejectModal();
        getcurrentListById();
    };

    const formActionHandler = {
        // CLEAR: handleClear,
        // DOWNLOAD: handleDownload,
        REJECT: handleRejectbutton,
        APPROVE: handleApproveButton,
        REJECT_SUCCESS: rejectSuccess,
        SAVE_SUCCESS: saveSuccess,
    };

    const modelComponent = [
        {
            value: 1,
            label: "SGBC- Accredited CPD Events",
            component: (
                <SGBCAccreditedCpdEvents ref={SGBCAccreditedCpdEventsRef} formActionHandler={formActionHandler} />
            ),
        },
        {
            value: 2,
            label: "BCA Green Mark Project",
            component: (
                <BcaGreenMarkProject ref={BcaGreenMarkProjectRef} formActionHandler={formActionHandler} />
            ),
        },
        {
            value: 3,
            label: "Masters Degree/Doctorate (Ph.D) programmes",
            component: (
                <MastersDegreeDoctorProgrammes ref={MastersDegreeDoctorProgrammesRef} formActionHandler={formActionHandler} />
            ),
        },
        {
            value: 4,
            label: "Publication of Research Paper",
            component: (
                <PublicationofResearchPaper ref={PublicationofResearchPaperRef} formActionHandler={formActionHandler} />
            ),
        },
        {
            value: 5,
            label: "Industry Technical Committees",
            component: (
                <IndustryTechnicalCommittees ref={IndustryTechnicalCommitteesRef} formActionHandler={formActionHandler} />
            ),
        },
        {
            value: 6,
            label: "CPD Programmes by Overseas Green Building Councils",
            component: (
                <CpdprogrammesbyOverseasgreenbuildingCouncils ref={CpdprogrammesbyOverseasgreenbuildingCouncilsRef} formActionHandler={formActionHandler} />
            ),
        },
        {
            value: 7,
            label: "SGBC e-Learning",
            component: (
                <SgbcElearning ref={SgbcElearningRef} formActionHandler={formActionHandler} />
            ),
        },
        {
            value: 8,
            label: "Speaking/Teaching Engagement",
            component: (
                <SpeakingTeachingEngagement ref={SpeakingTeachingEngagementRef} formActionHandler={formActionHandler} />
            ),
        },
    ];

    const handleRowClick = (data) => {
        if (data.cpdActivityTypeId === 9) return;

        setCpdSubmissionData({
            ...cpdSubmissionData,
            cpdSubmissionId: data.cpdSubmissionId,
        });
        const modelObj = modelComponent.find(
            (obj) => obj.value === data.cpdActivityTypeId
        );
        setModelDetail({ ...modelObj });
        openCloseModal();
    };

    const actionHandler = {
        ROW_CLICK: handleRowClick,
    };

    return (
        <>
            <MolGrid
                ref={CurrentCpdCycleTablRef}
                configuration={CurrentCpdCycleGridConfig}
                dataSource={currentCPDCycle}
                allowPagination={false}
                isLoading={getCPDByMemberDetailListIsLoading}
                onActionChange={actionHandler}
                isAllowRowClick={true}
            />

            {isModelOpen && (
                <CenterModel
                    modelTitle={modelDetail.label}
                    show={"test"}
                    handleClose={openCloseModal}
                    onUpload={openCloseModal}
                >
                    {modelDetail.component}
                </CenterModel>
            )}

            <div className="rejection-reson-modal">
                {isRejectModalOpen && (
                    <CenterModel
                        modelTitle="Rejection Reason"
                        show={"test"}
                        handleClose={openCloseRejectModal}
                        className="reject-model"
                    // onUpload={props.openCloseRejectModal}
                    >
                        <RejectReason
                            ref={rejectReasonRef}
                            isModalOpen={isRejectModalOpen}
                            openCloseRejectModal={openCloseRejectModal}
                            formActionHandler={formActionHandler}
                        />
                    </CenterModel>
                )}
            </div>
        </>
    );
};
