
export const ZERO = 0;
export const NINE = 9
export const ONE = 1;
export const THREE = 3;
export const FIVE = 5;
export const HUNDREAD = 100;

export const paginationObj = {
    pageNo: ONE,
    pageSize: NINE,
    endPage: HUNDREAD,
}

export const MEMBERDIRECTORYAGESIZE = NINE;

export const APIURL = process.env.NEXT_PUBLIC_REACT_APP_BaseUrl;
