/* eslint-disable react-hooks/exhaustive-deps */

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import FormCreator from "../../../../../components/Forms/FormCreator";
import { AccountInformationFormDataDiscrationaryRenewal } from "./AccountInformationFormDiscrationaryRenewal.Data";
import { useLazyGetGMAPAccountInformationByMemberIdQuery } from "../../../../../app/services/GMAP/editApplicationTaskListApi";
import { useParams } from "react-router-dom";
import { decryptAESID } from "../../../../../utils/Encryption";
import { setDiscretionaryAccountInformationInitials, setDiscretionaryAccountInformationOthers } from "../../utils/UtilsEditDiscretionaryRenewal";
import { useLazyGetDiscretionaryRenewalRequestByIdQuery } from "../../../../../app/services/GMAP/DiscrertionaryRenewalApi";


const DiscretionaryRenewalAccountInformation = forwardRef((props, ref) => {

    const discretionaryRenewalAccountInformationFormRef = useRef();

    const { memberId, requestId } = useParams();

    const [discretionaryRenewalAccountInformation, setDiscretionaryRenewalAccountInformation] = useState(AccountInformationFormDataDiscrationaryRenewal);

    const [getAccInfoById,
        { isSuccess: isSuccessAccInfo,
            isLoading: isFetchingAccInfo,
            data: accountInformationResponse },
    ] = useLazyGetGMAPAccountInformationByMemberIdQuery();

    const [getDiscretionaryRenewalRequestById,
        { isSuccess: getDiscretionaryRenewalRequestByIdIsFetching,
            isLoading: getDiscretionaryRenewalRequestByIdIsLoading,
            data: getDiscretionaryRenewalRequestByIdResponse },
    ] = useLazyGetDiscretionaryRenewalRequestByIdQuery();

    useEffect(() => {
        if (!isFetchingAccInfo && isSuccessAccInfo && accountInformationResponse) {
            // setNewMemberData({
            //     ...newMemberData, GMAPMemberSubscriptionId: accountInformationResponse.gmapMemberSubscriptionId
            // });
            let updateForm = setDiscretionaryAccountInformationInitials(discretionaryRenewalAccountInformation, accountInformationResponse);
            setDiscretionaryRenewalAccountInformation(updateForm);
        }
    }, [isFetchingAccInfo, isSuccessAccInfo, accountInformationResponse]);

    useEffect(() => {
        if (!getDiscretionaryRenewalRequestByIdIsLoading && getDiscretionaryRenewalRequestByIdIsFetching && getDiscretionaryRenewalRequestByIdResponse) {
            let updateForm = setDiscretionaryAccountInformationOthers(discretionaryRenewalAccountInformation, getDiscretionaryRenewalRequestByIdResponse);
            setDiscretionaryRenewalAccountInformation(updateForm);
        }
    }, [getDiscretionaryRenewalRequestByIdIsLoading, getDiscretionaryRenewalRequestByIdIsFetching, getDiscretionaryRenewalRequestByIdResponse]);


    useEffect(() => {
        getAccInfoById(decryptAESID(memberId))
        let req = { requestId: decryptAESID(requestId), memberId: decryptAESID(memberId) }
        getDiscretionaryRenewalRequestById(req);
    }, [memberId, requestId])

    useImperativeHandle(ref, () => ({
        getFormData: () => {
            if (ref?.current) {
                const AccountInformationFormData =
                    discretionaryRenewalAccountInformationFormRef.current.getFormData();
                return AccountInformationFormData;
            }
            return null;
        },
    }));

    return (
        <div className="row">
            <FormCreator
                ref={discretionaryRenewalAccountInformationFormRef}
                {...discretionaryRenewalAccountInformation}
            />
        </div>
    );
});

export default DiscretionaryRenewalAccountInformation;
