import { FormFieldTypes } from "../../../../../data/formFieldType";

export const ZoomAccountFormData = {
    name: "ZoomAccountFormData",
    initialState: {
        accountType: 'meeting',
        noOfSession: '',
        noOfSeats: '',
        sessionDate: '',
        startAt: '',
        endsAt: ''
    },
    formFields: [
        {
            id: "accountType",
            label: "",
            Field_Name: "Account Type",
            fieldType: FormFieldTypes.RADIOBUTTON,
            dataField: "accountType",
            fieldSetting: {
                placeholder: "",
                options: [
                    { label: "Meeting", value: "meeting", isDisable: false, optionClass: "", },
                    { label: "Webinar", value: "webinar", isDisable: false, optionClass: "ml-3", },
                    { label: "Others", value: "others", isDisable: false, optionClass: "ml-3", },
                ]
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-12 mb-4",
            },
        },
        {
            id: "zoomMeetingId",
            lable: "Zoom Meeting Id",
            Field_Name: "Zoom Meeting Id",
            fieldType: FormFieldTypes.INPUT,
            dataField: "zoomMeetingId",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-3 mb-2",
            },
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: '',
            style: {
                containerCss: "col-lg-9 mb-2",
            }
        },
        {
            id: "zoomAccountType",
            lable: "Zoom Account",
            Field_Name: "Zoom Account",
            fieldType: FormFieldTypes.SELECT,
            dataField: "zoomAccountType",
            fieldSetting: {
                placeholder: "Select Zoom Account",
                options: [
                    { value: 1, label: "Zoom 1" },
                    { value: 2, label: "Zoom 2" },
                ],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-4 mb-2",
            }
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: '',
            style: {
                containerCss: "col-lg-8 mb-2",
            }
        },
        {
            id: "noOfSession",
            lable: "No. of sessions",
            Field_Name: "No. of sessions",
            fieldType: FormFieldTypes.NUMERIC,
            dataField: "noOfSession",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-3 mb-2",
            },
        },
        {
            id: "noOfSeats",
            lable: "No. of seats",
            Field_Name: "No. of seats",
            fieldType: FormFieldTypes.NUMERIC,
            dataField: "noOfSeats",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-3 mb-2",
            },
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: '',
            style: {
                containerCss: "col-lg-6 mb-2",
            }
        },
        {
            id: "sessionDate",
            lable: "Date",
            Field_Name: "Date",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "sessionDate",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-3 mb-2"
            }
        },
        {
            id: "startAt",
            lable: "Start At",
            Field_Name: "Start At",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "startAt",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-3 mb-2"
            }
        },
        {
            id: "endsAt",
            lable: "Ends At",
            Field_Name: "Ends At",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "endsAt",
            fieldSetting: {
                placeholder: "",
                options: [],
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-md-3 mb-2"
            }
        },
        {
            fieldType: FormFieldTypes.COMPONENT,
            additionalComponent: '',
            style: {
                containerCss: "col-lg-3 mb-2",
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}