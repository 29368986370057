import React from "react";
import "./Login.scss";
import Image from "../../components/image/Image";
import { AppIcons } from "../../data/appIcons";
import LoginForm from "../../features/login/Login";

const LoginPage = () => {
  return (
    <div className="login-section">
      <div className="row p-0 m-0">
        <div className="col-xxl-7 col-lg-7 col-md-6 col-12 p-0">
          <div className="left-bg-section">
            <Image
              imagePath={AppIcons.logoImage}
              imgCustomClassName="open-bar"
              altText="Icon"
            ></Image>
          </div>
        </div>
        <div className="col-xxl-5 col-lg-5 col-md-6 col-12 p-0">
          <div className="right-form-section">
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
