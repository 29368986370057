import { FormFieldTypes } from "../../../../data/formFieldType";

export const ApproveCpdSubmissionData={
    name: "ApproveCpdSubmissionData",
    initialState: {
        name: '',
        gmapNumber: "",
        submissionDate: "",
        submissionDateEnd: "",
        cpdStatusFilter: ''
    },
    formFields: [
        {
            id: "name",
            lable: "Name/GMP Number",
            Field_Name: "Name/GMAP Number",
            fieldType: FormFieldTypes.INPUT,
            dataField: "name",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12",
            },
        },
        {
            id: "cpdStatusFilter",
            lable: "Status",
            Field_Name: "Status",
            fieldType: FormFieldTypes.SELECT,
            dataField: "cpdStatusFilter",
            fieldSetting: {
                placeholder: "",
                options: []
            },
            style: {
                containerCss: "col-lg-6 col-xl-6 col-md-6 col-sm-12 col-12"
            }
        },
    ],
    formSetting: {
        isViewOnly: false
    }
}