// dropdownUtils.js

import { ZERO } from "../../../../../data/constantVariables";
import { APIUrl } from "../../../../../utils/API/fetchBaseQuery";
import { addEditMemberModelData } from "../../../../membership/component/AddEditMemberModel.Data";
import { academicQualificationData } from "../AcademicQualification/AcademicQualification.Data";
import { AccountInformationFormData } from "../AccountInformation/AccountInformation.Data";
import { CurrentEmpData, careerHistoryData } from "../CareerHistory/CareerHistory.data";
import { handleConditionBaseFields } from "../CareerHistory/utils/CareerHistoryFormUtils";
import { certificateCourseData,certificateCourseConfig } from "../CertificateCourse/CertificateCourse.Data";
import { gmapMembershipData } from "../GmapMembership/GmapMembership.Data";
import { greenMarkProjectData,greenMarkProjectConfig } from "../GreenMarkProject/GreenMarkProject.Data";
import { personalParticularsData } from "../PersonalParticulars/PersonalParticulars.Data";

export const setFieldParameter = (apiResponse, section) => {
    const defaultCss = "col-md-4 my-4";
    const hiddenCss = "col-md-4 mb-2 d-none";
    const careerHistoryIndex = careerHistoryData.formFields.findIndex(field => field.id === "careerHistoryTable");
    const courseHistoryIndex = certificateCourseData.formFields.findIndex(field => field.id === "certificateHistoryTable");
    const greenMarkProjectIndex = greenMarkProjectData.formFields.findIndex(field => field.id === "greenMarkProjectTable");
    const pathWayField = certificateCourseData.formFields.find((item) => item.id === "gmaPathwayTypeID");
    const gmapCertificateTypeField = certificateCourseData.formFields.find((item) => item.id === "gmapCertificateTypeId");
    const gmaapCertificateTypeField = certificateCourseData.formFields.find((item) => item.id === "gmaapCertificateTypeId");
    const ihlCertificateTypeId = certificateCourseData.formFields.find((item)=> item.id === "IHLCertificateTypeId")
    const gmapPathwayTypeIDField = greenMarkProjectData.formFields.find((item) => item.id === "gmapPathwayTypeID");
    const gmaapPathwayTypeIDField = greenMarkProjectData.formFields.find((item) => item.id === "gmaapPathwayTypeID");

    if (section === "memberDetails") {
        if (apiResponse?.gmapProfessionTypeId) {
            handleConditionBaseFields(apiResponse?.gmapProfessionTypeId, "CurrentOtherProfessionName");
        }
        personalParticularsData.initialState.salutationsID = apiResponse.gmapSalutationId;
        personalParticularsData.initialState.fullName = apiResponse.fullName;
        personalParticularsData.initialState.primaryEmail = apiResponse.primaryEmail;
        personalParticularsData.initialState.secondaryEmail = apiResponse.secondaryEmail;
        personalParticularsData.initialState.dateOfBirthDate = apiResponse.dateOfBirth;
        personalParticularsData.initialState.isGenderMale = apiResponse.isGenderMale ? "male" : "female";
        personalParticularsData.initialState.mobilePhone = apiResponse.mobilePhone;
        personalParticularsData.initialState.addressLine1 = apiResponse.addressLine1;
        personalParticularsData.initialState.addressLine2 = apiResponse.addressLine2;
        personalParticularsData.initialState.addressLine3 = apiResponse.addressLine3;
        personalParticularsData.initialState.postalCode = apiResponse.postalCode;
        personalParticularsData.initialState.comments = apiResponse.professionalProfileDescription;
        personalParticularsData.initialState.nationalityID = apiResponse.gmapNationalityId;
        personalParticularsData.initialState.countryOfResID = apiResponse.countryofResidenceId;
        personalParticularsData.initialState.profilePhotoFile = apiResponse.professionalProfilPhotoName;
        personalParticularsData.initialState.linkedinAdd = apiResponse.linkedInAddressOrProfessionalHomepage;
        personalParticularsData.initialState.competenciesID = apiResponse.gmapCompetenciesId?.split(",")?.map(Number);
        personalParticularsData.initialState.otherProfessionQualificationID = apiResponse.gmapOtherProfessionalQualificationId?.split(",")?.map(Number);
        personalParticularsData.initialState.password = apiResponse.passwordSalt;
        personalParticularsData.initialState.confirmPassword = apiResponse.hashedPassword;
        academicQualificationData.initialState.graduateYearID = apiResponse.graduateYearId;
        academicQualificationData.initialState.highestQualificationID = apiResponse.gmapHighestRelevantQualificationId;
        academicQualificationData.initialState.academicQualification = apiResponse.academicInstitution;
        academicQualificationData.initialState.fieldOfStudy = apiResponse.fieldOfStudy;
        academicQualificationData.initialState.academicQualificationCertificationFile = apiResponse.academicCertificateName;
        CurrentEmpData.initialState.isUnemployed = apiResponse?.isCurrentlyEmployed || false;
        CurrentEmpData.initialState.CurrentCompanyName = apiResponse?.currentCompanyName;
        CurrentEmpData.initialState.CurrentPeriodFrom = apiResponse?.currentPeriodFrom;
        CurrentEmpData.initialState.CurrentPeriodTo = apiResponse?.currentPeriodTo;
        CurrentEmpData.initialState.CurrentDesignation = apiResponse?.currentDesignation;
        CurrentEmpData.initialState.GMAPProfessionTypeId = apiResponse?.gmapProfessionTypeId?.value || apiResponse?.gmapProfessionTypeId;

        CurrentEmpData.initialState.CurrentOtherProfessionName = apiResponse?.otherProfessionName;
        if (careerHistoryIndex !== -1) {
            const updatedFormData = { ...careerHistoryData };
            updatedFormData.formFields[careerHistoryIndex].fieldSetting.dataSource = apiResponse.gmapCareerHistoryList;
        }
        //hide and show fields  
        pathWayField.style.containerCss = (apiResponse.gmapApplicationTypeId === 1) ? defaultCss : hiddenCss;
        gmapCertificateTypeField.style.containerCss = (apiResponse.gmapApplicationTypeId === 2) ? "col-md-6 mb-2" : hiddenCss;
        gmaapCertificateTypeField.style.containerCss = (apiResponse.gmapApplicationTypeId === 3) ? "col-md-6 mb-2" : hiddenCss;
        gmapPathwayTypeIDField.style.containerCss = (apiResponse.gmapApplicationTypeId === 2) ? defaultCss : hiddenCss;
        gmaapPathwayTypeIDField.style.containerCss = (apiResponse.gmapApplicationTypeId === 3) ? defaultCss : hiddenCss;
        ihlCertificateTypeId.style.containerCss=(apiResponse.gmapApplicationTypeId === 1)? "col-md-6 mb-2" : hiddenCss;

        //add and remove validation on fields
        pathWayField.validation = (apiResponse.gmapApplicationTypeId === 1) ? [{ type: "require" }] : [];
        gmapCertificateTypeField.validation = (apiResponse.gmapApplicationTypeId === 2) ? [{ type: "require" }] : [];
        gmaapCertificateTypeField.validation = (apiResponse.gmapApplicationTypeId === 3) ? [{ type: "require" }] : [];
        gmapPathwayTypeIDField.validation = (apiResponse.gmapApplicationTypeId === 2) ? [{ type: "require" }] : [];
        gmaapPathwayTypeIDField.validation = (apiResponse.gmapApplicationTypeId === 3) ? [{ type: "require" }] : [];
        if (courseHistoryIndex !== -1) {
            const updatedFormData = { ...certificateCourseData };
            let tempCertificateListResponse = apiResponse.gmapCertificationCourseList
            tempCertificateListResponse = tempCertificateListResponse.map((obj) => {
                return { ...obj, certificateLink: `${APIUrl}/Common/getPDF?type=CertificateMarketRefresherCourse&fileName=${obj.certificateAttachment}` }
            })
            updatedFormData.formFields[courseHistoryIndex].fieldSetting.dataSource = tempCertificateListResponse;
        }
        
        certificateCourseData.initialState.gmaPathwayTypeID = apiResponse?.gmapApplicationTypeId === 1 ? apiResponse?.gmapCertificationCourseList[0]?.gmaPathwayTypeID : null
        if (greenMarkProjectIndex !== -1) {
            const updatedFormData = { ...greenMarkProjectData };
            let tempGMProjectListData = apiResponse.gmapGreenMarkProjectList
            tempGMProjectListData = tempGMProjectListData.map((obj) => {
                return {
                    ...obj,
                    clientLetterOfEndorsementLink: `${APIUrl}/Common/getPDF?type=LetterofEndorsement&fileName=${obj.clientLetterOfEndorsement}`,
                    projectLetterOfAwardLink: `${APIUrl}/Common/getPDF?type=GreenMarkProjectLetterOfAward&fileName=${obj.greenMarkProjectLetterOfAward}`,
                    proofofJobDescriptionLink: `${APIUrl}/Common/getPDF?type=ProofOfJobDesc&fileName=${obj.proofofJobDescription}`,
                    proofofsignificantinvolvementLink: `${APIUrl}/Common/getPDF?type=ProofOfSignificantInvolvement&fileName=${obj.proofofsignificantinvolvement}`
                }
            })
            updatedFormData.formFields[greenMarkProjectIndex].fieldSetting.dataSource = tempGMProjectListData;
        }
    } else if (section === "accountInformation") {
        AccountInformationFormData.initialState.accountType = apiResponse.gmapApplicationTypeId;
        AccountInformationFormData.initialState.joinedDate = apiResponse.submissionDate;
        AccountInformationFormData.initialState.accountstatus = apiResponse.statusId;
        AccountInformationFormData.initialState.gmapStatus = apiResponse.subscriptionStatusId
        AccountInformationFormData.initialState.billingCategory = apiResponse.billingCategoryId;
        AccountInformationFormData.initialState.gmapNumber = apiResponse.gmapNumber;
        AccountInformationFormData.initialState.subscriptionStartDate = apiResponse.qualificationStartDate;
        AccountInformationFormData.initialState.subscriptionEndDate = apiResponse.qualificationEndDate;
        AccountInformationFormData.initialState.updatedBy = apiResponse.updatedBy;
        AccountInformationFormData.initialState.updatedDate = apiResponse.updatedDate;
        AccountInformationFormData.initialState.appeal = apiResponse.isAppeal;
        AccountInformationFormData.initialState.qualify = apiResponse.isQualify;
        AccountInformationFormData.initialState.voidLateFee = apiResponse.isvoidLateFee;
        AccountInformationFormData.initialState.pendingExpiry = apiResponse.isPendingExpiry;
    }
    else if (section === "viewMemberDirectory") {
        addEditMemberModelData.initialState.name = apiResponse.gmapContactName;
        addEditMemberModelData.initialState.companyName = apiResponse.companyName;
        addEditMemberModelData.initialState.gmapNumber = apiResponse.mobilePhone;
        addEditMemberModelData.initialState.gmapTier = apiResponse.gmapApplicationType;
        addEditMemberModelData.initialState.areaofCompetencies = apiResponse.gmapCompetenciesId?.split(",").map(Number);
        addEditMemberModelData.initialState.typeofProfessionalQualification = apiResponse.gmapOtherProfessionalQualificationId?.split(",").map(Number);
        addEditMemberModelData.initialState.linkedin = apiResponse.linkedInAddressOrProfessionalHomepage;
        addEditMemberModelData.initialState.description = apiResponse.professionalProfileDescription;

    }
    else if (section === "membershipAccResponse") {
        gmapMembershipData.initialState.accountType = apiResponse.gmapApplicationTypeId;
        gmapMembershipData.initialState.billingCategory = apiResponse.billingCategoryId;
        gmapMembershipData.initialState.subscriptionStartDate = apiResponse.qualificationStartDate;
        gmapMembershipData.initialState.subscriptionEndDate = apiResponse.qualificationEndDate;
        gmapMembershipData.initialState.updatedBy = apiResponse.updatedBy;
        gmapMembershipData.initialState.lastUpdatedDate = apiResponse.updatedAt;
        gmapMembershipData.initialState.penaltyCharge = apiResponse.isPendingExpiry;
        gmapMembershipData.initialState.qualify = apiResponse.isQualify;
        gmapMembershipData.initialState.pendingExpiry = apiResponse.ispenaltyCharge;

    }

};

export const setCourseRequirementGrid = (gmapApplicationTypeId) => {
    let courseRequirementgrid = { ...certificateCourseConfig }
    let greenMarkProjectgrid = { ...greenMarkProjectConfig }
    let courseRequirementGridColumns = courseRequirementgrid?.columns;
    let greenMarkProjectGridColumns = greenMarkProjectgrid?.columns;
    courseRequirementGridColumns = courseRequirementGridColumns.filter(obj => obj.fieldName !== "gmaCertificateName" && obj.fieldName !== "gmaapCertificateName" && obj.fieldName !== "certificateName")
    greenMarkProjectGridColumns = greenMarkProjectGridColumns.filter(obj => obj.fieldName !== "gmapAwardRatingName" && obj.fieldName !== "gmapGreenMarkCategoryName" && obj.fieldName !== "gmaapAwardRatingName" && obj.fieldName !== "gmaapGreenMarkCategoryName")
  
    switch (gmapApplicationTypeId) {
        case 1: {
            courseRequirementGridColumns.splice(ZERO, ZERO, {
                name: "Certificate Type",
                fieldName: "certificateName", // this is GMA
            })
            break;
        }
        case 2: {
            courseRequirementGridColumns.splice(ZERO, ZERO, {
                name: "Certificate Type",
                fieldName: "certificateName", // this is GMAP
            })
            greenMarkProjectGridColumns.splice(1, ZERO, {
                name: "Green Mark Category",
                fieldName: "gmapGreenMarkCategoryName",
            },)
            greenMarkProjectGridColumns.splice(1, ZERO, {
                name: "Award Rating",
                fieldName: "gmapAwardRatingName",
            },)
  
            break;
        }
        case 3: {
            courseRequirementGridColumns.splice(ZERO, ZERO, {
                name: "Certificate Type",
                fieldName: "certificateName", //this is GMAP
            })
            greenMarkProjectGridColumns.splice(1, ZERO, {
                name: "Green Mark Category",
                fieldName: "gmaapGreenMarkCategoryName",
            },)
            greenMarkProjectGridColumns.splice(1, ZERO, {
                name: "Award Rating",
                fieldName: "gmaapAwardRatingName",
            },)
            break;
        }
    }
    certificateCourseConfig.columns = courseRequirementGridColumns
    greenMarkProjectConfig.columns = greenMarkProjectGridColumns
  
  }