import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import FormCreator from "../../components/Forms/FormCreator";
import Buttons from "../../components/ui/button/Buttons";
import loginFormData from "./data/LoginForm.data";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ToastService from "../../services/toastService/ToastService";
import { authentication } from "../../app/slice/authSlice";
import { useUserLoginMutation } from "../../app/services/login/authApi";

const LoginForm = (props) => {
  const loginFromRef = useRef();
  const { isLogedin } = useSelector((state) => state.auth)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Declare admin login API
  const [login, { isLoading: loginLoading, isSuccess: loginSuccess, data: authData }] = useUserLoginMutation();

  useEffect(() => {
    if (!loginLoading && loginSuccess && authData) {
      if (authData.isAuthenticated === false) {
        ToastService.error(authData.message);
      } else if (authData.isAuthenticated === true) {
        ToastService.success("You are login successfully", "Success");
        dispatch(authentication(authData));
      }
    }
  }, [loginLoading, loginSuccess, authData]);

  // Effect to handle navigation based on the authentication
  useEffect(() => {
    if (isLogedin) {
      navigate(`/`);
    }
  }, [isLogedin])


  const handleLogin = () => {
    // Retrieve form data
    const authData = loginFromRef.current?.getFormData();

    // Check if form data exists
    if (!authData) return;

    // Create the request object
    const request = {
      userName: authData.emailAddress,
      password: authData.password,
    };

    // Proceed with the login if form data exists
    login(request);
  };

  return (
    <div className="login-form-section">
      <h2>Login</h2>
      <FormCreator
        ref={loginFromRef}
        {...loginFormData}
      ></FormCreator>

      <div className="button-part">
        {loginLoading ?
          <Buttons
            buttonTypeClassName="btn success-btn"
            buttonText="Login"
            isLoading={loginLoading}
          />
          :
          <Buttons
            buttonTypeClassName="btn success-btn"
            buttonText="Login"
            onClick={handleLogin}
          // isLoading={loginLoading}
          />
        }
      </div>
    </div>
  );
};

export default LoginForm;
