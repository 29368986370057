import React, { useEffect, useRef, useState } from 'react'
import DropdownManagementFormData from './dataFile/DropdownManagementForm.Data';
import FormCreator from '../../../components/Forms/FormCreator';
import DataLoader from '../../../components/ui/dataLoader/DataLoader';
import { useAddEditDropdownDataMutation, useDeleteDropdowndataMutation, useGetDropdownRecordListMutation, useLazyGetDropdownDatabyIdQuery } from '../../../app/services/FieldManagement/DropdoenManagement/DropdownManagementApi';
import MolGrid from '../../../components/Grid/MolGrid';
import { DropdownSelect3Config, DropdownSelect2Config, DropdownSelectConfig } from './dataFile/DropdownSelectConfig';

import SwalAlert from '../../../services/swalService/SwalService';
import Buttons from '../../../components/ui/button/Buttons';
import FinalMolGrid from '../../../components/FinalMolGrid/FinalMolGrid';

const DropdownManagementFeature = () => {

    const { success, error, confirm } = SwalAlert()
    const [selectedDropdownId, setSelectedDropDownId] = useState();
    const [isShowAdd, setIsShowAdd] = useState(false);
    const [isEditing_, setisEditing_] = useState(false);
    const DropDownSelectRef = useRef(null);
    const DropdownManagementFeatureRef = useRef();
    const [dropdownSelected, setDropdownSelected] = useState(null)

    const [getDropdownRecordList, {
        isSuccess: isSuccessDropdown,
        isFetching: isFetchingDropdown,
        data: dropdownResponse
    }] = useGetDropdownRecordListMutation()

    const [getDropdownDatabyId, {
        isSuccess: isSuccessEmailById,
        isFetching: isFetchingEmailById,
        data: dropdownByIdResponse
    }] = useLazyGetDropdownDatabyIdQuery();

    const [deleteDropdowndata, {
        isSuccess: isSuccessdeleteDropdowndata,
        data: deleteDropdowndataResponse
    }] = useDeleteDropdowndataMutation()

    const [addEditDropdown, {
        isSuccess: isSuccessAddEditDropdown,
        isFetching: isFetchingAddEditDropdown,
        data: addEditDropdownResponse
    }] = useAddEditDropdownDataMutation()

    useEffect(() => {
        if (!isFetchingAddEditDropdown && isSuccessAddEditDropdown && addEditDropdownResponse) {
            if (addEditDropdownResponse.hasError === false) {
                success("Dropdown update Successfully")
                getDropdownDatabyId(selectedDropdownId)
            }
            else {
                error(addEditDropdownResponse.errorMessage)
            }
        }
    }, [isFetchingAddEditDropdown, isSuccessAddEditDropdown, addEditDropdownResponse]);

    useEffect(() => {
        if (isSuccessdeleteDropdowndata && deleteDropdowndataResponse) {
            if (deleteDropdowndataResponse.hasError === false) {
                success("Dropdown Deleted Successfully");
                getDropdownDatabyId(selectedDropdownId)
            }
            else {
                error(deleteDropdowndataResponse.errorMessage)
            }
        }
    }, [isSuccessdeleteDropdowndata, deleteDropdowndataResponse]);

    useEffect(() => { getDropdownRecordList() }, [])

    useEffect(() => {
        if (!isFetchingEmailById && isSuccessEmailById && dropdownByIdResponse) {
            setDropdownSelected(dropdownByIdResponse)
        }
    }, [isFetchingEmailById, isSuccessEmailById, dropdownByIdResponse]);

    useEffect(() => {
        if (!isFetchingDropdown && isSuccessDropdown && dropdownResponse) {

            let field = DropdownManagementFormData?.formFields?.find(obj => obj.id === "Dropdown")
            field.fieldSetting.options = dropdownResponse?.map((obj) => ({ value: obj.id, label: obj.name }))
        }
    }, [isFetchingDropdown, isSuccessDropdown, dropdownResponse]);

    const onDropDownChange = (data) => {
        debugger
        const { value } = data.Dropdown
        setSelectedDropDownId(value);
        setIsShowAdd(true);
        getDropdownDatabyId(value)
    }

    const handleSave = (data) => {
        debugger
        console.log(data)
        // let temp = dropdownSelected.map(obj => obj.id === data.id ? data : obj);
        const request = {
            DropdownId: selectedDropdownId,
            TabelDataId: data.id ? data.id : 0,
            Name: data.name,
            ValueOrdering: parseInt(data.valueOrdering) ? parseInt(data.valueOrdering) : "",
            CreatedBy: 115,
            CreatedAppId: 1,
            GMPDW: parseInt(data.cpdPointsforBCA) ? parseInt(data.cpdPointsforBCA) : "",
            StartDate: data.startDate,
            EndDate: data.endDate
        }
        debugger
        addEditDropdown(request);
    }
    const handleDelete = (data) => {
        debugger
        confirm('Delete', "Are you sure you want to delete", 'Yes', 'Cancel')
            .then(value => {
                if (value) {
                    const request = {
                        TabelDataId: data.id,
                        Id: selectedDropdownId,
                        deletedBy: 115,
                        appId: 1
                    }
                    deleteDropdowndata(request)
                }
            })
    }
    const handleAdd = () => {
        debugger
        let temp = [...dropdownSelected]
        let valueOrdering = (dropdownSelected[dropdownSelected.length - 1].valueOrdering)
            ? (dropdownSelected[dropdownSelected.length - 1].valueOrdering) + 1 : null
        temp.push({
            id: 0,
            name: "",
            valueOrdering: valueOrdering
        })
        setDropdownSelected(temp)


    }

    const actionHandler = {
        SAVE: handleSave,
        DELETE: handleDelete,
    }
    const onRowDataUpdate = (updateData, rowIndexToUpdate) => {
        handleSave(updateData);
    }
    const onRowDataDelete = (deleteData, rowIndexToUpdate) => {
        debugger
        handleDelete(deleteData);
    }

    return (
        <>
            {dropdownResponse ?
                <div className='row'>
                    <FormCreator
                        ref={DropdownManagementFeatureRef}
                        {...DropdownManagementFormData}
                        onFormDataChange={onDropDownChange}
                    >
                    </FormCreator>
                    <div className={`col-6 d-flex justify-content-end mb-4 align-items-end ${isShowAdd ? '' : 'd-none'}`}>
                        <Buttons
                            buttonText="+ Add New"
                            buttonTypeClassName="outline-btn"
                            onClick={handleAdd}
                        />
                    </div>
                </div>
                :
                <DataLoader />
            }
            <FinalMolGrid
                ref={DropDownSelectRef}
                configuration={selectedDropdownId === 12 ? DropdownSelect2Config : selectedDropdownId == 13 ? DropdownSelect3Config : DropdownSelectConfig}
                dataSource={dropdownSelected}
                allowPagination={false}
                onActionChange={actionHandler}
                isLoading={isFetchingEmailById}
                allowAddGridItem={true}
                onRowDataUpdate={onRowDataUpdate}
            //abbButtonText="Add Dropdown"
            />

            {/* {dropdownSelected && <CustomDataGrid rows={dropdownSelected} />} */}
        </>
    )
}

export default DropdownManagementFeature


export const CustomDataGrid = ({ rows }) => {
    debugger
    const [data, setData] = useState(rows);

    const editRow = (id) => {
        setData((prevData) =>
            prevData.map((row) =>
                row.id === id
                    ? { ...row, isEditing: true }
                    : row
            )
        );
    };

    const cancelEdit = (id) => {
        debugger
        setData((prevData) =>
            prevData.map((row) =>
                row.id === id
                    ? { ...row, isEditing: false }
                    : row
            )
        );
    };

    const saveRow = (id) => {
        setData((prevData) =>
            prevData.map((row) => {
                if (row.id === id) {
                    const updatedRow = {
                        ...row,
                        isEditing: false,
                    };
                    Object.keys(row).forEach((key) => {
                        updatedRow[key] = document.getElementById(`${key}-${id}`).value;
                    });
                    return updatedRow;
                }
                return row;
            })
        );
    };

    const deleteRow = (id) => {
        debugger
        setData((prevData) => prevData.filter((row) => row.id !== id));
    };

    return (
        <table>
            <thead>
                <tr>
                    {Object.keys(rows[0]).map((key) => (
                        <th key={key}>{key}</th>
                    ))}
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <p>{JSON.stringify(data)}</p>
                {data.map((row) => (
                    <tr key={row.id}>
                        {Object.keys(row).map((key) => (
                            <td key={`${key}-${row.id}`}>
                                {row.isEditing && key !== 'id' ? (
                                    <><p>{key}</p>
                                        <input
                                            type="text"
                                            defaultValue={row[key]}
                                            id={`${key}-${row.id}`}
                                        />
                                    </>
                                ) : (
                                    row[key]
                                )}
                            </td>
                        ))}
                        <td>
                            {row.isEditing ? (
                                <>
                                    <button onClick={() => saveRow(row.id)}>Save</button>
                                    <button onClick={() => cancelEdit(row.id)}>Cancel</button>
                                </>
                            ) : (
                                <>
                                    <button onClick={() => editRow(row.id)}>Edit</button>
                                    <button onClick={() => deleteRow(row.id)}>Delete</button>
                                </>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

