import { FormFieldTypes } from "../../../../data/formFieldType";

export const CurrentCpdCyclePointFormData = {
    initialState: {
        typeofActivity: '',
        titleofActivity: '',
        dateofActivity: '',
        cpdPoints: "",
        activityCertificate: "",
        remarks: "",
    },
    formFields: [
        {
            id: "typeofActivity",
            lable: "Type of Activity",
            Field_Name: "Type of Activity",
            fieldType: FormFieldTypes.INPUT,
            dataField: "typeofActivity",
            fieldSetting: {
                placeholder: "",
                options: [],
                // value: "Research Paper 1",
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mb-3"
            }
        },
        {
            id: "titleofActivity",
            lable: "Title of Activity",
            Field_Name: "Title of Activity",
            fieldType: FormFieldTypes.INPUT,
            dataField: "titleofActivity",
            fieldSetting: {
                placeholder: "",
                // value: "Mr Alex Wong",
                allowSpace: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mb-3",
            },
        },
        {
            id: "dateofActivity",
            lable: "Date of Activity",
            Field_Name: "Date of Activity",
            fieldType: FormFieldTypes.DATEPICKER,
            dataField: "dateofActivity",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                options: []
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mb-3"
            }
        },

        {
            id: "cpdPoints",
            lable: "CPD Points",
            Field_Name: "CPD Points",
            fieldType: FormFieldTypes.INPUT,
            dataField: "cpdPoints",
            isViewOnly: true,
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
                // value: 6,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mb-3"
            }
        },
        {
            id: "activityCertificate",
            lable: 'Activity Certificate (please upload in PDF format max 2mb) ',
            subLable: "Research Paper 1.pdf",
            linkName: "View",
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'activityCertificate',
            fieldSetting: {
                // presentFileName: null,
                placeholder: 'Select Profile Photo',
                isDownloadable: true,
                acceptedFiles: '.pdf',
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6 mb-3"
            }
        },
        {
            id: "remarks",
            lable: "Remarks",
            Field_Name: "Remarks",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "remarks",
            fieldSetting: {
                placeholder: "",
                // value: "Request 8 CPD points",
                allowSpace: true,
                maxLength: 4000,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-lg-12 col-12 mb-3",
            },
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}