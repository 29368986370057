import { useState } from "react";
import GridCheckbox from "../ui/checkbox/GridCheckbox";

// Function for rendering the checkbox column
const RenderGridCheckboxColumn = ({
  rowData,
  col,
  rowIndex,
  onColumnDataChange
}) => {

  const [value, setValue] = useState(rowData[col.fieldName]);
  const { colSettings, fieldName } = col;
  const isDisabled = colSettings?.isDisabled === false;

  const handleCheckboxChange = (e) => {
    const newValue = !value;
    setValue(newValue);

    if (onColumnDataChange) {
      const newRowData = { ...rowData, [fieldName]: newValue };
      onColumnDataChange(fieldName, newRowData, rowIndex);
    }
  };

  return (
    <GridCheckbox
      type="checkbox"
      checked={value}
      disabled={isDisabled}
      onChange={(handleCheckboxChange)}
    />
  );
};


export default RenderGridCheckboxColumn