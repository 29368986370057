/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
import CareerHistoryForm from "./component/CareerHistoryForm";
import { careerHistoryData } from "./CareerHistory.data";
import { CreateIndividualMembersContext } from "../../../../../pages/gmap/viewIndividualMembers/createIndividualMembers";
import Buttons from "../../../../../components/ui/button/Buttons";
import { useAddEditGMAPCareerHistoryMutation, useDeleteGMAPCareerHistoryMutation, useLazyGetGMAPCareerHistoryListQuery } from "../../../../../app/services/GMAP/careerHistoryApi";
import SwalAlert from "../../../../../services/swalService/SwalService";
import ToastService from "../../../../../services/toastService/ToastService";

const CareerHistory = forwardRef((props, ref) => {
    const { newMemberData, setContextData } = useContext(CreateIndividualMembersContext);
    const careerHistoryRef = useRef();
    const [careerHistoryFormData, setCareerHistoryFormData] = useState(careerHistoryData.initialState);
    const [isEdit, setIsEdit] = useState(false);
    const [addEditGMAPCareerHistory,
        { isSuccess: isAddGMAPCareer,
            data: addEditCareerHistoryData }
    ] = useAddEditGMAPCareerHistoryMutation()

    const [getGMAPCareerHistoryList,
        { isSuccess: isGetGMAPCareerHistoryFetched,
            isFetching: isGetGMAPCareerHistoryFetching,
            data: CareerHistoryListResponse },
    ] = useLazyGetGMAPCareerHistoryListQuery();

    const [deleteGMAPCareerHistory,
        { isSuccess: isDeleteSuccess,
            data: deleteCareereHistory },
    ] = useDeleteGMAPCareerHistoryMutation();

    const { confirm, error } = SwalAlert();

    useEffect(() => {
        if (isAddGMAPCareer && addEditCareerHistoryData) {
            ToastService.success("Add Item successfully")
            const data = responceValid(addEditCareerHistoryData);
            if (data) {
                getCareerHistoryList();
            }
        }
    }, [isAddGMAPCareer, addEditCareerHistoryData]);
    useEffect(() => {
        if (isDeleteSuccess && deleteCareereHistory) {
            ToastService.error("Delete Item successfully");
            getCareerHistoryList();
        }
    }, [isDeleteSuccess && deleteCareereHistory])

    useEffect(() => {
        if (!isGetGMAPCareerHistoryFetching && isGetGMAPCareerHistoryFetched && CareerHistoryListResponse) {

            const tableIndex = careerHistoryData.formFields.findIndex(field => field.id === "careerHistoryTable");
            if (tableIndex !== -1) {
                const updatedFormData = { ...careerHistoryData };
                updatedFormData.formFields[tableIndex].fieldSetting.dataSource = CareerHistoryListResponse;
            }
            resetForm()
        }
    }, [isGetGMAPCareerHistoryFetching, isGetGMAPCareerHistoryFetched, CareerHistoryListResponse])

    const responceValid = (responce) => {
        if (responce !== null) {
            const data = responce;
            if (data.errorMessage === "") {
                return data;
            }
            else {
                alert("error")
            }
        }
    }
    const getCareerHistoryList = async () => {
        let request = [newMemberData.gMAPMemberID]
        getGMAPCareerHistoryList(request);
    }
    const handleAdd = () => {
        let careerdetails = careerHistoryRef.current.getFormData();
        if (careerdetails) {
            let careerObjReq = {
                "isUnemployed": careerdetails.isUnemployed,
                "companyName": careerdetails.companyName || "-",
                "designation": careerdetails.designation || "-",
                "periodFrom": careerdetails.periodFrom,
                "periodTo": careerdetails.periodTo,
                "isCurrent": careerdetails.isCurrent,
                "gmapProfessionTypeId": careerdetails.gmapProfessionTypeId?.value || careerdetails.gmapProfessionTypeId,
                "AppID": 114,
                "gMAPMemberId": newMemberData.gMAPMemberID,
            };
            if (!careerdetails.gmapCareerHistoryId && !isEdit) {
                addEditGMAPCareerHistory(careerObjReq);
            } else if (careerdetails.gmapCareerHistoryId && isEdit) {
                careerObjReq.gmapCareerHistoryId = careerdetails.gmapCareerHistoryId;
                addEditGMAPCareerHistory(careerObjReq);
                resetForm();
            }
        }
    }
    const handleTableEdit = (data) => {
        setIsEdit(true);
        setCareerHistoryFormData(data);
    }
    const handleTableDelete = (data) => {
        let key = data.gmapCareerHistoryId;
        let request = {
            Id: key,
            AppId: 114,
            DeletedBy: newMemberData.gMAPMemberID,
        }
        confirm("Delete?", "Are you sure you want to delete?", "Delete", "Cancel")
            .then((confirmed) => {
                if (confirmed) {
                    deleteGMAPCareerHistory(request);
                    resetForm();
                }
            });


    }
    //** Reset Data */
    const resetForm = () => {
        let form = { ...careerHistoryData.initialState };
        setCareerHistoryFormData(form);
        setIsEdit(false);
    };

    return (
        <React.Fragment>
            <CareerHistoryForm
                ref={careerHistoryRef}
                initData={careerHistoryFormData}
                isEdit={isEdit}
                onReset={resetForm}
                handleTableEdit={handleTableEdit} handleTableDelete={handleTableDelete}
            />
            <div className="col-md-12 mb-2 d-flex justify-content-end">
                <Buttons
                    buttonText={isEdit ? "Update" : "+ Add"}
                    buttonTypeClassName="outline-btn"
                    onClick={handleAdd}
                />
            </div>
        </React.Fragment>
    )
})

export default CareerHistory