import React, { useRef, useState } from 'react'
import * as XLSX from "xlsx";
import * as yup from "yup";
import SwalAlert from '../../../../services/swalService/SwalService';
import Buttons from '../../../../components/ui/button/Buttons';
import CenterModel from '../../../../components/ui/centerModel/CenterModel';
import { useUploadCpdBatchMutation } from '../../../../app/services/CPD/CPDApi';
import moment from 'moment';

const UploadCpdButton = ({ getUploadedCpdList }) => {

    const { error, success } = SwalAlert();
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isUploadSuccess, setIsUploadSuccess] = useState(false);
    const [fileName, setFileName] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [successExcelResponse, setSuccessExcelResponse] = useState([]);
    const fileInputRef = useRef(null);

    const [uploadCPD, { data: cpdUploadResponse, isSuccess, isLoading }] = useUploadCpdBatchMutation();


    const schema = yup.object().shape({
        Event_Name: yup.string().required("Event name is required"),
        CPD_Points: yup
            .number()
            .required("CPD points is required"),
        Event_Organiser: yup.string().required("Event Organizer name is required"),
        Event_Date: yup.date().required("Event date is required"),
        GMA_Name: yup.string().required("GMA name is required"),
        GMA_Number: yup.string().required("GMA number is required"),
    });

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        setFileName(file?.name);
        setIsUploadSuccess(false);
        if (!file) {
            error("No file selected", "");
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            const arrayBuffer = e.target.result;
            const workbook = XLSX.read(arrayBuffer, {
                type: "array",
                cellDates: true,
                cellText: false
            });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false, dateNF: 'dd-mmm-yyyy' });
            setData(jsonData);
            validateData(jsonData);
            setShowModal(true)
        };
        reader.readAsArrayBuffer(file);
    }

    const validateData = async (jsonData) => {
        const validationErrors = [];
        for (let i = 0; i < jsonData.length; i++) {
            try {
                await schema.validate(jsonData[i], { abortEarly: false });
            } catch (err) {
                validationErrors.push({ row: i + 1, errors: err.errors });
            }
        }
        setErrors(validationErrors);
    };

    const handleModalClose = () => {
        setShowModal(false);
        // setFile(null); // Reset file state
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Reset file input value
        }
        getUploadedCpdList({ pageNo: 1, pageSize: 10 });
    };

    // Dynamically get headers from the first item in the data array
    const headers = data.length > 0 ? Object.keys(data[0]) : [];

    const convertedHeaders = headers.map(header =>
        header
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/\s*\(\s*/g, ' (') // Handle spacing before opening parenthesis
            .replace(/\s*\)\s*/g, ')') // Handle spacing after closing parenthesis
    );

    const handleSubmit = async () => {
        if (errors.length > 0) {
            return error("Please fix the errors before submitting.", "");
        }
        const request = {
            UploadCpdDetails: data.map((item) => ({
                EventName: item.Event_Name || null,
                EventOrganiser: item.Event_Organiser || null,
                EventDate: item.Event_Date || null,
                CpdPoint: item.CPD_Points || null,
                GmaNumber: item.GMA_Number || null,
                GmaName: item.GMA_Name || null,
            }))
        }
        const successHandler = (response, successMessage) => {
            if (response?.length > 0) {
                success("", successMessage);
                setSuccessExcelResponse(response);
                setIsUploadSuccess(true);
            }
        };
        const response = await uploadCPD(request);
        successHandler(response.data, "CPD Uploades Successfully");
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Reset file input value
        }
    };

    return (
        <>
            <div className="row">
                <div className='col-lg-12 d-flex'>
                    <div className="file-upload-wrapper">
                        <input
                            ref={fileInputRef}
                            id='file-input'
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileUpload}
                            className="file-input"
                        />
                        <label
                            htmlFor="file-input"
                            className="file-upload-label"
                        >
                            Upload CPD
                        </label>
                    </div>
                    <a className="btn outline-btn ml-3" href={`${process.env.PUBLIC_URL}/UploadCpdTemplate/CPDUploadTemplate.xls`} download>
                        Download Template
                    </a>
                </div>
                {showModal && (
                    <CenterModel
                        modelTitle={`${fileName}`}
                        show={showModal}
                        handleClose={handleModalClose}
                        onUpload={handleModalClose}
                    >
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-lg-12 mt-4 table-responsive mol-grid">
                                    <table className="mt-2">
                                        <thead className="">
                                            <tr>
                                                {convertedHeaders.map((header) => {
                                                    return <th key={header}>{header}</th>
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, rowIndex) => {
                                                const rowErrors = errors.find(
                                                    (error) => error.row === rowIndex + 1
                                                );
                                                const hasError = rowErrors ? "bg-danger" : "";
                                                return (
                                                    <tr key={rowIndex} className={hasError}>
                                                        {headers.map((header) => {
                                                            const cellError =
                                                                rowErrors &&
                                                                rowErrors.errors.some((err) =>
                                                                    err.includes(header)
                                                                );
                                                            return (
                                                                <td
                                                                    key={header}
                                                                    className={cellError ? "text-danger" : ""}
                                                                >
                                                                    {item[header]}
                                                                    {cellError && (
                                                                        <div className="small text-danger">
                                                                            {rowErrors.errors.join(", ")}
                                                                        </div>
                                                                    )}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    {isUploadSuccess && (
                                        <React.Fragment>
                                            <h4 className="mt-5">Status:</h4>
                                            <hr />
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Event Name</th>
                                                        <th>Event Organiser</th>
                                                        <th>Event Date</th>
                                                        <th>CPD Points</th>
                                                        <th>GMA Name</th>
                                                        <th>GMA Number</th>
                                                        <th>Message</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {successExcelResponse?.map((item, index) => {
                                                        const Status = item.errorMessage === "Add Successfully" ? "Approved" : "Error";
                                                        return (
                                                            <tr key={index} className={Status === "Error" ? "bg-danger" : ""} >
                                                                <td>{item?.eventName}</td>
                                                                <td>{item?.eventOrganiser}</td>
                                                                <td>{moment(item?.eventDate).format('DD-MMM-yyyy')}</td>
                                                                <td>{item?.cpdPoint}</td>
                                                                <td>{item?.gmaName}</td>
                                                                <td>{item?.gmaNumber}</td>
                                                                <td>{item?.errorMessage}</td>
                                                                <td>{Status || "-"}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </React.Fragment>
                                    )}
                                </div>
                                <div className="col-lg-12">
                                    {errors.length > 0 && (
                                        <div className="mt-3">
                                            <h5>Errors:</h5>
                                            <ul>
                                                {errors.map((error, index) => (
                                                    <li key={index}>
                                                        Row {error.row}: {error.errors.join(", ")}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                {!isUploadSuccess && (
                                    <div className="col-lg-12 d-flex justify-content-end mt-4">
                                        <div>
                                            <Buttons
                                                buttonTypeClassName="outline-btn"
                                                buttonText="Cancel"
                                                onClick={handleModalClose}
                                            />
                                        </div>
                                        <div className="ml-2">
                                            <Buttons
                                                buttonTypeClassName="success-btn"
                                                buttonText="Submit"
                                                onClick={handleSubmit}
                                                isLoading={isLoading}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </CenterModel>
                )}
            </div>

        </>
    )
}

export default UploadCpdButton