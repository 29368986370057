// Menus & Header Images

// Others
import calender from "../assets/images/calender.png";
import logo from "../assets/images/logo.png";
// import logotext from "../assets/images/logo-text.png";
import edit from "../assets/images/editIcon.png";
import deleted from "../assets/images/deleteIcon.png";
import copy from "../assets/images/copy.png";
import user from "../assets/images/user.png";
import search from "../assets/images/search.png";
import notification from "../assets/images/notification.png";
import arrow from "../assets/images/arrow.png";
import setting from "../assets/images/setting.png";
import logout from "../assets/images/logout.png";


// footer logo
import footerLogo from "../assets/images/footer-logo.png";
import fourzerofour from "../assets/images/404.png";
import linkedin from "../assets/images/linkedin.png";
import gmapProfile from "../assets/images/gmap-profile.png";

// dashboard images
import dashboardQrcode from "../assets/images/dashboard-qrcode.png";


import cpdIcon from "../assets/images/continuing-professional-development.png";
import PqIcon from "../assets/images/Professionalqualification.png";
import pendingApplication from "../assets/images/Peddingapplication.png";
import upcommingEvent from "../assets/images/upcomming-event-booking.png";

import eventBookingDate from "../assets/images/event-booking-date.svg";
import eventBookingLocation from "../assets/images/event-booking-location.svg";
import workshopIcon from "../assets/images/workshop-icon.svg";
import dummySliderImage from "../assets/images/dummy-slider.png";



import EditTable from "../assets/images/EditTable.png";
import sucessIcon from "../assets/images/sucess-check.png";
import checkNot from "../assets/images/checkNot.png";

import lockIcon from "../assets/images/lock.svg";
import unLockIcon from "../assets/images/unlock.svg";

import noRecordIcon from "../assets/images/noRecord.png";
import defaultImg from "../assets/images/default-img.png";
import swapIcon from "../assets/images/Swap-icon.png"


export const AppIcons = {

  // Menus & Header Images

  // Others
  calenderIcon: calender,
  logoImage: logo,
  // logoText: logotext,
  editIcon: edit,
  deleteIcon: deleted,
  copyIcon: copy,
  userIcon: user,
  searchIcon: search,
  notificationIcon: notification,
  arrowIcon: arrow,
  settingIcon: setting,
  logoutIcon: logout,
  logo: logo,
  DefaultImg: defaultImg,

  // footer logo
  footerLogo: footerLogo,
  fourzerofour: fourzerofour,

  linkedin: linkedin,
  gmapProfile: gmapProfile,

  // dashboard images
  cpdIcon: cpdIcon,
  dashboardQrcode: dashboardQrcode,
  PqIcon: PqIcon,
  pendingApplication: pendingApplication,
  upcommingEvent: upcommingEvent,
  eventBookingDate: eventBookingDate,
  eventBookingLocation: eventBookingLocation,
  workshopIcon: workshopIcon,
  dummySliderImage: dummySliderImage,



  EditTable: EditTable,
  sucessIcon: sucessIcon,
  checkNot: checkNot,
  lockIcon: lockIcon,
  unLockIcon: unLockIcon,

  swapIcon: swapIcon,

  NoRecordIcon: noRecordIcon,
};
