import { FormFieldTypes } from "../../../data/formFieldType";

export const addEditMemberModelData = {
    initialState: {
        name: '',
        gmapNumber: '',
        companyName: '',
        gmapTier: '',
        areaofCompetencies: '',
        typeofProfessionalQualification: '',
        linkedin: '',
        description: '',
    },
    formFields: [
        {
            fieldType: FormFieldTypes.SEPARATOR,
            style: {
                containerCss: "separation-line col-12  pl-0 pr-0",
            },
        },
        {
            id: "name",
            lable: "Name",
            Field_Name: "Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "name",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 mb-3",
            },
        },

        {
            id: "companyName",
            lable: "Company Name",
            Field_Name: "Company Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "companyName",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 mb-3",
            },
        },
        {
            id: "gmapNumber",
            lable: "GMAP Number",
            Field_Name: "Name",
            fieldType: FormFieldTypes.INPUT,
            dataField: "gmapNumber",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 mb-3",
            },
        },

        {
            id: "gmapTier",
            lable: "GMAP Tier",
            Field_Name: "GMAP Tier",
            fieldType: FormFieldTypes.INPUT,
            dataField: "gmapTier",
            fieldSetting: {
                placeholder: "",
                options: [],
                isDisable: true,
            },
            style: {
                containerCss: "col-12 mb-3"
            }
        },

        {
            id: "areaofCompetencies",
            lable: "Area of Competencies",
            Field_Name: "Area of Competencies",
            fieldType: FormFieldTypes.SELECT,
            dataField: "areaofCompetencies",
            fieldSetting: {
                placeholder: " ",
                options: [],
                isMultiSelect: true,
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 mb-3"
            }
        },

        {
            id: "typeofProfessionalQualification",
            lable: "Type of Professional Qualification",
            Field_Name: "Type of Professional Qualification",
            fieldType: FormFieldTypes.SELECT,
            dataField: "typeofProfessionalQualification",
            fieldSetting: {
                placeholder: " ",
                options: [],
                isMultiSelect: true,
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 mb-3"
            }
        },

        {
            id: "linkedin",
            lable: "LinkedIn",
            Field_Name: "LinkedIn",
            fieldType: FormFieldTypes.INPUT,
            dataField: "linkedin",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 mb-3",
            },
        },

        {
            id: "description",
            lable: "Description",
            Field_Name: "Description",
            fieldType: FormFieldTypes.TEXTAREA,
            dataField: "description",
            fieldSetting: {
                placeholder: "",
                allowSpace: true,
                isDisable: true,
            },
            validation: [{ type: "require" }],
            style: {
                containerCss: "col-12 mb-3",
            },
        },

    ],
    formSetting: {
        isViewOnly: false
    }
}


export const addEditProfileImageData = {
    name: 'AddEditProfileImageData',
    initialState: {
        name: 'resourcesDetailsDataImageUploaderData',
    },
    formFields: [
        {
            id: "uploadFeatureImage",
            lable: '(Dimension: 260px by 130px Maximum Size: 1MB)',
            Field_Name: 'file',
            fieldType: FormFieldTypes.FILE,
            dataField: 'uploadFeatureImage',
            fieldSetting: {
                // presentFileName: null,
                placeholder: '',
                isDownloadable: false,
                acceptedFiles: '.jpg, .png',
                isDisable: true,
            },
            // validation: [{ type: "require" }],
            style: {
                containerCss: " col-md-8 col-12 mb-3 file-uploader-img"
            }
        },


    ],
    formSetting: {
        isViewOnly: false
    }
}